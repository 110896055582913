import HomePostDetail from "src/Modules/HomeModule/Components/HomePostDetail/HomePostDetail";
import Layout from "src/Modules/Layout/Layout";

function HomePostDetailPage() {
  return (
    <>
      <Layout>
        <div id="content" className={"ltr-content-padding"}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-9">
                <div className="col_selector">
                  <div className="messages pt-4 post_feed">
                    <HomePostDetail />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default HomePostDetailPage;
