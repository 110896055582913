import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { CREATE_GROUP_REQUEST } from "../Types/RequestTypes";
import { GET_MY_GROUPS } from "./useMyGroupListInfiniteQuery";

const createGroup = async (
  data: CREATE_GROUP_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createGroup,
    method: "POST",
    data: data,
  });
};

function useCreateGroupMutation() {
  return useMutation(createGroup, {
    onSuccess: (responseData) => {
      if (responseData) {
        queryClient.refetchQueries(GET_MY_GROUPS(""));
      }
    },
  });
}

export default useCreateGroupMutation;
