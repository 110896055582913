import { useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useCommentPostMutation from "../../Hooks/useCommentPostMutation";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

type POST_COMMENT_PROPS_TYPE = {
  postId: string;
};
function PostCommentInput(props: POST_COMMENT_PROPS_TYPE) {
  const { postId } = props;
  const [commentText, setCommentText] = useState("");
  const { user } = useAuthValue();
  const homePostDetailCommentMutation = useCommentPostMutation();
  const commentPostDetail = () => {
    if (commentText?.trim() === "") {
      return SnackbarHandler.errorToast("Please enter comment on input");
    }
    return homePostDetailCommentMutation?.mutate(
      {
        postId: postId,
        subId: user?.username,
        comment: commentText,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            setCommentText("");
          }
        },
      }
    );
  };
  return (
    <div className="d-flex">
      <div className="w-100 position-relative comment-Box">
        <textarea
          value={commentText}
          className="commentArea fz14 bg-transparent"
          placeholder={"Write a comment"}
          maxLength={150}
          onChange={(e: any) => setCommentText(e.target.value)}
        ></textarea>

        <button
          className="commentIcons base_color"
          onClick={commentPostDetail}
          disabled={homePostDetailCommentMutation?.isLoading}
        >
          <span>Send</span>
          <i
            className="fa fa-paper-plane  cursor-pointer fz18"
            aria-hidden="true"
          ></i>
          <VisibilityToggle visible={homePostDetailCommentMutation?.isLoading}>
            <span className="spinner-border spinner-border-sm"></span>
          </VisibilityToggle>
        </button>
      </div>
    </div>
  );
}
export default PostCommentInput;
