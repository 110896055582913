import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";
import { fetcher } from "src/Utils/Helpers";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { CREATE_GENERAL_POST_REQUEST } from "../Types/RequestTypes";
import { GET_HOME_FEED_QUERY_KEY } from "./useHomeFeedInfiniteQuery";

const createGeneralPost = async (
  data: CREATE_GENERAL_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createGeneralPost,
    method: "POST",
    data: data,
  });
};
function useCreateGeneralPostMutation() {
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(createGeneralPost, {
    onSuccess: (responseData) => {
      queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
      rewardMutation?.mutate({
        type: "POST",
        subId: user?.username,
        refId: String(responseData?.data?.id ?? ""),
        title: "POst",
      });
    },
  });
}
export default useCreateGeneralPostMutation;
