import moment from "moment";
import { useParams } from "react-router-dom";
import { groupReportComment } from "src/Modules/GroupModule/Components/GroupDetailsReportCommentModal/GroupDetailsReportCommentModal";
import { queryClient } from "src/Utils/ReactQueryConfig";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { GET_COMMENTS_QUERY_KEY } from "../../Hooks/useCommentsInfiniteQuery";
import useLikePostCommentMutation from "../../Hooks/useLikePostCommentMutation";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import './CommentItem.css'

function CommentItem(props: any) {
  const { item } = props;
  const { user } = useAuthValue();
  const params: any = useParams();

  const likePostCommentMutation = useLikePostCommentMutation();
  const likePostcomment = () => {
    if (user.username === item.subId || !user.username) {
      SnackbarHandler.errorToast("You can't like your own post.");
      return;
    }

    likePostCommentMutation.mutate(
      {
        subId: user?.username,
        commentId: item?.id,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            queryClient.setQueryData(
              GET_COMMENTS_QUERY_KEY(params?.id),
              (oldData: any) => ({
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  Items: page?.Items?.map((el: any) =>
                    el.id === item?.id
                      ? {
                          ...el,
                          is_like: responseData?.data?.is_like,
                          likes: responseData?.data?.likes,
                        }
                      : el
                  ),
                })),
              })
            );
          }
        },
      }
    );
  };

  return (
    <>
      <div className="d-flex comment-row-box">
        <div className="comment_ProfileLeftCol">
          <img
            src={item.picture !== "" ? item.picture : images.profileCamera}
            className="rounded-circle object_fit"
            width="40"
            height="40"
            alt=""
          />
        </div>
        <div className="right_Comment_Box">
          <div className="d-flex align-items-center justify-content-between">
            <h4 className="fz16 mb-1 mb-4 poppinssemibold">
              {item.name} <br />
              <i className="fz10 poppinsregular text-secondary ml-0">
                {item.timestamp
                  ? moment(new Date(item.timestamp)).fromNow()
                  : ""}
              </i>
            </h4>
          </div>
          <div className="d-flex  commentsLine_row align-items-center mb-3 border-bottom pb-2">
            <p className="fz14 poppinsregular text-secondary pl-0 mb-0 col-md-10 pr-0">
              {item.comment}
            </p>
            <span className="text-secondary like_report fz12 cursor-pointer col-md-2">
              <i
                className={`fa fz14 ${
                  item.is_like ? "fa-heart post-like" : "fa-heart-o base_color"
                }`}
                aria-hidden="true"
                onClick={() => likePostcomment()}
              ></i>
              <span
                className="ml-2 exclamation "
                onClick={() => groupReportComment(item)}
              >
                <i
                  className={`fa fa-exclamation base_color`}
                  aria-hidden="true"
                ></i>
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
export default CommentItem;
