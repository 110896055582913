import logo from "src/assets/images/logo.png";
import usFlag from "src/assets/images/usa@2x.png";
import koFlag from "src/assets/images/franch@2x.png";
import whiteLogo from "src/assets/images/new-white-logo.png";
import homeBanner from "src/assets/images/home_element.png";
import tick from "src/assets/images/tick.png";
import lock from "src/assets/images/lock.png";
import proven from "src/assets/images/proven.png";
import stronger from "src/assets/images/stronger.png";
import neighbour from "src/assets/images/neighbour.png";
import sharegood from "src/assets/images/sharegood.png";
import homeActiveIco from "src/assets/images/homeactive@2x.png";
import homeInActiveIco from "src/assets/images/homeinactive@2x.png";
import messageActiveIco from "src/assets/images/icons/messages_active.png";
import messageInActiveIco from "src/assets/images/messagesinactive@2x.png";
import groupActiveIco from "src/assets/images/icons/groups_active.png";
import groupInActiveIco from "src/assets/images/groupsinactive@2x.png";
import saleActiveIco from "src/assets/images/icons/sale_active.png";
import saleInActiveIco from "src/assets/images/saleinactive@2x.png";
import businessActiveIco from "src/assets/images/icons/business_active.png";
import businessInActiveIco from "src/assets/images/businessinactive@2x.png";
import profileCamera from "src/assets/images/profile-camera.png";
import userIco from "src/assets/images/user.png";
import myPostIco from "src/assets/images/mypost.png";
import hiddenPostIco from "src/assets/images/hidden-post.png";
import bookMarkIco from "src/assets/images/bookmark.png";
import settingIco from "src/assets/images/settings.png";
import logoutIco from "src/assets/images/logout.png";
import locationDark from "src/assets/images/icons/location-dark.png";
import upChervon from "src/assets/images/icons/up-chevron.png";
import sellIco from "src/assets/images/icons/sellIcon.png";
import pollIco from "src/assets/images/icons/poll.png";
import photoIco from "src/assets/images/icons/photoIcon.png";
import safetyIco from "src/assets/images/icons/safety.png";
import recomendIco from "src/assets/images/icons/recomend.png";
import plusIco from "src/assets/images/icons/plusIcon.png";
import deleteIco from "src/assets/images/icons/deleteIcon.png";
import leftBackArrow from "src/assets/images/icons/leftBackArrow.png";
import pinLocationIco from "src/assets/images/icons/pinLocation.png";
import emailIco from "src/assets/images/icons/email.png";
import callIco from "src/assets/images/icons/callicon.png";
import crossIcon from "src/assets/images/icons/crossBtnIcon.png";
import removeIco from "src/assets/images/icons/removeIcon.png";
import rightArrow from "src/assets/images/icons/rightArrowBtn.png";
import leftAddPhoto from "src/assets/images/icons/photo_categ.png";
import eventCamera from "src/assets/images/icons/cameraIcon.png";
import dateIco from "src/assets/images/icons/dateicon.png";
import timeIco from "src/assets/images/icons/time_Icon.png";
import bulbImg from "src/assets/images/icons/bulb.png";
import profileIco from "src/assets/images/icons/profile.png";
import groupIco from "src/assets/images/grpIcon.png";
import editGroup from "src/assets/images/icons/edit_group.png";
import leaveGroup from "src/assets/images/icons/leave_group.png";
import hotelImg from "src/assets/images/hotel.png";
import delIconOption from "src/assets/images/icons/deleteIconoption.svg";
import cloudIcon from "src/assets/images/icons/cloud-icon.png";
import dummyProfile from "src/assets/images/icons/dummy-profile.png";
import clockIcon from "src/assets/images/icons/clockIcon.svg";
import menuImg from "src/assets/images/icons/menu.svg";
import allListIcon from "src/assets/images/icons/all_list_icon.png";
import productFound from "src/assets/images/icons/product-found.png";
import LogoutBlue from "src//assets/images/icons/LogoutBlue.png";
import currentlocation from "src/assets/images/icons/currentlocation.png";
import editBtn from "src/assets/images/icons/editBtn.png";
import aboutIco from "src/assets/images/about@2x.png";
import faqIco from "src/assets/images/faq@2x.png";
import termsIco from "src/assets/images/term@2x.png";
import privacyIco from "src/assets/images/privacy@2x.png";
import contactIco from "src/assets/images/contact@2x.png";
import language from "src/assets/images/language@2x.png";
import safetyIcon from "src/assets/images/icons/safetyicon.png";
import pinIcon from "src/assets/images/icons/pingreyIcon.png";
import dategreyicon from "src/assets/images/icons/date-grey-icon.png";
import businessPlaceholder from "src/assets/images/no_business.svg";
import resturantImg from "src/assets/images/resturant.png";
import groupPlaceholder from "src/assets/images/group_placeholder.png";
import korenFlag from "src/assets/images/koreanFlag.png";
import aboutImg from "src/assets/images/aboutbanner.png";
import nodataFound from "src/assets/images/nodata.svg";
import rewardIcon from "src/assets/images/rewardIcon.png";
import timelineDot from "src/assets/images/timelineDot.png";
import filterIcon from "src/assets/images/filterIcon.png";
import shopimage from "src/assets/images/shopimage.png";
import shopifyImg from "src/assets/images/shopifyImg.png";
import menuIcon from "src/assets/images/menuIcon.png";
import productItemfirst from "src/assets/images/productItemfirst.png";
import productItemImg from "src/assets/images/productItemImg.png";
import productItemlast from "src/assets/images/productItemlast.png";
import shopifyImgplaceholder from "src/assets/images/shopifyImgplaceholder.png";
import shopActiveIco from "src/assets/images/shopActiveIco.png";
import shopInActiveIco from "src/assets/images/shopInActiveIco.png";
import rewardActiveIco from "src/assets/images/rewardActiveIco.png";
import rewardInActiveIco from "src/assets/images/rewardInActiveIco.png";
import backArrowImg from "src/assets/images/backArrow.png";
import cartInactiveImg from "src/assets/images/cartInactive.png";
import cartActiveImg from "src/assets/images/cartActive.png";
import rewardIconNew from "src/assets/images/rewardIconNew.png";

const images = {
  logo,
  timelineDot,
  rewardIconNew,
  shopifyImgplaceholder,
  shopActiveIco,
  shopInActiveIco,
  rewardActiveIco,
  rewardInActiveIco,
  productItemfirst,
  productItemImg,
  productItemlast,
  menuIcon,
  shopifyImg,
  shopimage,
  filterIcon,
  rewardIcon,
  nodataFound,
  groupPlaceholder,
  aboutImg,
  korenFlag,
  usFlag,
  resturantImg,
  businessPlaceholder,
  pinIcon,
  safetyIcon,
  dategreyicon,
  koFlag,
  whiteLogo,
  homeBanner,
  tick,
  lock,
  proven,
  stronger,
  hotelImg,
  neighbour,
  sharegood,
  homeActiveIco,
  homeInActiveIco,
  messageActiveIco,
  messageInActiveIco,
  groupActiveIco,
  groupInActiveIco,
  saleActiveIco,
  saleInActiveIco,
  businessActiveIco,
  businessInActiveIco,
  profileCamera,
  userIco,
  myPostIco,
  hiddenPostIco,
  bookMarkIco,
  settingIco,
  logoutIco,
  locationDark,
  upChervon,
  sellIco,
  pollIco,
  photoIco,
  safetyIco,
  recomendIco,
  plusIco,
  deleteIco,
  leftBackArrow,
  pinLocationIco,
  emailIco,
  callIco,
  crossIcon,
  removeIco,
  rightArrow,
  leftAddPhoto,
  eventCamera,
  dateIco,
  timeIco,
  bulbImg,
  profileIco,
  groupIco,
  editGroup,
  leaveGroup,
  delIconOption,
  cloudIcon,
  dummyProfile,
  clockIcon,
  menuImg,
  allListIcon,
  productFound,
  LogoutBlue,
  currentlocation,
  editBtn,
  aboutIco,
  faqIco,
  termsIco,
  privacyIco,
  contactIco,
  language,
  backArrowImg,
  cartInactiveImg,
  cartActiveImg,
};

export default images;
