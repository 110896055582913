import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useNotificationInfinteQuery, {
  extractNotificationList,
} from "../../Hooks/useNotificationInfinteQuery";
import NotificationItem from "../NotificationItem/NotificationItem";
import "./NotificationList.css";

function NotificationList() {
  const notificationInfiniteQuery = useNotificationInfinteQuery();

  const notificationList = useMemo(
    () => extractNotificationList(notificationInfiniteQuery?.data?.pages ?? []),
    [notificationInfiniteQuery?.data?.pages]
  );
  const { refetch, hasNextPage, fetchNextPage } = notificationInfiniteQuery;

  console.log("List Notification", notificationList);

  return (
    <>
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 post_feed notification_Box">
                <h2 className="fz20 poppinssemibold notification_heading">
                  Notification
                </h2>
                <ul className={"notificationData"}>
                  <InfiniteScroll
                    dataLength={notificationList?.length} //This is important field to render the next data
                    next={() => {
                      // if (hasNextPage) {
                      fetchNextPage();
                      // }
                    }}
                    hasMore={true}
                    loader={
                      <VisibilityToggle
                        visible={notificationInfiniteQuery?.isLoading}
                      >
                        <span className="spinner-border spinner-border-lg"></span>
                      </VisibilityToggle>
                    }
                    refreshFunction={refetch}
                    pullDownToRefresh
                    // pullDownToRefreshThreshold={10}
                  >
                    {notificationList?.map(
                      (notification: any, index: number) => {
                        return (
                          <NotificationItem
                            item={notification}
                            key={`notification-${index}`}
                          />
                        );
                      }
                    )}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
}

export default NotificationList;
