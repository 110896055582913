import { Fragment } from "react";
import Layout from "src/Modules/Layout/Layout";
import MyCart from "src/Modules/ShopsModule/Components/MyCart/MyCart";

function MyCartPage() {
  return (
    <Fragment>
      <Layout>
        <MyCart />
      </Layout>
    </Fragment>
  );
}
export default MyCartPage;
