import { Link } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";

function NavBar() {
  const { language, toggleMenu } = useAuthValue();
  return (
    <nav className="navbar navbar-expand-lg navbar-light pt-4">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <div>
            <img
              src={images.logo}
              className="img-fluid"
              alt="Bloom Logo"
              style={{ width: 120 }}
            />
          </div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => {
            setAuthValue({
              ...getAuthValue(),
              toggleMenu: !toggleMenu,
            });
          }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse custom-navbar-mbl ${
            toggleMenu ? "show" : ""
          }`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto fz16 align-items-lg-center">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                {strings.home}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                {strings.about}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/privacy-policy">
                {strings.privacy_policy}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/terms-and-condition">
                {strings.terms_and_conditions}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/faq">
                {strings.faq}
              </Link>
            </li>
            <li className="nav-item ml-0">
              <Link to="/login">
                <button className="btn custom_btn">{strings.login}</button>
              </Link>
            </li>
            <li>
              <div className="slider_down_arrow position-relative ml-3 notification_part">
                <span className="lang_icon">
                  <img
                    src={language !== "en" ? images.korenFlag : images.usFlag}
                    className="mr-4"
                    height="25"
                    width="25"
                    alt="korenFlag"
                  />
                  <i
                    className="fa fa-sort-desc fz18 pb-2"
                    title="Account"
                    aria-hidden="true"
                  >
                    {" "}
                  </i>
                </span>
                <div className="user_actions user_actions_posLTR">
                  <ul className="list-unstyled mb-0 poppinsbold profile-list">
                    <li
                      className="cursor-pointer m-3"
                      onClick={() =>
                        setAuthValue({
                          ...getAuthValue(),
                          language: "en",
                        })
                      }
                    >
                      <img
                        src={images.usFlag}
                        className="mr-4"
                        height="25"
                        width="25"
                        alt="logo"
                      />
                      {strings.english}
                    </li>
                    <li
                      className="cursor-pointer m-3"
                      onClick={() =>
                        setAuthValue({
                          ...getAuthValue(),
                          language: "ko",
                        })
                      }
                    >
                      <img
                        src={images.korenFlag}
                        className="mr-4"
                        height="25"
                        width="25"
                        alt="logo"
                      />
                      {strings.korean}
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
