import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import useAuthValue, { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_GROUP_ADMIN_LIST_KEY = (groupId: any) => ["adminKey", groupId];

const getAdmins = async (groupId: any): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.groupMembers}?subId=${user?.username}&groupId=${groupId}&type=admins`,
    method: "GET",
  });
};
function useGroupAdminMembersQuery() {
  const params = useParams();
  return useQuery(GET_GROUP_ADMIN_LIST_KEY(params?.id), () =>
    getAdmins(params?.id)
  );
}

export default useGroupAdminMembersQuery;
