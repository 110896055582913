import { Auth } from "aws-amplify";
import { useCallback, useState } from "react";
// import { useQueryCache } from "react-query";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import axios from "axios";
import EndPoints from "src/Constants/EndPoints";
import { useNavigate } from "react-router-dom";
import { getAuthValue, setAuthValue } from "./useAuthValue";
import useUpdateLanguageMutation from "src/Modules/Layout/Hooks/useUpdateLanguageMutation";

function useAuth() {
  const [loading, setLoading] = useState(false);
  const [isVerificationVisible, setIsVerificationVisible] = useState(false);
  const [forgetPasswordVisible, setForgetPasswordVisible] = useState(false);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const updateLanguageMutation = useUpdateLanguageMutation();
  // const queryCache = useQueryCache();
  const navigate = useNavigate();

  const login = useCallback(({ email, password }: any) => {
    setLoading(true);
    Auth.signIn({
      username: email,
      password: password,
    })
      .then(async (res) => {
        setLoading(false);
        console.log("sign-in successfuly", res);
        // queryCache.clear();
        const data = {
          subId: res?.username,
          city_id: res?.attributes["custom:city_id"],
          state_id: res?.attributes["custom:state_id"],
        };
        const getProfileDetails: any = await axios.post(
          `${EndPoints.editProfile}`,
          data
        );
        const profileObj = {
          attributes: null,
          username: null,
        };
        profileObj.attributes = {
          ...res?.attributes,
          ...getProfileDetails?.data?.Items[0],
          username: getProfileDetails?.data?.Items[0]?.subId,
        };
        profileObj.username = getProfileDetails?.data?.Items[0]?.subId;
        // AppStateHandler.loginUser(profileObj);
        setAuthValue({
          ...getAuthValue(),
          loggedIn: true,
          user: profileObj,
        });
        const { user, language } = getAuthValue();
        updateLanguageMutation.mutate({
          lang: language === "ko" ? "kr" : language,
          subId: user?.username,
        });
        navigate("/home");
      })
      .catch((err) => {
        setLoading(false);
        console.log("error in signing in", err);
        SnackbarHandler.errorToast(err?.message);
        if (err.code === "UserNotConfirmedException") {
          Auth.resendSignUp(email);
          setIsVerificationVisible(true);
        }
      });
  }, []);

  const verify = useCallback(
    (email: string, verificationCode: string, cb: any) => {
      setVerificationLoading(true);
      Auth.confirmSignUp(email, verificationCode)
        .then((res) => {
          console.log("verification successful", res);
          SnackbarHandler.successToast("User verified successfully");
          setIsVerificationVisible(false);
          setVerificationLoading(false);
          cb();
        })
        .catch((err) => {
          console.log("not verified", err);
          SnackbarHandler.errorToast(err.message);
          setVerificationLoading(false);
        });
    },
    []
  );

  const forgetPassword = useCallback(async ({ email }: any) => {
    console.log("EMAIL FORGET", email);
    try {
      setLoading(true);
      const response = await Auth.forgotPassword(email);
      SnackbarHandler.successToast(
        "Verfication code is sent to your email for resetting password."
      );
      setLoading(false);
      console.log(response);
      setChangePasswordVisible(true);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error.code === "LimitExceededException") {
        SnackbarHandler.errorToast(error.message);
      }
    }
  }, []);

  const changePassword = useCallback(
    async ({ password, verificationCode }: any, email: string) => {
      try {
        setLoading(true);
        const response = await Auth.forgotPasswordSubmit(
          email,
          verificationCode,
          password
        );
        setLoading(false);
        console.log(response);
        SnackbarHandler.successToast(
          "Password changed successfully. Please login."
        );
        setForgetPasswordVisible(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error);
        if (error.code === "CodeMismatchException") {
          SnackbarHandler.errorToast(error.message);
        }
      }
    },
    []
  );

  const signup = useCallback(
    ({
      email,
      password,
      name,
      lastName,
      phone,
      state_id,
      city_id,
      address,
    }: any) => {
      setLoading(true);
      Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:name": name,
          "custom:family_name": lastName,
          email: email,
          "custom:phone_number": `+91${phone}`,
          "custom:state_id": `${state_id}`,
          "custom:city_id": `${city_id}`,
          "custom:address": address,
        },
      })
        .then((res) => {
          setLoading(false);
          console.log("Signed up---", res);
          setIsVerificationVisible(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          SnackbarHandler.errorToast(err.message);
        });
    },
    []
  );

  return {
    login,
    signup,
    verify,
    loading,
    isVerificationVisible,
    setIsVerificationVisible,
    verificationLoading,
    forgetPasswordVisible,
    setForgetPasswordVisible,
    forgetPassword,
    changePasswordVisible,
    setChangePasswordVisible,
    changePassword,
  };
}

export default useAuth;
