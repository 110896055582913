import { getCartValue, setCartValue } from "../../Hooks/useAddToCartValue";
import { CART } from "../../Types/CommonTypes";
import "./MyCartItem.css";

type MY_CART_ITEM_PROPS = {
  item: CART;
};

function MyCartItem(props: MY_CART_ITEM_PROPS) {
  const { item } = props;

  const decrement = () => {
    const myCart = [...getCartValue()];
    var quantity = item?.quantity - 1;
    const idx = myCart.findIndex((el) => el?.variantId === item?.variantId);
    if (quantity === 0) {
      myCart.splice(idx, 1);
      setCartValue(myCart);
    } else {
      const curObj = { ...myCart[idx] };
      myCart[idx] = { ...curObj, quantity: quantity };
      setCartValue(myCart);
    }
  };

  const increment = () => {
    const myCart = [...getCartValue()];
    var quantity = item?.quantity + 1;
    const idx = myCart.findIndex((el) => el?.variantId === item?.variantId);
    const curObj = { ...myCart[idx] };
    myCart[idx] = { ...curObj, quantity: quantity };
    setCartValue(myCart);
  };

  return (
    <>
      <div className="d-flex my_cart_item">
        <div className="my_cart_item_imgBx">
          <img src={item?.image} alt={"jhjk"} />
        </div>
        <div className="my_cart_item_info">
          <h6>$ {item?.price}</h6>
          <p>{item?.title}</p>
          <p className="my_cart_item_product_cat">{item?.productType}</p>
        </div>
        <div className="my_cart_item_quantity_bx">
          <div className="qunatityBx">
            <button onClick={decrement} className="minusBtn">
              -
            </button>
            <span>{item?.quantity}</span>
            <button onClick={increment} className="plusBtn">
              +
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default MyCartItem;
