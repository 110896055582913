import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import { emitEvent } from "../../../../Hooks/Shared/useEventEmmiter";
import useDeletePostMutation from "src/Modules/HomeModule/Hooks/useDeletePostMutation";
import { useParams, useNavigate } from "react-router-dom";
import { queryClient } from "../../../../Utils/ReactQueryConfig";
import { GET_SALE_LIST_QUERY_KEY } from "../../Hooks/useSaleInfiniteQuery";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const DELETE_SALE_POST_EVENT = "deleteSalePostEvent";

export const openDeleteSalePostModal = (data: any) => {
  emitEvent(DELETE_SALE_POST_EVENT, data);
};

function DeleteSalePostModal(props: any) {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEventEmitter(DELETE_SALE_POST_EVENT, (data) => {
    setShowModal(true);
  });
  const { user } = useAuthValue();
  const params = useParams();
  const navigate = useNavigate();

  const deletePostMutation = useDeletePostMutation();
  const deletePost = () => {
    deletePostMutation?.mutate(
      {
        postId: Number(params?.id),
        subId: user?.username,
      },
      {
        onSuccess: (res) => {
          if (res) {
            navigate("/sales");
            queryClient.refetchQueries(GET_SALE_LIST_QUERY_KEY("", undefined));
          }
        },
      }
    );
  };
  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg create-group-modal delete_Modal">
          <div className="modal-content border-0 Delete_Modal">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <img src={images.deleteIco} alt="icon" />
              <h4 className="fz22 my-4">{strings.del_para}</h4>
              <div className="row justify-content-center ">
                <button
                  className="cancel_CustomBtn"
                  onClick={() => setShowModal(false)}
                >
                  {strings.no}
                </button>
                <button className="save_CustomBtn" onClick={deletePost}>
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default DeleteSalePostModal;
