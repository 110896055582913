import { Fragment } from "react";
import ChatHistory from "src/Modules/ChatModule/Components/ChatHistory/ChatHistory";
import Layout from "src/Modules/Layout/Layout";

function MessageHistoryPage() {
  return (
    <Fragment>
      <Layout>
        <ChatHistory />
      </Layout>
    </Fragment>
  );
}
export default MessageHistoryPage;
