import React from 'react'
import AddAudioVideo from 'src/Modules/HomeModule/Components/AddAudioVideo/AddAudioVideo'
import Layout from 'src/Modules/Layout/Layout'

function CreatePost() {
  return (
    <Layout>
        <AddAudioVideo />
    </Layout>
  )
}

export default CreatePost