import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_HOME_POST_DETAILS_KEY = (postId: number, groupId: number) => [
  "groupPostDetailkey",
  postId,
  groupId,
];

const groupPostDetails = async (
  postId: number,
  groupId: number
): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.postDetail}?subId=${user?.username}&postId=${postId}&groupId=${groupId}`,
    method: "GET",
  });
};
function useCreateGroupPostDetailsQuery() {
  const params = useParams();
  console.log(params, "paramsparams");
  return useQuery(
    GET_HOME_POST_DETAILS_KEY(Number(params?.postId), Number(params?.id)),
    () => groupPostDetails(Number(params?.postId), Number(params?.id))
  );
}

export default useCreateGroupPostDetailsQuery;
