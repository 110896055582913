import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import NavBar from "src/Components/Custom/NavBar/NavBar";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useAuth from "src/Hooks/Custom/useAuth";
import Footer from "src/Components/Custom/Footer/Footer";
import CopyRight from "src/Components/Custom/CopyRight/CopyRight";
import VerficationModal from "src/Components/Custom/VerificationModal/VerificationModal";
import images from "src/Utils/images";
import { strings } from "src/Utils/Localization";
import useStateListQuery from "src/Hooks/Shared/useStateListQuery";
import useCityListQuery from "src/Hooks/Shared/useCityListQuery";
import AddCommunityPopup from "src/Modules/Layout/AddCommunityPopup/AddCommunityPopup";

function SignupPage() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
      state: "",
      city: "",
    },
    mode: "onChange",
  });
  const navigate = useNavigate();
  const [refreshAutoComplete, setRefreshAutoComplete] =
    useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<string>("");
  // const [userLocation, setUserLocation] = useState<any>({
  //   state: null,
  //   city: null,
  // });
  // const [stateCityIds, setStateCityIds] = useState({
  //   stateId: null,
  //   cityId: null,
  // });

  const [checkTermsCondition, setCheckTermsCondition] =
    useState<boolean>(false);
  const [showCommunityPopup, setShowCommunityPopup] = useState<boolean>(false);
  const [communityLatLng, setCommunityLatLng] = useState<any>({});

  useEffect(() => {
    if (watch("state")) {
      setValue("city", "");
    }
  }, [setValue, watch]);
  const {
    signup,
    loading,
    verificationLoading,
    verify,
    isVerificationVisible,
    setIsVerificationVisible,
  } = useAuth();

  // const verifyStateCityMutation = useVerifyStateCityMutation();

  const onSubmit = handleSubmit(async (params: any) => {
    if (Object.keys(communityLatLng).length === 0) {
      return SnackbarHandler.errorToast(strings.select_street_address);
    }
    signup({
      ...params,
      state_id: communityLatLng?.lat,
      city_id: communityLatLng.lng,
      address: "",
    });
  });

  const GOOGLE_API_KEY = "AIzaSyAw0B6V6MVtGO3jD3U7qMgZV5BGrv_sS7M";

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const xyz = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${GOOGLE_API_KEY}`
        );

        console.log("Google api response", xyz);

        xyz.data.results?.map((item: any, index: number) => {
          if (item.types[0] === "establishment") {
            setCurrentLocation(item.formatted_address);
          }
          if (item.types[0] === "administrative_area_level_1") {
            console.log("State", item.address_components[0]?.long_name);
            // setUserLocation((prevState: any) => {
            //   return {
            //     ...prevState,
            //     state: item.address_components[0]?.long_name,
            //   };
            // });
          }
          if (item.types[0] === "locality") {
            console.log("City", item.address_components[0]?.long_name);
            // setUserLocation((prevState: any) => {
            //   return {
            //     ...prevState,
            //     city: item.address_components[0]?.long_name,
            //   };
            // });
          }
          setRefreshAutoComplete(!refreshAutoComplete);
        });
      });
    } else {
      SnackbarHandler.errorToast("Please enable location permission");
    }
  };

  return (
    <Fragment>
      <header className="">
        <NavBar />
      </header>
      <section className="login_section login_header">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="col_selector"></div>
            </div>
            <div className="col-md-5">
              <div className="col_selector">
                <h2 className="poppinssemibold mb-2 text-right">
                  {strings.account_setup}
                </h2>
                <form className="poppinsregular">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name ? "is-invalid" : ""
                        }`}
                        placeholder={strings.first_name}
                        // name={"name"}
                        {...register("name", {
                          required: {
                            value: true,
                            message: strings.first_name_is_required,
                          },
                        })}
                      />
                      <VisibilityToggle visible={errors.name ? true : false}>
                        <p className="text-danger">{errors.name?.message}</p>
                      </VisibilityToggle>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.lastName?.message ? "is-invalid" : ""
                        }`}
                        placeholder={strings.last_name}
                        // name={"lastName"}
                        {...register("lastName", {
                          required: {
                            value: true,
                            message: strings.last_name_is_required,
                          },
                        })}
                      />
                      <VisibilityToggle
                        visible={errors.lastName?.message ? true : false}
                      >
                        <p className="text-danger">
                          {errors.lastName?.message}
                        </p>
                      </VisibilityToggle>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="number"
                        className={`form-control ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder={strings.phone_number}
                        {...register("phone", {
                          required: {
                            value: true,

                            message: strings.phone_number_is_required,
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message: strings.phone_number_is_invalid,
                          },
                        })}
                        // name={"phone"}
                      />
                      <VisibilityToggle visible={errors.phone ? true : false}>
                        <p className="text-danger">{errors.phone?.message}</p>
                      </VisibilityToggle>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder={strings.email}
                        // name={"email"}
                        {...register("email", {
                          required: {
                            value: true,
                            message: strings.email_is_required,
                          },
                          pattern: {
                            value:
                              /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                            message: strings.email_address_is_invalid,
                          },
                        })}
                      />
                      <VisibilityToggle visible={errors.email ? true : false}>
                        <p className="text-danger">{errors.email?.message}</p>
                      </VisibilityToggle>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="password"
                        className={`form-control ${
                          errors.password ? "is-invalid" : ""
                        }`}
                        placeholder={strings.password}
                        // name={"password"}
                        {...register("password", {
                          required: {
                            value: true,
                            message: strings.password_is_required,
                          },
                          pattern: {
                            value:
                              /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!*^?]/,
                            message: strings.pswd_mssg,
                          },
                        })}
                      />
                      <VisibilityToggle
                        visible={errors.password ? true : false}
                      >
                        <p className="text-danger">
                          {errors.password?.message}
                        </p>
                      </VisibilityToggle>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="password"
                        className={`form-control ${
                          errors.confirm_password ? "is-invalid" : ""
                        }`}
                        placeholder={strings.confirm_password}
                        // name={"confirm_password"}
                        {...register("confirm_password", {
                          required: {
                            value: true,
                            message: strings["confirm_pwd-reqd"],
                          },
                          validate: (val: string) => {
                            if (watch("password") !== val) {
                              return strings.your_pwd_do_not_match;
                            }
                          },
                        })}
                      />
                      <VisibilityToggle
                        visible={errors.confirm_password ? true : false}
                      >
                        <p className="text-danger">
                          {errors.confirm_password?.message}
                        </p>
                      </VisibilityToggle>
                    </div>
                  </div>

                  {/* Add Community Popup */}
                  <div className="form-row neighhbourhood_row">
                    <div className="col-sm-12 position-relative">
                      <input
                        type="text"
                        className="form-control pr-5"
                        placeholder={strings.select_community}
                        onClick={() => setShowCommunityPopup(true)}
                        value={currentLocation}
                        aria-hidden="true"
                        readOnly
                      />

                      <i className="fa fa-search"></i>
                    </div>
                  </div>
                  <div className="form-check pt-2">
                    <label className="customCheckBox" htmlFor="agree">
                      <p className="mb-0 fz12">
                        {strings.by_signing_up}{" "}
                        <Link to="/terms-and-condition">
                          {strings.comp_terms_of_service}
                        </Link>{" "}
                        {strings.and}{" "}
                        <Link to="/privacy-policy">
                          {strings.privacy_policy_full_stop}
                        </Link>
                      </p>
                      <input
                        type="checkbox"
                        id="agree"
                        onChange={(e) =>
                          setCheckTermsCondition(e?.target?.checked)
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="form-row align-items-center signBtn_row">
                    <div className="form-group col-md-6">
                      <button
                        disabled={loading || !checkTermsCondition}
                        className="btn custom_btn w-100"
                        onClick={onSubmit}
                      >
                        <VisibilityToggle visible={loading}>
                          <span className="spinner-border spinner-border-sm"></span>
                          &nbsp;
                        </VisibilityToggle>
                        {strings.sign_up}
                      </button>
                    </div>
                    <div className="col-md-6">
                      <p className="text-right fz14 poppinsregular">
                        {strings.already_have_an_account}
                        <Link
                          to={"/login"}
                          className="font-weight-bold ml-1 login-text"
                        >
                          {strings.login}
                        </Link>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="location_modal"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title poppinssemibold">
                {strings.select_a_location}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group_custom position-relative">
                <Autocomplete
                  apiKey={GOOGLE_API_KEY}
                  inputMode="search"
                  key={refreshAutoComplete.toString()}
                  defaultValue={currentLocation}
                  placeholder={strings.search_your_location}
                  onPlaceSelected={(place) => {
                    console.log("Places", place.address_components);

                    place.address_components.map((item: any, index: number) => {
                      if (item.types[0] === "administrative_area_level_1") {
                        console.log("State", item.long_name);
                        // setUserLocation((prevState: any) => {
                        //   return {
                        //     ...prevState,
                        //     state: item.long_name,
                        //   };
                        // });
                      }
                      if (item.types[0] === "locality") {
                        console.log("City", item.long_name);
                        // setUserLocation((prevState: any) => {
                        //   return {
                        //     ...prevState,
                        //     city: item.long_name,
                        //   };
                        // });
                      }
                    });
                  }}
                  options={{
                    types: ["address"],
                  }}
                />
                <i className="fa fa-search " aria-hidden="true"></i>
              </div>
              <button
                className="location_button pt-3 mb-0 border-0 bg-transparent"
                onClick={getCurrentLocation}
              >
                <img src={images.currentlocation} alt="current_location" />
                <span className="curent_location fz13 poppinsmedium pl-2">
                  {strings.use_current_location}
                </span>
              </button>

              {currentLocation !== "" && (
                <div className="text-center mt-3">
                  <button
                    className="btn saveBtn"
                    onClick={() => setShowModal(false)}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <Footer />
      <CopyRight />
      <VerficationModal
        email={watch("email")}
        cb={() => navigate("/login")}
        verificationLoading={verificationLoading}
        verify={verify}
        isVerificationVisible={isVerificationVisible}
        setIsVerificationVisible={setIsVerificationVisible}
      />
      <AddCommunityPopup
        isOpenPopup={showCommunityPopup}
        popupClose={() => setShowCommunityPopup(false)}
        setCommunityLatLng={setCommunityLatLng}
      />
    </Fragment>
  );
}

export default SignupPage;
