import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import images from "src/Utils/images";
import { strings } from "src/Utils/Localization";
import useGroupAdminMembersQuery from "../../Hooks/useGroupAdminMembersQuery";
import { openGroupRemoveAdminModal } from "../ConfirmGroupModals/GroupAdminRemoveConfirmModal/GroupAdminRemoveConfirmModal";

type GROUP_MEMBER_PROPS = {
  isAdmin: boolean;
};
function GroupAdminMembers(props: GROUP_MEMBER_PROPS) {
  const { isAdmin } = props;
  const { user } = useAuthValue();
  const { data, isLoading } = useGroupAdminMembersQuery();
  const groupAdmins = data?.data?.Items ?? [];
  return (
    <>
      {groupAdmins?.length !== 0 && (
        <div
          style={{
            color: "gray",
            marginBottom: "10px",
            fontSize: "12px",
          }}
        >
          {groupAdmins?.length > 1 ? strings.admins : strings.admin}
        </div>
      )}
      {groupAdmins?.map((a: any, i: number) => {
        return (
          <div key={i}>
            <div
              className={`d-flex  profileBox_Row position-relative align-items-center pl-0`}
            >
              <div
                className={`message_user_icon message_user_icon_posLTR position-relative`}
              >
                <img
                  src={a.user_image !== "" ? a.user_image : images.profileIco}
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
              <h4
                className={`fz16 mb-1 pl-3 mb-0 poppinsmedium min_height text-break rightProfileName`}
              >
                {a?.user_name}
              </h4>
              <VisibilityToggle visible={isAdmin && a.subId !== user?.username}>
                <div className="slider_down_arrow position-relative">
                  <i
                    className="fa fa-angle-down fz26 mr-4"
                    aria-hidden="true"
                  ></i>
                  <div className={`user_actions user_actions_posLTR`}>
                    <ul className={`list-unstyled text-left mb-0 poppinsbold`}>
                      <li>
                        <a
                          className="py-4 text-danger"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            openGroupRemoveAdminModal(a.id);
                          }}
                        >
                          <i
                            className="fa fa-user-times mr-4 fz18"
                            aria-hidden="true"
                          ></i>
                          {/* <VisibilityToggle visible={isLoading}>
                          <span className="spinner-border spinner-border-sm"></span>
                        </VisibilityToggle> */}
                          {strings.remove}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </VisibilityToggle>
            </div>

            <hr />
          </div>
        );
      })}
    </>
  );
}

export default GroupAdminMembers;
