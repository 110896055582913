import { useQuery } from "react-query";
import { fetcherUrl } from "src/Utils/Helpers";

export const GET_ORDER_LISTING_QUERY = ["getOrderListing"];

const getOrderListing = async (query: string) => {
  const response = await fetcherUrl({
    baseURL: `https://bloomyourcity-dev.myshopify.com/api/graphql`,
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": "e60d9567f7b96d3b7d8a08a528ae1e9a",
    },
    data: query,
  });
  return response?.data?.data;
};
function useOrderListingQuery(data: string) {
  return useQuery(GET_ORDER_LISTING_QUERY, () => getOrderListing(data));
}

export default useOrderListingQuery;
