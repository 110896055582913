import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import { emitEvent } from "../../../../Hooks/Shared/useEventEmmiter";
import { useParams, useNavigate } from "react-router-dom";
import useDeleteBusinessPostMutation from "../../Hooks/useDeleteBusinessPostMutation";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const DELETE_BUSINESS_POST_EVENT = "deleteBusinessPostEvent";

export const openDeleteBusinessPostModal = () => {
  emitEvent(DELETE_BUSINESS_POST_EVENT);
};

function BusinessPostDeleteModal() {
  const [showModal, setShowModal] = useState<boolean>(false);

  const { user } = useAuthValue();
  const params = useParams();
  const navigate = useNavigate();
  useEventEmitter(DELETE_BUSINESS_POST_EVENT, () => {
    setShowModal(true);
  });

  const deleteBusinessPostMutation = useDeleteBusinessPostMutation();
  const handleDeleteBusinessPost = () => {
    deleteBusinessPostMutation.mutate(
      {
        postId: Number(params?.id),
        subId: user?.username,
      },
      {
        onSuccess: (res: any) => {
          if (res) {
            navigate("/business");
          }
        },
      }
    );
  };
  //   const deletePostMutation = useDeletePostMutation();
  //   const deletePost = () => {
  //     deletePostMutation?.mutate(
  //       {
  //         postId: Number(params?.id),
  //         subId: user?.username,
  //       },
  //       {
  //         onSuccess: (res) => {
  //           if (res) {
  //             navigate("/sales");
  //             queryClient.refetchQueries(GET_SALE_LIST_QUERY_KEY(""));
  //           }
  //         },
  //       }
  //     );
  //   };
  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg create-group-modal delete_Modal">
          <div className="modal-content border-0 Delete_Modal">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <img src={images.deleteIco} alt="icon" />
              <h4 className="fz22 my-4">{strings.del_para}</h4>
              <div className="row justify-content-center ">
                <button
                  className="cancel_CustomBtn"
                  onClick={() => setShowModal(false)}
                >
                  {strings.no}
                </button>
                <button
                  className="save_CustomBtn"
                  onClick={handleDeleteBusinessPost}
                >
                  <VisibilityToggle
                    visible={deleteBusinessPostMutation.isLoading}
                  >
                    <span className="spinner-border spinner-border-sm"></span>
                    &nbsp; &nbsp;
                  </VisibilityToggle>
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default BusinessPostDeleteModal;
