import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { of } from "rxjs";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import { GET_MY_BOOKMARKS } from "src/Modules/BookmarksModule/Hooks/useBookmarkPostsListInfiniteQuery";
import { GET_HIDDEN_POSTS } from "src/Modules/HiddenPostsModule/Hooks/useHiddenPostsInfiniteQuery";
import { GET_HOME_FEED_QUERY_KEY } from "src/Modules/HomeModule/Hooks/useHomeFeedInfiniteQuery";
import useLikePostMutation from "src/Modules/HomeModule/Hooks/useLikePostMutation";
import { GET_MY_POSTS_LISTS } from "src/Modules/MyPostsModule/Hooks/useMyPostsListInfiniteQuery";
import { strings } from "src/Utils/Localization";
import { queryClient } from "src/Utils/ReactQueryConfig";
import SnackbarHandler from "src/Utils/SnackbarHandler";
type FEED_ITEM_FOOTER_PROPS = {
  item: any;
};
function FeedItemFooter(props: FEED_ITEM_FOOTER_PROPS) {
  const { item } = props;
  const { pathname } = useLocation();
  const { user } = useAuthValue();
  const likePostMutation = useLikePostMutation();

  const likePost = async () => {
    if (user.username === item?.subId || !user.username) {
      SnackbarHandler.errorToast("You can't like your own post.");
      return;
    }
    likePostMutation?.mutate(
      {
        subId: user?.username,
        postId: item?.id,
        groupId: item?.groupId,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            if (pathname === "/hidden-posts") {
              queryClient.setQueryData(GET_HIDDEN_POSTS, (oldData: any) => ({
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  Items: page?.Items?.map((el: any) =>
                    el.id === item?.id
                      ? {
                          ...el,
                          is_like: responseData?.data?.is_like,
                          likes: responseData?.data?.likes,
                        }
                      : el
                  ),
                })),
              }));
            } else if (pathname === "/bookmarks") {
              queryClient.setQueryData(GET_MY_BOOKMARKS, (oldData: any) => ({
                pages: oldData.pages.map((page: any) => ({
                  ...page,
                  Items: page?.Items?.map((el: any) =>
                    el.id === item?.id
                      ? {
                          ...el,
                          is_like: responseData?.data?.is_like,
                          likes: responseData?.data?.likes,
                        }
                      : el
                  ),
                })),
              }));
            } else {
              queryClient.setQueryData(
                GET_HOME_FEED_QUERY_KEY(""),
                (oldData: any) => ({
                  pages: oldData.pages.map((page: any) => ({
                    ...page,
                    Items: page?.Items?.map((el: any) =>
                      el.id === item?.id
                        ? {
                            ...el,
                            is_like: responseData?.data?.is_like,
                            likes: responseData?.data?.likes,
                          }
                        : el
                    ),
                  })),
                })
              );
            }
          }
        },
      }
    );
  };
  return (
    <Fragment>
      <div className="post_list px-2 CategoriesPostList">
        <div className="row">
          <ul className="list-unstyled d-flex mb-0 ">
            <li className="post_li_ltr">
              <button onClick={likePost}>
                <i
                  className={`fa ${
                    item?.is_like ? `fa-heart` : `fa-heart-o`
                  } base_color post_li_i_ltr`}
                  aria-hidden="true"
                ></i>
                <span className="poppinssemibold">{item?.likes}</span>
              </button>
            </li>
            <li>
              <Link to={`/home/${item?.id}`}>
                <i
                  className="fa fa-comment-o base_color post_li_i_ltr"
                  aria-hidden="true"
                ></i>
                <span className="poppinssemibold">
                  {item?.total_comments} {strings?.comments}
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
}

export default FeedItemFooter;
