import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { JOIN_GROUP_REQUEST_TYPE } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_MY_GROUPS } from "./useMyGroupListInfiniteQuery";
import { GET_NEAR_BY_GROUPS } from "./useNearByGroupsInfiniteQuery";

const joinGroup = async (
  data: JOIN_GROUP_REQUEST_TYPE
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.joinGroup,
    method: "POST",
    data: data,
  });
};

function useJoinGroupMutation() {
  return useMutation(joinGroup, {
    onSuccess: (responseData) => {
      console.log(responseData, "responseData/////////////////");
      queryClient.refetchQueries(GET_NEAR_BY_GROUPS(""));
      queryClient.refetchQueries(GET_MY_GROUPS(""));
    },
  });
}

export default useJoinGroupMutation;
