import React, { Fragment, useEffect } from "react";
import Layout from "src/Modules/Layout/Layout";
import ShopCardList from "src/Modules/ShopsModule/Components/ShopCardList/ShopCardList";

function ShopsPage() {
  // useEffect(() => {
  //   (async () => {
  //     let query =
  //       "{products(first: 5) {edges {cursor node { id, title, description, createdAt, productType, variants(first: 5) {nodes {compareAtPrice, price, image {src}}}}}}}";

  //     fetch("https://bloomyourcity-dev.myshopify.com/api/graphql", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/graphql",
  //         "X-Shopify-Storefront-Access-Token":
  //           "e60d9567f7b96d3b7d8a08a528ae1e9a",
  //       },
  //       body: query,
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         console.log("Result", result);
  //       });
  //   })();
  // }, []);

  return (
    <Fragment>
      <Layout>
        <ShopCardList />
      </Layout>
    </Fragment>
  );
}

export default ShopsPage;
