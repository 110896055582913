import React, { useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GET_HOME_FEED_QUERY_KEY } from "src/Modules/HomeModule/Hooks/useHomeFeedInfiniteQuery";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useBookmarkPostMutation from "../../Hooks/useBookmarkPostMutation";
import useHidePostMutation from "../../Hooks/useHidePostMutation";
import { openDeleteGlobalPostModal } from "../ConfirmDeletePostModal/ConfirmDeletePostModal";
import { openReportPostModal } from "../ReportPostModal/ReportPostModal";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function HomeFeedOptions(props: any) {
  const { item } = props;
  const { user } = useAuthValue();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [isBookMarkPost, setIsBookMarkPost] = useState<number>(
    item?.is_bookmark
  );
  const bookMarkPostApiMutation = useBookmarkPostMutation();
  const handleBookmarkPostApi = (e: any) => {
    e.preventDefault();
    bookMarkPostApiMutation.mutate(
      {
        subId: user?.username,
        postId: item?.id,
        groupId: item?.groupId,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            setIsBookMarkPost(responseData?.data?.is_bookmark);
          }
        },
      }
    );
  };

  const hidePostApiMutation = useHidePostMutation();
  const handleHidePostApi = (e: any) => {
    e.preventDefault();
    hidePostApiMutation.mutate(
      {
        subId: user?.username,
        postId: item?.id,
        groupId: item?.groupId,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
          }
        },
      }
    );
  };

  const editPost = (e: any) => {
    e?.preventDefault();
    if (item?.type === "SALE") {
      return navigate(`/sales/edit/${item?.id}`);
    }
    if (item?.type === "SAFETY") {
      return navigate(`/edit-safety/${item?.id}`);
    }
    if (item?.type === "GENERAL") {
      return navigate(`/edit-post/${item?.id}`);
    }
    if (item?.type === "EVENT") {
      return navigate(`/edit-event/${item?.id}`);
    }
  };

  return (
    <div className={`slider_down_arrow position-relative pl-4`}>
      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
      <div className={`user_actions user_actions_posLTR`}>
        <ul className={`list-unstyled text-left mb-0 poppinsbold`}>
          <VisibilityToggle visible={user.username === item?.subId}>
            <li>
              <a
                className="py-4 d-flex align-items-center"
                href="/"
                onClick={(e) => {
                  e?.preventDefault();
                  openDeleteGlobalPostModal(item);
                }}
              >
                {/* <VisibilityToggle visible={deleteStatus === "loading"}>
                  <span className="spinner-border spinner-border-sm"></span>
                  &nbsp;
                </VisibilityToggle> */}
                {strings.delete}
              </a>
            </li>
          </VisibilityToggle>
          <VisibilityToggle
            visible={
              user.username !== item?.subId &&
              pathname !== `/home/${params?.id}` &&
              pathname !== `/bookmarks`
            }
          >
            <li>
              <a
                className="py-4 d-flex align-items-center"
                href="/"
                onClick={handleHidePostApi}
              >
                <VisibilityToggle visible={hidePostApiMutation?.isLoading}>
                  <span className="spinner-border spinner-border-sm"></span>
                  &nbsp;
                </VisibilityToggle>
                {pathname.includes("hidden-posts") ? strings?.unhide : strings?.hide}
              </a>
            </li>
          </VisibilityToggle>
          <VisibilityToggle
            visible={user.username === item?.subId && item?.type !== "POLL"}
          >
            <li>
              <a
                className="py-4 d-flex align-items-center"
                href="/"
                onClick={editPost}
              >
                {strings.edit}
              </a>
            </li>
          </VisibilityToggle>
          <VisibilityToggle visible={user.username !== item?.subId}>
            <li>
              <a
                className="py-4 d-flex align-items-center"
                href="/"
                onClick={(e) => {
                  e?.preventDefault();
                  openReportPostModal(item);
                }}
              >
                {strings.report}
              </a>
            </li>
          </VisibilityToggle>
          <VisibilityToggle
            visible={
              user.username !== item?.subId && item?.groupId === undefined
            }
          >
            <li>
              <a
                className="py-4 d-flex align-items-center"
                href="/"
                onClick={handleBookmarkPostApi}
              >
                <VisibilityToggle visible={bookMarkPostApiMutation?.isLoading}>
                  <span className="spinner-border spinner-border-sm"></span>
                  &nbsp; &nbsp;
                </VisibilityToggle>
                {isBookMarkPost === 1 ? strings?.remove_bookmark : strings?.add_bookmark}
              </a>
            </li>
          </VisibilityToggle>
        </ul>
      </div>
    </div>
  );
}

export default HomeFeedOptions;
