import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_NOTIFICATION_COUNT = ["getNotificationCount"];

const getNotificationCount = async (): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.notificationsCount}?subId=${user?.username}`,
    method: "GET",
  });
};
function useNotificationCountQuery() {
  return useQuery(GET_NOTIFICATION_COUNT, getNotificationCount);
}

export default useNotificationCountQuery;
