import moment from "moment";
import React from "react";
import { REWARD_LISTING_ITEM } from "../../Types/ResponseTypes";
import "./RewardItem.css";
type REWARDS_ITEM = {
  item: REWARD_LISTING_ITEM;
};
function RewardItem(props: REWARDS_ITEM) {
  const { item } = props;
  return (
    <>
      <ul>
        <li>
          <h5 className="reward-name">
            {item?.type === "LIKE"
              ? "Liked a post and received"
              : item?.type === "POST"
              ? "Posted a post and received"
              : item?.type === "COMMENT"
              ? "Commented on post and received"
              : item?.type === "PURCHASE"
              ? "Purchased a product and received"
              : ""}
          </h5>
          {item.timestamp && (
            <h6 className="reward-time poppinsregular">
              {moment(new Date(item.timestamp)).fromNow()}
            </h6>
          )}
        </li>
        <li>
          <h5 className="coins">{item?.rewardPoints} Blome</h5>
          {item.timestamp && (
            <h6 className="reward-date poppinsregular">
              {moment(new Date(item.timestamp)).format("DD-MM-YYYY")}
            </h6>
          )}
        </li>
      </ul>
    </>
  );
}

export default RewardItem;
