import { useEffect } from "react";
import SocketIOClient from "socket.io-client";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import { getChatValue, setChatValue } from "./useChatValue";

const connectSocket = () => {
  return SocketIOClient("https://chat.letsbloom.today", {
    reconnection: true,
    transports: ["websocket"],
    upgrade: true,
  });
};

function useChatConnection() {
  const { loggedIn } = useAuthValue();
  useEffect(() => {
    if (loggedIn) {
      setChatValue({ ws: connectSocket() });
    }
    return () => {
      const { ws }: any = getChatValue();
      ws.close();
    };
  }, [loggedIn]);
}

export default useChatConnection;
