import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";
import { useParams } from "react-router-dom";

export const GET_MY_PROFILE_KEY = (userId?: string) => [
  "getMyProfileKey",
  userId,
];

const getMyProfile = async (userId?: string): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.editProfile}`,
    method: "POST",
    data: {
      subId: userId ? userId : user?.username,
    },
  });
};
function useMyProfieQuery() {
  const params = useParams();
  const { user } = getAuthValue();
  return useQuery(
    GET_MY_PROFILE_KEY(params?.id ? String(params?.id) : user?.username),
    () => getMyProfile(params?.id ? String(params?.id) : user?.username)
  );
}

export default useMyProfieQuery;
