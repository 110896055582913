import React from "react";
import Loader from "src/Components/Shared/Loader/Loader";
import EditEvent from "src/Modules/EventModule/Components/EditEvent/EditEvent";
import useHomePostDetailQuery from "src/Modules/HomeModule/Hooks/useHomePostDetailQuery";
import Layout from "src/Modules/Layout/Layout";

function EditSafetyPage() {
  const GetHomePostDetailQuery = useHomePostDetailQuery();
  const getHomePostDetail = GetHomePostDetailQuery?.data?.data?.data;
  console.log("getHomePostDetailgetHomePostDetail", getHomePostDetail);
  return (
    <>
      {GetHomePostDetailQuery?.isLoading && <Loader />}
      <Layout>
        {!GetHomePostDetailQuery?.isLoading && (
          <EditEvent postDetails={getHomePostDetail} />
        )}
      </Layout>
    </>
  );
}

export default EditSafetyPage;
