import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_GROUP_MEMBERS_KEY = (groupId: any) => [
  "getGroupMembersKey",
  groupId,
];

const groupMembers = async (groupId: any): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.groupMembers}?subId=${user?.username}&groupId=${groupId}`,
    method: "GET",
  });
};
function useGroupMembersQuery() {
  const params = useParams();
  return useQuery(GET_GROUP_MEMBERS_KEY(params?.id), () =>
    groupMembers(params?.id)
  );
}

export default useGroupMembersQuery;
