import { useEffect, useRef } from "react";
import { Socket } from "socket.io-client";

function useChatEvent(
  ws?: Socket,
  eventName: string = "",
  onEvent: (data: any) => void = () => {}
) {
  const onEventRef = useRef(onEvent);

  useEffect(() => {
    const listener: any = ws?.on(eventName, onEventRef.current);
    return () => {
      listener?.close();
    };
  }, [ws, eventName]);
}

export default useChatEvent;
