import Loader from "src/Components/Shared/Loader/Loader";
import EditAudioVedioForm from "src/Modules/HomeModule/Components/EditAudioVedioForm/EditAudioVedioForm";
import useHomePostDetailQuery from "src/Modules/HomeModule/Hooks/useHomePostDetailQuery";
import Layout from "src/Modules/Layout/Layout";

function EditGeneralPost() {
  const GetHomePostDetailQuery = useHomePostDetailQuery();
  const getHomePostDetail = GetHomePostDetailQuery?.data?.data?.data;
  return (
    <>
      {GetHomePostDetailQuery?.isLoading && <Loader />}
      <Layout>
      {!GetHomePostDetailQuery?.isLoading && (
        <EditAudioVedioForm postDetails={getHomePostDetail} />
      )}
      </Layout>
    </>
  );
}
export default EditGeneralPost;
