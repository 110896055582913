import React, { Fragment } from 'react'
import Layout from 'src/Modules/Layout/Layout'
import Rewards from 'src/Modules/RewardsModule/Components/Rewards/Rewards'

function RewardsPage() {
  return (
    <Fragment>
        <Layout>
            <Rewards />
        </Layout>
    </Fragment>
  )
}

export default RewardsPage