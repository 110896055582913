import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";


export const GET_CITIES_LIST = (stateId: string) => ['getCitiesList', stateId]
const getCities = async (stateId: string): Promise<AxiosResponse<any>> => {
  return fetcher({
    baseURL: `${EndPoints.cities}?state_id=${stateId}`,
    method: "GET",
  });
};
function useCityListQuery(stateId: string){
    return useQuery(GET_CITIES_LIST(stateId), () => getCities(stateId));
}

export default useCityListQuery;