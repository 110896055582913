import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import useAuth from "src/Hooks/Custom/useAuth";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";

import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import "react-multi-carousel/lib/styles.css";
import useSaleCategoriesQuery from "../../Hooks/useSaleCategoriesQuery";
import useSalesInfiniteQuery, {
  extractSale,
} from "../../Hooks/useSaleInfiniteQuery";
import SaleItem from "src/Modules/SaleModule/Components/SaleItem/SaleItem";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import Carousel from "react-multi-carousel";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const EVENT = "LISTING_SALES";
export const listingSales = () => {
  emitEvent(EVENT);
};
function SalePostList() {
  const { language } = useAuthValue();
  const { loading } = useAuth();
  const saleCategories = useSaleCategoriesQuery();
  const saleCategoriesItem = saleCategories?.data?.data?.Items ?? [];
  console.log(saleCategoriesItem, "saleCategories");

  const [activeBtn, setActiveBtn] = useState<number>(0);

  const [search, setSearchText] = useState<string>("");
  const [searchValue] = useDebounce(search, 500);
  const [categoryId, setCategoryId] = useState<number | undefined>();
  const saleInfiniteQuery = useSalesInfiniteQuery(searchValue, categoryId);
  const { hasNextPage, refetch, fetchNextPage, isLoading } = saleInfiniteQuery;
  const saleList = useMemo(
    () => extractSale(saleInfiniteQuery?.data?.pages ?? []),
    [saleInfiniteQuery?.data?.pages]
  );
  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-between mb-5 align-items-center">
        <h3 className="fz20 mb-0 col-sm-3 col-md-3 col-lg-5 col-xl-6 poppinssemibold pl-0">
          {strings?.sale}
        </h3>
        <div className="d-flex align-items-center position-relative col-sm-9 col-md-9 col-lg-7 col-xl-6 pr-0">
          <Link to="/create-sale" className="add_sale">
            <i
              className="fa fa-plus plus_icon fz18 top_icon"
              title="Plus"
              aria-hidden="true"
            ></i>
          </Link>
          <input
            value={search}
            type="text"
            className="form-control fz12 poppinsregular custom_input border-0 "
            placeholder={strings?.search}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <i className={` fa fa-search fa-search-ltr`}></i>
        </div>
      </div>

      <div className="furniture_section">
        <div className="categoriesSlider furniture_btns mb-4 salelist_slick">
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            //  customTransition="all 1s linear"
            draggable={false}
            focusOnSelect={false}
            infinite
            keyBoardControl={true}
            minimumTouchDrag={80}
            pauseOnHover
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 5,
                partialVisibilityGutter: 10,
              },
              tabletLandsacpe: {
                breakpoint: {
                  max: 1199,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 20,
              },
              tabletPortrait: {
                breakpoint: {
                  max: 991,
                  min: 768,
                },
                items: 2,
                partialVisibilityGutter: 20,
              },
              mobileLandscape: {
                breakpoint: {
                  max: 767,
                  min: 575,
                },
                items: 2,
                partialVisibilityGutter: 20,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 20,
              },
            }}
            shouldResetAutoplay
            showDots={false}
            slidesToSlide={1}
            swipeable
            transitionDuration={500}
          >
            <div
              className={`text-center saleItem custom_Slider-Categories ${
                categoryId === undefined ? "active_sale_category" : ""
              }`}
              onClick={() => setCategoryId(undefined)}
            >
              <img
                src={images.allListIcon}
                alt="iconImg"
                width="28"
                height="21"
                className="mx-auto cursor-pointer"
              />
              <span>{strings.sale_listing}</span>
            </div>
            {saleCategoriesItem.map((c: any, i: number) => (
              <div
                key={i}
                className={`text-center saleItem custom_Slider-Categories ${
                  c?.id === categoryId ? "active_sale_category" : ""
                }`}
                onClick={() => setCategoryId(c?.id)}
              >
                <img
                  src={c.icon}
                  alt="iconImg"
                  width="45"
                  height="45"
                  className="mx-auto cursor-pointer"
                  // onClick={() => changeFilter(c.id)}
                />
                <span
                  className={
                    activeBtn === c.id ? "active-category fz15" : "fz15"
                  }
                >
                  {language === "ko" ? c.ko_name : c.name}
                </span>
              </div>
            ))}
          </Carousel>
        </div>

        <InfiniteScroll
          dataLength={saleList.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          loader={
            <VisibilityToggle visible={loading}>
              <span className="spinner-border spinner-border-lg"></span>
              &nbsp;
            </VisibilityToggle>
          }
          endMessage={
            <p className="text-center">
              <b>{strings.u_have_seen_all}</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refetch}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          <div className="container-fluid">
            <div className="row mt-3 carItemRow">
              {(saleList ?? []).length !== 0 ? (
                saleList.map((item: any, index: number) => (
                  <SaleItem key={index} item={item} />
                ))
              ) : (
                <div className="empty-product text-center py-5 mx-auto">
                  <img src={images.productFound} alt="empty" />
                  <h4 className="fz15 pt-2">{strings.no_product}</h4>
                </div>
              )}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default SalePostList;
