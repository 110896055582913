import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { EDIT_BUSINESS_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_BUSINESS_POST_DETAILS } from "./useBusinessPostDetailsQuery";
import { useParams } from "react-router-dom";

const editBusinessPost = async (
  data: EDIT_BUSINESS_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.editBusinessPost,
    method: "POST",
    data: data,
  });
};

function useEditBusinessPostMutation() {
  const params = useParams();
  return useMutation(editBusinessPost, {
    onSuccess: (responseData) => {
      SnackbarHandler.successToast("Business Post updated successfully");
      queryClient.refetchQueries(GET_BUSINESS_POST_DETAILS(params?.id));
    },
  });
}
export default useEditBusinessPostMutation;
