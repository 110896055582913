import React, { useEffect, useState } from "react";
//@ts-ignore
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import "./EditEvent.css";
import { getRequiredRules } from "../../../../Utils/Validators";
import TimeKeeper from "react-timekeeper";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useEditEventMutation from "../../Hooks/useEditEventMutation";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function EditEvent(props: any) {
  const { postDetails } = props;
  const navigate = useNavigate();
  const { user } = useAuthValue();
  const [startDate, setStartDate] = useState<string>("");
  const [imageList, setImageList] = useState<any>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [deleteImgs, setDeleteImgs] = useState<any>([]);
  const [showTime, setShowTime] = useState(false);
  const [timeInput, setTimeInput] = useState<string>("");
  const [showToTime, setShowToTime] = useState(false);
  const [toTime, setToTime] = useState<string>("00:00");

  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
    reset,
  } = useForm<any>({
    defaultValues: {},
    mode: "onChange",
  });
  useEffect(() => {
    reset({
      title: postDetails?.title,
      location: postDetails?.location,
    });
    const dateStr: any = new Date(postDetails?.date);
    setStartDate(dateStr);
    setImageUrls(postDetails?.images);
    setImageList(postDetails?.images);
    const fromTimeStr: string = postDetails?.from?.toString();
    const toTimeStr: string = postDetails?.to?.toString();
    setTimeInput(
      postDetails?.from?.toString().includes(".")
        ? postDetails?.from?.toString().replace(".", ":")
        : `${fromTimeStr}:00`
    );
    setToTime(
      postDetails?.to?.toString().includes(".")
        ? postDetails?.to?.toString().replace(".", ":")
        : `${toTimeStr}:00`
    );
  }, []);

  const editEventMutation = useEditEventMutation();
  const handleEditEvent = handleSubmit((values) => {
    if (startDate?.length === 0) {
      return SnackbarHandler.errorToast("Please Select Date");
    }
    if (imageUrls?.length === 0) {
      return SnackbarHandler.errorToast("Please Upload Photo");
    }
    if (timeInput?.length === 0 || toTime?.length === 0) {
      return SnackbarHandler.errorToast("Please Select Appropriate Time");
    }
    editEventMutation?.mutate(
      {
        subId: user?.username,
        postId: postDetails?.id,
        title: values?.title,
        location: values?.location,
        from: Number(timeInput.replace(":", ".")),
        to: Number(toTime?.replace(":", ".")),
        date: new Date(startDate).toDateString(),
        dateOffset: new Date().getTimezoneOffset(),
        images: imageList.filter((_item: any) => typeof _item !== "object"),
      },
      {
        onSuccess: () => {
          navigate("/home");
          SnackbarHandler.successToast("Post Updated Successfully");
        },
      }
    );
  });
  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (urlsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast("You can select maximum 5 images");
        return;
      }
    }
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: number) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };

  return (
    <>
      {editEventMutation?.isLoading && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <button
                type="button"
                className="border-0 mb-3 text-captilize left_Arrow"
                onClick={() => navigate("/home")}
              >
                <img src={images.leftBackArrow} alt="arrow" />
                <span className="fz16 mb-3">{strings?.edit_event}</span>
              </button>
              {/* create_post_Wrapper-start */}
              <div className="create_post_Wrapper">
                {/* add-categories */}
                <h3 className="fz16 text-captilize poppinsbold mb-3">
                  {strings.categories}
                </h3>
                <div className="categories_add_row">
                  <figure className="mb-0 leftAddPhotoBox">
                    <img src={images.leftAddPhoto} alt="icon" />
                  </figure>
                  <div className="right_addTextCol">
                    <p className="mb-0 poppinsmedium">
                      {strings.create_an_event}
                    </p>
                  </div>
                </div>
                {/* add-categories-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* AddPhotoAndVideo-start */}
                <div className="AddPhotoAndVideo">
                  <h3 className="fz16 text-captilize poppinsbold">
                    {strings.add_photos}
                  </h3>
                  <p className="fz14">{strings.you_upload_up_files_in_jpg}</p>
                  {/* upload_photo_Box-start */}
                  <div className="upload_photo_Box">
                    <div className="file-button1">
                      <label
                        htmlFor="add_photo_Multiple"
                        className="button-upload"
                      >
                        <div className="Upload-text1">
                          <p>
                            <img
                              src={images.eventCamera}
                              alt="img"
                              width="23"
                              height="20"
                            />
                            <span className="pl-2 fz15">
                              {strings.add_photo}
                            </span>
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="photos"
                        id="add_photo_Multiple"
                        multiple={true}
                        accept=".jpg, .png, .jpeg, .gif"
                        onChange={(e: any) =>
                          fileSelectedHandler(e?.target?.files)
                        }
                      />
                    </div>
                    <div className="upload_Row d-flex pt-3">
                      {imageUrls?.map((img: any, index: number) => {
                        return (
                          <div className="img_col position-relative">
                            <div
                              className="uploadImgBox w-100"
                              key={`item${index}`}
                            >
                              <img src={img?.url} alt="img" />
                              <div className="on_hover_overlay"></div>
                              <div
                                className="remove_icon"
                                onClick={() =>
                                  handleImageRemove(index, img?.id)
                                }
                              >
                                <img src={images.removeIco} alt="icon" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* upload_photo_Box-start */}
                </div>
                {/* AddPhotoAndVideo-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* Event-Form-start */}
                <form className="description pt-2" onSubmit={handleEditEvent}>
                  <div className="form-row">
                    <div className="form-group w-100">
                      <label className="fz16 text-captilize poppinsbold">
                        {strings.event_name}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Event Name"
                        className="form-control-Cstm"
                        {...register("title", getRequiredRules("Event Name"))}
                      />
                      {errors?.title?.message && (
                        <div className="text-danger">
                          {errors?.title?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6 col-md-12 col-lg-6">
                      <label className="fz16 text-captilize poppinsbold">
                        {strings.location}
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Location"
                        className="form-control-Cstm"
                        {...register("location", getRequiredRules("Location"))}
                      />
                      {errors?.location?.message && (
                        <div className="text-danger">
                          {errors?.location?.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-sm-6 col-md-12 col-lg-6 form-groupCstm position-relative">
                      <label className="fz16 text-captilize poppinsbold">
                        {strings?.start_date}
                      </label>
                      <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                          <DatePicker
                            customInput={
                              <div className="form-control-Cstm position-relative starting-date-col">
                                <img src={images.dateIco} alt=" dateIcon" />
                                {startDate ? (
                                  <span>
                                    {moment(startDate).format("MM-DD-YYYY")}
                                  </span>
                                ) : (
                                  <span>MM/DD/YYYY</span>
                                )}
                              </div>
                            }
                            selected={startDate}
                            onChange={(date: string) => {
                              console.log("New Date", date);
                              setStartDate(date);
                            }}
                            peekNextMonth
                            value={field.value}
                            dateFormat="dd-MM-yyyy"
                            format="dd-MM-yyyy"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6 col-md-12 col-lg-6">
                      <label className="fz16 text-captilize poppinsbold">
                        {strings.from}
                      </label>
                      <div className="custom-form-clock position-relative form-control-Cstm">
                        <div onClick={() => setShowTime(true)}>
                          <div className="timeformat">
                            {" "}
                            {timeInput ? (
                              <span>{timeInput}</span>
                            ) : (
                              <span>00:00</span>
                            )}
                          </div>
                          <img src={images.timeIco} alt=" dateIcon" />
                        </div>
                        {showTime && (
                          <TimeKeeper
                            time={timeInput}
                            onChange={(newTime: any) => {
                              console.log("Time///////", newTime);
                              setTimeInput(newTime?.formatted24);
                            }}
                            onDoneClick={() => setShowTime(false)}
                            hour24Mode
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group col-sm-6 col-md-12 col-lg-6">
                      <label className="fz16 text-captilize poppinsbold">
                        {strings.to}
                      </label>
                      <div className="custom-form-clock position-relative form-control-Cstm">
                        <div onClick={() => setShowToTime(true)}>
                          <div className="timeformat">
                            {" "}
                            {toTime ? (
                              <span>{toTime}</span>
                            ) : (
                              <span>00:00 pm</span>
                            )}
                          </div>
                          <img src={images.timeIco} alt=" dateIcon" />
                        </div>
                        {showToTime && (
                          <TimeKeeper
                            time={toTime}
                            onChange={(data: any) =>
                              setToTime(data?.formatted24)
                            }
                            onDoneClick={() => setShowToTime(false)}
                            hour24Mode
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Event-Form-close */}

                  {/* tipsBox-start==== */}
                  <div className="tipsBox mt-4 position-relative">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.tips_getting_good_answers}
                    </h3>
                    <ul className="pl-0">
                      <li>{strings.pricillas_a_perfect_nanny}</li>
                      <li>{strings.keep_your_question_short}</li>
                      <li>{strings.double_check_grammar_and_spelling}</li>
                    </ul>
                    <img src={images.bulbImg} alt="bulb" />
                  </div>

                  <button
                    type="submit"
                    className="posttbn mt-4"
                    disabled={editEventMutation?.isLoading}
                  >
                    {strings.update} <img src={images.rightArrow} alt="icons" />
                  </button>
                </form>
              </div>
            </div>
            {/* add-categories-close */}
          </div>
        </div>

        <div className="col-md-3"></div>
      </div>
    </>
  );
}

export default EditEvent;
