import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_GROUP_POST_LIST_KEY = (groupId: number) => [
  "getGroupPostListKey",
  groupId,
];

const LIMIT = 100;

const getGroupPostList = async (
  page: number,
  groupId: number
): Promise<any> => {
  const { user } = getAuthValue();
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.getGroupPosts}?subId=${user?.username}&groupId=${groupId}&page=${page}`,
  });
  return response?.data;
};

export const extractGroupPostList = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getChatNextPageParam = (lastPage: any) => {
  const { Page, TotalPages, Items } = lastPage;
  return TotalPages > Number(Page) && Items?.length !== 0
    ? Number(Page) + 1
    : undefined;
};

function useGroupPostListInfiniteQuery() {
  const params = useParams();
  console.log("Params//////////", params);
  return useInfiniteQuery(
    GET_GROUP_POST_LIST_KEY(Number(params?.id)),
    ({ pageParam = 1 }) =>
      getGroupPostList(pageParam === null ? 1 : pageParam, Number(params?.id)),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getChatNextPageParam,
    }
  );
}

export default useGroupPostListInfiniteQuery;
