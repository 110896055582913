import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useGroupMembersQuery from "../../Hooks/useGroupMembersQuery";
import { openGroupRemoveConfirm } from "../ConfirmGroupModals/GroupRemoveConfirmModal/GroupRemoveConfirmModal";
import { openGroupMakeAdminModal } from "../ConfirmGroupModals/GroupMakeAdminConfirmModal/GroupMakeAdminconfirmModal";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";

type GROUP_MEMBER_LIST_PROPS = {
  isAdmin: boolean;
};

function GroupMemberList(props: GROUP_MEMBER_LIST_PROPS) {
  const { isAdmin } = props;
  const groupMembersQuery = useGroupMembersQuery();
  const getGroupMembers = groupMembersQuery?.data?.data?.Items ?? [];

  return (
    <>
      {getGroupMembers?.length !== 0 && (
        <div
          style={{
            color: "gray",
            marginBottom: "10px",
            fontSize: "12px",
          }}
        >
          {strings.members}
        </div>
      )}
      {getGroupMembers.map((m: any, i: number) => {
        return (
          <div className="">
            <div
              className={`d-flex profileBox_Row align-items-center position-relative pl-0`}
            >
              <div className={`message_user_icon message_user_icon_posLTR`}>
                <img
                  src={m?.user_image !== "" ? m?.user_image : images.profileIco}
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
              <h4
                title={m?.user_name?.length > 25 ? m?.user_name : ""}
                className={`fz16 mb-1 pl-3 mb-0 min_height text-break poppinsmedium`}
              >
                {m.user_name.length > 25
                  ? m?.user_name.substr(0, 20) + "..."
                  : m?.user_name}{" "}
              </h4>
              <VisibilityToggle visible={isAdmin}>
                <div className={`slider_down_arrow position-relative pl-4`}>
                  <i
                    className="fa fa-angle-down fz26 py-2"
                    aria-hidden="true"
                  ></i>
                  <div className={`user_actions user_actions_posLTR`}>
                    <ul className={`list-unstyled text-left mb-0 poppinsbold`}>
                      <VisibilityToggle visible={m.is_member}>
                        <li>
                          <a
                            className="py-4"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              openGroupMakeAdminModal(m.id);
                            }}
                          >
                            <i
                              className="fa fa-user-secret mr-4 fz18"
                              aria-hidden="true"
                            ></i>
                            {strings.make_admin}
                          </a>
                        </li>
                        <li>
                          <a
                            className="py-4 text-danger"
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              openGroupRemoveConfirm(m.id);
                            }}
                          >
                            <i
                              className="fa fa-user-times mr-4 fz18"
                              aria-hidden="true"
                            ></i>
                            {strings.remove}
                          </a>
                        </li>
                      </VisibilityToggle>
                    </ul>
                  </div>
                </div>
              </VisibilityToggle>
            </div>
            <hr />
          </div>
        );
      })}
    </>
  );
}

export default GroupMemberList;
