import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import CSS from "csstype";

import SnackbarHandler from "src/Utils/SnackbarHandler";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useCreateGroupPostMutation from "../../Hooks/useCreateGroupPostMutation";
import { useParams } from "react-router-dom";
import useUpdateGroupPostMutation from "../../Hooks/useUpdateGroupPostMutation";
import { useForm } from "react-hook-form";
import { getRequiredRules } from "../../../../Utils/Validators";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const CREATE_POST_EVENT = "createPost";
export const openCreatePostModal = (data?: any) => {
  emitEvent(CREATE_POST_EVENT, data);
  console.log("MMMMMMMMMMMMMMMMMMMMM", data);
};

function GroupPostModal() {
  const { user } = useAuthValue();
  const params = useParams();
  const [postImages, setPostImages] = useState<Array<string>>([]);
  const [imageUrls, setImageUrls] = useState<Array<any>>([]);
  const [deleteImgs, setDeleteImgs] = useState<Array<string>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [groupPostId, setGroupPostId] = useState<number | undefined>(0);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      reportMessage: "",
    },
    mode: "onChange",
  });

  const imgPreview: CSS.Properties = {
    width: "100%",
    height: "200px",
  };

  const crossIcon: CSS.Properties = {
    color: "#e01515",
    position: "absolute",
    right: "-2px",
    fontSize: "20px",
    cursor: "pointer",
    top: "-5px",
  };

  useEventEmitter(CREATE_POST_EVENT, (data: any) => {
    setShowModal(true);
    debugger;
    if (data) {
      setGroupPostId(data?.id);
      reset({
        reportMessage: data?.message,
      });
      setPostImages(data?.images);
      setImageUrls(data?.images);
    } else {
      setGroupPostId(undefined);
      reset({
        reportMessage: "",
      });
      setPostImages([]);
      setImageUrls([]);
    }
  });

  const createPostApi = useCreateGroupPostMutation();
  const updateGroupPostMutation = useUpdateGroupPostMutation();
  const handleCreatePostApi = (values: any) => {
    !groupPostId
      ? createPostApi.mutate(
          {
            subId: user?.username,
            message: values?.reportMessage,
            images: postImages,
            delete_images: deleteImgs,
            groupId: Number(params?.id),
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setPostImages([]);
              setImageUrls([]);
              SnackbarHandler.successToast(strings.post_created_successfully);
            },
          }
        )
      : updateGroupPostMutation.mutate(
          {
            subId: user?.username,
            message: values?.reportMessage,
            images: postImages.filter(
              (_item: any) => typeof _item !== "object"
            ),
            delete_images: deleteImgs,
            groupId: Number(params?.id),
            postId: groupPostId,
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setPostImages([]);
              setImageUrls([]);
              SnackbarHandler.successToast(strings.post_updated_successfully);
            },
          }
        );
  };

  const handleImageRemove = (index: number, id: any) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...postImages];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setPostImages(imgsArr);
    setImageUrls(urlsArr);
  };

  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr: Array<string> =
      postImages !== undefined ? [...postImages] : [];
    const urlsArr: Array<any> = imageUrls !== undefined ? [...imageUrls] : [];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64: any = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast(strings.u_can_select_max_five_images);
        return;
      }
    }
    setPostImages(imgsArr);
    setImageUrls(urlsArr);
  };

  return (
    <Fragment>
      {(createPostApi?.isLoading || updateGroupPostMutation?.isLoading) && (
        <Loader />
      )}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form onSubmit={handleSubmit(handleCreatePostApi)}>
                  <div className={`form-group`}>
                    <label className="mb-0">
                      {!groupPostId
                        ? strings?.post_a_message
                        : strings?.edit_post}
                    </label>
                  </div>
                  <div className={`form-group`}>
                    <label>{strings.message}</label>
                    <textarea
                      // id="msgId"
                      className="form-control"
                      placeholder={strings?.write_a_message}
                      {...register(
                        "reportMessage",
                        getRequiredRules(strings.message, strings.msg_reqd)
                      )}
                    ></textarea>
                    {errors?.reportMessage?.message && (
                      <div className="text-danger">
                        {errors?.reportMessage?.message}
                      </div>
                    )}
                  </div>
                  <div className="d-sm-flex justify-content-between group-btn-footer-row">
                    <div className="mb-3 mb-sm-0">
                      <label
                        htmlFor="addGroupPostImages"
                        className="mb-0 add-photo-group"
                      >
                        <p className="d-inline-block btn post-modal-btn custom_btn rounded text-secondary bg-transparent border fz15 mb-0">
                          <i
                            className="fa fa-camera mr-3 base_color fz18"
                            aria-hidden="true"
                          ></i>
                          {(imageUrls ?? [])?.length === 0
                            ? strings?.add_photo
                            : `Photos(${imageUrls?.length})`}
                        </p>
                      </label>
                      <input
                        id="addGroupPostImages"
                        type="file"
                        accept="image/*"
                        multiple={true}
                        onChange={(e) => fileSelectedHandler(e.target.files)}
                      />
                    </div>
                    <div className="d-flex">
                      <button
                        type="button"
                        className="post-modal-btn mr-3 border text-secondary bg-transparent fz14"
                        onClick={() => setShowModal(false)}
                      >
                        {strings.cancel}
                      </button>
                      <button
                        type="submit"
                        className="post-modal-btn1 fz14"
                        disabled={
                          createPostApi?.isLoading ||
                          updateGroupPostMutation?.isLoading
                        }
                      >
                        {strings.submit}
                      </button>
                    </div>
                  </div>
                </form>
                <VisibilityToggle visible={(imageUrls ?? []).length !== 0}>
                  <div className="row mt-3 upload-groupphoto-row mt-5">
                    {(imageUrls ?? [])?.map((img: any, i: number) => {
                      return (
                        <div
                          key={i}
                          style={{ maxWidth: "110px" }}
                          className="px-3"
                        >
                          <div
                            style={{ position: "relative", maxWidth: "110px" }}
                          >
                            <img
                              key={i}
                              // style={imgPreview}
                              alt="London"
                              src={img.url}
                              className="object_fit mb-0"
                              height="70"
                              width="100%"
                            ></img>
                            <i
                              key={i + "cross"}
                              style={crossIcon}
                              className="fa fa-times-circle base_color"
                              aria-hidden="true"
                              onClick={() => handleImageRemove(i, img.id)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </VisibilityToggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div className={`modal-backdrop fade ${showModal ? "show" : ""}`}></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default GroupPostModal;
