import { Auth } from "aws-amplify";
import React, { Fragment, useEffect, useRef, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import OtpInput from "react-otp-input";
import { strings } from "src/Utils/Localization";

function VerficationModal({
  email,
  cb,
  verificationLoading,
  verify,
  isVerificationVisible,
  setIsVerificationVisible,
}: any) {
  const intervalRef = useRef<any>();
  const [showResendTime, setShowResendTime] = useState<number>(
    Date.now() + 60 * 1000
  );
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      if (showResendTime < Date.now()) {
        setShowResend(true);
      } else {
        setShowResend(false);
      }
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [showResendTime]);

  const [verificationCode, setVerificationCode] = useState(undefined);

  const handleVerifyOtp = () => {
    verify(email, verificationCode, cb);
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${isVerificationVisible ? "show" : ""}`}
        id="verify_modal"
        style={{ display: isVerificationVisible ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="optModalWrap">
                <button
                  type="button"
                  onClick={() => setIsVerificationVisible(false)}
                  className="cancelIcon"
                >
                  {strings?.cross_sign}
                </button>
                <h4>{strings?.enter_your_code}</h4>
                <p>{strings?.please_enter_code}</p>
                <p>
                  {" "}
                  {strings?.verify_code_sent_email}{" "}
                  <a href={`mailto:${email}`}>{email}</a>
                </p>
                <form className="verification_form">
                  <OtpInput
                    value={verificationCode}
                    onChange={setVerificationCode}
                    numInputs={6}
                  />
                  <button
                    disabled={verificationLoading}
                    type="button"
                    className="btn custom_btn fz14"
                    onClick={handleVerifyOtp}
                  >
                    <VisibilityToggle visible={verificationLoading}>
                      <span className="spinner-border spinner-border-sm"></span>
                      &nbsp;
                    </VisibilityToggle>
                    {strings.submit}
                  </button>
                </form>
                {showResend && (
                  <p>
                    {strings.didnt_get_code}
                    <button
                      className="submitBtn"
                      onClick={() => {
                        setShowResendTime(Date.now() + 60 * 1000);
                        Auth.resendSignUp(email);
                      }}
                    >
                      {" "}
                      {strings.resend}
                    </button>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <VisibilityToggle visible={isVerificationVisible}>
      <div
        className={`modal-backdrop fade ${isVerificationVisible ? "show" : ""
          }`}
      ></div>
      </VisibilityToggle> */}
    </Fragment>
  );
}

export default VerficationModal;
