import { Link } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import images from "../../../Utils/images";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="col_selector py-3 d-md-flex justify-content-between align-items-center">
              <a
                href="index.html"
                className="d-inline-block"
                style={{ width: 90 }}
              >
                <img
                  src={images.whiteLogo}
                  className="img-fluid"
                  alt="logo"
                  height="50"
                  width="90"
                />
              </a>
              <ul className="list-unstyled fz14 d-md-flex mb-0 poppinsregular footer-right-col">
                <li>
                  <Link className="nav-link" to="/contact-us">
                    {strings.contact_us}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/privacy-policy">
                    {strings.privacy_policy}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/terms-and-condition">
                    {strings.terms_and_conditions}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/about">
                    {strings.about}
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/faq">
                    {strings.faq}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
