import React, { Fragment } from "react";

function FAQsContentEn() {
  return (
    <Fragment>
      <section className="terms_condition_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="col_selector terms_condition_section-content">
                <h2 className="poppinssemibold">FAQ's</h2>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">What is All Bloom?</h4>
                  <p>All Bloom is a social app that helps you communicate with your neighbors. As an alternative to traditional forms of social media, All Bloom offers a way to foster relationship with your neighbors and stay updated with latest news and deals in your neighborhood.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">How is All Bloom different than other online groups?</h4>
                  <p>Below are the key benefits All Bloom offers today for neighborhood groups as compared to Facebook Groups, Google Groups and other groups:</p>
                  <ul>
                    <li>A neighborhood directory, which is built on top of real profile pages, making it possible to really get to know your neighbors (as well as having a dynamically updated directory of contact info).</li>
                    <li>A neighborhood map.</li>
                    <li>The ability to communicate with people who live near you, but outside the neighborhood through the Nearby Neighborhoods feature, as well as the ability to create public or private groups (sub-groups) within the neighborhood.</li>
                    <li>The ability for members to control their email settings based on the types of messages they want to receive.</li>
                    <li>An Urgent Alerts feature, which makes it possible to blast out urgent information (emergency, crime, service outage, etc.).</li>
                    <li>Event functionality with the ability to indicate who is planning to go.</li>
                    <li>A For Sale and Free category which makes this kind of exchange easier and more efficient than a purely message based solution or costly e-Commerce solutions.</li>

                  </ul>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">Supported Browsers and App</h4>
                  <p>You can access All Bloom on the internet using browsers such as Firefox, Safari, Chrome, and Edge.</p>
                  <ul>
                    <li>Firefox (<a href="https://www.mozilla.org/firefox" target="_blank" rel="noreferrer">https://www.mozilla.org/firefox</a>)</li>
                    <li>Safari (<a href="https://www.apple.com/safari" target="_blank" rel="noreferrer">https://www.apple.com/safari</a>)</li>
                    <li>Chrome (<a href="https://www.google.com/chrome" target="_blank" rel="noreferrer">https://www.google.com/chrome</a>)</li>
                    <li>Edge (<a href="microsoft.com/edge" target="_blank">microsoft.com/edge</a>)</li>
                  </ul>
                  <p>All Bloom is also available as an app for iOS devices through App Store and Android devices through Google Play Store.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">How to invite neighbors?</h4>
                  <ol>
                    <li>Open All Bloom on mobile or web app.</li>
                    <li>Select the invite icon on the right of the search option. </li>
                    <li>Send email invitations.</li>
                  </ol>
                  <p>Alternatively, you can use the feature Share under posts to tell your friends about All Bloom via WhatsApp, Viber, Facebook, or Twitter. </p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">What is a sponsored post?</h4>
                  <p>Sponsored posts are posts created by regional and national businesses who are participating in All Bloom's paid advertising program. These posts will appear in your newsfeed, daily digest emails, and in the For Sale and Free section. They look like most other messages on All Bloom, except that they will be clearly marked as "Sponsored." You may also see video in some sponsored posts.</p>
                  <p>Some sponsored posts are associated with businesses who have a business page on All Bloom. All Bloom members may click through on the sponsor's name to read more about their business. Members may also thank, reply to, or hide posts from sponsors with business pages. </p>
                  <p>Members may also see sponsored posts from businesses that do not have a business page on All Bloom. The business name will appear in grey text at the top left corner of the post, but it will not click through to a page on All Bloom and instead link directly to an advertiser's site. Members will not have the ability to thank, reply to, or hide sponsored posts that are not associated with a All Bloom Business page. However, members will be able to report the sponsored ad content if it is in violation of All Bloom's policies.</p>

                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">Will I get more emails because of sponsored content?</h4>
                  <p>No, you will not get any additional emails that you are not already receiving, based on your current profile settings.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">Is my personal information shared with any of the businesses?</h4>
                  <p>Businesses will never be able to see conversations between neighbors, nor will they be able to access the neighborhood directory. They will only be able to see and reply to comments on their own posts and on private messages neighbors initiate with them via the sponsored content or on their business page.</p>
                  <p>If a member chooses to comment on a business's post or share a message, the only information the business will see is the member's profile picture, first name, last initial, and neighborhood. They will not be able to access any additional information about that member.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">How does All Bloom decide which businesses to work with?</h4>
                  <p>All Bloom works with businesses that focus a lot of their energy advertising online who offer products or services that may be of value to All Bloom members.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">How can I advertise my business and how can my business participate?</h4>
                  <p>Sign up on the Business section and contact us on <a href="mailto:support@AllBloom.com">support@AllBloom.com</a> to add your ad and be informed of future opportunities for sponsored messages and other advertising opportunities.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">How does All Bloom decide what sponsored content to show me?</h4>
                  <p>Most of the time, everyone in the neighborhood sees the same sponsored content.  Some businesses promote products that are only relevant to certain households. In these cases, All Bloom may personalize ads more relevant to you based on the topics you follow on All Bloom or publicly available third party data about your home. Other businesses may only want to reach their existing customers. These businesses can upload a list of members which are relevant to their services. In any scenario, All Bloom never shares any of your profile information or other activity with businesses, advertisers, or third parties.</p>
                </div>
                <div>
                  <h4 className="fz18 poppinssemibold mb-2">Third party advertising on All Bloom</h4>
                  <p>You may receive sponsored content delivered by third parties on All Bloom. </p>
                  <p>Some advertisements may be from businesses that do not have a business page on All Bloom. While these ads do not offer you the ability to reply, thank, or hide, you may report sponsored content.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default FAQsContentEn;
