import Layout from "src/Modules/Layout/Layout";
import MyHiddenPosts from "src/Modules/HiddenPostsModule/Components/MyHiddenPosts/MyHiddenPosts";

function HiddenPosts() {
  return (
    <Layout>
      <div id="content" className={`ltr-content-padding`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="col_selector">
                <MyHiddenPosts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default HiddenPosts;
