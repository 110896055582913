import { useLocation } from "react-router-dom";
import { createPost } from "src/Components/Custom/PostModal/PostModal";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import HomePostList from "src/Modules/HomeModule/Components/HomePostList/HomePostList";
import Layout from "src/Modules/Layout/Layout";
import { withAuthenticator } from "@aws-amplify/ui-react";

function Home() {
  const { pathname } = useLocation();
  const { user } = useAuthValue();

  return (
    <Layout>
      <div id="content" className={`ltr-content-padding`}>
        <div className={`container-fluid`}>
          <div className="row">
            <div className="col-lg-9">
              <div className="col_selector">
                {/* {pathname === "/home" ? (
                  <div
                    className="home_search_section mb-4 post_feed px-3"
                    style={{ borderRadius: "9px" }}
                  > */}
                {/* <div className="d-flex align-items-center">
                      <img
                        src={
                          user.attributes.picture !== ""
                            ? user.attributes.picture
                            : "assets/images/profile-camera.png"
                        }
                        alt=""
                        className={`rounded-circle mr-3 object_fit`}
                        width="50"
                        height="50"
                      />
                      <div
                        className="w-90 position-relative"
                        onClick={createPost}
                      >
                        <input
                          type="text"
                          disabled={true}
                          className="form-control custom_input border-0 fz14 cursor-pointer"
                          style={{ borderRadius: "9px" }}
                          placeholder={`What's on your mind ${user.attributes.name}?`}
                        />
                      </div>
                    </div> */}
                {/* </div>
                ) : (
                  <></>
                )} */}

                <HomePostList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withAuthenticator(Home);
