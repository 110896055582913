import { useState } from "react";
import Linkify from "react-linkify";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import useLikePostMutation from "src/Modules/HomeModule/Hooks/useLikePostMutation";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import GroupPostListOptions from "../GroupPostListOptions/GroupPostListOptions";
import useCreateGroupPostDetailsQuery from "../../Hooks/useCreateGroupPostDetailsQuery";
import CommentItem from "src/Modules/HomeModule/Components/CommentItem/CommentItem";
import useGroupPostDetailsCommentsQuery from "../../Hooks/useGroupPostDetailsCommentsQuery";
import useGroupPostCommentMutation from "../../Hooks/useGroupPostCommentMutation";
import GroupDetailsReportCommentModal from "../GroupDetailsReportCommentModal/GroupDetailsReportCommentModal";
import images from "../../../../Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import { strings } from "src/Utils/Localization";

function GroupPostDetails({
  postId,
  groupId: gId,
  postData,
  setPostData,
}: any) {
  const params = useParams();
  const [commentText, setCommentText] = useState("");
  const { user } = useAuthValue();
  const createGroupPostDetailsQuery = useCreateGroupPostDetailsQuery();
  const groupPostDetails = createGroupPostDetailsQuery?.data?.data?.data;
  console.log("groupPostDetails", groupPostDetails);
  const groupPostDetailsImage = groupPostDetails?.images;
  console.log(groupPostDetailsImage, "groupPostDetailsImage");

  const likeHomePostDetailMutation = useLikePostMutation();
  const [isLikePost, setIsLikePost] = useState<number>(
    groupPostDetails?.is_like
  );
  const [postLikes, setPostsLikes] = useState<number>(groupPostDetails?.likes);
  const likeHomePostDetail = async () => {
    if (user.username === groupPostDetails?.subId || !user.username) {
      SnackbarHandler.errorToast("You can't like your own post.");
      return;
    }
    if (isLikePost === 0) {
      setIsLikePost(1);
      setPostsLikes((prevData) => prevData + 1);
    }
    if (isLikePost === 1) {
      setIsLikePost(0);
      setPostsLikes((prevData) => prevData - 1);
    }
    likeHomePostDetailMutation?.mutate(
      {
        subId: user?.username,
        postId: groupPostDetails?.id,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            console.log("Response Data/////////////ms", responseData);
            setIsLikePost(responseData?.data?.is_like);
            setPostsLikes(responseData?.data?.likes);
          }
        },
        onError: () => {
          setIsLikePost(groupPostDetails?.is_like);
          setPostsLikes(groupPostDetails?.likes);
        },
      }
    );
  };

  const groupCommentPostMutation = useGroupPostCommentMutation();
  const groupCommentPostDetail = () => {
    return groupCommentPostMutation?.mutate(
      {
        postId: groupPostDetails?.id,
        subId: user?.username,
        comment: commentText,
        groupId: Number(params?.id),
      },
      {
        onSuccess: () => {
          setCommentText("");
        },
      }
    );
  };

  const groupPostDetailsCommentsQuery = useGroupPostDetailsCommentsQuery();
  const getGroupPostDetailsComments =
    groupPostDetailsCommentsQuery?.data?.data?.Items ?? [];
  console.log("getGroupPostDetailsComments", getGroupPostDetailsComments);
  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="post post_feed">
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between px-3">
            <div className="d-flex align-items-center profile_top_Row">
              <div className="mr-3">
                <Link to={`/groups/${params?.id}`}>
                  <i
                    className={`fa  fz24 cursor-pointer p-2 fa-angle-left`}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
              <div className="mb-0 mb-md-0 mr-3 user_Profile">
                <img
                  src={
                    groupPostDetails?.user_image !== ""
                      ? groupPostDetails?.user_image
                      : images.profileCamera
                  }
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt="icon"
                />
              </div>
              <div className="user_profileContent">
                <h3 className="fz16 poppinssemibold mb-0">
                  {groupPostDetails?.user_name}
                </h3>
                <p className="fz10 mb-0 poppinsregular text-secondary">
                  <span className="ml-0">
                    <i className="fa fa-circle fz6 mr-1" aria-hidden="true"></i>
                    {groupPostDetails?.id
                      ? moment(new Date(groupPostDetails?.id)).fromNow()
                      : ""}
                  </span>
                </p>
              </div>
            </div>
            <GroupPostListOptions />
          </div>
        </div>
        <div className="post_content_wrap">
          <div className="slider_section1 custom_group_Slider my-4">
            <Slider {...detilaSlider}>
              {(groupPostDetailsImage ?? []).map((item: any, index: number) => {
                return (
                  <div className="detilaSlider_Item" key={`item${index}`}>
                    <img
                      src={item?.url}
                      className="img-fluid w-100 rounded"
                      alt=""
                    />
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="px-3">
            <Linkify>
              <p className="mb-0 text-secondary fz16 poppinsregular post_message">
                {groupPostDetails?.message}
              </p>
            </Linkify>
          </div>
          <div className="px-3">
            <hr />
          </div>
          <div className="post_list px-3">
            <ul className="list-unstyled d-flex mb-0">
              <li>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    likeHomePostDetail();
                  }}
                >
                  <i
                    className={`fa ${
                      groupPostDetails?.is_like
                        ? "fa-heart text-danger "
                        : "fa-heart-o base_color mr-2"
                    }`}
                    aria-hidden="true"
                  ></i>
                  {groupPostDetails?.likes ?? 0}
                </a>
              </li>
              <li>
                <a href="/">
                  <i
                    className="fa fa-comment-o base_color mr-3"
                    aria-hidden="true"
                  ></i>
                  {groupPostDetails?.total_comments}
                </a>
              </li>
            </ul>
          </div>
          <div className="py-5 px-3">
            <div className="mb-4">
              <h3 className={`fz16 mb-0 `}>{strings?.comments}</h3>
            </div>
            {getGroupPostDetailsComments.map((item: any, i: number) => {
              return <CommentItem item={item} key={i} />;
            })}

            <div className="d-flex">
              <div className="w-100 position-relative comment-Box">
                <textarea
                  value={commentText}
                  className="commentArea fz14 bg-transparent"
                  placeholder={strings?.write_a_comment}
                  maxLength={150}
                  onChange={(e: any) => setCommentText(e.target.value)}
                ></textarea>
                <button
                  className="commentIcons base_color"
                  onClick={groupCommentPostDetail}
                  disabled={groupCommentPostMutation?.isLoading}
                >
                  <span>{strings.send}</span>
                  <i
                    className="fa fa-paper-plane  cursor-pointer fz18"
                    aria-hidden="true"
                  ></i>
                  <VisibilityToggle
                    visible={groupCommentPostMutation?.isLoading}
                  >
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                </button>
              </div>
            </div>
          </div>
        </div>
        <GroupDetailsReportCommentModal />
      </div>
    </>
  );
}

export default GroupPostDetails;
