import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { emitEvent } from "../../../../Hooks/Shared/useEventEmmiter";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import useBusinessCategoriesQuery from "../../Hooks/useBusinessCategoriesQuery";
import { useForm } from "react-hook-form";
import useAddBusinessPostMutation from "../../Hooks/useAddBusinessPostMutation";
import "./BusinessPostModal.css";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import {
  getRequiredRules,
  getEmailValidationRules,
} from "../../../../Utils/Validators";
import useEditBusinessPostMutation from "../../Hooks/useEditBusinessPostMutation";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const BUSINESS_POST_EVENT = "businessPost";
export const openBusinessPostModal = (data: any) => {
  console.log(data, "dddddddddddddddddddddd");
  emitEvent(BUSINESS_POST_EVENT, data);
};

function BusinessPostModal() {
  const { user } = useAuthValue();
  const { data: categories } = useBusinessCategoriesQuery();
  const categoryList = categories?.data?.Items ?? [];
  console.log("*********categoryList", categoryList);

  const businessPostMutation = useAddBusinessPostMutation();
  const editBusinessPostMutation = useEditBusinessPostMutation();
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      category: "",
      name: "",
      email: "",
      address: "",
      phone: "",
      description: "",
    },
    mode: "onChange",
  });

  const [imageList, setImageList] = useState<Array<any>>([]);
  const [imageUrls, setImageUrls] = useState<Array<any>>([]);
  const [deleteImgs, setDeleteImgs] = useState<Array<number>>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [businessId, setBusinessId] = useState(0);

  const handleCreateUpdatePost = async (values: any) => {
    console.log("........values", values);
    !businessId
      ? businessPostMutation?.mutate(
          {
            subId: user?.username,
            type: "SALE",
            category_id: Number(values?.category),
            message: values?.description,
            title: values?.name,
            email: values?.email,
            phone_number: values?.phone,
            location: values?.address,
            images: imageList,
            delete_images: deleteImgs,
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setImageList([]);
              setImageUrls([]);
            },
          }
        )
      : editBusinessPostMutation?.mutate(
          {
            postId: businessId,
            subId: user?.username,
            type: "SALE",
            category_id: Number(values?.category),
            message: values?.description,
            title: values?.name,
            email: values?.email,
            phone_number: values?.phone,
            location: values?.address,
            images: imageList.filter((_item: any) => typeof _item !== "object"),
            delete_images: deleteImgs,
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setImageList([]);
              setImageUrls([]);
            },
          }
        );
  };
  useEventEmitter(BUSINESS_POST_EVENT, (data) => {
    setShowModal(true);
    setBusinessId(data?.id);
    reset({
      name: data?.title,
      email: data?.email,
      address: data?.location,
      phone: data?.phone_number,
      description: data?.message,
      category: data?.category_id,
    });
    setImageList(data?.images);
    setImageUrls(data?.images);
  });

  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr: Array<any> =
      (imageList ?? []).length !== 0 ? [...imageList] : [];
    const urlsArr: Array<any> =
      (imageUrls ?? []).length !== 0 ? [...imageUrls] : [];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast(strings.image_length_validation);
        return;
      }
    }
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: any) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr: Array<any> = [...imageList];
    const urlsArr: Array<any> = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog custom_BusinessModal_Dialog">
          <div className="modal-content border-0 businessModal_content">
            <div className="modal-header px-4">
              <>
                {!businessId ? (
                  <h5 className="modal-title fz20">{strings.business}</h5>
                ) : (
                  <h5 className="modal-title fz20">{strings.edit_business}</h5>
                )}
              </>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
                <img src={images.crossIcon} alt="icon" />
              </button>
            </div>
            <div className="modal-body p-3 p-md-5 custom_modal custom_Business_modal mt-2">
              <div className="">
                <form onSubmit={handleSubmit(handleCreateUpdatePost)}>
                  <div className="form-row">
                    <div className={`form-group col-md-12`}>
                      <select
                        className="form-control fz12 cstm_Right_Arrow"
                        {...register(
                          "category",
                          getRequiredRules(
                            strings.category,
                            strings.business_cat_reqd
                          )
                        )}
                      >
                        <option value={""}>{strings.select_category}</option>
                        {(categoryList ?? []).map((item: any) => (
                          <option key={item?.id} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                      {errors?.category?.message && (
                        <div className="text-danger">
                          {errors?.category?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`form-group`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={strings.name}
                      {...register(
                        "name",
                        getRequiredRules(strings.name, strings.name_is_required)
                      )}
                    />
                    {errors?.name?.message && (
                      <div className="text-danger">{errors?.name?.message}</div>
                    )}
                  </div>
                  <div className={`form-group `}>
                    <input
                      type="email"
                      // pattern=" /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/"
                      className="form-control"
                      placeholder={strings.email}
                      {...register(
                        "email",
                        getEmailValidationRules(strings.email_is_required)
                      )}
                    />
                    {errors?.email?.message && (
                      <div className="text-danger">
                        {errors?.email?.message}
                      </div>
                    )}
                  </div>
                  <div className={`form-group`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={strings.address}
                      {...register(
                        "address",
                        getRequiredRules(
                          strings.address,
                          strings.address_is_reqd
                        )
                      )}
                    />
                    {errors?.address?.message && (
                      <div className="text-danger">
                        {errors?.address?.message}
                      </div>
                    )}
                  </div>
                  <div className={`form-group`}>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={strings.phone_number}
                      {...register(
                        "phone",
                        getRequiredRules(
                          strings.phone_number,
                          strings.phone_number_is_required
                        )
                      )}
                    />
                    {errors?.phone?.message && (
                      <div className="text-danger">
                        {errors?.phone?.message}
                      </div>
                    )}
                  </div>
                  <div className={`form-group`}>
                    <textarea
                      className="form-control"
                      placeholder={strings.write_a_description}
                      {...register(
                        "description",
                        getRequiredRules(
                          strings.description,
                          strings.description_is_required
                        )
                      )}
                    ></textarea>
                    {errors?.description?.message && (
                      <div className="text-danger">
                        {errors?.description?.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="add_PhotoBox mt-4">
                      {!businessId ? (
                        <h3 className="fz16">{strings.add_a_photo}</h3>
                      ) : (
                        <></>
                      )}
                      {!businessId ? (
                        <small>{strings.jpg_format}</small>
                      ) : (
                        <></>
                      )}
                      <label
                        htmlFor="businessPhotos"
                        className="mb-0 d-flex mt-3"
                      >
                        <p className="custom_btnAdd_Photo bg-transparent fz15 mb-0">
                          <i className="fa fa-camera" aria-hidden="true"></i>
                          {strings.add_photo}
                        </p>
                      </label>
                      <input
                        id="businessPhotos"
                        name="photos"
                        type="file"
                        accept="image/*"
                        multiple={true}
                        onChange={(e) => fileSelectedHandler(e.target.files)}
                      />
                    </div>
                    {/* upload-img-start=== */}
                    <div className="upload_Row d-flex pt-3">
                      {(imageUrls ?? [])?.map((img: any, i: any) => {
                        return (
                          <div
                            key={`img${i}`}
                            className="img_col position-relative"
                          >
                            <div className="uploadImgBox w-100">
                              <img key={`img${i}`} src={img?.url} alt="img" />
                              <div className="on_hover_overlay"></div>
                              <div
                                className="remove_icon"
                                key={`img${i}`}
                                onClick={() => handleImageRemove(i, img.id)}
                              >
                                <img src={images.removeIco} alt="icon" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* upload-img-close=== */}
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="posttbn fz16 mt-2"
                        disabled={
                          businessPostMutation?.isLoading ||
                          editBusinessPostMutation?.isLoading
                        }
                      >
                        <span>{strings?.submit}</span>
                        <VisibilityToggle
                          visible={
                            businessPostMutation?.isLoading ||
                            editBusinessPostMutation?.isLoading
                          }
                        >
                          <span className="spinner-border spinner-border-lg"></span>
                          &nbsp;
                        </VisibilityToggle>
                        <img src={images.rightArrow} alt="icons" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default BusinessPostModal;
