import { Amplify } from "aws-amplify";
import { BehaviorSubject } from "rxjs";
import PersistStorage from "src/Utils/PersistStorage";
import { AUTH_STATE } from "../Types/CommonTypes";
// import awsConfig from "src/Constants/awsConfig";
import awsmobile from "src/Constants/aws-exports";
import { CART } from "src/Modules/ShopsModule/Types/CommonTypes";

class AuthService {
  queryKeys = {};
  initialAuthState: AUTH_STATE = {
    loggedIn: false,
    user: undefined,
    language: "en",
    toggleMenu: false,
  };

  authState$ = new BehaviorSubject<AUTH_STATE>(this.initialAuthState);
  cartState$ = new BehaviorSubject<Array<CART>>([]);

  constructor() {
    new PersistStorage("authState", this.authState$);
    new PersistStorage("cartState", this.cartState$);
    // Amplify.configure(awsConfig);
    Amplify.configure(awsmobile);
  }

  resetAuthValue = () => {
    this.authState$.next(this.initialAuthState);
    this.cartState$.next([]);
    localStorage.clear();
  };
}

export default new AuthService();
