import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { LIKE_POST_REQUEST } from "../Types/RequestTypes";

const deletePost = async (
  data: LIKE_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.deletePost,
    method: "DELETE",
    data: data,
  });
};

function useDeletePostMutation() {
  return useMutation(deletePost, {
    onSuccess: (responseData) => {
      console.log(responseData, "responseData/////////////////");
    },
  });
}

export default useDeletePostMutation;
