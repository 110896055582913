import { useForm } from "react-hook-form";
import { getRequiredRules } from "../../Utils/Validators";

function ContactUsEn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      fname: "",
      lname: "",
      email: "",
      question: "",
    },
    mode: "onChange",
  });

  return (
    <section className="terms_condition_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="col_selector">
              <h2 className="poppinssemibold">Contact Us</h2>
              <div className="login_section">
                <div className="row">
                  <div className="col-6">
                    <div className="contact">
                      <p>
                        <span className="mr-2">
                          <i className="fa fa-envelope"></i>
                        </span>{" "}
                        <a href="mailto:support@Allbloom.com">support@AllBloom.com</a>
                      </p>
                    </div>
                    <form
                      className="poppinsregular"
                      // onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          // name="fname"
                          className="form-control text-left"
                          placeholder="First Name"
                          //  ref={register({ required: "*First name required" })}
                          {...register("fname", getRequiredRules("First name"))}
                        />

                        {errors.fname && (
                          <span className="ml-3 text-danger">
                            {errors.fname.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          // name="lname"
                          className="form-control text-left"
                          placeholder="Last Name"
                          // ref={register({ required: "*Last name required" })}
                          {...register("lname", getRequiredRules("Last name"))}
                        />
                        {errors.lname && (
                          <span className="ml-3 text-danger">
                            {errors.lname.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          // name="email"
                          className="form-control text-left"
                          placeholder="Your Email"
                          {...register("email", {
                            required: "*Email required",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Enter a valid Email Address",
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="ml-3 text-danger">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <textarea
                          // name="question"
                          cols={30}
                          rows={8}
                          style={{ height: "150px" }}
                          className="form-control text-left"
                          placeholder="Your Question?"
                          // ref={register({ required: "*What is your query?" })}
                          {...register(
                            "question",
                            getRequiredRules("*What is your query?")
                          )}
                        ></textarea>
                        {errors.question && (
                          <span className="ml-3 text-danger">
                            {errors.question.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group">
                        <button className="btn custom_btn w-100" type="submit">
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default ContactUsEn;
