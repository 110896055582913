import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { GROUP_COMMENT_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_GROUP_POST_DETAILS_COMMENTS_KEY } from "./useGroupPostDetailsCommentsQuery";
import { useParams } from "react-router-dom";

const groupPost = async (
  data: GROUP_COMMENT_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.commentOnPost,
    method: "POST",
    data: data,
  });
};

function useGroupPostCommentMutation() {
  const params = useParams();
  return useMutation(groupPost, {
    onSuccess: (responseData) => {
      console.log(responseData, "responseData/////////////////");
      queryClient.refetchQueries(
        GET_GROUP_POST_DETAILS_COMMENTS_KEY(
          Number(params?.postId),
          Number(params?.id)
        )
      );
    },
  });
}

export default useGroupPostCommentMutation;
