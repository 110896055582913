import { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useRemoveAdminMutation from "src/Modules/GroupModule/Hooks/useRemoveAdminMutation";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const GROUP_ADMIN_REMOVE_CONFIRM_EVENT = "groupAdminRemoveModal";
export const openGroupRemoveAdminModal = (data: number) => {
  emitEvent(GROUP_ADMIN_REMOVE_CONFIRM_EVENT, data);
};

function GroupAdminRemoveConfirmModal() {
  const { user } = useAuthValue();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [memberId, setMemberId] = useState<number>();
  const removeAdminMutation = useRemoveAdminMutation();
  const handleRemoveMember = () => {
    removeAdminMutation?.mutate(
      {
        subId: user?.username,
        memberId: memberId,
      },
      {
        onSuccess: () => {
          setShowModal(false);
        },
      }
    );
  };
  useEventEmitter(GROUP_ADMIN_REMOVE_CONFIRM_EVENT, (data) => {
    setShowModal(true);
    setMemberId(data);
  });

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="confirm_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="text-center">
                <p className="fz24 text-secondary mb-4">Are You Sure?</p>

                <button
                  className="btn custom_btn mr-2 text-secondary bg-transparent border border-secondary"
                  onClick={() => setShowModal(false)}
                >
                  {strings.cancel}
                </button>
                <button className="btn custom_btn" onClick={handleRemoveMember}>
                  <VisibilityToggle visible={removeAdminMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default GroupAdminRemoveConfirmModal;
