import { Dispatch, Fragment, SetStateAction, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import Autocomplete from "react-google-autocomplete";
import { strings } from "src/Utils/Localization";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useNearByPlaceQuery from "../Hooks/useNearByPlaceQuery";

type ADD_COMMUNITY_POPUP_PROPS = {
  isOpenPopup: boolean;
  popupClose: Dispatch<SetStateAction<boolean>>;
  setCommunityLatLng: Dispatch<SetStateAction<any>>;
};
function AddCommunityPopup(props: ADD_COMMUNITY_POPUP_PROPS) {
  const { isOpenPopup = false, popupClose, setCommunityLatLng } = props;
  const GOOGLE_API_KEY = "AIzaSyAw0B6V6MVtGO3jD3U7qMgZV5BGrv_sS7M";
  const [currentLocation, setCurrentLocation] = useState<string>("");
  const [latLng, setLatLng] = useState<any>();

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        setLatLng({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      SnackbarHandler.errorToast("Please enable location permission");
    }
  };

  const { data } = useNearByPlaceQuery({
    lat: latLng?.lat,
    lng: latLng?.lng,
  });

  console.log("LaT Lng", data?.data?.body?.list);
  const communityList = data?.data?.body?.list ?? [];
  return (
    <Fragment>
      <div
        className={`modal fade ${isOpenPopup ? "show" : ""}`}
        id="verify_modal"
        style={{ display: isOpenPopup ? "block" : "none" }}
        role={"dialog"}
        // onClick={() => popupClose(false)}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div>
                <Autocomplete
                  apiKey={GOOGLE_API_KEY}
                  inputMode="search"
                  defaultValue={currentLocation}
                  placeholder={strings.search_your_location}
                  onPlaceSelected={(place) => {
                    console.log("Places", place.address_components);

                    place.address_components.map((item: any, index: number) => {
                      if (item.types[0] === "administrative_area_level_1") {
                        console.log("State", item.long_name);
                        // setUserLocation((prevState: any) => {
                        //   return {
                        //     ...prevState,
                        //     state: item.long_name,
                        //   };
                        // });
                      }
                    });
                  }}
                  options={{
                    types: ["address", "regions"],
                  }}
                />
              </div>

              <div>
                <button onClick={getCurrentLocation}>Current Location</button>
              </div>
              {communityList.map((item: any, index: number) => {
                return (
                  <button
                    key={`item${index}`}
                    onClick={() => {
                      setCommunityLatLng({
                        lat: item?.latitude,
                        lng: item?.longitude,
                      });
                      popupClose(false);
                    }}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={isOpenPopup}>
        <div
          className={`modal-backdrop fade ${isOpenPopup ? "show" : ""}`}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}
export default AddCommunityPopup;
