import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { READ_NOTIFICATION_TYPE } from "../Types/RequestTypes";
import { GET_NOTIFICATION_COUNT } from "./useNotificationCountQuery";
import { GET_NOTIFICATION_LIST } from "./useNotificationInfinteQuery";

const readNotification = async (
  data: READ_NOTIFICATION_TYPE
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.readNotification,
    method: "POST",
    data: data,
  });
};

function useReadNotificationMutation() {
  return useMutation(readNotification, {
    onSuccess: () => {
      queryClient.refetchQueries(GET_NOTIFICATION_COUNT);
      queryClient.refetchQueries(GET_NOTIFICATION_LIST);
    },
  });
}
export default useReadNotificationMutation;
