import React from "react";
import Loader from "src/Components/Shared/Loader/Loader";
import images from "src/Utils/images";
import useRewardsHistoryQuery from "../../Hooks/useRewardsHistoryQuery";
import RewardItem from "../RewardItem/RewardItem";
import "./Rewards.css";

function Rewards() {
  const { data, isLoading } = useRewardsHistoryQuery();
  const rewardsHistory = data?.data?.data?.result ?? [];
  const totalRewards = data?.data?.data?.totalRewardPoints;
  return (
    <>
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 post_feed notification_Box">
                {/* reward-header-header */}
                <div className="reward-header-row">
                  <h3>My Rewards</h3>

                  {/* right-reward-col */}
                  <ul className="right-reward-col">
                    <li>
                      <img
                        src={images.rewardIcon}
                        alt="reward"
                        width={"20px"}
                        height="28px"
                      />
                    </li>
                    <li>
                      <h6>Blomes</h6>
                      <h4>{totalRewards ?? 0}</h4>
                    </li>
                  </ul>
                </div>
                {/* reward-header-close */}
                <div className="reward-list-box">
                  {rewardsHistory?.length > 0
                    ? rewardsHistory?.map((reward: any, index: number) => {
                        return (
                          <RewardItem item={reward} key={`reward-${index}`} />
                        );
                      })
                    : "No Data Found"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
    </>
  );
}

export default Rewards;
