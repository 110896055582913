import React from "react";
import AppRouter from "./Navigation/AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "src/assets/css/font-awesome.min.css";
import "src/assets/css/bootstrap.min.css";
import "src/assets/fonts/poppin-fonts/stylesheet.css";
import "src/assets/css/customStyle.css";
import "src/assets/css/media.css";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./Utils/ReactQueryConfig";
import "@aws-amplify/ui-react/styles.css";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
