import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "./BusinessList.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BusinessItem from "../BusinessItem/BusinessItem";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useBusinessListInfiniteQuery, {
  extractBusinessFeed,
} from "../../Hooks/useBusinessListInfiniteQuery";
import { openBusinessPostModal } from "../BusinessPostModal/BusinessPostModal";
import useBusinessCategoriesQuery from "../../Hooks/useBusinessCategoriesQuery";
//@ts-ignore
import { useDebounce } from "use-debounce";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";

const EVENT = "openAddBusinessModal";
export const openAddBusinessModal = () => {
  emitEvent(EVENT);
};
function BusinessList() {
  const [search, setSearchText] = useState<string>("");
  const [searchValue] = useDebounce(search, 500);
  const [categoryId, setCategoryId] = useState<number | undefined>();
  const { data: categories } = useBusinessCategoriesQuery();
  const categoryList = categories?.data?.Items ?? [];
  console.log("Category List", categoryList);
  const businessListInfiniteQuery: any = useBusinessListInfiniteQuery(
    searchValue,
    categoryId
  );
  const { refetch, fetchNextPage, isLoading } = businessListInfiniteQuery;
  const businessList = useMemo(
    () => extractBusinessFeed(businessListInfiniteQuery?.data?.pages ?? []),
    [businessListInfiniteQuery?.data?.pages]
  );

  console.log("Business List", businessList);
  return (
    <>
      <div className="d-flex justify-content-between mb-5 align-items-center px-1">
        <h3 className="fz20 mb-0 custom_Busniess_heading">
          {strings.business}
        </h3>
        <div className="d-flex align-items-center w-50 position-relative">
          <button
            onClick={openBusinessPostModal}
            className="categoryAddBtn mr-1 bg-transparent"
          >
            <i
              className="fa fa-plus plus_icon fz18 top_icon"
              title="Plus"
              aria-hidden="true"
            ></i>
          </button>
          <input
            value={search}
            type="text"
            className="form-control fz12 poppinsregular custom_input border-0"
            placeholder={strings.search}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <i className={`fa fa-search fa-search-ltr`}></i>
        </div>
      </div>
      <div className="furniture_section">
        <div className="businessSlider furniture_btns salelist_slick">
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            draggable={false}
            focusOnSelect={false}
            infinite
            keyBoardControl={true}
            minimumTouchDrag={80}
            pauseOnHover
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 5,
                partialVisibilityGutter: 5,
              },
              laptopSmall: {
                breakpoint: {
                  max: 1199,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 20,
              },
              tabletLandscape: {
                breakpoint: {
                  max: 1023,
                  min: 851,
                },
                items: 2,
                partialVisibilityGutter: 20,
              },
              tablet: {
                breakpoint: {
                  max: 850,
                  min: 768,
                },
                items: 1,
                partialVisibilityGutter: 20,
              },
              mobileLandscape: {
                breakpoint: {
                  max: 767,
                  min: 481,
                },
                items: 2,
                partialVisibilityGutter: 10,
              },
              mobile: {
                breakpoint: {
                  max: 480,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 10,
              },
            }}
            shouldResetAutoplay
            showDots={false}
            slidesToSlide={1}
            swipeable
            transitionDuration={500}
          >
            {/* slider-wrapper-start */}
            <div
              className={`sliderWrap position-relative ${
                categoryId === undefined ? "business_active-category" : ""
              }`}
              onClick={() => setCategoryId(undefined)}
            >
              <div className="overlay_img"></div>
              <h4 className="categoryName">{strings.all}</h4>
            </div>
            {(categoryList ?? []).map((item: any, index: number) => {
              return (
                <div
                  className={`sliderWrap position-relative ${
                    item?.id === categoryId ? "business_active-category" : ""
                  }`}
                  key={`item${index}`}
                  onClick={() => setCategoryId(item?.id)}
                >
                  <div className="overlay_img"></div>
                  <h4 className="categoryName">{item?.name}</h4>
                  <img src={item?.icon} alt="resturant" />
                </div>
              );
            })}
            {/* slider-wrapper-close */}
          </Carousel>
        </div>
        <InfiniteScroll
          dataLength={businessList.length} //This is important field to render the next data
          next={fetchNextPage}
          hasMore={true}
          loader={isLoading && <Loader />}
          endMessage={
            <p className="text-center">
              <b>{strings.u_have_seen_all}</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refetch}
          pullDownToRefresh
        >
          <div className="container-fluid">
            <div className="row">
              <h3 className="fz16 my-4 custom_Busniess_heading">
                {strings.local_deals}
              </h3>
            </div>
            <div className="row carItemRow">
              {businessList.length !== 0
                ? businessList.map((item: any, index: number) => (
                    <BusinessItem key={index} item={item} />
                  ))
                : !isLoading && (
                    <div className="empty-product text-center py-5 mx-auto">
                      <img
                        src={images.businessPlaceholder}
                        alt="no_business"
                        className="mx-auto"
                      />
                      <h4 className="fz15 pt-2">{strings?.no_hotel}</h4>
                    </div>
                  )}
            </div>
          </div>
        </InfiniteScroll>
      </div>
      {/* <BusinessPostModal /> */}
    </>
  );
}

export default BusinessList;
