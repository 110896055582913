import React, { useState } from "react";
import useShopifyProductListQuery from "../../Hooks/useShopifyProductListQuery";
import ShopCardItem from "../ShopCardItem/ShopCardItem";
import "./ShopCardList.css";

function ShopCardList() {
  const [searchText, setSearchText] = useState("");
  const { data } = useShopifyProductListQuery(searchText);
  const productList = data?.products?.edges ?? [];

  return (
    <>
      <div id="content" className="mb-4 createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 px-0 shop-right-col-box">
              <div className="messages pt-4 post_feed notification_Box">
                {/* reward-header-header */}
                <div className="reward-header-row">
                  <h3>Shop</h3>

                  {/* right-reward-col */}
                  <div className="inputboxcustom search-boxcustom">
                    <input
                      className="form-control fz12 poppinsregular custom_input pr-5"
                      type="text"
                      placeholder="search"
                      width={"100%"}
                      onChange={(e: any) => setSearchText(e?.target?.value)}
                    />
                    <i className="fa fa-search fa-search-ltr"></i>
                  </div>
                </div>
                {/* reward-header-close */}
                <div className="row mx-0  shopitme-row car-Item-row">
                  {productList?.length !== 0
                    ? productList.map((item: any, index: number) => {
                        return (
                          <ShopCardItem item={item} key={`product-${index}`} />
                        );
                      })
                    : "No Data Found"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopCardList;
