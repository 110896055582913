import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import CSS from "csstype";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { emitEvent } from "../../../../Hooks/Shared/useEventEmmiter";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import { useForm } from "react-hook-form";
import { getRequiredRules } from "../../../../Utils/Validators";
import useEditProfileMutation from "../../Hooks/useEditProfileMutation";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";
import Autocomplete from "react-google-autocomplete";
import axios from "axios";
// import InputPhone from "src/Components/Shared/InputPhone/InputPhone";

const EDIT_PROFILE_EVENT = "editProfileEvent";
export const openEditProfile = (data: any) => {
  emitEvent(EDIT_PROFILE_EVENT, data);
};
const GOOGLE_API_KEY = "AIzaSyAw0B6V6MVtGO3jD3U7qMgZV5BGrv_sS7M";

function EditProfileModal(props: any) {
  const { user } = useAuthValue();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false);
  const [iconUrl, setIconUrl] = useState<any>("");

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: "",
      family_name: "",
      // country_code: "+91",
      phone_number: "",
      state_name: "",
      city_name: "",
    },
    mode: "onChange",
  });
  // const country_code = watch("country_code");

  const [profilePic, setProfilePic] = useState<any>("");
  const [imgExt, setImgExt] = useState<string>("");
  const [refreshAutoComplete, setRefreshAutoComplete] =
    useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<string>("");

  const cameraIcon: CSS.Properties = {
    position: "absolute",
    top: "75%",
    right: "39%",
    fontSize: "24px",
    color: "darkslategray",
    cursor: "pointer",
  };

  useEventEmitter(EDIT_PROFILE_EVENT, (data) => {
    setShowModal(true);
    reset({
      name: data?.name,
      family_name: data?.family_name,
      phone_number: data?.phone_number,
      state_name: data?.state_name,
      city_name: data?.city_name,
    });
    setCurrentLocation(data?.address);
    setIconUrl(data?.picture);
  });

  const editProfileMutation = useEditProfileMutation();
  const handleEditProfile = (values: any) => {
    editProfileMutation?.mutate(
      {
        subId: user?.username,
        imgExtension: imgExt,
        imgBase64: profilePic,
        name: values?.name,
        phone_number: values?.phone_number,
        family_name: values?.family_name,
        address: currentLocation,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            setShowModal(false);
            const newData: any = {
              attributes: null,
              username: null,
            };
            newData.attributes = {
              ...responseData?.data?.Items[0],
              username: responseData?.data?.Items[0]?.subId,
              "custom:city_id": `${responseData?.data?.Items[0]?.city_id}`,
              "custom:state_id": `${responseData?.data?.Items[0]?.state_id}`,
            };
            newData.username = responseData?.data?.Items[0]?.subId;
            setAuthValue({
              ...getAuthValue(),
              user: newData,
            });
          }
        },
      }
    );
  };

  const fileSelectedHandler = async (imgs: any) => {
    const icon = imgs.length ? imgs[0] : null;
    const MAX_SIZE = 2;
    if (icon) {
      if (icon.size / 1024 / 1024 > MAX_SIZE) {
        SnackbarHandler.errorToast(
          `${icon.name} image size is more than ${MAX_SIZE}MB`
        );
      } else {
        try {
          const imgBase64: any = await convertImagesToBase64(icon);
          const ext = imgBase64
            .split(",")[0]
            .replace("data:image/", "")
            .replace(";base64", "");
          setImgExt(ext);
          setIconUrl(URL.createObjectURL(icon));
          setProfilePic(imgBase64.split(",")[1]);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const xyz = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${GOOGLE_API_KEY}`
        );
        xyz.data.results?.map((item: any, index: number) => {
          if (item.types[0] === "establishment") {
            setCurrentLocation(item.formatted_address);
          }
          setRefreshAutoComplete(!refreshAutoComplete);
        });
      });
    } else {
      SnackbarHandler.errorToast("Please enable location permission");
    }
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form onSubmit={handleSubmit(handleEditProfile)}>
                  <div className={`form-group `}>
                    <label className="mb-0 fz20">{strings.edit_profile}</label>
                  </div>
                  <div className={`form-group `}>
                    <div>
                      <div
                        className="mb-3 position-relative profile_circle_box"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          width="160"
                          height="160"
                          className="rounded-circle object_fit"
                          alt=""
                          src={iconUrl !== "" ? iconUrl : images.profileCamera}
                        ></img>
                        <label htmlFor="photos">
                          <i
                            style={cameraIcon}
                            className="fa fa-camera mbl-camera-icon"
                            aria-hidden="true"
                          ></i>
                        </label>
                        <input
                          id="photos"
                          className="d-none"
                          type="file"
                          accept="image/*"
                          onChange={(e) => fileSelectedHandler(e.target.files)}
                        />
                      </div>
                    </div>
                    <div className={`form-group `}>
                      <label>{strings?.first_name}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={strings?.first_name}
                        {...register(
                          "name",
                          getRequiredRules(
                            strings.first_name,
                            strings.first_name_is_required
                          )
                        )}
                      />
                      {errors.name && (
                        <span className="ml-3 text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className={`form-group `}>
                      <label>{strings?.last_name}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={strings?.last_name}
                        {...register(
                          "family_name",
                          getRequiredRules(
                            strings.last_name,
                            strings.last_name_is_required
                          )
                        )}
                      />
                      {errors.family_name && (
                        <span className="ml-3 text-danger">
                          {errors.family_name.message}
                        </span>
                      )}
                    </div>
                    <div className={`form-group `}>
                      <label>{strings?.phone_number}</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={strings?.phone_number}
                        {...register("phone_number", {
                          required: {
                            value: true,
                            message: strings.phone_number_is_required,
                          },
                          pattern: {
                            value: /^[+][0-9]{10,14}$/,
                            message: strings.phone_number_is_invalid,
                          },
                        })}
                      />
                      {errors.phone_number && (
                        <span className="ml-3 text-danger">
                          {errors.phone_number.message}
                        </span>
                      )}

                      {/* <InputPhone
                        error={errors?.mobile_number?.message}
                        country_code={country_code}
                        register={register}
                        setValue={setValue}
                        trigger={trigger}
                      /> */}
                    </div>

                    <div className="form-row neighhbourhood_row">
                      <label>{strings.address}</label>
                      <div className="w-100 position-relative">
                        <input
                          type="text"
                          value={currentLocation}
                          className="form-control pr-5"
                          placeholder="Street address"
                          aria-hidden="true"
                          data-toggle="modal"
                          data-target="#location_modal"
                          readOnly
                          onClick={() => setShowLocationModal(true)}
                        />
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="d-flex mt-3">
                      <button
                        type="button"
                        className="btn custom_btn mr-3 border text-secondary bg-transparent fz14"
                        onClick={() => setShowModal(false)}
                      >
                        {strings.cancel}
                      </button>
                      <button
                        type="submit"
                        className="btn custom_btn fz14"
                        disabled={editProfileMutation?.isLoading}
                      >
                        <VisibilityToggle
                          visible={editProfileMutation?.isLoading}
                        >
                          <span className="spinner-border spinner-border-sm"></span>
                          &nbsp; &nbsp;
                        </VisibilityToggle>
                        {strings.submit}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal fade ${showLocationModal ? "show" : ""}`}
          id="location_modal"
          style={{ display: showLocationModal ? "block" : "none" }}
          role={"dialog"}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title poppinssemibold">
                  {strings.select_a_location}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowLocationModal(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group_custom position-relative">
                  <Autocomplete
                    apiKey={GOOGLE_API_KEY}
                    inputMode="search"
                    key={refreshAutoComplete.toString()}
                    defaultValue={currentLocation}
                    placeholder={strings.search_your_location}
                    onPlaceSelected={(place) => {
                      setCurrentLocation(place?.formatted_address);
                    }}
                    options={{
                      types: ["address"],
                    }}
                  />
                  <i className="fa fa-search " aria-hidden="true"></i>
                </div>
                <button
                  className="location_button pt-3 mb-0 border-0 bg-transparent"
                  onClick={getCurrentLocation}
                >
                  <img src={images.currentlocation} alt="current_location" />
                  <span className="curent_location fz13 poppinsmedium pl-2">
                    {strings.use_current_location}
                  </span>
                </button>

                {currentLocation !== "" && (
                  <div className="text-center mt-3">
                    <button
                      className="btn saveBtn"
                      onClick={() => setShowLocationModal(false)}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default EditProfileModal;
