import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { UPDATE_SAFETY_POST_REQUEST } from "../Types/RequestTypes";

const editSafety = async (
  data: UPDATE_SAFETY_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.editSafetyPost,
    method: "POST",
    data: data,
  });
};

function useEditSafetyMutation() {
  return useMutation(editSafety, {
    onSuccess: (responseData) => {
      SnackbarHandler.successToast("Post updated successfully");
    },
  });
}
export default useEditSafetyMutation;
