import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { strings } from "src/Utils/Localization";

function ForgetPassword({
  visible,
  setVisible,
  changePassword,
  forgetPassword,
  changePasswordVisible,
  setChangePasswordVisible,
  loading,
}: any) {
  const [email, setEmail] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      email: "",
      verificationCode: "",
      password: "",
      cnfPassword: "",
    },
  });

  const {
    handleSubmit: handleChangePwdSubmit,
    register: registerChangePwd,
    formState: { errors: changePwdError },
    watch: watchChangePwd,
  } = useForm({
    defaultValues: {
      email: "",
      verificationCode: "",
      password: "",
      cnfPassword: "",
    },
  });

  const onApply = handleSubmit((_) => {
    console.log("VALUES", _);
    forgetPassword(_);
    setEmail(_.email);
  });

  const onChangeSubmit = handleChangePwdSubmit((_) => changePassword(_, email));

  useEffect(() => {
    setChangePasswordVisible(false);
  }, [setChangePasswordVisible, visible]);

  return (
    <Fragment>
      <div
        className={`modal fade ${visible ? "show" : ""}`}
        id="forgot_password"
        style={{ display: visible ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content border-0">
            <div className="modal-body p-4 p-sm-5 custom_modal">
              <VisibilityToggle visible={!changePasswordVisible}>
                <div className="forget-password">
                  <button
                    className="cancelIcon"
                    data-dismiss="modal"
                    onClick={() => setVisible(false)}
                  >
                    {strings?.cross_sign}
                  </button>
                  <h3 className="poppinssemibold">{strings.forget_password}</h3>
                  <div className="form-group mb-4">
                    <label className="mb-0">
                      {strings.enter_reset_pswd_mssg}
                    </label>
                  </div>
                  <form onSubmit={onApply}>
                    <div className="form-group">
                      <input
                        type="email"
                        className={`form-control fz12 ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder={strings?.enter_email}
                        // name={"email"}
                        {...register("email", {
                          required: {
                            value: true,
                            message: strings?.email_is_required,
                          },
                          pattern: {
                            value:
                              /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                            message: strings?.email_address_is_invalid,
                          },
                        })}
                      />
                      <VisibilityToggle visible={errors.email ? true : false}>
                        <p className="text-danger">{errors.email?.message}</p>
                      </VisibilityToggle>
                    </div>
                    <div className="d-flex justify-content-center mt-4 text-right">
                      <button className="btn custom_btn" type="submit">
                        {strings.submit}
                      </button>
                    </div>
                  </form>
                </div>
              </VisibilityToggle>

              <VisibilityToggle visible={changePasswordVisible}>
                <div className="">
                  <form onSubmit={onChangeSubmit}>
                    <div className="form-group mb-4">
                      <label className="mb-0">{strings?.change_password}</label>
                    </div>
                    <div className="form-group">
                      <input
                        type="verificationCode"
                        className={`form-control fz12 ${
                          changePwdError.verificationCode ? "is-invalid" : ""
                        }`}
                        placeholder={strings?.enter_verification_code}
                        // name={"verificationCode"}
                        {...registerChangePwd("verificationCode", {
                          required: {
                            value: true,
                            message: strings?.verification_required,
                          },
                          maxLength: {
                            value: 6,
                            message: strings?.verification_invalid,
                          },
                          minLength: {
                            value: 6,
                            message: strings?.verification_invalid,
                          },
                        })}
                        maxLength={6}
                      />
                      <VisibilityToggle
                        visible={changePwdError.verificationCode ? true : false}
                      >
                        <p className="text-danger">
                          {changePwdError.verificationCode?.message}
                        </p>
                      </VisibilityToggle>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className={`form-control fz12 ${
                          changePwdError.password ? "is-invalid" : ""
                        }`}
                        placeholder={strings?.password}
                        // name={"password"}
                        {...registerChangePwd("password", {
                          required: {
                            value: true,
                            message: strings?.password_is_required,
                          },
                        })}
                      />
                      <VisibilityToggle
                        visible={changePwdError.password ? true : false}
                      >
                        <p className="text-danger">
                          {changePwdError.password?.message}
                        </p>
                      </VisibilityToggle>
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className={`form-control fz12 ${
                          changePwdError.password ? "is-invalid" : ""
                        }`}
                        placeholder={strings?.confirm_password}
                        // name={"cnfPassword"}
                        {...registerChangePwd("cnfPassword", {
                          required: {
                            value: true,
                            message: strings["confirm_pwd-reqd"],
                          },
                          validate: {
                            value: (value: any) =>
                              value === watchChangePwd("password")
                                ? true
                                : strings.your_pwd_do_not_match,
                          },
                        })}
                      />
                      <VisibilityToggle
                        visible={changePwdError.cnfPassword ? true : false}
                      >
                        <p className="text-danger">
                          {changePwdError.cnfPassword?.message}
                        </p>
                      </VisibilityToggle>
                    </div>
                    <div className="d-flex justify-content-center mt-4 text-right">
                      <button
                        className="btn custom_btn mr-2 text-secondary bg-transparent border border-secondary"
                        data-dismiss="modal"
                        onClick={() =>
                          changePasswordVisible
                            ? setChangePasswordVisible(false)
                            : setVisible(false)
                        }
                      >
                        {changePasswordVisible
                          ? strings?.back
                          : strings?.cancel}
                      </button>
                      <button
                        // disabled={loading}
                        type="submit"
                        className="btn custom_btn"
                        onClick={
                          changePasswordVisible ? onChangeSubmit : onApply
                        }
                      >
                        <VisibilityToggle visible={loading}>
                          <span className="spinner-border spinner-border-sm"></span>
                          &nbsp;
                        </VisibilityToggle>
                        {strings?.submit}
                      </button>
                    </div>
                  </form>
                </div>
              </VisibilityToggle>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={visible}>
        <div
          className={`modal-backdrop fade ${visible ? "show" : ""}`}
          onClick={() => setVisible(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default ForgetPassword;
