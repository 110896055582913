import React from "react";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import ReadMoreLessMore from "src/Components/Custom/ReadMoreLessMore/ReadMoreLessMore";
import FeedItemFooter from "../FeedItemFooter/FeedItemFooter";

type AUDIO_VIDEO_PROPS_TYPES = {
  item: any;
};

function AudioVideoItem(props: AUDIO_VIDEO_PROPS_TYPES) {
  const { item } = props;
  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="post_content_wrap pt-4">
        <div className="slider_section1 custom_group_Slider mb-3">
          {(item?.images ?? []).length !== 0 && (
            <Slider {...detilaSlider}>
              {(item?.images ?? []).map(
                (audioVideoItem: any, index: number) => {
                  return (
                    <div
                      className="detilaSlider_Item"
                      key={`audioVideoItem${index}`}
                    >
                      <img
                        src={audioVideoItem?.url}
                        className="img-fluid w-100 rounded"
                        alt="hotelimg"
                      />
                    </div>
                  );
                }
              )}
            </Slider>
          )}
          {(item?.videos ?? []).length !== 0 &&
            ReactPlayer.canPlay(item?.videos[0]?.url) && (
              <ReactPlayer url={item?.videos[0]?.url} width={"100%"} height={'300px'} />
            )}
        </div>

        {/* description */}
        <div className="Safety-description">
          <p className="mb-0 fz14 poppinsregular post_message mt-3 post_description readmore">
            <ReadMoreLessMore text={item?.message} showChar={250} />
          </p>
        </div>
        {/* description-close */}

        <div className="px-0">
          <hr />
        </div>

        {/* Like-and-comment-section-start */}
        <FeedItemFooter item={item} />
        {/* Like-and-comment-section-close */}
      </div>
    </>
  );
}

export default AudioVideoItem;
