import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useMyProfieQuery from "src/Modules/MyProfileModule/Hooks/useMyProfieQuery";
import images from "src/Utils/images";
import useChatEmit from "../../Hooks/useChatEmit";
import { EVENTS } from "../../Hooks/useChatListeners";
import useChatValue, {
  getChatValue,
  setChatValue,
} from "../../Hooks/useChatValue";
import "./ChatHeader.css";

function ChatHeader() {
  const { data } = useMyProfieQuery();
  const userProfile = data?.data?.Items[0] ?? {};
  const navigate = useNavigate();
  const { emitEventWS } = useChatEmit();
  const { isBlocked, hasBlocked } = useChatValue();
  const params = useParams();

  const blockUser = () => {
    emitEventWS(
      EVENTS.BLOCK_USER,
      {
        receiverId: params?.id,
      },
      (data: any) => {
        const blockedData = JSON.parse(data?.body);
        setChatValue({
          ...getChatValue(),
          hasBlocked: true,
          blockedUser: blockedData?.data ?? [],
        });
      }
    );
  };

  const unBlockUser = () => {
    emitEventWS(
      EVENTS.UNBLOCK_USER,
      {
        receiverId: params?.id,
      },
      (data: any) => {
        setChatValue({
          ...getChatValue(),
          hasBlocked: false,
          blockedUser: [],
        });
      }
    );
  };
  return (
    <Fragment>
      <div className="header-box">
        <div className="header-row">
          <figure className="colleftheader mb-0">
            <div className="profileheaderrow">
              <img
                src={images.leftBackArrow}
                alt="arrow"
                width={"13px"}
                height={"20px"}
                onClick={() => navigate("/messages")}
              />
              <figcaption className="headerprofileColLeft">
                <ul className="list-item">
                  <li>
                    <div className="user-profile">
                      <img
                        src={
                          userProfile?.picture
                            ? userProfile?.picture
                            : images.profileCamera
                        }
                        alt="img"
                      />
                    </div>
                  </li>
                  <li>
                    <h4 className="header-user-name poppinssemibold mb-0">
                      {`${userProfile?.name} ${userProfile?.family_name}`}
                    </h4>
                    <p className="ml-2 fz11 regular">
                      {userProfile?.city_name} {userProfile?.state_name}
                    </p>
                  </li>
                </ul>
              </figcaption>
            </div>
          </figure>
          {/* right-dropdown-box-start */}
          {!isBlocked && (
            <div className="colrightheader">
              <div className={`slider_down_arrow position-relative pl-4`}>
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                <div className={`user_actions user_actions_posLTR`}>
                  <ul className={`list-unstyled text-left mb-0 poppinsbold custom-message-dropdown`}>
                    <li>
                      <a
                        className="py-4 customdropdownblock"
                        href="/"
                        onClick={(e: any) => {
                          e.preventDefault();
                          !hasBlocked ? blockUser() : unBlockUser();
                        }}
                      >
                        <i
                          className="fa fa-user-secret mr-4 fz18"
                          aria-hidden="true"
                        ></i>
                        <span>{!hasBlocked ? "Block" : "Unblock"}</span>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="py-4 text-danger customdropdownblock"
                        href="/"
                        onClick={(e: any) => {
                          e.preventDefault();
                          unBlockUser();
                        }}
                      >
                        <i
                          className="fa fa-user-times mr-4 fz18"
                          aria-hidden="true"
                        ></i>
                        <span>Unblock</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {/* right-dropdown-close */}
        </div>
      </div>
    </Fragment>
  );
}
export default ChatHeader;
