import { Link } from "react-router-dom";
import images from "src/Utils/images";
import { strings } from "src/Utils/Localization";

function SaleItem(props: any) {
  const { item } = props;
  const { images:imageList, title, sale_price, user_image, user_name, condition } = item;
  const imgUrl = imageList.length ? imageList[0].url : "";

  return (
    <Link to={`/sales/${item?.id}`} className="saleCardItem">
      <div className="col_selector">
        <div className="furniture_card">
          <img src={imgUrl} className="img-fluid w-100" alt="" />
          <figure className="row justify-content-between m-0 px-2 py-2 saleItem_Box">
            <div className="leftColItemPrice">
              <h3 className="fz14 poppinsbold item_Price mb-0 pt-1">
                <span className="currenyIcon">{strings.dollar_sign}</span>
                {sale_price === 0 ? strings?.free : `USD ${sale_price}`}
              </h3>
            </div>
            {/* <div className="rightColFavoriteCol">
              <img
                src="/assets/images/icons/heart.png"
                alt="icon"
                width="18px"
                height="18px"
              />
            </div> */}
          </figure>
          <p className="fz14 mb-0 poppinsregular paraSaleItem px-2 pb-3 text-break text-left">
            {title}
          </p>
          <div className="row justify-content-between align-items-center m-0 px-2 pb-3">
            <h6 className="colLeftUsed mb-0">{condition ?? "NA"}</h6>
            <div className="profileRightCol">
              <div className="profileBox">
                <img
                  src={
                    user_image
                      ? user_image
                      : images.dummyProfile
                  }
                  alt="img"
                />
              </div>
              <h5 className="fz12">{user_name}</h5>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default SaleItem;
