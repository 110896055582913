import React, { Fragment } from "react";
import CopyRight from "src/Components/Custom/CopyRight/CopyRight";
import Footer from "src/Components/Custom/Footer/Footer";
import NavBar from "src/Components/Custom/NavBar/NavBar";
import images from "src/Utils/images";
import { strings } from "src/Utils/Localization";

function IntroPage() {
  return (
    <Fragment>
      <header>
        <NavBar />
      </header>
      <section className="home_banner pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="col_selector">
                <h2 className="poppinssemibold mb-3">
                  {strings.the_private_social_network} <br />{" "}
                  {strings.for_your_community}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="bannerImg">
          <img src={images.homeBanner} className="" alt="banner" />
        </div>
      </section>

      <section className="known_banner">
        <div className="container">
          <div className="row">
            <div className="col-md-7"></div>
            <div
              className="col-md-5 d-flex align-items-center"
              style={{ minHeight: 660 }}
            >
              <div className="col_selector">
                <div className="known_things position-relative">
                  <img src={images.tick} className="img-fluid" alt="images" />
                  <div className="social-network">
                    <h4
                      className="mb-2 fz18 poppinssemibold"
                      style={{ color: "#F55767" }}
                    >
                      {strings.useful}
                    </h4>
                    <p className="mb-0 fz14 poppinsregular">
                      {strings.the_easiest_way_keep_your_community}
                    </p>
                  </div>
                </div>
                <div className="known_things position-relative">
                  <img src={images.lock} className="img-fluid" alt="images" />
                  <div className="social-network">
                    <h4
                      className="mb-2 fz18 poppinssemibold"
                      style={{ color: "#2563FF" }}
                    >
                      {strings.private}
                    </h4>
                    <p className="mb-0 fz14 poppinsregular">
                      {strings.a_private_env_designed_your_neighbours}
                    </p>
                  </div>
                </div>
                <div className="known_things position-relative">
                  <img src={images.proven} className="img-fluid" alt="images" />
                  <div className="social-network">
                    <h4
                      className="mb-2 fz18 poppinssemibold"
                      style={{ color: "#40975F" }}
                    >
                      {strings.proven}
                    </h4>
                    <p className="mb-0 fz14 poppinsregular">
                      {strings.over_communities_rely_on_bloom}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="neighbours_section">
        <div className="container">
          <h2 className="text-center poppinssemibold base_color">
            {strings.when_neighbours_start_talking_good_things_happen}
          </h2>
          <div className="row">
            <div className="col-sm-6 col-md-4">
              <div className="col_selector">
                <img src={images.stronger} className="img-fluid" alt="images" />
                <h4
                  className="mt-4 mb-2 poppinssemibold fz18"
                  style={{ color: "#F55767" }}
                >
                  {strings.build_a_stronger_community}
                </h4>
                <p className="mb-0 fz14 poppinsregular">
                  {
                    strings.connect_with_neighbours_to_stay_informed_share_useful
                  }{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="col_selector">
                <img
                  src={images.neighbour}
                  className="img-fluid"
                  alt="images"
                />
                <h4
                  className="mt-4 mb-2 poppinssemibold fz18"
                  style={{ color: "#2563FF" }}
                >
                  {strings.keep_community_safe}
                </h4>
                <p className="mb-0 fz14 poppinsregular">
                  {strings.look_out_each_other_send_updates_Community_safe}{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="col_selector">
                <img
                  src={images.sharegood}
                  className="img-fluid"
                  alt="images"
                />
                <h4
                  className="mt-4 mb-2 poppinssemibold fz18"
                  style={{ color: "#40975F" }}
                >
                  {strings.share_goods_recommendations}
                </h4>
                <p className="mb-0 fz14 poppinsregular">
                  {
                    strings.find_a_great_babysitter_trusty_dentist_borrow_a_ladder_sell_that_old
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <CopyRight />
    </Fragment>
  );
}

export default IntroPage;
