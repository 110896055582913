import React from "react";
import "./FeedItemHeader.css";
import images from "src/Utils/images";
import moment from "moment";
import HomeFeedOptions from "../HomeFeedOptions/HomeFeedOptions";

type FEED_ITEM_HEADER = {
  item: any;
};
function FeedItemHeader(props: FEED_ITEM_HEADER) {
  const { item } = props;
  return (
    <>
      <div className="safetyHeaderRow">
        {/* Leftcol-profile-box */}
        <div className="safetyColLeft">
          <figure className="mb-0 safetyProfileRow">
            <div className="post-profile">
              <img
                src={
                  item?.user_image !== ""
                    ? item?.user_image
                    : images.profileCamera
                }
                width="100%"
                alt="profile"
              />
            </div>
            <figcaption className="rightProfileName">
              <ul className="mb-0 pl-0">
                <li>
                  <h4 className="fz16 poppinssemibold mb-0">
                    {item?.user_name}
                  </h4>
                </li>
                <li>
                  <small className="ml-0 fz10">
                    {item?.timestamp
                      ? moment(new Date(item?.timestamp)).fromNow()
                      : ""}
                  </small>
                </li>
              </ul>
            </figcaption>
          </figure>
        </div>
        {/* Leftcol-profile-close */}

        {/* Right-col-dropdown */}
        <HomeFeedOptions item={item} />
        {/* Right-col-dropdown-close */}
      </div>
    </>
  );
}

export default FeedItemHeader;
