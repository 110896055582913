import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import NavBar from "src/Components/Custom/NavBar/NavBar";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useAuth from "src/Hooks/Custom/useAuth";
import CopyRight from "src/Components/Custom/CopyRight/CopyRight";
import Footer from "src/Components/Custom/Footer/Footer";
import VerficationModal from "src/Components/Custom/VerificationModal/VerificationModal";
import ForgetPassword from "src/Components/Custom/ForgetPassword/ForgetPassword";
import { strings } from "src/Utils/Localization";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

function LoginPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onChange",
  });
  const {
    login,
    loading,
    verificationLoading,
    verify,
    isVerificationVisible,
    setIsVerificationVisible,
    forgetPasswordVisible,
    setForgetPasswordVisible,
    changePassword,
    forgetPassword,
    changePasswordVisible,
    setChangePasswordVisible,
  } = useAuth();

  const onSubmit = handleSubmit(login);

  return (
    <Fragment>
      <header className="">
        <NavBar />
      </header>
      <section className="login_section login_header">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="col_selector"></div>
            </div>
            <div className="col-md-4">
              <div className="col_selector">
                <h2 className="poppinssemibold mb-2 text-right">
                  {strings.login}
                </h2>
                <form className="poppinsregular">
                  <div className="form-group">
                    <input
                      // name={"email"}
                      {...register("email", {
                        required: {
                          value: true,
                          message: strings.email_is_required,
                        },
                        pattern: {
                          value:
                            /[A-Za-z0-9._%+-]{1,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})/,
                          message: strings.email_address_is_invalid,
                        },
                      })}
                      type="text"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder={strings.email_address}
                    />
                    <VisibilityToggle
                      visible={errors.email?.message ? true : false}
                    >
                      <p className="text-danger">{errors.email?.message}</p>
                    </VisibilityToggle>
                  </div>
                  <div className="form-group">
                    <input
                      // name={"password"}
                      {...register("password", {
                        required: {
                          value: true,
                          message: strings.password_is_required,
                        },
                      })}
                      type="password"
                      className={`form-control ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      placeholder={strings.password}
                    />
                    <VisibilityToggle
                      visible={errors.password?.message ? true : false}
                    >
                      <p className="text-danger">{errors.password?.message}</p>
                    </VisibilityToggle>
                  </div>
                  <div className="form-group">
                    <button
                      disabled={loading}
                      className="btn custom_btn w-100"
                      onClick={onSubmit}
                    >
                      <VisibilityToggle visible={loading}>
                        <span className="spinner-border spinner-border-sm"></span>
                        &nbsp;
                      </VisibilityToggle>
                      {strings.login}
                    </button>
                  </div>
                  <div className="form-group">
                    <p className="mb-0 text-right">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          setForgetPasswordVisible(true);
                        }}
                        className="base_color"
                      >
                        {strings.forgot_password}
                      </a>
                    </p>
                  </div>
                  <div>
                    <p className="text-right fz14 poppinsregular">
                      {strings.dont_have_an_account}
                      <Link to={"/signup"} className="font-weight-bold ml-1">
                        {strings.sign_up}
                      </Link>
                    </p>
                  </div>
                </form>
                <button
                  onClick={() => {
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    });
                  }}
                >
                  Google Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <CopyRight />
      <VerficationModal
        email={watch("email")}
        cb={onSubmit}
        verificationLoading={verificationLoading}
        verify={verify}
        isVerificationVisible={isVerificationVisible}
        setIsVerificationVisible={setIsVerificationVisible}
      />
      <ForgetPassword
        visible={forgetPasswordVisible}
        loading={loading}
        setVisible={setForgetPasswordVisible}
        changePassword={changePassword}
        forgetPassword={forgetPassword}
        changePasswordVisible={changePasswordVisible}
        setChangePasswordVisible={setChangePasswordVisible}
      />
    </Fragment>
  );
}

export default LoginPage;
