import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReadMoreLessMore from "src/Components/Custom/ReadMoreLessMore/ReadMoreLessMore";
import images from "src/Utils/images";
import { strings } from "src/Utils/Localization";
import FeedItemFooter from "../FeedItemFooter/FeedItemFooter";
import "./SafetyItem.css";
type SAFETY_ITEM_PROPS = {
  item: any;
};
function SafetyItem(props: SAFETY_ITEM_PROPS) {
  const { item } = props;
  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="post_content_wrap pt-4">
        <div className="slider_section1 custom_group_Slider mb-3">
          <Slider {...detilaSlider}>
            {(item?.images).map((_item: any, index: number) => {
              return (
                <div className="detilaSlider_Item" key={`_item${index}`}>
                  <img
                    src={_item?.url}
                    className="img-fluid w-100 rounded"
                    alt="hotelimg"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        {/* Safety-Title */}
        <div className="safeyheadingRow">
          <img
            src={images.safetyIcon}
            alt="safety"
            width={"20px"}
            height={"18px"}
          />
          <span className="safetyHeading fz14 poppinsmedium">
            {strings?.post_about_safety}
          </span>
        </div>
        {/* Safety-Title-close */}

        {/* description */}
        <div className="Safety-description">
          <p className="mb-0 fz14 poppinsregular post_message mt-3 post_description readmore">
            <ReadMoreLessMore text={item?.message} showChar={250} />
          </p>
        </div>
        {/* description-close */}

        <div className="px-0">
          <hr />
        </div>

        {/* Like-and-comment-section-start */}
        <FeedItemFooter item={item} />
        {/* Like-and-comment-section-close */}
      </div>
    </>
  );
}

export default SafetyItem;
