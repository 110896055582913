import { useEffect, useState } from "react";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEditProfileMutation from "src/Modules/MyProfileModule/Hooks/useEditProfileMutation";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { strings } from "src/Utils/Localization";
import useStateListQuery from "src/Hooks/Shared/useStateListQuery";
import useCityListQuery from "src/Hooks/Shared/useCityListQuery";
import { useForm } from "react-hook-form";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GET_HOME_FEED_QUERY_KEY } from "src/Modules/HomeModule/Hooks/useHomeFeedInfiniteQuery";

const LOCATION_EVENT = "location-event";
export const locationModalEvent = () => {
  emitEvent(LOCATION_EVENT);
};
function LocationModal() {
  const { user } = useAuthValue();
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      state: "",
      city: "",
    },
    mode: "onChange",
  });

  const stateListQuery = useStateListQuery();
  const citiesListQuery = useCityListQuery(watch("state"));
  const stateList = stateListQuery?.data?.data?.Items ?? [];
  const cityList = citiesListQuery?.data?.data?.Items ?? [];

  useEventEmitter(LOCATION_EVENT, () => {
    setShowModal(true);
  });

  const stateVal = watch("state");

  useEffect(() => {
    if (stateVal !== "") {
      setValue("city", "");
    }
  }, [stateVal]);

  useEffect(() => {
    reset({
      state: user.attributes.state_id,
      city: user.attributes.city_id,
    });
  }, [reset, user.attributes.city_id, user.attributes.state_id]);

  const editProfileMutation = useEditProfileMutation();
  const updateLocation = handleSubmit((values) => {
    editProfileMutation?.mutate(
      {
        subId: user?.username,
        city_id: Number(values?.city),
        state_id: Number(values?.state),
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            const newData: any = {
              attributes: null,
              username: null,
            };
            newData.attributes = {
              ...responseData?.data?.Items[0],
              username: responseData?.data?.Items[0]?.subId,
              "custom:city_id": `${responseData?.data?.Items[0]?.city_id}`,
              "custom:state_id": `${responseData?.data?.Items[0]?.state_id}`,
            };
            newData.username = responseData?.data?.Items[0]?.subId;
            setAuthValue({
              ...getAuthValue(),
              user: newData,
            });
            setShowModal(false);
            queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
          }
        },
      }
    );
  });

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="location_modal"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        {/* <div
        className="modal fade"
       
        data-backdrop="static"
        data-keyboard="false"
      > */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title poppinssemibold">
                {strings?.select_community}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body custom_modal">
              <form onSubmit={updateLocation}>
                <div className="mb-3">
                  <select
                    className={`form-control ${
                      errors.state ? "is-invalid" : ""
                    }`}
                    {...register("state", {
                      required: {
                        value: true,
                        message: strings?.state_reqd,
                      },
                    })}
                  >
                    <option value="">Select State</option>
                    {stateList.map((stateItem: any, index: number) => {
                      return (
                        <option value={stateItem?.id} key={`state_${index}`}>
                          {stateItem?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.state && (
                    <span className="ml-3 text-danger">
                      {errors.state.message}
                    </span>
                  )}
                </div>

                <div>
                  <select
                    className={`form-control ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    {...register("city", {
                      required: {
                        value: true,
                        message: strings?.city_reqd,
                      },
                    })}
                  >
                    <option value="">Select City</option>
                    {cityList.map((cityItem: any, index: number) => {
                      return (
                        <option value={cityItem?.id} key={`city_${index}`}>
                          {cityItem?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.city && (
                    <span className="ml-3 text-danger">
                      {errors.city.message}
                    </span>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={editProfileMutation?.isLoading}
                    className="update_Location"
                  >
                    <VisibilityToggle visible={editProfileMutation?.isLoading}>
                      <span className="spinner-border spinner-border-sm"></span>
                    </VisibilityToggle>
                    {strings?.update} {strings?.location}
                  </button>
                </div>
              </form>
              {/* <div className="form-group_custom position-relative">
                <Autocomplete
                  apiKey={GOOGLE_API_KEY}
                  inputMode="search"
                  key={refreshAutoComplete.toString()}
                  defaultValue={currentLocation}
                  placeholder="Search your location"
                  onPlaceSelected={(place) => {
                    console.log("Places", place.address_components);

                    place.address_components.map((item: any, index: number) => {
                      if (item.types[0] === "administrative_area_level_1") {
                        console.log("State", item.long_name);
                        setUserLocation((prevState: any) => {
                          return {
                            ...prevState,
                            state: item.long_name,
                          };
                        });
                      }
                      if (item.types[0] === "locality") {
                        console.log("City", item.long_name);
                        setUserLocation((prevState: any) => {
                          return {
                            ...prevState,
                            city: item.long_name,
                          };
                        });
                      }
                    });
                  }}
                  options={{
                    types: ["address"],
                  }}
                />
                <i className="fa fa-search " aria-hidden="true"></i>
              </div> */}

              {/* <button
                className="location_button pt-3 mb-0 border-0 bg-transparent"
                onClick={getCurrentLocation}
              >
                <img src={images.currentlocation} alt="current_location" />
                {isLoading && <Loader />}
                <span className="curent_location fz13 poppinsmedium pl-2">
                 {strings?.use_current_location}
                </span>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
export default LocationModal;
