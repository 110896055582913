import useChatValue, { getChatValue, setChatValue } from "./useChatValue";
import useChatEvent from "./useChatEvent";
import useChatEmit from "./useChatEmit";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { useNavigate, useParams } from "react-router-dom";

export const EVENTS = {
  INIT: "init",
  MANUAL_DISCONNECT: "manualDisconnect",
  CHAT_LIST: "chatList",
  CHAT_HISTORY: "chatHistory",
  SEND_MESSAGE: "sendMessage",
  ON_CONNECT: "connect",
  ON_DISCONNECT: "disconnect",
  ON_RECEIVE_MESSAGE: "receiveMessage",
  ON_RECEIVE_CHAT: "receiveChatList",
  ON_USER_ONLINE: "userOnline",
  ON_USER_OFFLINE: "userOffline",
  ON_LISTEN_BLOCK: "listenBlock",
  GET_CHAT_LIST: "chatParticipantsList",
  BLOCK_USER: "blockUser",
  UNBLOCK_USER: "unBlockUser",
};

function useChatListeners() {
  const { user } = useAuthValue();
  const { emitEventWS } = useChatEmit();
  const { ws } = useChatValue();
  const navigate = useNavigate();
  const params = useParams();

  useChatEvent(ws, EVENTS.ON_CONNECT, () => {
    emitEventWS(
      EVENTS.INIT,
      {
        subId: user?.username,
      },
      (data: any) => {
        setChatValue({
          ...getChatValue(),
          socketConnected: true,
        });
        if (data.status === 200) {
        }
      }
    );
  });

  useChatEvent(ws, EVENTS.ON_RECEIVE_MESSAGE, (data) => {
    const { chatHistory } = getChatValue();
    console.log("DATA/////////", data);
    if (data?.senderId === params?.id) {
      setChatValue({
        ...getChatValue(),
        chatHistory: [data, ...(chatHistory ?? [])],
      });
    } else {
      SnackbarHandler.notificationToast(
        {
          title: data?.senderName,
          body: data?.message,
        },
        () => {
          navigate(`/messages/${data?.senderId}`);
        }
      );
    }
  });

  useChatEvent(ws, EVENTS.ON_LISTEN_BLOCK, (data) => {
    if (data?.senderId === params?.id) {
      setChatValue({
        ...getChatValue(),
        isBlocked: data?.isBlocked === 1 ? true : false,
        blockedUser: data?.blocked ?? [],
      });
    }
  });

  useChatEvent(ws, EVENTS.ON_RECEIVE_CHAT, (data) => {
    const list = JSON.parse(data?.body);
    setChatValue({
      ...getChatValue(),
      participantList: list?.data,
    });
  });
}

export default useChatListeners;
