import React, { useMemo } from "react";
import useMyPostsListInfiniteQuery, {
  extractMyPostsList,
} from "../../Hooks/useMyPostsListInfiniteQuery";
import HomeFeedItem from "src/Modules/HomeModule/Components/HomeFeedItem/HomeFeedItem";
import InfiniteScroll from "react-infinite-scroll-component";
import ConfirmDeletePostModal from "src/Modules/HomeModule/Components/ConfirmDeletePostModal/ConfirmDeletePostModal";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import PollModal from "src/Modules/PollModule/Components/PollModal/PollModal";

function MyPostsList() {
  const myPostsListInfiniteQuery = useMyPostsListInfiniteQuery();
  console.log("myPostsListInfiniteQuery", myPostsListInfiniteQuery);
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    myPostsListInfiniteQuery;
  const myPostsList = useMemo(
    () => extractMyPostsList(myPostsListInfiniteQuery?.data?.pages ?? []),
    [myPostsListInfiniteQuery?.data?.pages]
  );

  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={myPostsList.length}
        next={fetchData}
        hasMore={true}
        loader={isLoading && <Loader />}
        endMessage={
          <p className="text-center">
            <b>{strings.u_have_seen_all}</b>
          </p>
        }
        refreshFunction={refetch}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h6 className="text-center">
            &#8595; {strings.pull_down_to_refresh}
          </h6>
        }
        releaseToRefreshContent={
          <h6 className="text-center">&#8593; {strings.release_to_refresh}</h6>
        }
      >
        {(myPostsList ?? []).map((item: any, index: number) => (
          <HomeFeedItem key={index} item={item} />
        ))}
      </InfiniteScroll>
      <ConfirmDeletePostModal />
      <PollModal />
    </>
  );
}

export default MyPostsList;
