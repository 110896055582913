import { AxiosResponse } from "axios";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useAuthValue, { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_BUSINESS_POST_DETAILS = (postId: any) => [
  "getBusinessDetails",
  postId,
];

const getBusinessDetails = async (postId: any): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.businessPostDetail}?subId=${user?.username}&postId=${postId}`,
    method: "GET",
  });
};
function useBusinessPostDetailsQuery() {
  const params = useParams();
  console.log("ppppppppppppppppp", params);
  return useQuery(GET_BUSINESS_POST_DETAILS(params?.id), () =>
    getBusinessDetails(params?.id)
  );
}

export default useBusinessPostDetailsQuery;
