import React, { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PollItem from "src/Modules/PollModule/Components/PollItem/PollItem";
import FeedItemHeader from "../FeedItemHeader/FeedItemHeader";
import SafetyItem from "../FeedItems/SafetyItem/SafetyItem";
import EventItem from "../FeedItems/EventItem/EventItem";
import AudioVideoItem from "../FeedItems/AudioVideoItem/AudioVideoItem";
type HOME_FEED_ITEM_PROPS = {
  item: any;
};
function HomeFeedItem(props: HOME_FEED_ITEM_PROPS) {
  const { item } = props;
  console.log("Items", item);
  return (
    <Fragment>
      <div className="post mb-2 post_feed">
        <FeedItemHeader item={item} />
        <Fragment>
          {item?.type === "SAFETY" ? <SafetyItem item={item} /> : <Fragment />}
        </Fragment>
        <Fragment>
          {item?.type === "EVENT" ? <EventItem item={item} /> : <Fragment />}
        </Fragment>
        <Fragment>
          {item?.type === "POLL" ? <PollItem item={item} /> : <Fragment />}
        </Fragment>
        <Fragment>
          {item?.type === "GENERAL" ? (
            <AudioVideoItem item={item} />
          ) : (
            <Fragment />
          )}
        </Fragment>
        <Fragment>
          {item?.type === "POST" ? (
            <AudioVideoItem item={item} />
          ) : (
            <Fragment />
          )}
        </Fragment>
      </div>
    </Fragment>
  );
}

export default HomeFeedItem;
