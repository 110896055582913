import axios, { AxiosRequestConfig } from "axios";
import SnackbarHandler from "./SnackbarHandler";
import uuid from "react-uuid";

export const fetcher = (config: AxiosRequestConfig) => {
  const { url, method = "GET", data, headers }: any = config;

  return axios.request({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    url,
    method,
    data,
    headers: {
      ...headers,
    },
    ...config,
  });
};

export const FILE_URL = process.env.NEXT_PUBLIC_FILE_URL;

export const onError = (error: any) => {
  if (error?.response) {
    // if (error?.response?.status === 401) {
    //   AuthService.resetAuthValue();
    // }
    if (error?.response?.data?.message) {
      SnackbarHandler.errorToast(error?.response?.data?.message);
    }
  } else {
  }
};

export const jsonToFormData = (data: any) => {
  const formData = new FormData();
  for (let key in data) {
    if (data[key] !== null && data[key] !== undefined) {
      if (Array.isArray(data[key])) {
        data[key].forEach((element: any) => {
          formData.append(key, element);
        });
      } else {
        formData.append(key, data[key]);
      }
    }
  }
  return formData;
};

export const convertImagesToBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = function () {
      resolve(fileReader.result);
    };
    fileReader.onerror = function (error) {
      console.log("Error: ", error);
      reject(error);
    };
  });
};

export const getBase64 = (
  file: File,
  cb: (base64: string | ArrayBuffer | null) => void
) => {
  if (typeof window !== "undefined") {
    let reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
    }
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  }
};

export const fetcherUrl = (config: AxiosRequestConfig) => {
  const { url, method = "GET", data } = config;
  return axios.request({
    url,
    method,
    data,
    ...config,
  });
};

//----------------for socket-----------------//

export const getNextPageParam = (lastPage: any) => {
  const { count, limit, page } = lastPage.data.data;
  const totalPages = Math.ceil(count / limit);
  return totalPages > page ? page + 1 : undefined;
};

export const combineStringIds = (id1: string, id2: string) => {
  return `${id1}${id2}`
    .split("")
    .sort((a, b) => a.charCodeAt(0) - b.charCodeAt(0))
    .join("");
};

export const getUniqueId = () => {
  const deviceId = localStorage.getItem("deviceId");
  if (deviceId) {
    return deviceId;
  }
  const uid = uuid();
  localStorage.setItem("deviceId", uid);
  return uid;
};
