import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { useForm } from "react-hook-form";
import useReportPostMutation from "src/Modules/HomeModule/Hooks/useReportPostMutation";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEventEmitter from "src/Hooks/Shared/useEventEmmiter";
import { useParams } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const REPORT_BUSINESS_EVENT = "reportEvent";

export const reportBusinessPostModal = () => {
  emitEvent(REPORT_BUSINESS_EVENT);
};

function BusinessPostReportModal() {
  const { user } = useAuthValue();
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      message: "",
    },
    mode: "onChange",
  });

  useEventEmitter(REPORT_BUSINESS_EVENT, () => {
    setShowModal(true);
  });
  const params = useParams();

  const reportBusinessPostMutation = useReportPostMutation();
  const handleReportBusinessPostApi = (values: any) => {
    return reportBusinessPostMutation.mutate(
      {
        subId: user?.username,
        type: "business",
        postId: Number(params?.id),
        message: values.message,
      },
      {
        onSuccess: () => {
          reset();
          setShowModal(false);
        },
      }
    );
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form onSubmit={handleSubmit(handleReportBusinessPostApi)}>
                  {/* <form> */}
                  <div className={`form-group`}>
                    <label className="mb-0">{strings.write_a_report}</label>
                  </div>
                  <div className={`form-group `}>
                    <textarea
                      // name="message"
                      className="form-control"
                      placeholder={strings.write_a_message}
                      {...register("message", {
                        required: strings.msg_reqd,
                      })}
                    ></textarea>
                    {errors?.message?.message && (
                      <span className="ml-3 text-danger">
                        {errors.message?.message}
                      </span>
                    )}
                  </div>
                  <div className="d-sm-flex justify-content-between">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn custom_btn mr-3 border text-secondary bg-transparent fz14"
                        onClick={() => setShowModal(false)}
                      >
                        {strings.cancel}
                      </button>
                      <button type="submit" className="btn custom_btn fz14">
                        <VisibilityToggle
                          visible={reportBusinessPostMutation?.isLoading}
                        >
                          <span className="spinner-border spinner-border-sm"></span>
                        </VisibilityToggle>
                        {strings.submit}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default BusinessPostReportModal;
