import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { EDIT_GENERAL_POST_REQUEST } from "../Types/RequestTypes";

const editSalePost = async (
  data: EDIT_GENERAL_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.editGeneralPost,
    method: "POST",
    data: data,
  });
};

function useEditGeneralPostMutation() {
  return useMutation(editSalePost, {
    onSuccess: (responseData) => {
      SnackbarHandler.successToast("Post updated successfully");
    },
  });
}
export default useEditGeneralPostMutation;
