import { BehaviorSubject } from "rxjs";
import { CHAT_STATE } from "../Types/CommonTypes";

const chat$ = new BehaviorSubject<CHAT_STATE>({
  socketConnected: false,
  chatHistory: [],
  isBlocked: false,
  hasBlocked: false,
  participantList: [],
  blockedUser: [],
});

export default chat$;
