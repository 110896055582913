import { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import AuthAboutPage from "src/Pages/About/AuthAboutPage";
import AuthFAQsPage from "src/Pages/FAQs/AuthFAQsPage";
import TermsPage from "src/Pages/Terms/TermsPage";
import AuthContactUs from "src/Pages/ContactUs/AuthContactUs";

import ProtectedRoute from "./ProtectedRoute";
import PrivacyPage from "src/Pages/Privacy/PrivacyPage";
import LoginPage from "src/Pages/Login/LoginPage";
import SignupPage from "src/Pages/Signup/SignupPage";
import Sales from "src/Pages/Dashboard/Components/Sales/Sales";
import Home from "src/Pages/Dashboard/Components/Home/Home";
import IntroPage from "src/Pages/Intro/IntroPage";
import CreatePost from "src/Pages/CreatePost/CreatePost";
import HomePostDetailPage from "src/Pages/HomePostDetailPage/HomePostDetailPage";
import Poll from "src/Pages/Poll/Poll";
import Event from "src/Pages/Event/Event";

import GroupsDetailPage from "../Pages/GroupsDetailPage/GroupsDetailPage";
import Business from "src/Pages/Business/Business";
import Safety from "src/Pages/SafetyPost/Safety";
import CreateSale from "src/Pages/CreateSale/CreateSale";
import SaleDetailsPage from "src/Pages/SaleDetailsPage/SaleDetailsPage";
import GroupsPostDetailsPage from "src/Pages/GroupsPostDetailsPage/GroupsPostDetailsPage";
import EditSaleDetailPage from "src/Pages/EditSaleDetailPage/EditSaleDetailPage";
import MyPosts from "src/Pages/Dashboard/Components/MyPosts/MyPosts";
import Groups from "src/Pages/Dashboard/Components/Groups/Groups";
import BookmarkPosts from "src/Pages/Dashboard/Components/BookmarkPosts/BookmarkPosts";
import Profile from "src/Pages/Dashboard/Components/Profile/Profile";
import Settings from "../Pages/Settings/Settings";
import HiddenPosts from "src/Pages/Dashboard/Components/HiddenPosts/HiddenPosts";
import BusinessDetailPage from "src/Pages/BusinessDetailPage/BusinessDetailPage";
import EditSafetyPage from "src/Pages/EditSafetyPage/EditSafetyPage";
import EditGeneralPost from "src/Pages/EditGeneralPost/EditGeneralPost";
import EditEventPage from "src/Pages/EditEventPage/EditEventPage";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";
import { strings } from "src/Utils/Localization";
import MessagePage from "src/Pages/Message/MessagePage";
import MessageHistoryPage from "src/Pages/MessageHistory/MessageHistoryPage";
import NotificationPage from "src/Pages/Notification/NotificationPage";
import RewardsPage from "src/Pages/RewardsPage/RewardsPage";
import ShopsPage from "src/Pages/ShopsPage/ShopsPage";
import ShopifyPage from "src/Pages/ShopifyPage/ShopifyPage";
import MyCartPage from "src/Pages/MyCartPage/MyCartPage";
import MyOrderPage from "src/Pages/MyOrderPage/MyOrderPage";
import { Auth } from "aws-amplify";
import axios from "axios";
import EndPoints from "src/Constants/EndPoints";

function AppRouter() {
  const { language } = useAuthValue();
  const [pageLoad, setPageLoad] = useState<boolean>(true);
  useEffect(() => {
    setPageLoad(!pageLoad);
    strings?.setLanguage(language ?? "en");
  }, [language]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        console.log("User: ", user);
        // const data = {
        //   subId: user?.username,
        //   city_id: "",
        //   state_id: "",
        // };
        // const getProfileDetails: any = await axios.post(
        //   `${EndPoints.editProfile}`,
        //   data
        // );
        // const profileObj = {
        //   attributes: null,
        //   username: null,
        // };
        // profileObj.attributes = {
        //   ...getProfileDetails?.data?.Items[0],
        //   username: getProfileDetails?.data?.Items[0]?.subId,
        // };
        // profileObj.username = getProfileDetails?.data?.Items[0]?.subId;
        setAuthValue({
          ...getAuthValue(),
          loggedIn: true,
          user: user,
        });
        setPageLoad(!pageLoad);
      })
      .catch((error) => {});
  }, []);

  return (
    <Fragment key={pageLoad?.toString()}>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <AuthRoute>
                <IntroPage />
              </AuthRoute>
            }
          />
          <Route
            path={"/login"}
            element={
              <AuthRoute>
                <LoginPage />
              </AuthRoute>
            }
          />
          <Route
            path={"/signup"}
            element={
              <AuthRoute>
                <SignupPage />
              </AuthRoute>
            }
          />
          <Route path={"/sales"} element={<Sales />} />
          <Route
            path={"/create-sale"}
            element={
              <ProtectedRoute>
                <CreateSale />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/sales/:id"}
            element={
              <ProtectedRoute>
                <SaleDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/sales/edit/:id"}
            element={
              <ProtectedRoute>
                <EditSaleDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/groups"}
            element={
              <ProtectedRoute>
                <Groups />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/rewards"}
            element={
              <ProtectedRoute>
                <RewardsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/shop"}
            element={
              <ProtectedRoute>
                <ShopsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/shop/:id`}
            element={
              <ProtectedRoute>
                <ShopifyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/my-cart`}
            element={
              <ProtectedRoute>
                <MyCartPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={`/my-order`}
            element={
              <ProtectedRoute>
                <MyOrderPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/groups/:id"}
            element={
              <ProtectedRoute>
                <GroupsDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/groups/:id/:postId"}
            element={
              <ProtectedRoute>
                <GroupsPostDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/create-post"}
            element={
              <ProtectedRoute>
                <CreatePost />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/edit-post/:id"}
            element={
              <ProtectedRoute>
                <EditGeneralPost />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/create-poll"}
            element={
              <ProtectedRoute>
                <Poll />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/create-safety"}
            element={
              <ProtectedRoute>
                <Safety />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/edit-safety/:id"}
            element={
              <ProtectedRoute>
                <EditSafetyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/create-event"}
            element={
              <ProtectedRoute>
                <Event />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/edit-event/:id"}
            element={
              <ProtectedRoute>
                <EditEventPage />
              </ProtectedRoute>
            }
          />
          <Route path={"/home"} element={<Home />} />
          <Route
            path={"/home/:id"}
            element={
              <ProtectedRoute>
                <HomePostDetailPage />
              </ProtectedRoute>
            }
          />
          <Route path={"/about"} element={<AuthAboutPage />} />
          <Route path={"/faq"} element={<AuthFAQsPage />} />
          <Route path={"/terms-and-condition"} element={<TermsPage />} />
          <Route path={"/contact-us"} element={<AuthContactUs />} />
          <Route path={"/privacy-policy"} element={<PrivacyPage />} />

          <Route
            path={"/business"}
            element={
              <ProtectedRoute>
                <Business />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/business/:id"}
            element={
              <ProtectedRoute>
                <BusinessDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/my-profile"}
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/hidden-posts"}
            element={
              <ProtectedRoute>
                <HiddenPosts />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/hidden-posts/:id"}
            element={
              <ProtectedRoute>
                <HomePostDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/my-posts"}
            element={
              <ProtectedRoute>
                <MyPosts />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/my-posts/:id"}
            element={
              <ProtectedRoute>
                <HomePostDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/bookmarks"}
            element={
              <ProtectedRoute>
                <BookmarkPosts />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/bookmarks/:id"}
            element={
              <ProtectedRoute>
                <HomePostDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/settings"}
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />

          <Route
            path={"/messages"}
            element={
              <ProtectedRoute>
                <MessagePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={"/notification"}
            element={
              <ProtectedRoute>
                <NotificationPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={"/messages/:id"}
            element={
              <ProtectedRoute>
                <MessageHistoryPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default AppRouter;
