import BusinessPostDetail from "src/Modules/BusinessModule/Components/BusinessPostDetail/BusinessPostDetail";
import Layout from "src/Modules/Layout/Layout";

function BusinessDetailPage() {
  return (
    <Layout>
      <div id="content" className={"ltr-content-padding"}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="col_selector">
                <div className="messages pt-4 post_feed">
                  <BusinessPostDetail />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default BusinessDetailPage;
