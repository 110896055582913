import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { ADD_BUSINESS_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_BUSINESS_LIST_QUERY_KEY } from "./useBusinessListInfiniteQuery";

const addBusinessPost = async (
  data: ADD_BUSINESS_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createBusinessPost,
    method: "POST",
    data: data,
  });
};

function useAddBusinessPostMutation() {
  return useMutation(addBusinessPost, {
    onSuccess: (responseData) => {
      if (responseData) {
        SnackbarHandler.successToast("Post created successfully");
        queryClient.refetchQueries(GET_BUSINESS_LIST_QUERY_KEY("", undefined));
      }
    },
  });
}

export default useAddBusinessPostMutation;
