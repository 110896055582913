import { useState } from "react";

type READ_MORE_LESS_MORE_PROPS = {
  text: string;
  showChar: number;
};
function ReadMoreLessMore(props: READ_MORE_LESS_MORE_PROPS) {
  const { text, showChar } = props;
  const [showMore, setShowMore] = useState<boolean>(false);
  return (
    <>
      {showMore ? text : text?.substring(0, showChar)}
      {text?.length > showChar && (
        <button
          onClick={(e) => {
            e?.preventDefault();
            setShowMore(!showMore);
          }}
        >
          {!showMore ? "Show More" : " Less More"}
        </button>
      )}
    </>
  );
}

export default ReadMoreLessMore;
