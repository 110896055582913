import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import GroupItem from "../GroupItem/GroupItem";
import useNearByGroupsInfiniteQuery, {
  extractNearByGroups,
} from "../../Hooks/useNearByGroupsInfiniteQuery";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";

type NEAR_BY_GROUP_LIST_PROPS = {
  searchText: string;
};
function NearByGroupList(props: NEAR_BY_GROUP_LIST_PROPS) {
  const { searchText } = props;

  const groupsToJoinInfiniteQuery = useNearByGroupsInfiniteQuery(searchText);
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    groupsToJoinInfiniteQuery;
  const groupsToJoinList = useMemo(
    () => extractNearByGroups(groupsToJoinInfiniteQuery?.data?.pages ?? []),
    [groupsToJoinInfiniteQuery?.data?.pages]
  );
  // const postId: any = homeFeedList?.map((item) => item?.id);
  console.log("groupsToJoinList/////////", groupsToJoinList);
  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <h3 className={`fz16 mb-3`}>{strings.nearby_groups}</h3>
      <InfiniteScroll
        dataLength={groupsToJoinList.length} //This is important field to render the next data
        next={fetchData}
        hasMore={true}
        loader={isLoading && <Loader />}
        endMessage={
          <p className="text-center">
            <b>{strings.u_have_seen_all}</b>
          </p>
        }
        refreshFunction={refetch}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
      >
        {groupsToJoinList?.length !== 0
          ? groupsToJoinList.map((item: any, index: number) => (
              <GroupItem key={index} item={item} />
            ))
          : !isLoading && <p>{strings.no_near_gp_found}</p>}
      </InfiniteScroll>
    </>
  );
}

export default NearByGroupList;
