import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { LEAVE_GROUP_REQUEST } from "../Types/RequestTypes";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { GET_MY_GROUPS } from "./useMyGroupListInfiniteQuery";

const leaveGroup = async (
  data: LEAVE_GROUP_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.leaveGroup,
    method: "POST",
    data: data,
  });
};

function useLeaveGroupMutation() {
  return useMutation(leaveGroup, {
    onSuccess: (responseData) => {
      const { statusCode, message } = responseData?.data;

      if (statusCode === 200) {
        SnackbarHandler.successToast(message);
        queryClient.refetchQueries(GET_MY_GROUPS(""));
      }
    },
  });
}
export default useLeaveGroupMutation;
