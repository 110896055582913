import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import CSS from "csstype";

import SnackbarHandler from "src/Utils/SnackbarHandler";
import useCreatePostMutation from "src/Modules/HomeModule/Hooks/useCreatePostMutation";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const CREATE_POST_EVENT = "createPost";
export const createPost = () => {
  emitEvent(CREATE_POST_EVENT);
};

function PostModal(props: any) {
  const { user } = useAuthValue();

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [images, setImages] = useState<any>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [deleteImgs, setDeleteImgs] = useState<any>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const imageField: CSS.Properties = {
    width: "1",
    height: "1",
    opacity: "0",
    position: "absolute",
    overflow: "hidden",
    zIndex: -1,
  };

  const imgPreview: CSS.Properties = {
    width: "100%",
    height: "200px",
  };

  const crossIcon: CSS.Properties = {
    color: "#e01515",
    position: "absolute",
    right: "-2px",
    fontSize: "20px",
    cursor: "pointer",
    top: "-5px",
  };

  useEventEmitter(CREATE_POST_EVENT, () => {
    setShowModal(true);
  });

  const createPostApi = useCreatePostMutation();
  const handleCreatePostApi = () => {
    if (
      message.trim() === "" &&
      images.length === 0 &&
      imageUrls.length === 0
    ) {
      setMessageError("Post is required");
      return;
    }
    createPostApi.mutate(
      {
        subId: user?.username,
        message: message,
        images: images,
        delete_images: deleteImgs,
      },
      {
        onSuccess: () => {
          setShowModal(false);
          setMessage("");
          setImages([]);
          setImageUrls([]);
        },
      }
    );
  };

  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr = [...images];
    const urlsArr = [...imageUrls];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast("You can select maximum 5 images");
        return;
      }
    }
    setImages(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: number) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...images];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImages(imgsArr);
    setImageUrls(urlsArr);
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
        // onClick={() => setShowModal(false)}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form>
                  <div className={`form-group`}>
                    <label className="mb-0">{"Post a message"}</label>
                  </div>
                  <div className={`form-group`}>
                    <label>{strings.message}</label>
                    <textarea
                      value={message}
                      id="msgId"
                      className="form-control"
                      placeholder="Write a message"
                      onChange={(e) => {
                        setMessage(e.target.value);
                        e.target.value.length === 0 &&
                        images.length === 0 &&
                        imageUrls.length === 0
                          ? setMessageError("Post is required")
                          : setMessageError("");
                      }}
                    ></textarea>
                    {messageError && (
                      <p
                        className={`form-group`}
                        style={{
                          color: "red",
                          fontSize: "10px",
                          paddingTop: "50",
                        }}
                      >
                        {" "}
                        {messageError}{" "}
                      </p>
                    )}
                  </div>
                  <div className="d-sm-flex justify-content-between">
                    <div className="mb-3 mb-sm-0">
                      <label htmlFor="photos" className="mb-0">
                        <p className="d-inline-block btn custom_btn rounded text-secondary bg-transparent border fz15 mb-0">
                          <i
                            className="fa fa-camera mr-3 base_color fz18"
                            aria-hidden="true"
                          ></i>
                          {strings.add}
                        </p>
                      </label>
                      <input
                        id="photos"
                        style={imageField}
                        type="file"
                        accept="image/*"
                        multiple={true}
                        onChange={(e) => fileSelectedHandler(e.target.files)}
                      />
                    </div>
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn custom_btn mr-3 border text-secondary bg-transparent fz14"
                        onClick={() => setShowModal(false)}
                      >
                        {strings.cancel}
                      </button>
                      <button
                        type="button"
                        className="btn custom_btn fz14"
                        onClick={() => handleCreatePostApi()}
                      >
                        <VisibilityToggle visible={createPostApi?.isLoading}>
                          <span className="spinner-border spinner-border-sm"></span>
                        </VisibilityToggle>
                        {strings.submit}
                      </button>
                    </div>
                  </div>
                </form>
                <VisibilityToggle visible={imageUrls.length !== 0}>
                  <div className="row mt-3">
                    {imageUrls.map((img: any, i: number) => {
                      return (
                        <div key={i} className="col-md-6">
                          <div style={{ position: "relative" }}>
                            <img
                              key={i}
                              style={imgPreview}
                              alt="London"
                              src={img.url}
                              className="object_fit mb-3"
                            ></img>
                            <i
                              key={i + "cross"}
                              style={crossIcon}
                              className="fa fa-times-circle base_color"
                              aria-hidden="true"
                              onClick={() => handleImageRemove(i, img.id)}
                            ></i>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </VisibilityToggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div className={`modal-backdrop fade ${showModal ? "show" : ""}`}></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default PostModal;
