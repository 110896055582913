import React, { useState } from "react";
import Layout from "src/Modules/Layout/Layout";
import MyGroupList from "src/Modules/GroupModule/Components/MyGroupList/MyGroupList";
import NearByGroupList from "src/Modules/GroupModule/Components/NearByGroupList/NearByGroupList";
import { useDebounce } from "use-debounce";
import GroupAddModal, {
  addGroupEvent,
} from "src/Modules/GroupModule/Components/GroupAddModal/GroupAddModal";
import { strings } from "src/Utils/Localization";

function Groups() {
  const [search, setSearch] = useState<string>("");
  const [searchValue] = useDebounce(search, 500);

  return (
    <Layout>
      <div id="content" className={`ltr-content-padding`}>
        <div className="container-fluid">
          <div className="row">
            <>
              <div className="sale_Details-CenterCol">
                <div className="col_selector">
                  <h3 className="fz20 mb-3">{strings?.groups}</h3>
                  <div className="messages pt-4 update_message post_feed group_update">
                    <div className="d-flex justify-content-between mb-4 align-items-center px-3">
                      <div className="d-flex align-items-center w-100 justify-content-between position-relative cursor-pointer mbl_ResponsiveGrp_search_row">
                        <div className="custom-searchBar">
                          <input
                            value={search}
                            type="text"
                            className="form-control fz12 poppinsregular custom_input pr-5"
                            placeholder={strings?.search_groups}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <i className={`fa fa-search fa-search-ltr`}></i>
                        </div>
                        <button
                          onClick={() => addGroupEvent()}
                          className="groupBtn"
                        >
                          <i
                            className="fa fa-plus fz18 top_icon"
                            title="Plus"
                            aria-hidden="true"
                          ></i>
                          <span>{strings.add_gp}</span>
                        </button>
                      </div>
                    </div>
                    <div className="px-3">
                      <MyGroupList searchText={searchValue} />
                    </div>
                    <div className="px-3">
                      <NearByGroupList searchText={searchValue} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <GroupAddModal />
    </Layout>
  );
}

export default Groups;
