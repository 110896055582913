import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";
import { fetcher } from "src/Utils/Helpers";

export const GET_NOTIFICATION_LIST = ["getNotificationList"];

const getNotificationList = async (page: number): Promise<any> => {
  const { user } = getAuthValue();
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.notifications}?subId=${user?.username}&page=${page}`,
  });
  return response?.data;
};

export const extractNotificationList = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getNotificationNextPageParam = (lastPage: any) => {
  // console.log("Last Page", lastPage);
  const { Page, TotalPages } = lastPage;
  const nextPage = TotalPages > Number(Page) ? Number(Page) + 1 : undefined;
  console.log("Page", nextPage);
  return nextPage;
};

function useNotificationInfinteQuery() {
  return useInfiniteQuery(
    GET_NOTIFICATION_LIST,
    ({ pageParam = 1 }) =>
      getNotificationList(pageParam === null ? 1 : pageParam),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getNotificationNextPageParam,
    }
  );
}

export default useNotificationInfinteQuery;
