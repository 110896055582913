import { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useChatEmit from "../../Hooks/useChatEmit";
import { EVENTS } from "../../Hooks/useChatListeners";
import useChatValue, {
  setChatValue,
  getChatValue,
} from "../../Hooks/useChatValue";
import ChatInput from "../ChatInput/ChatInput";
import LeftBubble from "../LeftBubble/LeftBubble";
import RightBubble from "../RightBubble/RightBubble";
import "./ChatHistory.css";
import InfiniteScroll from "react-infinite-scroll-component";
import ChatHeader from "../ChatHeader/ChatHeader";
import moment from "moment";
import ChatDate from "../ChatDate/ChatDate";
import Loader from "src/Components/Shared/Loader/Loader";

function ChatHistory() {
  const [loader, setLoader] = useState<boolean>(false);
  const { user } = useAuthValue();
  const params: any = useParams();
  const { emitEventWS } = useChatEmit();
  const { ws } = useChatValue();
  const messageEl: any = useRef<any>(null);

  useEffect(() => {
    if (messageEl) {
      messageEl.current.addEventListener("DOMNodeInserted", (event: any) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, []);

  useEffect(() => {
    (() => {
      try {
        setLoader(true);
        emitEventWS(
          EVENTS.CHAT_HISTORY,
          {
            senderId: user?.username,
            receiverId: params?.id,
          },
          (responseData) => {
            const chatList: any = JSON.parse(responseData?.body);
            const hasBlocked = (chatList?.blocked ?? []).some(
              (_item: string) => _item === params?.id
            );
            setChatValue({
              ...getChatValue(),
              chatHistory: chatList?.data,
              blockedUser: chatList?.blocked ?? [],
              isBlocked: chatList?.isBlocked === 1 ? true : false,
              hasBlocked: hasBlocked,
            });
            setLoader(false);
          }
        );
      } catch (error: any) {
        setLoader(false);
      }
    })();

    return () => {
      setChatValue({
        ...getChatValue(),
        chatHistory: [],
        isBlocked: false,
        hasBlocked: false,
        blockedUser: [],
      });
    };
  }, [emitEventWS, params, params?.id, user?.username, ws]);
  const { chatHistory = [] } = useChatValue();

  return (
    <>
      {loader && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 post_feed notification_Box">
                {/* chat-section */}
                <div className="bublechat">
                  <div className="content">
                    <ChatHeader />
                    {/* chat-bubble-start */}
                    <div className="messages-box" ref={messageEl}>
                      <InfiniteScroll
                        dataLength={(chatHistory ?? []).length}
                        next={() => console.log("Hii")}
                        inverse={true}
                        hasMore={true}
                        loader={<Fragment />}
                        scrollableTarget="chatScreenBody"
                      >
                        <Fragment>
                          <ChatDate
                            date={
                              chatHistory[chatHistory?.length - 1]?.createdAt
                            }
                          />
                          <ul
                            style={{
                              display: "flex",
                              flexDirection: "column-reverse",
                            }}
                          >
                            {(chatHistory ?? []).map(
                              (item: any, index: number) => {
                                const isRightBubble =
                                  user?.username === item?.senderId;
                                const showChatDate =
                                  moment(
                                    chatHistory[index - 1]?.createdAt
                                  ).format("YYYY-MM-DD") !==
                                    moment(item?.createdAt).format(
                                      "YYYY-MM-DD"
                                    ) && chatHistory[index + 1]
                                    ? true
                                    : false;
                                return (
                                  <Fragment key={`chat${index}`}>
                                    {!isRightBubble && (
                                      <LeftBubble item={item} />
                                    )}
                                    {isRightBubble && (
                                      <RightBubble item={item} />
                                    )}
                                    {showChatDate && (
                                      <ChatDate
                                        date={chatHistory[index - 1]?.createdAt}
                                      />
                                    )}
                                  </Fragment>
                                );
                              }
                            )}
                          </ul>
                        </Fragment>
                      </InfiniteScroll>
                    </div>
                    {/* chat-bubble-close */}
                    <ChatInput />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
}

export default ChatHistory;
