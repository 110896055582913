import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { extractGroupPostList } from "../../Hooks/useGroupPostListInfiniteQuery";
import useGroupPostListInfiniteQuery from "../../Hooks/useGroupPostListInfiniteQuery";
import ReportModal from "src/Components/Custom/ReportModal/ReportModal";
import GroupPostListItems from "../GroupPostListItems/GroupPostListItems";
import ConfirmDeletePostModal from "src/Modules/HomeModule/Components/ConfirmDeletePostModal/ConfirmDeletePostModal";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
type PostListProps = {
  placeholderMessage: string;
  postData?: any;
  setPostData?: any;
  modalVisibility?: boolean;
  setModalVisibility?: any;
  is_private?: boolean;
  search?: string;
};

function GroupPostList({ placeholderMessage }: PostListProps) {
  const groupPostListInfiniteQuery = useGroupPostListInfiniteQuery();
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    groupPostListInfiniteQuery;
  const groupPostList = useMemo(
    () => extractGroupPostList(groupPostListInfiniteQuery?.data?.pages ?? []),
    [groupPostListInfiniteQuery?.data?.pages]
  );
  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      {isLoading && groupPostList?.length === 0 && (
        <p className="text-center">{placeholderMessage}</p>
      )}
      <>
        <InfiniteScroll
          dataLength={groupPostList.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          loader={isLoading && <Loader />}
          endMessage={
            <p className="text-center">
              <b>{strings.u_have_seen_all}</b>
            </p>
          }
          refreshFunction={refetch}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          {(groupPostList?.length ?? []) !== 0 ? (
            groupPostList.map((item: any, index: number) => (
              <GroupPostListItems key={index} item={item} />
            ))
          ) : (
            <p>{strings.no_posts_in_this_gp}</p>
          )}
        </InfiniteScroll>
        <ReportModal />
        <ConfirmDeletePostModal />
      </>
    </>
  );
}

export default GroupPostList;
