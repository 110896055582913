import React from "react";
import Layout from "src/Modules/Layout/Layout";
import CreatePoll from "src/Modules/PollModule/Components/CreatePoll/CreatePoll";

function Poll() {
  return (
    <Layout>
      <CreatePoll />
    </Layout>
  );
}

export default Poll;
