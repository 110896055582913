import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { ADD_REWARDS_POINT } from "../Types/RequestTypes";

const addReward = async (
  data: ADD_REWARDS_POINT
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.addReward,
    method: "POST",
    data: data,
  });
};

function useAddRewardPoints() {
  return useMutation(addReward);
}
export default useAddRewardPoints;
