import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { MAKE_ADMIN_REQUEST } from "../Types/RequestTypes";
import { useParams } from "react-router-dom";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GET_GROUP_ADMIN_LIST_KEY } from "./useGroupAdminMembersQuery";
import { GET_GROUP_DETAILS_KEY } from "./useGroupDetailsQuery";
import { GET_GROUP_MEMBERS_KEY } from "./useGroupMembersQuery";

const makeAdmin = async (
  data: MAKE_ADMIN_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.makeAdmin,
    method: "POST",
    data: data,
  });
};

function useMakeAdminMutation() {
  const params = useParams();
  return useMutation(makeAdmin, {
    onSuccess: (responseData) => {
      if (responseData) {
        queryClient.refetchQueries(GET_GROUP_MEMBERS_KEY(params?.id));
        queryClient.refetchQueries(GET_GROUP_DETAILS_KEY(Number(params?.id)));
        queryClient.refetchQueries(GET_GROUP_ADMIN_LIST_KEY(params?.id));
      }
    },
  });
}

export default useMakeAdminMutation;
