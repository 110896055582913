import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import LogoutConfirmModal, {
  logoutConfirmEvent,
} from "src/Modules/LogoutModule/LogoutConfirmModal/LogoutConfirmModal";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import LocationModal, {
  locationModalEvent,
} from "src/Modules/Layout/LocationModal/LocationModal";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";
import { addSearchFilterValue } from "src/Modules/HomeModule/Components/HomePostList/HomePostList";
import useNotificationCountQuery from "src/Modules/NotificationModule/Hooks/useNotificationCountQuery";
import useAddToCartValue from "src/Modules/ShopsModule/Hooks/useAddToCartValue";

function UserNavBar() {
  const { toggleMenu } = useAuthValue();
  const { data } = useNotificationCountQuery();
  const [search, setSearch] = useState<string>("");
  const { pathname } = useLocation();
  const { user } = useAuthValue();
  // const user_name = user?.attributes?.name;
  const navigate = useNavigate();
  console.log("DATA", data?.data?.Count);
  const notificationCount = data?.data?.Count;
  const myCart = useAddToCartValue();

  return (
    <div className="top-bar" id="top-bar">
      <div className="container">
        <div className="row align-items-center custom_Navbar_Row">
          <div
            className="col-12 col-sm-7 col-md-7 col-lg-5 col-xl-4"
            style={{ zIndex: 1000 }}
          >
            <div className="col_selector d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center navBar-location-logo-row">
                <div className="" style={{ zIndex: 1000 }}>
                  <div className={`mr-2 mr-sm-4 top_logo`}>
                    <Link
                      className="d-inline-block"
                      style={{ width: 100 }}
                      to="/home"
                    >
                      <img src={images.logo} className="img-fluid" alt="logo" />
                    </Link>
                  </div>
                </div>
                {/* search-location===== */}

                <div className="search_location">
                  <span className="location_pin">
                    <img
                      src={images.locationDark}
                      className="img-fluid"
                      alt="icon"
                    />
                  </span>
                  <ul className="list-item">
                    {" "}
                    <Link
                      to="/"
                      onClick={(e: any) => {
                        e.preventDefault();
                        locationModalEvent();
                      }}
                    >
                      <li>
                        <span className="location_find">
                          {strings.community}
                        </span>
                      </li>
                      {/* <li>
                        <span className="fz14 adrees_Text text-capitalize">
                          {user.attributes.city_name &&
                            `${user.attributes.city_name}, ${user.attributes.state_name}`}
                        </span>
                        <img
                          src={images.upChervon}
                          className="img-fluid pl-2"
                          alt="arrow"
                        />
                      </li> */}
                    </Link>
                  </ul>
                </div>
                {/* search-location-close===== */}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-5 col-md-5 col-lg-7 col-xl-8">
            <div className="row justify-content-end custom_rightSideProfile_Col">
              <form className={`mr-2 top_form desktop_search`}>
                <div className="form-group my-2 d-flex align-items-center position-relative home_search">
                  <VisibilityToggle visible={pathname === "/home"}>
                    <input
                      value={search}
                      type="text"
                      className="form-control custom_input border-0 border-radius-1 pr-5 input_customClr"
                      placeholder={strings.search}
                      onChange={(e) => {
                        addSearchFilterValue(e.target.value);
                        setSearch(e.target.value);
                      }}
                    />
                    <i
                      className={` fa fa-search fa-search-ltr`}
                      aria-hidden="true"
                    ></i>
                  </VisibilityToggle>
                </div>
              </form>
              <div className="d-flex align-items-center justify-content-end mt-4 mt-md-0 profile-MblRight_Box">
                <i
                  className="fa fa-bars mr-2 mr-sm-3 fz20 text-secondary d-md-none mblToggle_Icon"
                  onClick={() => {
                    setAuthValue({
                      ...getAuthValue(),
                      toggleMenu: !toggleMenu,
                    });
                  }}
                  aria-hidden="true"
                ></i>
                <VisibilityToggle visible={pathname === "/home"}>
                  <a
                    href="/"
                    className={`d-inline-block custom_Plus_Icon position-relative ml-4`}
                    onClick={(e) => {
                      e?.preventDefault();
                      // createPost();
                    }}
                  >
                    <i
                      className="fa fa-plus fz16 top_icon"
                      title="Plus"
                      aria-hidden="true"
                    ></i>
                    <div className="custom_Top_Dropdown">
                      <ul>
                        <li>
                          <Link to="/create-post">
                            <span className="icon_Circle">
                              <img src={images.photoIco} alt="icon" />
                            </span>
                            <p className="mb-0">
                              {strings.create_general_post}
                            </p>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-sale">
                            <span className="icon_Circle">
                              <img src={images.sellIco} alt="icon" />
                            </span>
                            <p className="mb-0">
                              {strings.sell_or_give_away_an_item}
                            </p>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-poll">
                            <span className="icon_Circle">
                              <img src={images.pollIco} alt="icon" />
                            </span>
                            <p className="mb-0">
                              {strings.poll_your_neighbours}
                            </p>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-safety">
                            <span className="icon_Circle">
                              <img src={images.safetyIco} alt="icon" />
                            </span>
                            <p className="mb-0">{strings.post_about_safety}</p>
                          </Link>
                        </li>
                        <li>
                          <Link to="/create-event">
                            <span className="icon_Circle">
                              <img src={images.recomendIco} alt="icon" />
                            </span>
                            <p className="mb-0">{strings.create_an_event}</p>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </a>
                </VisibilityToggle>

                {/* notification-bell */}
                <div
                  className={`slider_down_arrow position-relative ml-3 notification_part`}
                >
                  <i
                    className="fa fa-bell fz16 top_icon"
                    title="Notifications"
                    aria-hidden="true"
                    onClick={() => navigate("/notification")}
                  ></i>
                  {notificationCount ? (
                    <span className="badge">{notificationCount}</span>
                  ) : (
                    ""
                  )}
                </div>
                {/* notification-bell-close */}

                {/* cart-menu-start */}
                <div className={"position-relative"}>
                  <Link to="/my-cart" className="ml-3 cartBtn">
                    <i
                      className="fa fa-shopping-cart fz16"
                      aria-hidden="true"
                    ></i>
                    {(myCart ?? []).length > 0 ? (
                      <span className="badge">{(myCart ?? []).length}</span>
                    ) : (
                      ""
                    )}
                  </Link>
                </div>
                {/* cart-menu-close */}

                <div className="user_panel align-items-center">
                  <div className="user_details ml-3">
                    <div className="user_name">
                      {/* <h6>
                        {user_name.length > 25
                          ? user_name.substr(0, 25) + "..."
                          : user_name}
                      </h6> */}
                    </div>
                  </div>
                  <div className={`user_img ml-1`}>
                    <img
                      src={
                        user?.attributes?.picture
                          ? user?.attributes?.picture
                          : images.profileCamera
                      }
                      alt=""
                    />
                  </div>
                </div>

                <div
                  className={`slider_down_arrow slide_down position-relative ml-1 notification_part`}
                >
                  <i
                    className="fa fa-sort-desc fz18 top_icon"
                    title={strings.account}
                    aria-hidden="true"
                  ></i>
                  <div className={`user_actions user_actions_posLTR`}>
                    <ul
                      className={`list-unstyled text-left user-navDropdown mb-0 poppinsbold`}
                    >
                      <li>
                        <Link to="/my-profile">
                          <img src={images.userIco} className={`mr-4`} alt="" />
                          {strings.profile}
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-posts">
                          <img
                            src={images.myPostIco}
                            className={`mr-4`}
                            alt=""
                          />
                          {strings.my_posts}
                        </Link>
                      </li>
                      <li
                        className={
                          pathname.indexOf("rewards") !== -1 ? "active" : ""
                        }
                      >
                        <Link to="/rewards">
                          <img
                            src={images.rewardIconNew}
                            alt="icon"
                            width="16"
                            height="16"
                            className={`mr-4`}
                          />
                          <span className="navLinkCustom">
                            {strings.my_rewards}
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/hidden-posts">
                          <img
                            src={images.hiddenPostIco}
                            className={`mr-4`}
                            alt=""
                          />
                          {strings.hidden_posts}
                        </Link>
                      </li>
                      <li>
                        <Link to="/bookmarks">
                          <img
                            src={images.bookMarkIco}
                            className={`mr-4`}
                            alt=""
                          />
                          {strings.bookmarks}
                        </Link>
                      </li>
                      <li>
                        <Link to="/settings">
                          <img
                            src={images.settingIco}
                            className={`mr-4`}
                            alt=""
                          />
                          {strings.settings}
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            logoutConfirmEvent();
                          }}
                        >
                          <img
                            src={images.logoutIco}
                            className={`mr-4`}
                            alt=""
                          />
                          {strings.logout}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* mobile search */}
        <form className={`mr-2 top_form mobile_search`}>
          <div className="form-group my-2 d-flex align-items-center position-relative home_search">
            <VisibilityToggle visible={pathname === "/home"}>
              <input
                value={search}
                type="text"
                className="form-control custom_input border-0 border-radius-1 pr-5 input_customClr"
                placeholder={strings.search}
                onChange={(e) => {
                  addSearchFilterValue(e.target.value);
                  setSearch(e.target.value);
                }}
              />
              <i
                className={` fa fa-search fa-search-ltr`}
                aria-hidden="true"
              ></i>
            </VisibilityToggle>
          </div>
        </form>
      </div>
      <LogoutConfirmModal />
      {/* <LocationModal /> */}
    </div>
  );
}

export default React.memo(UserNavBar);
