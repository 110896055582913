import React from "react";
import { strings } from "src/Utils/Localization";

function CopyRight() {
  return (
    <div className="footer_bottom">
      <div className="container">
        <p className="mb-0 fz12 poppinsregular">
          {strings.copyright_bloom_all_rights_reserved}
        </p>
      </div>
    </div>
  );
}

export default CopyRight;
