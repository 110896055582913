import AuthService from "src/AuthService/AuthService";
import useObservableValue from "src/Hooks/Custom/useObservableValue";
import { CART } from "../Types/CommonTypes";

function useAddToCartValue(): Array<CART> {
  return useObservableValue(AuthService.cartState$);
}

export default useAddToCartValue;

export const getCartValue = () => AuthService.cartState$.getValue();

export const setCartValue = (cartState: Array<CART>) =>
  AuthService.cartState$.next(cartState);
