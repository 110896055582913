import moment from "moment";

function ChatDate({ date }: { date: string }) {
  return (
    <h5 className="fz13 poppinsmedium text-center mt-3 mb-3">
      {moment(date).format("ddd, DD/MM/YYYY") ===
      moment().format("ddd, DD/MM/YYYY")
        ? "Today"
        : moment(date).format("ddd, DD/MM/YYYY")}
    </h5>
  );
}
export default ChatDate;
