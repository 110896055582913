import SalePostList from "src/Modules/SaleModule/Components/SalePostList/SalePostList";
import Layout from "src/Modules/Layout/Layout";
import { withAuthenticator } from "@aws-amplify/ui-react";

function Sales() {
  return (
    <Layout>
      <div id="content" className="ltr-content-padding">
        <div className="container-fluid">
          <div className="row sale_Details-Row">
            <div className="sale_Details-CenterCol">
              <div className="col_selector">
                <div className="messages pt-4 post_feed">
                  <SalePostList />
                </div>
              </div>
            </div>
            <div className="right_TripMapCol"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withAuthenticator(Sales);
