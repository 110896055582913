import { useCallback } from "react";
import { getChatValue } from "./useChatValue";

function useChatEmit() {
  const emitEventWS = useCallback(
    (eventName: string, data: any, callback: (responseData?: any) => void) => {
      const { ws } = getChatValue();
      ws?.emit(eventName, data, callback);
    },
    []
  );

  return {
    emitEventWS,
  };
}

export default useChatEmit;
