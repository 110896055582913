import { useQuery } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { GET_NEAR_BY_PLACES_REQUEST } from "../Types/RequestTypes";

const GET_NEAR_BY_PLACES = ["getNearByPlaces"];

const getNearByPlaces = async (data: GET_NEAR_BY_PLACES_REQUEST) => {
  const response = await fetcher({
    baseURL: EndPoints.nearByPlace,
    method: "POST",
    data: data,
  });
  console.log("DATA////////", data);
  return response;
};
function useNearByPlaceQuery(data: GET_NEAR_BY_PLACES_REQUEST) {
  return useQuery(GET_NEAR_BY_PLACES, () => getNearByPlaces(data), {
    enabled: data?.lat !== undefined || data?.lng !== undefined,
  });
}
export default useNearByPlaceQuery;
