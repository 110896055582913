import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { VOTE_POLL_REQUEST } from "../Types/RequestTypes";

const votePoll = async (
  data: VOTE_POLL_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.votePoll,
    method: "POST",
    data: data,
  });
};

function useVotePollMutation() {
  return useMutation(votePoll, {
    onSuccess: (responseData) => {
      //   SnackbarHandler.successToast("Poll created successfully");
    },
  });
}
export default useVotePollMutation;
