import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";
import { fetcher } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { CREATE_SALE_POST_REQUEST } from "../Types/RequestTypes";

const createSellPost = async (
  data: CREATE_SALE_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createSale,
    method: "POST",
    data: data,
  });
};

function useCreateSalePostMutation() {
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(createSellPost, {
    onSuccess: (responseData) => {
      SnackbarHandler.successToast("Post created successfully");
      console.log("Sale Post///", responseData);
      rewardMutation?.mutate({
        type: "POST",
        subId: user?.username,
        refId: String(responseData?.data?.id ?? ""),
        title: "POst",
      });
    },
  });
}
export default useCreateSalePostMutation;
