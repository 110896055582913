import React from 'react'
import CreateEvent from 'src/Modules/EventModule/Components/CreateEvent/CreateEvent'
import Layout from 'src/Modules/Layout/Layout'

function Event() {
  return (
    <Layout>
        <CreateEvent />
    </Layout>
  )
}

export default Event