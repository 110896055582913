import { Fragment } from "react";
import Loader from "src/Components/Shared/Loader/Loader";
import ContactsModal from "src/Modules/ChatModule/Components/ContactsModal/ContactsModal";
import ParticipantsList from "src/Modules/ChatModule/Components/ParticipantsList/ParticipantsList";
import useChatValue from "src/Modules/ChatModule/Hooks/useChatValue";
import Layout from "src/Modules/Layout/Layout";

function MessagePage() {
  const { socketConnected } = useChatValue();

  return (
    <Fragment>
      <Layout>{socketConnected ? <ParticipantsList /> : <Loader />}</Layout>
      <ContactsModal />
    </Fragment>
  );
}

export default MessagePage;
