function PrivacyContentEn() {
  return (
    <section className="terms_condition_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="col_selector terms_condition_section-content">
              <h2 className="poppinssemibold">Privacy Policy</h2>
              <p>
                <em>
                  Last updated on February 14 2021. This Privacy Policy is
                  effective February 14, 2021.
                </em>
              </p>
              <p>
                Welcome to All Bloom! We invite you to read this privacy policy,
                which explains our privacy practices and how we treat your
                personal information when you use our Services. It describes the
                types of personal information collected and created in
                connection with your use of All Bloom, how and why we use such
                information, who we share it with, and your legal rights. Unless
                otherwise indicated, this privacy policy (“Policy”) applies to
                all our websites, domains, apps, products, services, and
                features (the “Services”) in all geographies in which All Bloom
                offers its Services.
              </p>
              <p>
                When you use All Bloom, there are expectations for how you must
                respect and protect the privacy of other members. These
                expectations are spelled out in our Terms of Use. Please read
                it. Violating these rules or other aspects of the Terms of Use
                may lead to a loss of account privileges, potentially including
                a permanent ban from All Bloom.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">1. What is All Bloom?</h3>
              <p>
                All Bloom is a social and e-commerce app that helps you stay
                connected with your neighbours. As an alternative to traditional
                forms of social media, All Bloom allows you to build closer
                relationships with your neighbours and stay on top of the latest
                news and deals in your community.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">
                2. Who provides the Services and controls my information?
              </h3>
              <p>
                We use “All Bloom” here to refer to our Services and we also use
                “All Bloom”, “we”, “us”, and “our” to refer to the relevant All Bloom
                companies described in this section.
              </p>
              <p>
                The Services are operated and provided to you by All Bloom.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">
                3. What information is collected?
              </h3>
              <p>
                When we provide the Services, we collect or receive information
                in several different ways. In many cases you choose what
                information to provide. Some information is required in order to
                use All Bloom and for us to provide the Services. We use this
                information for the purposes described further below.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">
                3.1. From you, or with your permission.
              </h3>
              <p>
                We collect a variety of information from you when you provide it
                to us directly, when we request it from you, or when you give us
                permission to get it from a third party source. Here are some
                examples of these instances:
              </p>
              <ul>
                <li>Contact details. </li>
                <li className="list-unstyled">
                  <ol>
                    <li>
                      When you sign up for All Bloom, you provide your name,
                      address, phone number, profile photo, email address, and
                      similar information.{" "}
                    </li>
                    <li>
                      If you want to join All Bloom or to use a service on All Bloom
                      that is yet to be introduced to your neighborhood, but we
                      don’t yet have service for your neighborhood, you can
                      choose to give us your contact information and ask us to
                      notify you when we do.
                    </li>
                    <li>
                      If you contact support, you are providing us with your
                      contact information and a description of the issue so we
                      can respond.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Network details :</strong>If you decide to invite new
                  members to join All Bloom, you can choose to share their
                  residential or email address with us, or share your contacts
                  with us, so we can send an invitation and follow-up reminders
                  to potential new members on your behalf.{" "}
                </li>
                <li>
                  <strong>Social data :</strong> If you use Facebook to log in
                  to All Bloom (or later connect your Facebook account to your
                  All Bloom account) you authorize Facebook to share your personal
                  information with us, including your name and email address.
                </li>
                <li>
                  <em>Posts, interests, and recommendations.</em>
                  <ol>
                    <li>
                      If you want to support a local business, you can choose to
                      share information about it, in the form of a
                      recommendation or by creating a business page.
                    </li>
                    <li>
                      If you want to help your neighbors and All Bloom get to know
                      you better, you may share information about yourself in
                      your profile, including your interests, skills, or even
                      what you love about your neighborhood.
                    </li>
                    <li>
                      If you want to connect and communicate with neighbors
                      about specific interests, you may share information about
                      your interests.
                    </li>
                    <li>
                      If you want to communicate with your neighbors and share
                      Content (as defined in our Terms of Use), you might post
                      something on All Bloom.
                    </li>
                    <li>
                      If you want to allow us to show you where you are on your
                      neighborhood map, tag your posts, photos and events by
                      location, or verify your address (where available), you
                      can enable sharing this in your settings.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Payment information :</strong> If you make a payment
                  either to or through All Bloom, you are providing our trusted
                  third party payment processors with certain payment
                  information in order to complete the transaction.
                </li>
                <li>
                  <strong>Survey data :</strong> If you take part in surveys or
                  other general research we undertake, you may provide
                  information in response.
                </li>
              </ul>
              <h3 className="fz18 poppinssemibold mb-4">
                3.2. From your browser and device.{" "}
              </h3>
              <p>
                Like nearly every other online service, we automatically collect
                technical information when you visit or use All Bloom or read a
                message from us. We collect information from your browser,
                computer, or mobile device. This provides us with technical
                information when you use All Bloom as a visitor or a member,
                including:
              </p>
              <ul>
                <li>
                  Device data, which tells about the device you’re using, such
                  as what type and model it is, its operating system and
                  version, its unique identifier, crash data (if applicable),
                  and what settings you’ve enabled. Your device and software
                  settings affect what information your device makes available
                  to us.
                </li>
                <li>
                  Log data, which is automatically recorded by our servers based
                  on data sent by your app or browser. This typically includes
                  the date and time you visited, the browser or app version you
                  used (and its settings), the URLs you come from and go to,
                  your IP address and protocol, and how you used All Bloom (for
                  example, which links you clicked on).
                </li>
              </ul>
              <h3 className="fz18 poppinssemibold mb-4">
                4. How is my information used?
              </h3>
              <p>
                We use the information we have to help us provide, operate,
                improve, understand, customize, support, and market our
                Services. We may also use the information we have for other
                purposes where permitted by law.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">
                4.1. Establishing and developing neighborhoods.
              </h3>
              <p>
                We use information to identify and invite potential new members
                to All Bloom, and to set up new All Bloom neighborhoods. For example,
                we use information to identify new members and, if you ask us
                to, send invitations and reminders on your behalf, send direct
                mail invitations, invite your Facebook friends, or notify you
                when we start offering the Services to your neighborhood.
              </p>
              <h3 className="fz18 poppinssemibold mb-4">
                4.2. Setting you up on All Bloom.
              </h3>
              <p>
                In order to enable us to operate and provide the Services, we
                use certain information to get you started on All Bloom:
              </p>
              <ul>
                <li>
                  For residential members, we verify that you live at the
                  address you gave us through a combination of your address and
                  a second factor. The second factors we use may include a code
                  we provide you or geo-verification, if you are offered and
                  choose that option. We also use public registries to block
                  addresses of registered sex offenders in the United States
                  (and other countries where permitted by law).{" "}
                </li>
                <li>
                  Once you register, your personal information is used to
                  populate your profile.
                </li>
                <li>
                  We use business information to create Business Pages, which
                  help members learn about the businesses in their communities.
                  To claim your Business Page, create a business profile on the
                  Business tab of the app.
                </li>
                <li>
                  For business or other special accounts, we may verify your
                  address using third-party or publicly available sources, such
                  as business listings or your website.
                </li>
                <li>
                  When you sign up for All Bloom, you have the opportunity to ask
                  All Bloom to mail invitations to nearby households that are not
                  on All Bloom. These invitations contain your name and street name
                  and are only addressed to nearby households (and do not
                  generally contain recipient householders’ names).
                </li>
              </ul>
              <h3 className="fz18 poppinssemibold mb-4">
                4.3. Provide and improve the Services.
              </h3>
              <p>
                We use both manual and automated systems to analyze and use your
                Content (as defined in the Terms of Use) and other information:
              </p>
              <ul>
                <li>
                  To operate All Bloom, create neighborhoods, invite neighbors, and
                  improve our products and features.
                </li>
                <li>
                  To help you publish Content and view and respond to Content
                  from other members, based on posting selections you make when
                  you create your post.
                </li>
                <li>
                  To highlight popular neighborhood topics or resources on a
                  neighborhood’s public home page or an organization’s public
                  page.
                </li>
                <li>
                  To select top posts in an area and to deliver and tailor your
                  newsfeed.
                </li>

                <li>
                  To highlight businesses you may wish to recommend based on
                  your activity on All Bloom.
                </li>
                <li>
                  To help support businesses recommended by All Bloom members.
                </li>
                <li>To personalize your experience.</li>
                <li>
                  To understand the trends, usage statistics, and demographic
                  data of the members and neighborhoods we serve.
                </li>
                <li>To respond to your support requests and comments.</li>
                <li>
                  To test and evaluate potential new products and features on
                  All Bloom.
                </li>
                <li>
                  To diagnose and resolve problems, analyze trends, and monitor
                  usage and resource needs.
                </li>
                <li>
                  To accept payments, through our trusted third party payment
                  processors.
                </li>
              </ul>
              <h3 className="fz18 poppinssemibold mb-4">4.4. Advertising.</h3>
              <p>
                We use your information to provide and improve advertisements
                and other commercial offerings both on and off the Services:
              </p>
              <ul>
                <li>
                  To measure whether an advertisement was interesting to
                  members.
                </li>
                <li>
                  To measure whether an advertisement was effective for the
                  advertiser.
                </li>
                <li>
                  To help personalize advertisements based on what we think will
                  be of interest to you.
                </li>
                <li>
                  To provide and measure the effectiveness of personalized
                  advertising.
                </li>
                <li>To improve the personalization of advertisements.</li>
                <li>
                  We may customize advertising offers you see on All Bloom based on
                  information from our advertisers or vendors, on the
                  demographics of your neighborhood, or on your actions on
                  All Bloom. For example, a home services company might provide a
                  list of addresses eligible for a special deal that we may
                  offer to All Bloom members who live at those addresses. Or, if
                  you search All Bloom for a particular product, we might show you
                  advertisements for providers of that product or related or
                  similar products. Our third party advertising partners also
                  use cookies and similar technologies to collect information
                  about members for advertising purposes as described below and
                  in our Cookie Policy.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyContentEn;
