import React, { useState } from "react";
import moment from "moment";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { Link, useLocation } from "react-router-dom";
import ReactLinkify from "react-linkify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";
import useLikePostMutation from "src/Modules/HomeModule/Hooks/useLikePostMutation";
import ReadMoreLessMore from "src/Components/Custom/ReadMoreLessMore/ReadMoreLessMore";
import GroupPostListOptions from "../GroupPostListOptions/GroupPostListOptions";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function GroupPostListItems(props: any) {
  const { user } = useAuthValue();
  const { pathname } = useLocation();
  const { item } = props;

  const route =
    pathname === "/groups/:id" ? "/groups/" + item?.groupId : pathname;
  const url = item?.message?.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );

  const likePostMutation = useLikePostMutation();
  const [isLikePost, setIsLikePost] = useState<number>(item?.is_like);
  const [postLikes, setPostsLikes] = useState<number>(item?.likes);

  const likePost = async () => {
    if (user.username === item?.subId || !user.username) {
      SnackbarHandler.errorToast("You can't like your own post.");
      return;
    }
    if (isLikePost === 0) {
      setIsLikePost(1);
      setPostsLikes((prevData) => prevData + 1);
    }
    if (isLikePost === 1) {
      setIsLikePost(0);
      setPostsLikes((prevData) => prevData - 1);
    }
    likePostMutation?.mutate(
      {
        subId: user?.username,
        postId: item?.id,
        groupId: item?.groupId,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            console.log("Response Data/////////////ms", responseData);
            setIsLikePost(responseData?.data?.is_like);
            setPostsLikes(responseData?.data?.likes);
          }
        },
        onError: () => {
          setIsLikePost(item?.is_like);
          setPostsLikes(item?.likes);
        },
      }
    );
  };

  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="post mb-2 post_feed">
        <div className="d-flex">
          <div className={`mr-3`}>
            <img
              src={
                item?.user_image !== ""
                  ? item?.user_image
                  : images.profileCamera
              }
              className="rounded-circle img-fluid feed_img"
              alt=""
              style={{ height: 40, width: 40 }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between w-90">
            <div>
              <h3 className="fz16 poppinssemibold mb-0">{item?.user_name}</h3>
              <p className={`fz10 mb-0 poppinsregular text-secondary`}>
                {/* <span className="fz12">valentine point</span> */}
                <small className="ml-0 fz10">
                  {/* {i18n.language} */}
                  {item?.timestamp
                    ? moment(new Date(item?.timestamp)).fromNow()
                    : ""}
                </small>
              </p>
            </div>
            <GroupPostListOptions item={item} />
          </div>
        </div>

        <div className="post_content_wrap pt-0">
          <div className="slider_section1 custom_group_Slider my-4">
            <Slider {...detilaSlider}>
              {(item?.images ?? []).map((item: any, index: number) => {
                return (
                  <div className="detilaSlider_Item" key={`item${index}`}>
                    <img
                      src={item?.url}
                      className="img-fluid w-100 rounded"
                      alt=""
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="cursor-pointer">
            <Link to={route + "/" + item?.id}>
              <ReactLinkify>
                <p className="mb-0 fz16 poppinsregular post_message readmore">
                  {item?.type === "SALE" ? (
                    item?.title
                  ) : (
                    <ReadMoreLessMore text={item?.message} showChar={250} />
                  )}
                </p>
              </ReactLinkify>
            </Link>
            {ReactPlayer.canPlay(url) && (
              <ReactPlayer url={url} width={"100%"} />
            )}
          </div>
          <VisibilityToggle visible={item?.type === "SALE"}>
            <div className={"px-3 d-flex"}>
              <VisibilityToggle visible={item?.sale_price === "0"}>
                <p className="mb-0 text-secondary fz12 poppinsregular">Free</p>
              </VisibilityToggle>
              <VisibilityToggle visible={item?.sale_price !== "0"}>
                <p className="mb-0 text-secondary fz12 poppinsregular">
                  {strings.usd} {item?.sale_price}
                </p>
                &nbsp;
              </VisibilityToggle>
            </div>
          </VisibilityToggle>
          <VisibilityToggle visible={item?.type !== "SALE"}>
            <div className="px-0">
              <hr />
            </div>
            <div className="post_list px-3 CategoriesPostList">
              <div className="row">
                <ul className={`list-unstyled d-flex mb-0`}>
                  <li className={`post_li_ltr`}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        likePost();
                      }}
                    >
                      {isLikePost === 1 ? (
                        <i
                          className="fa fa-heart text-danger post_li_i_ltr"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <i
                          className="fa fa-heart-o base_color post_li_i_ltr"
                          aria-hidden="true"
                        ></i>
                      )}

                      {postLikes ?? 0}
                    </button>
                  </li>
                  <li>
                    <Link to={route + "/" + item?.id}>
                      <i
                        className={`fa fa-comment-o base_color post_li_i_ltr`}
                        aria-hidden="true"
                      ></i>
                      {item?.total_comments ?? 0}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </VisibilityToggle>
        </div>
      </div>
    </>
  );
}

export default GroupPostListItems;
