import AuthService from "src/AuthService/AuthService";
import { AUTH_STATE } from "src/Types/CommonTypes";
import useObservableValue from "./useObservableValue";


function useAuthValue(): AUTH_STATE {
  return useObservableValue(AuthService.authState$);
}

export default useAuthValue;

export const getAuthValue = () => AuthService.authState$.getValue();

export const setAuthValue = (authState: AUTH_STATE) =>
  AuthService.authState$.next(authState);

export const resetAuthValue = () => AuthService.resetAuthValue();