import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import { GET_GROUP_DETAILS_KEY } from "src/Modules/GroupModule/Hooks/useGroupDetailsQuery";
import { queryClient } from "src/Utils/ReactQueryConfig";
import useDeletePostMutation from "../../Hooks/useDeletePostMutation";
import { GET_HOME_FEED_QUERY_KEY } from "../../Hooks/useHomeFeedInfiniteQuery";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { GET_GROUP_POST_LIST_KEY } from "src/Modules/GroupModule/Hooks/useGroupPostListInfiniteQuery";
import Loader from "src/Components/Shared/Loader/Loader";
import { GET_MY_POSTS_LISTS } from "src/Modules/MyPostsModule/Hooks/useMyPostsListInfiniteQuery";
import { GET_HOME_POST_DETAILS } from "../../Hooks/useHomePostDetailQuery";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const DELETE_GLOBAL_POST_EVENT = "deleteGlobalPostEvent";

export const openDeleteGlobalPostModal = (data: any) => {
  emitEvent(DELETE_GLOBAL_POST_EVENT, data);
};

function ConfirmDeletePostModal() {
  const params = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [groupId, setGroupId] = useState<number>();
  const [postId, setPostId] = useState<number>();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthValue();
  useEventEmitter(DELETE_GLOBAL_POST_EVENT, (data) => {
    console.log("ggggggggggId", data);
    setShowModal(true);
    setPostId(data?.id);
    setGroupId(data?.groupId);
  });

  const deletePostmutation = useDeletePostMutation();

  const deletePost = () => {
    deletePostmutation?.mutate(
      {
        postId: Number(postId),
        subId: user?.username,
        groupId: Number(groupId),
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            setShowModal(false);
            SnackbarHandler.successToast(strings.post_deleted_successfully);
            if (groupId !== undefined) {
              queryClient.refetchQueries(
                GET_GROUP_DETAILS_KEY(Number(groupId))
              );
              queryClient.refetchQueries(
                GET_GROUP_POST_LIST_KEY(Number(groupId))
              );
            }
            queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
            queryClient.refetchQueries(GET_MY_POSTS_LISTS);
            if (location?.pathname === `/home/${postId}`) {
              return navigate("/");
            }
            queryClient.refetchQueries(GET_HOME_POST_DETAILS(Number(postId)));
            if (location?.pathname === `/my-posts/${postId}`) {
              return navigate("/my-posts");
            }
          }
        },
      }
    );
  };

  return (
    <>
      {deletePostmutation?.isLoading && <Loader />}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg create-group-modal delete_Modal">
          <div className="modal-content border-0 Delete_Modal">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <img src={images.deleteIco} alt="icon" />
              <h4 className="fz22 my-4">{strings.del_para}</h4>
              <div className="row justify-content-center ">
                <button
                  className="cancel_CustomBtn"
                  onClick={() => setShowModal(false)}
                >
                  {strings.no}
                </button>
                <button
                  className="save_CustomBtn"
                  onClick={deletePost}
                  disabled={deletePostmutation?.isLoading}
                >
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </>
  );
}
export default ConfirmDeletePostModal;
