import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBBg8FQIFGDwjkzoV7mgTE1oJJlCxpGgZQ",
  authDomain: "bloom-8180d.firebaseapp.com",
  projectId: "bloom-8180d",
  storageBucket: "bloom-8180d.appspot.com",
  messagingSenderId: "352222857199",
  appId: "1:352222857199:web:e2237072ec0f1e17a5d81a",
  measurementId: "G-L9J4BD0MMT",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BBqr2z69AXRYcRHZB84zr8meUCalgvtDHVFoUZIpWcIEPYPDjtCa7kbyQxwbl5GHm_JVcnjyGfYWnyLvP_OjX30",
  });
};

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
