import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import  { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_NEAR_BY_GROUPS = (search: string) => [
  "getNearByGroups",
  search,
];

const nearByGroups = async (page: number, search: string): Promise<any> => {
  const { user } = getAuthValue()
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.nearbyGroups}?subId=${user?.username}&page=${page}&search=${search}`,
  });
  console.log("Response Data", response?.data);
  return response?.data;
};

export const extractNearByGroups = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getChatNextPageParam = (lastPage: any) => {
  const { Page, TotalPages } = lastPage;
  return TotalPages > Number(Page) ? Number(Page) + 1 : undefined;
};

function useNearByGroupsInfiniteQuery(search: string) {
  return useInfiniteQuery(
    GET_NEAR_BY_GROUPS(search),
    ({ pageParam = 1 }) =>
      nearByGroups(pageParam === null ? 1 : pageParam, search),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getChatNextPageParam,
    }
  );
}

export default useNearByGroupsInfiniteQuery;
