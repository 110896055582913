import { Fragment } from "react";
import Layout from "src/Modules/Layout/Layout";
import MyOrder from "src/Modules/MyOrderModule/Components/MyOrder";


function MyOrderPage() {
  return (
    <Fragment>
      <Layout>
        <MyOrder/>
      </Layout>
    </Fragment>
  );
}
export default MyOrderPage;
