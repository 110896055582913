import { useQuery } from "react-query";
import { fetcherUrl } from "src/Utils/Helpers";

export const GET_PRODUCT_LIST_QUERY = (searchText: string) => [
  "getProductListQuery",
  searchText,
];

const getShoProductList = async (searchText: string) => {
  const response = await fetcherUrl({
    baseURL: `https://bloomyourcity-dev.myshopify.com/api/graphql`,
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": "e60d9567f7b96d3b7d8a08a528ae1e9a",
    },
    data: `{products(first: 50, query: "title:${searchText}") {edges {cursor node { id, title, description, createdAt, productType, featuredImage{src}, variants(first: 5) {nodes {id, compareAtPrice, price, image {src}}}}}}}`,
  });
  return response?.data?.data;
};
function useShopifyProductListQuery(searchText: string) {
  return useQuery(GET_PRODUCT_LIST_QUERY(searchText), () =>
    getShoProductList(searchText)
  );
}

export default useShopifyProductListQuery;
