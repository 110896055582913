import { useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useLeaveGroupMutation from "src/Modules/GroupModule/Hooks/useLeaveGroupMutation";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEventEmitter from "src/Hooks/Shared/useEventEmmiter";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import { strings } from "src/Utils/Localization";

const LEAVE_GROUP_EVENT = "leaveGroupEvent";
export const openLeaveGroupConfirmModal = () => {
  emitEvent(LEAVE_GROUP_EVENT);
};

function LeaveGroupConfirmModal() {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuthValue();
  const [showModal, setShowModal] = useState<boolean>(false);
  const leaveGroupMutation = useLeaveGroupMutation();
  const handleLeaveGroup = () => {
    leaveGroupMutation.mutate(
      {
        subId: user?.username,
        groupId: Number(params?.id),
      },
      {
        onSuccess: (responseData) => {
          const { statusCode } = responseData?.data;
          if (statusCode === 200) {
            setShowModal(false);
            navigate("/groups");
          }
        },
      }
    );
  };

  useEventEmitter(LEAVE_GROUP_EVENT, () => {
    setShowModal(true);
  });
  return (
    <>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="confirm_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="text-center">
                <p className="fz24 text-secondary mb-4">
                 {strings?.are_you_sure_want_leave_group}
                </p>

                <button
                  className="btn custom_btn mr-2 text-secondary bg-transparent border border-secondary"
                  onClick={() => setShowModal(false)}
                >
                  {strings.no}
                </button>
                <button
                  className="btn custom_btn"
                  onClick={handleLeaveGroup}
                  disabled={leaveGroupMutation?.isLoading}
                >
                  <VisibilityToggle visible={leaveGroupMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </>
  );
}
export default LeaveGroupConfirmModal;
