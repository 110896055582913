const EndPoints = {
  states:
    "https://2nwmrppf3mseynv5epf45c22i40imtyb.lambda-url.us-west-1.on.aws",
  cities:
    "https://xitij4hp4q5ylyuxyodspiok3u0zmmoj.lambda-url.us-west-1.on.aws",
  // getHome:
  //   "https://boxo7xcgboi4hbftdleimyiqwq0grxbt.lambda-url.us-west-1.on.aws/",
  getHome: "https://api.letsbloom.today/post/home",
  createPost:
    "https://fkm4u7jrrlanujaf7wztb7k2iy0ekemx.lambda-url.us-west-1.on.aws",
  // createPost: "https://api.letsbloom.today/post/create-post",
  postDetail:
    "https://w63xx2ph3rhqc3b5ynlq6pjxam0amvwl.lambda-url.us-west-1.on.aws",
  deletePost: "https://api.letsbloom.today/post/delete-post",
  updatePost:
    "https://w5bs3jhmb5z34o4hylk36ixbbe0wiiqx.lambda-url.us-west-1.on.aws",

  likePost:
    "https://nwoaxmddx2ejlxmfaozjewqnym0gooor.lambda-url.us-west-1.on.aws",
  bookmarkPost:
    "https://cmek32eqi5la7u3j433bxnnere0mreao.lambda-url.us-west-1.on.aws",
  hidePost:
    "https://7ns4wozjknlq6mcpfhwz72qbd40xckwx.lambda-url.us-west-1.on.aws",

  myBookmarks:
    "https://hedex4snymao6zovy4ms2bwewa0vqudo.lambda-url.us-west-1.on.aws",

  myPosts: "https://api.letsbloom.today/post/my-posts",

  hiddenPosts:
    "https://5j3z4fvmx2h5ctscislcepcvre0eopqc.lambda-url.us-west-1.on.aws/",

  comments:
    "https://dcy7c6h5ireol4ylwxw7mtwqbi0zmczs.lambda-url.us-west-1.on.aws",

  commentOnPost:
    "https://3o32skimzccjxdz3glib2cjyyy0qmxku.lambda-url.us-west-1.on.aws",

  likeComment:
    "https://orgh7xy7kykfswalkst3s7wayu0gahmi.lambda-url.us-west-1.on.aws",

  getSales:
    "https://ep6vs2vppywunyvxsjtytlt7by0dgnuc.lambda-url.us-west-1.on.aws",

  saleCategoris:
    "https://fm57anbcepmuaaafi377xha27q0hhzdf.lambda-url.us-west-1.on.aws/",

  myGroups:
    "https://wpnqjddsg3honjo6ysfmbcxodm0duxzi.lambda-url.us-west-1.on.aws",
  nearbyGroups:
    "https://no7w55pew27arjmehjp5fm5q5q0ffbhf.lambda-url.us-west-1.on.aws",
  joinGroup:
    "https://fmd7gjsceholhllo6wfaw4vjx40uzpjt.lambda-url.us-west-1.on.aws",
  createGroup:
    "https://sfgggyyhq5grxizggbvdnnynbi0pquqs.lambda-url.us-west-1.on.aws",
  updateGroup:
    "https://vcphngrlpzyiqaqhrse7eg2vjq0kjbin.lambda-url.us-west-1.on.aws",
  groupDetail:
    "https://72oomdtdake56ivdlhaaq3n5ci0scgcq.lambda-url.us-west-1.on.aws",
  getGroupPosts:
    "https://wfsr2ha33hh23y5x3oyian3l6u0kriiw.lambda-url.us-west-1.on.aws",
  createGroupPost:
    "https://uhqn4v7rddb3ayywmkvnfyue540ouhqa.lambda-url.us-west-1.on.aws",
  updateGroupPost:
    "https://bqghwves6kdhnqcz4tlzq2k63a0hiuzg.lambda-url.us-west-1.on.aws",
  leaveGroup:
    "https://6ihevekahofaaafz7xvhce2lwq0sbpfj.lambda-url.us-west-1.on.aws",
  deleteGroup:
    "https://4xmewxxmozlpgsw5wlwndgz6y40gppmn.lambda-url.us-west-1.on.aws",
  groupMembers:
    "https://5dbd7dc2cl4dkntzhy2ud6h5zm0chzhv.lambda-url.us-west-1.on.aws",
  addMember:
    "https://2u5xitc3vyczruthrtj5tyc4qu0zbdqv.lambda-url.us-west-1.on.aws",
  removeMember:
    "https://h4yjo7o3anzrnxclc7idyhcs4m0jaacy.lambda-url.us-west-1.on.aws",
  removeAdmin:
    "https://s4qulgs2u5yfixj3gyg7ga7ise0dloeu.lambda-url.us-west-1.on.aws",
  makeAdmin:
    "https://ljztpgda4z4bsqz42w7t4vjtpe0fnewq.lambda-url.us-west-1.on.aws",
  rejectMember:
    "https://nheqsnmoikhne2npebpnjx7gq40lamcj.lambda-url.us-west-1.on.aws",
  businessCategoris:
    "https://hodlz4bsne5r7ppon75ccge7iy0cnluq.lambda-url.us-west-1.on.aws/",
  createBusinessPost:
    "https://api.letsbloom.today/business/create-business-post",
  editBusinessPost:
    "https://c7xx44apzif7khe7pwfjn6x7de0tuxoa.lambda-url.us-west-1.on.aws",
  deleteBusinessPost:
    "https://i3n4jdycmrpive7jieicairbim0okwin.lambda-url.us-west-1.on.aws",
  businessPosts: "https://api.letsbloom.today/business/business-posts",
  businessPostDetail: "https://api.letsbloom.today/business/post-details",
  commentOnBusinessPost:
    "https://42rrkuvnm5nab2zmngnc7czhla0frzbh.lambda-url.us-west-1.on.aws",
  businessPostComments:
    "https://zbs75yt3zhv34ghh3jetjiffae0bdnrs.lambda-url.us-west-1.on.aws",
  notifications: "https://api.letsbloom.today/user/notification",
  notificationsCount:
    "https://api.letsbloom.today/user/notification-unseen-count",
  readNotification: "https://api.letsbloom.today/user/read-notification",
  contacts:
    "https://rb4eze3gcbk2nhpeg4cocqa4ci0lmqql.lambda-url.us-west-1.on.aws",
  contactUs:
    "https://14rfb8x8r0.execute-api.eu-central-1.amazonaws.com/contact-us",
  reportPost:
    "https://os34m4btfm5sg5awem5g7ze7fa0rljrh.lambda-url.us-west-1.on.aws",
  reportComment:
    "https://oj5wk6inm46me7ugxbvxkzpktm0ujwkb.lambda-url.us-west-1.on.aws",
  getProfile:
    "https://wvsaf6gshk6rztfm6mpe35sqfy0yqvxv.lambda-url.us-west-1.on.aws",
  editProfile: "https://api.letsbloom.today/user/edit-profile",
  // verifyStateCity:
  //   "https://ozwvsso6m4othozet2lsmwi7ju0naoyc.lambda-url.us-west-1.on.aws",
  verifyStateCity:
    "https://obnt9mkfse.execute-api.us-west-1.amazonaws.com/dev/verify-state-city",

  createPoll: "https://api.letsbloom.today/post/create-poll",
  votePoll: "https://api.letsbloom.today/post/vote-poll",
  createSafetyPost: "https://api.letsbloom.today/post/create-safety-post",
  editSafetyPost: "https://api.letsbloom.today/post/edit-safety-post",
  createSale: "https://api.letsbloom.today/post/create-sale",
  editSale: "https://api.letsbloom.today/post/edit-sale",
  CreateEvent: "https://api.letsbloom.today/post/create-event",
  createGeneralPost: "https://api.letsbloom.today/post/create-general-post",
  editEvent: "https://api.letsbloom.today/post/edit-event",
  editGeneralPost: "https://api.letsbloom.today/post/edit-general-post",
  saveDeviceToken: "https://api.letsbloom.today/user/add-device-details",
  getRewardHistory: "https://api.letsbloom.today/user/get-reward-history",
  addReward: "https://api.letsbloom.today/user/add-purchase-reward",
  updateLanguage: "https://api.letsbloom.today/user/update-language",
  nearByPlace:
    "https://pdr6lfx7nk.execute-api.us-west-1.amazonaws.com/dev/states/fetch-near-city",
  updateCommunity:
    "https://obnt9mkfse.execute-api.us-west-1.amazonaws.com/dev/update-community",
  favUnFavCommunity:
    "https://obnt9mkfse.execute-api.us-west-1.amazonaws.com/dev/fav-unfav-community",
  userLocation:
    "https://obnt9mkfse.execute-api.us-west-1.amazonaws.com/dev/get-city-detail",
};

export default EndPoints;
