import { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEventEmitter from "src/Hooks/Shared/useEventEmmiter";
import { useNavigate } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import images from "../../../Utils/images";
import AuthService from "src/AuthService/AuthService";

const LOGOUT_CONFIRM_EVENT = "logoutconfirm";
export const logoutConfirmEvent = () => {
  emitEvent(LOGOUT_CONFIRM_EVENT);
};

function LogoutConfirmModal() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEventEmitter(LOGOUT_CONFIRM_EVENT, () => {
    setShowModal(true);
  });
  const logout = () => {
    AuthService.resetAuthValue();
    navigate("/");
  };

  return (
    <Fragment>
      <div
        className={`modal fade custom_logout-modal ${showModal ? "show" : ""}`}
        id="confirm_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-md ">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="text-center">
                <img src={images.LogoutBlue} alt="logout" width="40" />
                <p className="fz24 my-4">{strings.are_u_sure_to_logout}</p>
                <div className="row justify-content-center">
                  <button
                    className="btn confirm_Btn custom_btn mr-2 text-secondary bg-transparent border border-secondary"
                    onClick={() => setShowModal(false)}
                  >
                    {strings.cancel}
                  </button>
                  <button
                    className="btn confirm_Btn custom_btn"
                    type="button"
                    onClick={logout}
                  >
                    {strings.yes}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default LogoutConfirmModal;
