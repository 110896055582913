import React, { Fragment } from 'react'
import Layout from 'src/Modules/Layout/Layout'
import ShopifyDetails from 'src/Modules/ShopifyDetailsModule/Components/ShopifyDetails/ShopifyDetails'

function ShopifyPage() {
  return (
    <Fragment>
        <Layout>
            <ShopifyDetails />
        </Layout>
    </Fragment>
  )
}

export default ShopifyPage