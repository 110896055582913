import React from "react";
import Layout from "src/Modules/Layout/Layout";
import BookmarkPostsList from "src/Modules/BookmarksModule/Components/BookmarkPostsList/BookmarkPostsList";

function BookmarkPosts() {
  return (
    <Layout>
      <div id="content" className={`ltr-content-padding`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="col_selector">
                <BookmarkPostsList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BookmarkPosts;
