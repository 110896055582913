import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { DELETE_BUSINESS_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_BUSINESS_LIST_QUERY_KEY } from "./useBusinessListInfiniteQuery";
import SnackbarHandler from "src/Utils/SnackbarHandler";

const deleteBusinessPost = async (
  data: DELETE_BUSINESS_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.deleteBusinessPost,
    method: "POST",
    data: data,
  });
};

function useDeleteBusinessPostMutation() {
  return useMutation(deleteBusinessPost, {
    onSuccess: (responseData) => {
      console.log(responseData, "responseData/////////////////");
      SnackbarHandler.successToast("Business Post Deleted successfully");
      queryClient.refetchQueries(GET_BUSINESS_LIST_QUERY_KEY("", undefined));
    },
  });
}

export default useDeleteBusinessPostMutation;
