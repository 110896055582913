import React, { useState } from "react";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import "./PollModal.css";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { GET_HOME_FEED_QUERY_KEY } from "src/Modules/HomeModule/Hooks/useHomeFeedInfiniteQuery";
import { queryClient } from "src/Utils/ReactQueryConfig";
import useVotePollMutation from "../../Hooks/useVotePollMutation";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import { useLocation } from "react-router-dom";
import { GET_MY_POSTS_LISTS } from "src/Modules/MyPostsModule/Hooks/useMyPostsListInfiniteQuery";
import { GET_HIDDEN_POSTS } from "src/Modules/HiddenPostsModule/Hooks/useHiddenPostsInfiniteQuery";
import { GET_MY_BOOKMARKS } from "src/Modules/BookmarksModule/Hooks/useBookmarkPostsListInfiniteQuery";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const CONFIRM_POLL_VOTE_MODAL = "confirmPollVoteModal";

export const openConfirmPollVoteModal = (data: any) => {
  emitEvent(CONFIRM_POLL_VOTE_MODAL, data);
};
function PollModal() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [postData, setPostData] = useState<any>();
  useEventEmitter(CONFIRM_POLL_VOTE_MODAL, (data: any) => {
    setShowModal(true);
    setPostData(data);
  });

  const votePollMutation = useVotePollMutation();
  const { user } = useAuthValue();
  const { pathname } = useLocation();

  const votePoll = () => {
    votePollMutation.mutate(
      {
        subId: user?.username,
        postId: postData?.postId,
        optionName: postData?.optionName,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            const { statusCode, message, data } = responseData?.data;
            if (statusCode === 200 || statusCode === 201) {
              if (pathname === "/my-posts") {
                queryClient.setQueryData(
                  GET_MY_POSTS_LISTS,
                  (oldData: any) => ({
                    pages: oldData.pages.map((page: any) => ({
                      ...page,
                      Items: page?.Items?.map((el: any) =>
                        el.id === postData?.postId
                          ? {
                              ...el,
                              options: data?.options,
                              vote: data?.vote,
                              totalVoteCount: data?.totalVoteCount,
                            }
                          : el
                      ),
                    })),
                  })
                );
              } else if (pathname === "/hidden-posts") {
                queryClient.setQueryData(GET_HIDDEN_POSTS, (oldData: any) => ({
                  pages: oldData.pages.map((page: any) => ({
                    ...page,
                    Items: page?.Items?.map((el: any) =>
                      el.id === postData?.postId
                        ? {
                            ...el,
                            options: data?.options,
                            vote: data?.vote,
                            totalVoteCount: data?.totalVoteCount,
                          }
                        : el
                    ),
                  })),
                }));
              } else if (pathname === "/bookmarks") {
                queryClient.setQueryData(GET_MY_BOOKMARKS, (oldData: any) => ({
                  pages: oldData.pages.map((page: any) => ({
                    ...page,
                    Items: page?.Items?.map((el: any) =>
                      el.id === postData?.postId
                        ? {
                            ...el,
                            options: data?.options,
                            vote: data?.vote,
                            totalVoteCount: data?.totalVoteCount,
                          }
                        : el
                    ),
                  })),
                }));
              } else {
                queryClient.setQueryData(
                  GET_HOME_FEED_QUERY_KEY(""),
                  (oldData: any) => ({
                    pages: oldData.pages.map((page: any) => ({
                      ...page,
                      Items: page?.Items?.map((el: any) =>
                        el.id === postData?.postId
                          ? {
                              ...el,
                              options: data?.options,
                              vote: data?.vote,
                              totalVoteCount: data?.totalVoteCount,
                            }
                          : el
                      ),
                    })),
                  })
                );
              }
              setShowModal(false);
            }

            if (statusCode === 400) {
              SnackbarHandler.errorToast(message);
              setShowModal(false);
              return;
            }
          }
        },
      }
    );
  };
  return (
    <>
      <div
        className={`modal fade pr-0 ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        id="pollModal"
        aria-hidden="true"
      >
        <div className="modal-dialog custom_poll_Modal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <img src={images.cloudIcon} alt="icon" />
              <p className="pt-3">{strings?.click_to_submit_vote} </p>
            </div>
            <div className="modal-footer justify-content-center pt-4">
              <button
                type="button"
                className="btnCancel"
                onClick={() => setShowModal(false)}
              >
                {strings.cancel}
              </button>
              <button
                type="button"
                className="saveBtn"
                onClick={votePoll}
                disabled={votePollMutation?.isLoading}
              >
                <VisibilityToggle visible={votePollMutation?.isLoading}>
                  <span className="spinner-border spinner-border-sm"></span>
                </VisibilityToggle>
                {strings.save}
              </button>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </>
  );
}

export default PollModal;
