import moment from "moment";
import Slider from "react-slick";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useSaleDetailQuery from "src/Modules/SaleModule/Hooks/useSaleDetailQuery";
import { Link } from "react-router-dom";
import DeleteSalePostModal, {
  openDeleteSalePostModal,
} from "../DeleteSalePostModal/DeleteSalePostModal";
import ReportSalePostModal, {
  reportSalePost,
} from "../ReportSalePostModal/ReportSalePostModal";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import ReadMoreLessMore from "src/Components/Custom/ReadMoreLessMore/ReadMoreLessMore";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function SalePostDetail() {
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { data, isLoading } = useSaleDetailQuery();
  const saleDetails = data?.data ?? {};
  console.log("sssssssssss", saleDetails);
  const { user } = useAuthValue();

  return (
    <>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-between px-3">
        <Link className="d-flex mb-4 align-items-center arrow_row" to="/sales">
          <i className="fa fa-angle-left fz24" aria-hidden="true"></i>
          <h3 className="fz20 mb-0 ml-3 poppinssemibold">{strings.detail}</h3>
        </Link>
        <div className={`slider_down_arrow position-relative pl-4`}>
          <i className="fa fa-angle-down fz26" aria-hidden="true"></i>
          <div className={`user_actions user_actions_posLTR`}>
            <ul className={`list-unstyled "text-left mb-0 poppinsbold`}>
              <VisibilityToggle
                visible={user.username === saleDetails?.data?.subId}
              >
                <li>
                  <Link
                    to={`/sales/edit/${saleDetails?.data?.id}`}
                    className="py-4"
                  >
                    <i
                      className="fa fa-pencil mr-4 fz18"
                      aria-hidden="true"
                    ></i>
                    {strings.edit}
                  </Link>
                </li>
              </VisibilityToggle>
              <VisibilityToggle
                visible={user.username === saleDetails?.data?.subId}
              >
                <li>
                  <a
                    className="py-4"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      openDeleteSalePostModal(saleDetails);
                    }}
                  >
                    <i className="fa fa-trash mr-4 fz18" aria-hidden="true"></i>
                    {/* <VisibilityToggle visible={deleteStatus === "loading"}>
                      <span className="spinner-border spinner-border-sm"></span>
                      &nbsp; &nbsp;
                    </VisibilityToggle> */}
                    {strings.delete}
                  </a>
                </li>
              </VisibilityToggle>
              <VisibilityToggle
                visible={user.username !== saleDetails?.data?.subId}
              >
                <li>
                  <a
                    className="py-4"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      reportSalePost();
                    }}
                  >
                    {strings.report}
                  </a>
                </li>
              </VisibilityToggle>
            </ul>
          </div>
        </div>
      </div>
      <div className="px-3 pt-2">
        <div className="ItemDetailsRow position-relative">
          <figure className="itemDetailcolLeft">
            <Slider {...detilaSlider}>
              {(saleDetails?.data?.images ?? []).map(
                (item: any, index: number) => {
                  return (
                    <div
                      className="detilaSlider_Item sale_Item_card"
                      key={`item${index}`}
                    >
                      <img src={item?.url} className="img-fluid w-100" alt="" />
                    </div>
                  );
                }
              )}
            </Slider>
          </figure>
          <div className="rightColDetails">
            <div className="para_row position-relative">
              <b className="itemPrice">USD {saleDetails?.data?.sale_price}</b>
              <p className="selectedTex">
                {saleDetails?.data?.condition ?? "NA"}
              </p>
              {/* favorite-box */}
              {/* <div className="favoriteBox">
                <img src="/assets/images/icons/heart_Icon.png" alt="heart" />
                <span className="fz16 pl-2">5k</span>
              </div> */}
            </div>
            <p className="fz20">{saleDetails?.data?.title}</p>
            <ul className="item_Details pl-0 mb-0">
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.clockIcon} alt="clock" />
                </figure>
                <p>
                  {saleDetails?.data?.timestamp
                    ? moment(saleDetails?.data?.timestamp).fromNow()
                    : ""}
                </p>
              </li>
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.pinLocationIco} alt="clock" />
                </figure>
                <p>
                  {saleDetails?.city?.name} {saleDetails?.state?.name}
                </p>
              </li>
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.menuImg} alt="clock" />
                </figure>
                <p>{saleDetails?.category?.name}</p>
              </li>
              <li>
                <figure className="iconLeftDetails">
                  <div className="Post_profile_box">
                    <img
                      src={
                        saleDetails?.data?.user_image
                          ? saleDetails?.data?.user_image
                          : images.dummyProfile
                      }
                      alt="clock"
                    />
                  </div>
                </figure>
                <p>{saleDetails?.data?.user_name}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="descriptionRow">
          <h3 className="fz14">{strings.description}</h3>
          <p className="readmore">
            <ReadMoreLessMore
              text={saleDetails?.data?.message}
              showChar={250}
            />
          </p>
        </div>
        <div className="btn_Group">
          <button type="button" className="contactBtn">
            {strings?.contact}
          </button>
        </div>
      </div>

      <DeleteSalePostModal />
      <ReportSalePostModal />
    </>
  );
}
export default SalePostDetail;
