import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PostModal from "src/Components/Custom/PostModal/PostModal";
import useHomeFeedInfiniteQuery, {
  extractHomeFeed,
} from "../../Hooks/useHomeFeedInfiniteQuery";
import HomeFeedItem from "../HomeFeedItem/HomeFeedItem";
import ConfirmDeletePostModal from "../ConfirmDeletePostModal/ConfirmDeletePostModal";
import PollModal from "src/Modules/PollModule/Components/PollModal/PollModal";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import ReportPostModal from "../ReportPostModal/ReportPostModal";
import { useDebounce } from "use-debounce";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import images from "src/Utils/images";

const HOME_SEARCH_EVENT = "homeSearchEvent";

export const addSearchFilterValue = (data: string) => {
  emitEvent(HOME_SEARCH_EVENT, data);
};
function HomePostList() {
  const [searchText, setSearchText] = useState<string>("");
  const [searchValue] = useDebounce(searchText, 500);
  const homeFeedInfiniteQuery = useHomeFeedInfiniteQuery(searchValue);
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    homeFeedInfiniteQuery;
  const homeFeedList = useMemo(
    () => extractHomeFeed(homeFeedInfiniteQuery?.data?.pages ?? []),
    [homeFeedInfiniteQuery?.data?.pages]
  );
  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  useEventEmitter(HOME_SEARCH_EVENT, (data: string) => {
    setSearchText(data);
  });

  return (
    <>
      {isLoading && <Loader />}
      {homeFeedList?.length === 0 && (
        <div className="text-center pt-3 ">
          <img src={images.nodataFound} alt={"img"} />
          <h5 className="fz16 pt-1">No Data Found</h5>
        </div>
      )}
      <>
        <InfiniteScroll
          dataLength={homeFeedList?.length} //This is important field to render the next data
          next={fetchData}
          hasMore={true}
          loader={isLoading && <Loader />}
          endMessage={
            <p className="text-center">
              <b>{strings.u_have_seen_all}</b>
            </p>
          }
          // below props only if you need pull down functionality
          refreshFunction={refetch}
          // pullDownToRefresh
          pullDownToRefreshThreshold={5}
          // pullDownToRefreshContent={
          //   <h6 className="text-center">
          //     &#8595; {strings.pull_down_to_refresh}
          //   </h6>
          // }
          // releaseToRefreshContent={
          //   <h6 className="text-center">
          //     &#8593; {strings.release_to_refresh}
          //   </h6>
          // }
        >
          {(homeFeedList ?? []).map((item: any, index: number) => (
            <HomeFeedItem key={index} item={item} />
          ))}
        </InfiniteScroll>
      </>
      <PostModal />
      <ConfirmDeletePostModal />
      <ReportPostModal />
      <PollModal />
    </>
  );
}

export default HomePostList;
