import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_SALE_DETAILS_KEY = (postId: any) => ["saleDetailsKey", postId];

const saleDetails = async (postId: any): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.postDetail}?subId=${user?.username}&postId=${postId}`,
    method: "GET",
  });
};
function useSaleDetailQuery() {
  const params = useParams();
  return useQuery(GET_SALE_DETAILS_KEY(params?.id), () =>
    saleDetails(params?.id)
  );
}

export default useSaleDetailQuery;
