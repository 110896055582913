import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import useAuthValue, { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_COMMENTS_QUERY_KEY = (postId: number) => [
  "getComments",
  postId,
];

const LIMIT = 100;

const getComments = async (page: number, postId: number): Promise<any> => {
  const { user } = getAuthValue();
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.comments}?subId=${user?.username}&postId=${postId}&page=${page}`,
  });
  console.log("Response Data", response?.data);
  return response?.data;
};

export const extractComments = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getChatNextPageParam = (lastPage: any) => {
  const { Page, TotalPages } = lastPage;
  return TotalPages > Number(Page) ? Number(Page) + 1 : undefined;
};

function useCommentsInfiniteQuery() {
  const params: any = useParams();
  console.log("params///////////////////////////////", params);
  return useInfiniteQuery(
    GET_COMMENTS_QUERY_KEY(params?.id),
    ({ pageParam = 1 }) =>
      getComments(pageParam === null ? 1 : pageParam, params?.id),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getChatNextPageParam,
    }
  );
}

export default useCommentsInfiniteQuery;
