import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import { fetcherUrl } from "src/Utils/Helpers";

const getShoProductDetails = async (
  query: any
): Promise<AxiosResponse<any>> => {
  return fetcherUrl({
    baseURL: `https://bloomyourcity-dev.myshopify.com/api/graphql`,
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      "X-Shopify-Storefront-Access-Token": "e60d9567f7b96d3b7d8a08a528ae1e9a",
    },
    data: query,
  });
};

function useAddToCartMutation() {
  return useMutation(getShoProductDetails);
}
export default useAddToCartMutation;
