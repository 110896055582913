import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import useAuthValue, { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_SALE_CATEGORIES_KEY = ["saleCategoriesKey"];

const getSaleCategories = async (): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.saleCategoris}?subId=${user?.username}&limit=10`,
    method: "GET",
  });
};
function useSaleCategoriesQuery() {
  return useQuery(GET_SALE_CATEGORIES_KEY, getSaleCategories);
}

export default useSaleCategoriesQuery;
