import EditProfileModal, {
  openEditProfile,
} from "../EditProfileModal/EditProfileModal";
import { Link } from "react-router-dom";
import useMyProfieQuery from "../../Hooks/useMyProfieQuery";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";

function MyProfile() {
  const myProfieQuery = useMyProfieQuery();
  const myPofileDetails = myProfieQuery?.data?.data?.Items ?? [];

  return (
    <>
      {myProfieQuery?.isLoading && <Loader />}
      <div id="content" className={`ltr-content-padding`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-9">
              {myPofileDetails?.map((item: any, i: any) => (
                <div
                  className="col_selector bg-white px-2 px-4 pt-3 pb-4 post_feed"
                  style={{ borderRadius: "9px" }}
                  key={i}
                >
                  <div className="mb-4">
                    <div className="d-flex justify-content-between">
                      <Link
                        className="d-flex align-items-center arrow_row"
                        to="/home"
                      >
                        <img
                          src={images.leftBackArrow}
                          alt="icon"
                          height="18"
                        />
                        <h3 className="fz20 mb-0 ml-3 poppinssemibold">
                          {strings.profile}
                        </h3>
                      </Link>
                      <div
                        className={`slider_down_arrow position-relative pl-4`}
                      >
                        <button
                          onClick={() => openEditProfile(item)}
                          className="poppinssemibold editButton bg-transparent"
                        >
                          <img
                            src={images.editBtn}
                            className="mr-2"
                            alt="editicon"
                          />
                          <span className="pt-1">{strings.edit}</span>
                        </button>
                      </div>
                    </div>

                    <div className="d-flex flex-column position-relative">
                      <div className="row my-3 ">
                        <div className="col-sm-12">
                          <p className="fz14 poppinsmedium mb-0 profile_info">
                            {strings.personal_info}
                          </p>
                        </div>
                      </div>
                      <div className="row align-items-center pl-2">
                        <div className="position-relative">
                          <img
                            src={
                              item?.picture !== ""
                                ? item?.picture
                                : images.profileCamera
                            }
                            width="80"
                            height="80"
                            className="rounded-circle object_fit"
                            alt=""
                          />
                        </div>
                        <div className="pl-3 rightprofile_Address">
                          <h3 className="fz20 poppinsbold mb-0">
                            {item?.name} {item?.family_name}
                          </h3>
                          <span className="fz15 poppinsbold base_color">
                            {item?.state_name}, {item?.city_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row fz15 poppinsregular">
                        <span className="col-sm-3 adrees_details">
                          {strings?.phone}
                        </span>
                        <div className="col-sm-9">
                          <p className="fz15 right-Address-Details">
                            {item?.phone_number}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row fz15 poppinsregular">
                        <span className="col-sm-3 adrees_details">
                          {strings?.email}
                        </span>
                        <div className="col-sm-9">
                          <p className="fz15 right-Address-Details">
                            {item?.email}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row fz15 poppinsregular">
                        <span className="col-sm-3 adrees_details">
                          {strings.address}
                        </span>
                        <div className="col-sm-9">
                          <p className="fz15 right-Address-Details">
                            {item.address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <EditProfileModal />
    </>
  );
}

export default MyProfile;
