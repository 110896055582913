function TermsContentEn() {

  return (
    <div className="terms_condition_section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="term-contition-wrapper">
              <h2>Terms of Use</h2>
              <div className="terms-condition-block">
                <p>Welcome to All Bloom, operated by All Bloom (The “Company,” “All Bloom,” “we,” or “us”).</p>
                <p>By accessing the All Bloom application or its website at <a href="https://www.All Bloom.io">https://www.All Bloom.io</a>, whether through a mobile device, mobile application, or computer (collectively, the “Service”), you (the “User”) agree to be bound by these Terms of Use (this “Agreement”), whether or not you create a All Bloom account. If you wish to create an account on All Bloom and use our Service, you must read and agree to these Terms of Use.</p>
                <p>You must also read the All Bloom Privacy Policy, which is referenced several times in the Agreement and available in full at <a href="https://www.All Bloom.io/privacy-policy">https://www.All Bloom.io/privacy-policy.</a> If you do not accept and agree to be bound by all of the terms contained within this Agreement, including the All Bloom Privacy Policy, please do not use the Service. Please contact us with any questions regarding this Agreement.</p>
                <ol className="listing-wrapper">
                  <li>Your Acceptance of our Terms of Use
                    <ol>
                      <li>This Agreement is an electronic contract that establishes the legally binding terms you must accept to use the Service. Remember that by accessing this application on Web or mobile platforms, you are agreeing to be bound by these Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you may be prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.</li>
                      <li>If you would like to withdraw your consent, you must terminate your account and cease using the Service altogether. We encourage you to print a copy of this Agreement for your records. Remember that this Agreement can be modified by All Bloom at any time and is effective once it is posted onto the Service. We encourage you to check back frequently for any changes to our Terms of Use.</li>
                    </ol>
                  </li>
                  <li>Eligibility
                    <ol>
                      <li>All Bloom is a Service offered at no charge to allow members of a community defined by a geographic boundary such as Daira or Wilaya to communicate with each other and use the services offered through the application functions. Any use of the Service is void where prohibited. By accessing and using the Service, you represent and warrant that you have the right and authority to use the Service and abide by all of the terms and conditions presented to you in this Agreement.
                      </li>
                      <li>If you create an account, you represent and warrant that you are currently living in the area you shared during your registration.
                      </li>
                    </ol>
                  </li>
                  <li>Registering for All Bloom
                    <ol>
                      <li>In order to use All Bloom, you must register with a valid and verifiable email address. By doing so, you authorize us to send you emails that may include, but are not limited to, questions regarding your satisfaction with our Service or requests to complete surveys regarding our Service. For more information on how we use and collect information from you, please refer to our Privacy Policy.</li>
                    </ol>
                  </li>
                  <li>Use License
                  <ol>
                      <li>Permission is granted to temporarily download one (1) copy of the materials (information or
                      software) on All Bloom’s website for personal, non-commercial transitory viewing only. This is the
                      grant of a license, not
                      a transfer of title, and under this license you may not: modify or copy the materials; use the
                      materials for any commercial purpose, or for any public display (commercial or non-commercial);
                      attempt to decompile
                      or reverse engineer any software contained on All Bloom’s website; remove any copyright or other
                      proprietary notations from the materials; or transfer the materials to another person or “mirror”
                      the materials on
                      any other server.
                    </li>
                      <li>This license shall automatically terminate if you violate any of these restrictions and may be
                      terminated by All Bloom at any time. Upon terminating your viewing of these materials or upon the
                      termination of this license,
                      you must destroy any downloaded materials in your possession whether in electronic or printed
                      format.
                    </li>
                    </ol>
                  </li>
                  <li>Termination of this Agreement
                  <ol>
                      <li>This agreement will remain valid and in full effect while you use the Service and/or have a
                      All Bloom account. You may disable your account at any time, for any reason, by following the
                      instructions in “Settings” in
                      the Service.
                    </li>
                      <li>All Bloom maintains the right to terminate or suspend your account at any time without notice if
                      the Company believes that you have breached this Agreement, or for any other reason, with or
                      without cause, in its sole
                      discretion.
                    </li>
                      <li>All Bloom is not required to disclose, and may be prohibited by law from disclosing, the reason for
                      the termination or suspension of your account.
                    </li>
                      <li>After such termination or suspension, all terms of this Agreement in addition to the terms of
                      our Privacy Policy survive such termination and continue in full force and effect, unless they
                      expire by nature or are
                      fully satisfied.
                    </li>
                    </ol>
                  </li>
                  <li>Your interactions with other Users
                  <ol>
                      <li>You are solely responsible for your interactions with other users.
                    </li>
                      <li>You understand that the company currently does not inquire into the backgrounds of all of its
                      users or attempt to verify the accuracy of the information provided by its users. We make no
                      representations or warranties
                      as to the conduct of our users and we reserve the right to conduct any criminal background check
                      or other screenings at any time.
                    </li>
                      <li>All Bloom is not responsible for the conduct of any user. In no event will All Bloom, its affiliates,
                      or its partners be liable for any losses or damages whatsoever arising out of use of our Service.
                      However, we will
                      do our best to remedy any situation of conflict that may arise when interacting with other users.
                    </li>
                      <li>You agree to take all necessary precautions in all interactions with other users, particularly
                      if you agree to meet in person or communicate using a different service.</li>
                      <li>For the avoidance of doubt, in no event will All Bloom, its affiliates, or its partners be liable
                      for your or any other users' behavior that violates applicable laws, regulations and / or
                      university policies related
                     .</li>
                      <li>You should not provide your financial information or send any money to other users of All Bloom.
                    </li>
                    </ol>
                  </li>
                  <li>Content created by you, the User, in our Service
                  <ol>
                      <li>You are solely responsible for any and all content and information that you make available on
                      the Service (hereinafter, “post”). This includes text messages, chats, video chats, photographs,
                      and profile text (hereinafter,
                      “content”).
                    </li>
                      <li>You are prohibited from posting any content that is offensive, vulgar, racist, profane,
                      threatening, or illegal, or any material that infringes or violates another person’s rights.
                    </li>
                      <li>
                        You represent and warrant that:
                      <ol>
                          <li>All information that you submit upon creation of your account is accurate and truthful and
                          that you will update any information should its accuracy or truthfulness change at any time
                          for any reason,</li>
                          <li>You have the right to post the content on the Service should it follow the above
                          regulations.</li>
                        </ol>
                      </li>
                      <li>You understand and agree that we may monitor or review any and all content that you post in our
                      Service and reserve the right to remove any and all of aforementioned content at our sole
                      discretion.
                    </li>
                      <li>By posting content to our Service, you grant All Bloom, our affiliates, licensees, and successors
                      an irrevocable, perpetual, non-exclusive, transferable, sub-licensable, worldwide right and
                      license to use, copy, store,
                      display, reproduce, record, play, adapt, modify, and distribute the content you post and
                      incorporate the content into other works.
                    </li>
                      <li>In addition to the types of content prohibited in Section 7.2 above, the following is a list of
                      some, but not all, of the content that is prohibited from being posted to our Service:
                      <ol>
                          <li>Advocating harassment or intimidation of another person
                        </li>
                          <li>Promoting racism, hatred, bigotry, or physical harm against any group or individual
                        </li>
                          <li>Involving “spamming,” “trolling,” or similar activities</li>
                          <li>Containing false or misleading information
                        </li>
                          <li>Containing material that exploits people in a sexual, violent, or other illegal manner</li>
                          <li>Containing instructional resources about illegal activities
                        </li>
                          <li>Containing viruses, trojans, or other harmful materials
                        </li>
                          <li>Containing any material that All Bloom determines to be harmful to other users</li>
                        </ol>
                      </li>
                      <li>All Bloom reserves the right to investigate and take any available legal action against anyone who
                      violates any of the provisions outlined in 7.
                    </li>
                      <li>You agree that All Bloom may access, preserve, and disclose your account information and any
                      content that you’ve ever posted if required by law to do so or if we believe, at our sole
                      discretion, that we should need
                      to do so.
                    </li>
                      <li>You agree that any other user or person participating in the Service may view any content you
                      post to our Service.
                    </li>
                    </ol>
                  </li>
                  <li>Prohibited Activities
                  <ol>
                      <li>Below is a list of some, but not all, of the actions that you may not engage in with respect to
                      our Service. You will not:
                    </li>
                      <ol>
                        <li>Post any content prohibited in Section 7
                      </li>
                        <li>Stalk or harass any user</li>
                        <li>Use All Bloom in an illegal manner or to commit an illegal act</li>
                        <li>Use All Bloom to commit an act that violates laws, regulations or university policies related
                      </li>
                        <li>In any way reproduce or circumvent the navigational structure or presentation of the Service
                        or its contents
                      </li>
                        <li>Interfere or disrupt the Service or its servers or networks
                      </li>
                        <li>Post or transmit any material containing software viruses, trojans, or other code designed to
                        interfere in any way with software, hardware, or telecommunications equipment
                      </li>
                        <li>Impersonate any person, user, or entity, especially, but not limited to, a member of the
                        All Bloom team</li>
                        <li>Reproduce any part of our Service without our prior written authorization</li>
                        <li>Screen scrape or data mine any content or information obtained from the Service unless it is
                        within accordance of this Agreement
                      </li>
                      </ol>
                    </ol>
                  </li>
                  <li>Commercial use of our Service
                  <ol>
                      <li>All Bloom is offered for personal use at no charge. Users may not use the Service or any of its
                      content in connection with any commercial endeavors without prior consent by All Bloom and its
                      affiliates. Such endeavors
                      may include, but are not limited to:
                      <ol>
                          <li>Advertising or soliciting any user to buy or sell products or services
                        </li>
                          <li>Soliciting other users to attend parties or other social functions in violation of current
                          regulations related</li>
                          <li>Soliciting other users to commit acts that violate applicable laws, regulations and policies
                          related</li>
                        </ol>
                      </li>
                      <li>Users of this Service may not use any information obtained through the Service to contact,
                      advertise to, solicit, or sell to any other user without his or her prior explicit consent.
                    </li>
                      <li>Organizations, companies, and/or businesses may not use All Bloom for any purpose without the prior
                      agreement with All Bloom or its affiliates</li>
                      <li>All Bloom reserves the right to investigate and/or take any available legal action in response to
                      illegal and/or unauthorized uses of the Service.
                    </li>
                    </ol>
                  </li>
                  <li>Account Security
                  <ol>
                      <li>You are solely responsible for maintaining the confidentiality of the username and password you
                      designate for your account upon registration with our Service. You are solely responsible for any
                      and all activities
                      that occur under your account.</li>
                      <li>You agree to immediately notify the Company if anyone else has unauthorized access to your
                      account or if any other breach of security occurs. You can contact us at support@AllBloom.com.
                    </li>
                    </ol>
                  </li>
                  <li>Links
                  <ol>
                      <li>All Bloom has not reviewed all of the sites and apps linked to its Internet web site and is not
                      responsible for the contents of any such linked site or app. The inclusion of any link does not
                      imply endorsement by All Bloom
                      of the site. Use of any such linked web site or app is at the user's own risk.
                    </li>
                    </ol>
                  </li>
                  <li>
                    Proprietary Rights
                  <ol>
                      <li>All Bloom owns and retains all proprietary rights in the Service and in all content, trademarks,
                      trade names, service marks, and other intellectual property rights related thereto.</li>
                      <li>You agree not to copy, modify, transmit, create any derivative works from, make use of, or
                      reproduce in any way copyrighted material, trade names, service marks, or any other intellectual
                      property of the Service
                      without first obtaining the prior written consent of the Company or the owner of such intellectual
                      property.</li>
                    </ol>
                  </li>
                  <li>Moderation
                  <ol>
                      <li>All Bloom does not tolerate the uploading of or creation of inappropriate content within the
                      platform. All Bloom moderates and removes inappropriate content, which can include, but is not
                      limited to, pornographic material.
                      Users can flag and report objectionable content they see within the mobile app. Reported content
                      will be reviewed, and users who publish said content may be removed from the service</li>
                    </ol>
                  </li>
                  <li>Governing Law
                  <ol>
                      <li>Any claim relating to All Bloom’s website or mobile apps shall be governed by the laws of
                      Democrative Republic of Algeria without regard to its conflict of law provisions. General Terms
                      and Conditions are applicable
                      to Use of a Web Site or mobile app.
                    </li>
                    </ol>
                  </li>
                  <li>Customer Service
                  <ol>
                      <li>All Bloom will provide you with assistance from time to time. However, while communicating with us,
                      you agree not to be abusive, offensive, or otherwise inappropriate towards us. If, at our sole
                      discretion, we find
                      your behavior to be inappropriate in any way, we reserve the right to immediately terminate your
                      account and take any available legal action.
                    </li>
                    </ol>
                  </li>
                  <li>
                    Changes to our Service
                  <ol>
                      <li>
                        All Bloom reserves the right to modify or discontinue any part of the Service with or without notice
                        for any amount of time.
                    </li>
                      <li>You agree that All Bloom will not be liable to you for any change we make to our Service.
                    </li>
                    </ol>
                  </li>
                  <li>Revisions and Errata
                  <ol>
                      <li>The materials appearing on All Bloom’s website or mobile apps could include technical,
                      typographical, or photographic errors. All Bloom does not warrant that any of the materials on its
                      web site are accurate, complete,
                      or current. All Bloom may make changes to the materials contained on its web site or mobile apps at
                      any time without notice. All Bloom does not, however, make any commitment to update the materials.
                    </li>
                    </ol>
                  </li>
                  <li>Disclaimer
                  <ol>
                      <li>The material and content on All Bloom’s website and mobile apps are provided “as is”. All Bloom makes
                      no warranties, expressed or implied, and hereby disclaims and negates all other warranties,
                      including without limitation,
                      implied warranties or conditions of merchantability, fitness for a particular purpose, or
                      non-infringement of intellectual property or other violation of rights.</li>
                      <li>Furthermore, All Bloom does not warrant or make any representations concerning the accuracy, likely
                      results, or reliability of the use of the materials on its Internet web site or mobile apps or
                      otherwise relating
                      to such materials or on any sites linked to this site.
                    </li>
                      <li>All Bloom is never, under any circumstances, responsible or liable for any losses or damages
                      resulting directly or indirectly from your use of our Service. </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default TermsContentEn;