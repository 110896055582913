import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GROUP_ACCEPT_REJECT_MEMBER_REQUEST } from "../Types/RequestTypes";
import { useParams } from "react-router-dom";
import { GET_GROUP_DETAILS_KEY } from "./useGroupDetailsQuery";
import { GET_INVITE_KEY } from "./useGroupRequestsQuery";
import { GET_GROUP_MEMBERS_KEY } from "./useGroupMembersQuery";

const acceptMember = async (
  data: GROUP_ACCEPT_REJECT_MEMBER_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.addMember,
    method: "POST",
    data: data,
  });
};

function useAcceptMemberMutation() {
  const params = useParams();
  return useMutation(acceptMember, {
    onSuccess: (responseData) => {
      if (responseData) {
        queryClient.refetchQueries(GET_GROUP_DETAILS_KEY(Number(params?.id)));
        queryClient.refetchQueries(GET_INVITE_KEY(params?.id));
        queryClient.refetchQueries(GET_GROUP_MEMBERS_KEY(params?.id));
      }
    },
  });
}

export default useAcceptMemberMutation;
