import React from 'react'
import Layout from 'src/Modules/Layout/Layout'
import CreateSaleDetails from 'src/Modules/SaleModule/Components/CreateSaleDetails/CreateSaleDetails'

function CreateSale() {
  return (
    <Layout>
      <CreateSaleDetails />
    </Layout>
  )
}

export default CreateSale