import React, { useState } from "react";
import Drawer from "src/Components/Custom/Drawer/Drawer";
import UserNavBar from "src/Components/Custom/UserNavBar/UserNavBar";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function Layout({ children }: any) {
  const { toggleMenu } = useAuthValue();
  return (
    <div className={toggleMenu ? "toggle_class" : ""}>
      <UserNavBar />
      <div className="wrapper">
        <div className="container">
          <Drawer />
          {children}
        </div>
      </div>
    </div>
  );
}

export default Layout;
