import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { useForm } from "react-hook-form";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEventEmitter from "src/Hooks/Shared/useEventEmmiter";
import useAuth from "src/Hooks/Custom/useAuth";
import { strings } from "src/Utils/Localization";

const GROUP_REPORT_COMMENT_EVENT = "reportEvent";

export const groupReportComment = (data: any) => {
  emitEvent(GROUP_REPORT_COMMENT_EVENT, data);
};

function GroupDetailsReportCommentModal() {
  const { loading } = useAuth();
  const [commentData, setCommentData] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      message: "",
    },
    mode: "onChange",
  });

  useEventEmitter(GROUP_REPORT_COMMENT_EVENT, (data) => {
    setShowModal(true);
    console.log("commentDataaaaa", data);
    setCommentData(data);
  });

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form>
                  <div className={`form-group `}>
                    <label className="mb-0">{strings?.write_a_report}</label>
                  </div>
                  <div className={`form-group `}>
                    <textarea
                      className="form-control"
                      placeholder={strings?.write_a_message}
                      {...register("message", {
                        required: "*Message required",
                      })}
                    ></textarea>
                    {errors?.message?.message && (
                      <span className="ml-3 text-danger">
                        {errors.message?.message}
                      </span>
                    )}
                  </div>
                  <div className="d-sm-flex justify-content-between">
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn custom_btn mr-3 border text-secondary bg-transparent fz14"
                        onClick={() => setShowModal(false)}
                      >
                        {strings?.cancel}
                      </button>
                      <button type="submit" className="btn custom_btn fz14">
                        <VisibilityToggle visible={loading}>
                          <span className="spinner-border spinner-border-sm"></span>
                        </VisibilityToggle>
                        {strings?.submit}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default GroupDetailsReportCommentModal;
