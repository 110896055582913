import React from "react";
import Slider from "react-slick";
import images from "src/Utils/images";
import moment from "moment";
import "./EventItem.css";
import FeedItemFooter from "../FeedItemFooter/FeedItemFooter";

type EVENT_TYPE_PROPS = {
  item: any;
};
function EventItem(props: EVENT_TYPE_PROPS) {
  const { item } = props;
  const eventDate: any =
    typeof item?.date !== "object"
      ? moment(item?.date).format("DD MMM YYYY")
      : moment(new Date()).format("DD MMM YYYY");
  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="post_content_wrap pt-4">
        <div className="slider_section1 custom_group_Slider mb-3 ">
          <Slider {...detilaSlider}>
            {(item?.images ?? []).map((eventItem: any, index: number) => {
              return (
                <div className="detilaSlider_Item" key={`eventItem${index}`}>
                  <img
                    src={eventItem?.url}
                    className="img-fluid w-100 rounded"
                    alt="hotelimg"
                  />
                </div>
              );
            })}
          </Slider>
        </div>
        {/* Title */}
        <h4 className="fz18 poppinsbold mb-3 event_Heading">{item?.title}</h4>
        {/* Title-close */}

        {/* Location-Row */}
        <div className="safeyheadingRow mb-2">
          <img src={images.pinIcon} alt="pinIcon" />
          <span className="safetyHeading grey_color fz14">
            {item?.location}
          </span>
        </div>
        {/* Location-Row-close */}

        {/* Event-Date-Row */}
        <div className="safeyheadingRow">
          <img src={images.dategreyicon} alt="dategreyicon" />
          <span className="safetyHeading grey_color fz14">{`${eventDate}, ${item?.from
            ?.toString()
            .replace(".", ":")} to ${item?.to
            .toString()
            .replace(".", ":")}`}</span>
        </div>
        {/* Event-Date-Row-close */}

        <div className="px-0">
          <hr />
        </div>
        {/* Like-and-comment-section-start */}
        <FeedItemFooter item={item} />
        {/* Like-and-comment-section-close */}
      </div>
    </>
  );
}

export default EventItem;
