import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import useAuthValue, { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_BUSINESS_LIST_QUERY_KEY = (
  search: string,
  categoryId: number | undefined
) => ["getBusinessListQuery", search, categoryId];
const LIMIT = 6;

const getBusinessList = async (
  page: number,
  search: string,
  categoryId: number | undefined
): Promise<any> => {
  const { user } = getAuthValue();
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.businessPosts}?subId=${
      user?.username
    }&limit=${LIMIT}&page=${page}&search=${search}${
      categoryId ? `&category_id=${categoryId}` : ""
    }`,
  });
  return response?.data;
};

export const extractBusinessFeed = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getChatNextPageParam = (lastPage: any) => {
  const { Page, TotalPages } = lastPage;
  return TotalPages > Number(Page) ? Number(Page) + 1 : undefined;
};

function useBusinessListInfiniteQuery(
  search: string,
  categoryId: number | undefined
) {
  return useInfiniteQuery(
    GET_BUSINESS_LIST_QUERY_KEY(search, categoryId),
    ({ pageParam = 1 }) =>
      getBusinessList(pageParam === null ? 1 : pageParam, search, categoryId),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getChatNextPageParam,
    }
  );
}

export default useBusinessListInfiniteQuery;
