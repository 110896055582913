import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useCreateSafetyMutation from "../../Hooks/useCreateSafetyMutation";
import "./SafetyPost.css";
import { getRequiredRules } from "../../../../Utils/Validators";
import { useNavigate } from "react-router-dom";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function SafetyPost() {
  const navigate = useNavigate();
  const [imageList, setImageList] = useState<any>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [deleteImgs, setDeleteImgs] = useState<Array<string>>([]);
  const createSafetyMutation = useCreateSafetyMutation();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<any>({
    defaultValues: {
      description: "",
    },
    mode: "onChange",
  });
  const { user } = useAuthValue();
  const addSafetyPost = handleSubmit((values) => {
    console.log("Values", values);
    if (imageUrls?.length === 0) {
      return SnackbarHandler.errorToast("Please Add Photo");
    }
    createSafetyMutation?.mutate(
      {
        subId: user?.username,
        message: values?.description,
        images: imageList,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            navigate("/home");
          }
        },
      }
    );
  });

  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast("You can select maximum 5 images");
        return;
      }
    }
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: any) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  return (
    <>
      {createSafetyMutation?.isLoading && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <button
                type="button"
                className="border-0 mb-3 text-captilize left_Arrow"
                onClick={() => navigate("/home")}
              >
                <img src={images.leftBackArrow} alt="arrow" />
                <span className="fz16 mb-3">{strings?.post_about_safety}</span>
              </button>
              {/* create_post_Wrapper-start */}
              <div className="create_post_Wrapper">
                {/* add-categories */}
                {/* add-categories */}
                <h3 className="fz16 text-captilize poppinsbold mb-3">
                  {strings.categories}
                </h3>
                <div className="categories_add_row">
                  <figure className="mb-0 leftAddPhotoBox">
                    <img src={images.leftAddPhoto} alt="icon" />
                  </figure>
                  <div className="right_addTextCol">
                    <p className="mb-0 poppinsmedium">
                      {strings.post_about_safety}
                    </p>
                  </div>
                </div>
                {/* add-categories-close */}
                {/* add-categories-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* AddPhotoAndVideo-start */}
                <div className="AddPhotoAndVideo">
                  <h3 className="fz16 text-captilize poppinsbold">
                    {strings.add_photo}
                  </h3>
                  <p className="fz14">{strings.you_upload_up_files_in_jpg}</p>
                  {/* upload_photo_Box-start */}
                  <div className="upload_photo_Box">
                    <div className="file-button1">
                      <label
                        htmlFor="add_photo_Multiple"
                        className="button-upload"
                      >
                        <div className="Upload-text1">
                          <p>
                            <img
                              src={images.eventCamera}
                              alt="img"
                              width="23"
                              height="20"
                            />
                            <span className="pl-2 fz15">{strings.upload}</span>
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="photos"
                        id="add_photo_Multiple"
                        multiple={true}
                        accept=".jpg, .png, .jpeg, .gif"
                        onChange={(e: any) =>
                          fileSelectedHandler(e?.target?.files)
                        }
                      />
                    </div>
                    <div className="upload_Row d-flex pt-3">
                      {imageList?.map((imgUrl: any, index: number) => {
                        return (
                          <div
                            className="img_col position-relative"
                            key={`imgUrl${index}`}
                          >
                            <div className="uploadImgBox w-100">
                              <img src={imgUrl} alt="img" />
                              <div className="on_hover_overlay"></div>
                              <div
                                className="remove_icon"
                                key={`imgUrl${index}`}
                                onClick={() =>
                                  handleImageRemove(index, imgUrl.id)
                                }
                              >
                                <img src={images.removeIco} alt="icon" />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* upload_photo_Box-start */}
                </div>
                {/* AddPhotoAndVideo-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}
                <form onSubmit={addSafetyPost}>
                  {/* description-area-start */}
                  <div className="description pt-2">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.add_description}
                    </h3>
                    <textarea
                      className="form-control"
                      placeholder="Write a description"
                      {...register(
                        "description",
                        getRequiredRules("Description")
                      )}
                    />
                    {errors?.description?.message && (
                      <div className="text-danger">
                        {errors?.description?.message}
                      </div>
                    )}
                  </div>
                  {/* description-area-close */}

                  {/* tipsBox-start==== */}
                  <div className="tipsBox mt-4 position-relative">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.tips_getting_good_answers}
                    </h3>
                    <ul className="pl-0">
                      <li>{strings.pricillas_a_perfect_nanny}</li>
                      <li>{strings.keep_your_question_short}</li>
                      <li>{strings.double_check_grammar_and_spelling}</li>
                    </ul>
                    <img src={images.bulbImg} alt="bulb" />
                  </div>

                  <button
                    type="submit"
                    className="posttbn mt-4"
                    disabled={createSafetyMutation?.isLoading}
                  >
                    {strings.post} <img src={images.rightArrow} alt="icons" />
                  </button>
                </form>
              </div>
              {/* create_post_Wrapper-close */}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SafetyPost;
