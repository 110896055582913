import { Fragment, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useChatEmit from "../../Hooks/useChatEmit";
import { EVENTS } from "../../Hooks/useChatListeners";
import useChatValue, {
  getChatValue,
  setChatValue,
} from "../../Hooks/useChatValue";

function ChatInput() {
  const [message, setMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const { emitEventWS } = useChatEmit();
  const { isBlocked, hasBlocked } = useChatValue();
  const { user } = useAuthValue();
  const params = useParams();

  const sendMessage = useCallback(() => {
    if (message.trim() === "") {
      return SnackbarHandler.errorToast("Please enter message");
    }
    setLoader(true);
    emitEventWS(
      EVENTS.SEND_MESSAGE,
      {
        senderId: user?.username,
        receiverId: params?.id,
        message: message,
        chatId: null,
      },
      (sendMessageData) => {
        const { chatHistory } = getChatValue();
        const chatList: Array<any> = [...(chatHistory ?? [])];
        const chatBody = JSON.parse(sendMessageData?.body);
        chatList.unshift(chatBody?.data);
        setChatValue({
          ...getChatValue(),
          chatHistory: chatList,
        });
        setLoader(false);
        setMessage("");
      }
    );
  }, [emitEventWS, message, params?.id, user?.username]);

  return (
    <Fragment>
      <div className="message-input">
        <div className="chat-input-box">
          <input
            type="text"
            placeholder={
              hasBlocked
                ? "You have blocked this user"
                : isBlocked
                ? "You have been blocked"
                : "Write your message..."
            }
            disabled={isBlocked || hasBlocked}
            data-emojiable="true"
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setMessage("");
                sendMessage();
              }
            }}
          />
          {
            <button
              className="submit"
              onClick={sendMessage}
              disabled={loader || isBlocked || hasBlocked}
            >
              Send
            </button>
          }
        </div>
      </div>
    </Fragment>
  );
}
export default ChatInput;
