import { AxiosResponse } from "axios";
import { useInfiniteQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_SALE_LIST_QUERY_KEY = (
  search: string,
  categoryId: number | undefined
) => ["getSaleList", search, categoryId];

const getSaleList = async (
  page: number,
  search: string,
  categoryId: number | undefined
): Promise<any> => {
  const { user } = getAuthValue();
  const response: AxiosResponse<any> = await fetcher({
    baseURL: `${EndPoints.getSales}?subId=${
      user?.username
    }&page=${page}&search=${search}${
      categoryId ? `&category_id=${categoryId}` : ""
    }`,
  });
  return response;
};

export const extractSale = (pages: any): Array<any> => {
  return (
    pages
      .map((_: any) => _.data?.Items)
      ?.reduce((a: any, b: any) => a.concat(b), []) ?? []
  );
};

export const getChatNextPageParam = (lastPage: any) => {
  const { Page, TotalPages, Items = [] } = lastPage?.data;
  return TotalPages > Number(Page) && Items?.length !== 0
    ? Number(Page) + 1
    : undefined;
};

function useSalesInfiniteQuery(search: string, categoryId: number | undefined) {
  return useInfiniteQuery(
    GET_SALE_LIST_QUERY_KEY(search, categoryId),
    ({ pageParam = 1 }) =>
      getSaleList(pageParam === null ? 1 : pageParam, search, categoryId),
    {
      onSuccess: (response) => {
        if (response) {
          console.log("Response", response);
        }
      },
      refetchOnWindowFocus: false,
      getNextPageParam: getChatNextPageParam,
    }
  );
}

export default useSalesInfiniteQuery;
