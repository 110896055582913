import React from "react";
import { useNavigate } from "react-router-dom";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useReadNotificationMutation from "../../Hooks/useReadNotificationMutation";
import { NOTIFICATION_LIST_ITEM } from "../../Types/ResponseTypes";
import "./NotificationItem.css";

type NOTIFICATION_LIST_ITEM_PROPS = {
  item: NOTIFICATION_LIST_ITEM;
};
function NotificationItem(props: NOTIFICATION_LIST_ITEM_PROPS) {
  const readMutation = useReadNotificationMutation();
  const { user } = useAuthValue();
  const navigate = useNavigate();

  const { item } = props;
  var notificationPostId = JSON.parse(item?.data?.payload);

  const notificationRedirect = () => {
    if (
      item?.data?.type === "POST_COMMENT" ||
      item?.data?.type === "POST_LIKE" ||
      item?.data?.type === "POST_COMMENT_LIKE"
    ) {
      if (notificationPostId?.groupId !== undefined) {
        return navigate(
          `/groups/${notificationPostId?.groupId}/${notificationPostId?.id}`
        );
      } else {
        return navigate(`/home/${notificationPostId?.id}`);
      }
    }

    if (
      item?.data?.type === "ADDED_IN_GROUP" ||
      item?.data?.type === "REQUESTED_JOIN" ||
      item?.data?.type === "JOINED_GROUP" ||
      item?.data?.type === "MADE_ADMIN" ||
      item?.data?.type === "REQUEST_REJECTED"
    ) {
      return navigate(`/groups/${notificationPostId?.groupId}`);
    }
  };
  const readNotification = () => {
    readMutation?.mutate(
      {
        id: item.id,
      },
      {
        onSuccess: (responseData) => {
          console.log("responseData", responseData);
          notificationRedirect();
        },
      }
    );
  };
  return (
    <li className="notificationListData" onClick={readNotification}>
      <div className="notificationRow">
        <div
          className={`notificationColLeft ${
            item?.isRead === 0 ? "unreadNotification" : "readNotification"
          }`}
        >
          <h3 className="poppinsbold fz16">{item?.notification.title}</h3>
          <p
            className="mb-0 fz13 poppinsregular paraTimeClr"
            dangerouslySetInnerHTML={{
              __html: item?.notification?.body,
            }}
          ></p>
        </div>
        <div className="notificationColRight">
          {/* <h6 className="paraTimeClr">{notificationitem.notificationHr}</h6> */}
        </div>
      </div>
    </li>
  );
}

export default NotificationItem;
