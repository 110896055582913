import { useState, Fragment } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useAcceptMemberMutation from "src/Modules/GroupModule/Hooks/useAcceptMemberMutation";
import { useParams } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const ACCEPT_GROUP_INVITE_MODAL_EVENT = "acceptGroupInviteModalEvent";
export const openAcceptGroupInviteModal = (data: number) => {
  emitEvent(ACCEPT_GROUP_INVITE_MODAL_EVENT, data);
};
function AcceptGroupInviteConfirmModal() {
  const { user } = useAuthValue();
  const params = useParams();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [memberSubId, setMemberSubId] = useState<string>("");
  const acceptMemberMutation = useAcceptMemberMutation();
  const handleRemoveMember = () => {
    acceptMemberMutation?.mutate(
      {
        subId: user?.username,
        memberSubId: memberSubId,
        groupId: Number(params?.id),
      },
      {
        onSuccess: () => {
          setShowModal(false);
        },
      }
    );
  };

  useEventEmitter(ACCEPT_GROUP_INVITE_MODAL_EVENT, (data) => {
    setShowModal(true);
    setMemberSubId(data);
  });
  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="confirm_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="text-center">
                <p className="fz24 text-secondary mb-4">
                  {strings.are_u_sure_accept_invite}
                </p>

                <button
                  className="btn custom_btn mr-2 text-secondary bg-transparent border border-secondary"
                  onClick={() => setShowModal(false)}
                >
                  {strings.no}
                </button>
                <button className="btn custom_btn" onClick={handleRemoveMember}>
                  <VisibilityToggle visible={acceptMemberMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.yes}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}
export default AcceptGroupInviteConfirmModal;
