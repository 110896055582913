import Layout from "src/Modules/Layout/Layout";
import MyPostsList from "src/Modules/MyPostsModule/Components/MyPostsList/MyPostsList";

function MyPosts() {
  return (
    <Layout>
      <div id="content" className={`ltr-content-padding`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="col_selector">
                <MyPostsList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MyPosts;
