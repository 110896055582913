import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "src/Components/Custom/Drawer/Drawer";
import UserNavBar from "src/Components/Custom/UserNavBar/UserNavBar";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue, {
  getAuthValue,
  setAuthValue,
} from "src/Hooks/Custom/useAuthValue";
import useUpdateLanguageMutation from "src/Modules/Layout/Hooks/useUpdateLanguageMutation";

function Settings() {
  const { language, toggleMenu } = useAuthValue();
  const [showModal, setShowModal] = useState<boolean>(false);
  const updateLanguageMutation = useUpdateLanguageMutation();
  const { user } = useAuthValue();

  const handleLangaugeModal = (e: any) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <Fragment>
      <div className={toggleMenu ? "toggle_class" : ""}>
        <UserNavBar />
        <div className="wrapper">
          <div className="container">
            <Drawer />
            <div id="content" className={"ltr-content-padding"}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="col_selector bg-white post_feed rounded px-3">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="col_selector mb-5 setting_shadow text-center">
                            <Link className="p-4 d-inline-block" to="/about">
                              <img
                                src={images.aboutIco}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">
                                {strings.about_us}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col_selector mb-5 setting_shadow text-center">
                            <Link className="p-4 d-inline-block" to="/faq">
                              <img
                                src={images.faqIco}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">{strings.faq}</p>
                            </Link>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col_selector mb-5 setting_shadow text-center">
                            <Link
                              className="p-4 d-inline-block"
                              to="/terms-and-condition"
                            >
                              <img
                                src={images.termsIco}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">
                                {strings.terms_and_conditions}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col_selector mb-5 setting_shadow text-center">
                            <Link
                              className="p-4 d-inline-block"
                              to="/privacy-policy"
                            >
                              <img
                                src={images.privacyIco}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">
                                {strings.privacy_policy}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col_selector setting_shadow text-center">
                            <Link
                              className="p-4 d-inline-block"
                              to="/contact-us"
                            >
                              <img
                                src={images.contactIco}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">
                                {strings.contact_us}
                              </p>
                            </Link>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="col_selector setting_shadow text-center">
                            <a
                              className="p-4 d-inline-block"
                              href="/"
                              onClick={handleLangaugeModal}
                            >
                              <img
                                src={images.language}
                                className="mb-4"
                                alt=""
                              />
                              <p className="p-0 text-center">
                                {strings.language}
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Fragment>
          <div
            className={`modal fade ${showModal ? "show" : ""}`}
            id="confirm_post"
            style={{ display: showModal ? "block" : "none" }}
            role={"dialog"}
          >
            <div className="modal-dialog modal-dialog-centered modal-md">
              <div className="modal-content border-0">
                <div className="modal-header p-4">
                  Select Language
                  <i
                    className="fa fa-times pt-1 cursor-pointer"
                    onClick={() => setShowModal(false)}
                  ></i>
                </div>
                <div className="modal-body p-3 p-md-5 custom_modal">
                  <div className="text-left">
                    <p
                      className="d-flex align-items-center justify-content-between cursor-pointer pb-2"
                      onClick={() => {
                        updateLanguageMutation.mutate(
                          {
                            lang: "en",
                            subId: user?.username,
                          },
                          {
                            onSuccess: (responseData) => {
                              if (responseData) {
                                setAuthValue({
                                  ...getAuthValue(),
                                  language: "en",
                                });
                              }
                            },
                          }
                        );
                      }}
                    >
                      <span>
                        <img
                          src={images.usFlag}
                          className="mr-4 us-flag"
                          height="25"
                          width="25"
                          alt="logo"
                        />
                        <span className="pl-3">{strings.english}</span>
                      </span>
                      {language === "en" ? (
                        <i
                          className="fa fa-check base_color"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <></>
                      )}
                    </p>

                    <p
                      className="d-flex align-items-center justify-content-between cursor-pointer"
                      onClick={() => {
                        updateLanguageMutation.mutate(
                          {
                            lang: "kr",
                            subId: user?.username,
                          },
                          {
                            onSuccess: (responseData) => {
                              if (responseData) {
                                setAuthValue({
                                  ...getAuthValue(),
                                  language: "ko",
                                });
                              }
                            },
                          }
                        );
                      }}
                    >
                      <span>
                        <img
                          src={images.korenFlag}
                          className="mr-4 korean-flag"
                          height="25"
                          width="25"
                          alt="logo"
                        />
                        <span className="pl-3">{strings.korean}</span>
                      </span>
                      {language === "ko" ? (
                        <i
                          className="fa fa-check base_color"
                          aria-hidden="true"
                        ></i>
                      ) : (
                        <></>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VisibilityToggle visible={showModal ? true : false}>
            <div
              className={`modal-backdrop fade ${showModal ? "show" : ""}`}
              onClick={() => setShowModal(false)}
            ></div>
          </VisibilityToggle>
        </Fragment>
      </div>
    </Fragment>
  );
}

export default Settings;
