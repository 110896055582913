import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import images from "../../../Utils/images";

function Drawer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <nav id="sidebar">
      <ul className="list-unstyled components" id="sidebar_menu">
        <li className={pathname.indexOf("home") !== -1 ? "active" : ""}>
          <Link to="/home" onClick={() => navigate("/home")}>
            <img
              src={
                pathname.indexOf("home") !== -1
                  ? images.homeActiveIco
                  : images.homeInActiveIco
              }
              alt="icon"
              height="14"
              width="17"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.home}</span>
          </Link>
        </li>
        <li className={pathname.indexOf("messages") !== -1 ? "active" : ""}>
          <Link to="/messages" onClick={() => navigate("/messages")}>
            <img
              src={
                pathname.indexOf("messages") !== -1
                  ? images.messageActiveIco
                  : images.messageInActiveIco
              }
              alt="icon"
              height="16"
              width="18"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.messages}</span>
            {/* {chatCount ? (
              <span className="badge" style={{ top: "82px", right: "85px" }}>
                {chatCount}
              </span>
            ) : (
              ""
            )} */}
          </Link>
        </li>
        <li className={pathname.indexOf("groups") !== -1 ? "active" : ""}>
          <Link to="/groups" onClick={() => navigate("/groups")}>
            <img
              src={
                pathname.indexOf("groups") !== -1
                  ? images.groupActiveIco
                  : images.groupInActiveIco
              }
              alt="icon"
              height="18"
              width="18"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.groups}</span>
          </Link>
        </li>
        <li className={pathname.indexOf("sales") !== -1 ? "active" : ""}>
          <Link to="/sales">
            <img
              src={
                pathname.indexOf("sales") !== -1
                  ? images.saleActiveIco
                  : images.saleInActiveIco
              }
              alt="icon"
              width="13"
              height="18"
              className={`mr-4`}
            />
            <span className="navLinkCustom">{strings.sale}</span>
          </Link>
        </li>
        <li className={pathname.indexOf("business") !== -1 ? "active" : ""}>
          <Link to="/business">
            <img
              src={
                pathname.indexOf("business") !== -1
                  ? images.businessActiveIco
                  : images.businessInActiveIco
              }
              alt="icon"
              width="18"
              height="19"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.business}</span>
          </Link>
        </li>
        <li className={pathname.indexOf("shop") !== -1 ? "active" : ""}>
          <Link to="/shop">
            <img
              src={
                pathname.indexOf("shop") !== -1
                  ? images.shopInActiveIco
                  : images.shopActiveIco
              }
              alt="icon"
              width="18"
              height="19"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.shop}</span>
          </Link>
        </li>
        {/* <li className={pathname.indexOf("rewards") !== -1 ? "active" : ""}>
          <Link to="/rewards">
            <img
              src={
                pathname.indexOf("rewards") !== -1
                  ? images.rewardInActiveIco
                  : images.rewardActiveIco
              }
              alt="icon"
              width="18"
              height="19"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.my_rewards}</span>
          </Link>
        </li> */}

        {/* <li className={pathname.indexOf("my-cart") !== -1 ? "active" : ""}>
          <Link to="/my-cart">
            <img
              src={
                pathname.indexOf("my-cart") !== -1
                  ? images.cartActiveImg
                  : images.cartInactiveImg
              }
              alt="icon"
              width="18"
              height="19"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.my_cart}</span>
          </Link>
        </li> */}
        {/* <li className={pathname.indexOf("my-order") !== -1 ? "active" : ""}>
          <Link to="/my-order">
            <img
              src={
                pathname.indexOf("my-order") !== -1
                  ? images.rewardInActiveIco
                  : images.rewardActiveIco
              }
              alt="icon"
              width="18"
              height="19"
              className={`mr-3`}
            />
            <span className="navLinkCustom">{strings.my_order}</span>
          </Link>
        </li> */}
      </ul>
    </nav>
  );
}

export default Drawer;
