import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { COMMENT_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { useParams } from "react-router-dom";
import { GET_HOME_POST_DETAILS } from "./useHomePostDetailQuery";
import { GET_COMMENTS_QUERY_KEY } from "./useCommentsInfiniteQuery";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const commentPost = async (
  data: COMMENT_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.commentOnPost,
    method: "POST",
    data: data,
  });
};

function useCommentPostMutation() {
  const params: any = useParams();
  const { user } = useAuthValue();
  const rewardMutation = useAddRewardPoints();
  return useMutation(commentPost, {
    onSuccess: (responseData, requestData) => {
      rewardMutation?.mutate({
        type: "COMMENT",
        subId: user?.username,
        refId: String(requestData?.postId ?? ""),
        title: "COMMENT",
      });
      queryClient.refetchQueries(GET_COMMENTS_QUERY_KEY(params?.id));
      queryClient.refetchQueries(GET_HOME_POST_DETAILS(Number(params?.id)));
    },
  });
}

export default useCommentPostMutation;
