import React, { useMemo } from "react";
import HomeFeedItem from "src/Modules/HomeModule/Components/HomeFeedItem/HomeFeedItem";
import InfiniteScroll from "react-infinite-scroll-component";
import useHiddenPostsInfiniteQuery, {
  extractHiddenPosts,
} from "../../Hooks/useHiddenPostsInfiniteQuery";
import Loader from "src/Components/Shared/Loader/Loader";
import ReportPostModal from "src/Modules/HomeModule/Components/ReportPostModal/ReportPostModal";
import { strings } from "src/Utils/Localization";
import ConfirmDeletePostModal from "src/Modules/HomeModule/Components/ConfirmDeletePostModal/ConfirmDeletePostModal";
import PollModal from "src/Modules/PollModule/Components/PollModal/PollModal";
function MyHiddenPosts() {
  const hiddenPostsInfiniteQuery = useHiddenPostsInfiniteQuery();
  console.log("hiddenPostsInfiniteQuery", hiddenPostsInfiniteQuery);
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    hiddenPostsInfiniteQuery;
  const myHiddenPosts = useMemo(
    () => extractHiddenPosts(hiddenPostsInfiniteQuery?.data?.pages ?? []),
    [hiddenPostsInfiniteQuery?.data?.pages]
  );

  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <InfiniteScroll
        dataLength={myHiddenPosts.length}
        next={fetchData}
        hasMore={true}
        loader={isLoading && <Loader />}
        endMessage={
          <p className="text-center">
            <b>{strings.u_have_seen_all}</b>
          </p>
        }
        refreshFunction={refetch}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        pullDownToRefreshContent={
          <h6 className="text-center">
            &#8595; {strings.pull_down_to_refresh}
          </h6>
        }
        releaseToRefreshContent={
          <h6 className="text-center">&#8593;{strings.release_to_refresh}</h6>
        }
      >
        {(myHiddenPosts ?? []).map((item: any, index: number) => (
          <HomeFeedItem key={index} item={item} />
        ))}
      </InfiniteScroll>
      <ReportPostModal />
      <ConfirmDeletePostModal />
      <PollModal />
    </>
  );
}

export default MyHiddenPosts;
