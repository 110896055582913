import { toast } from "react-toastify";

class SnackbarHandler {
  errorToast = (text: string) => {
    toast(text, { position: "bottom-center", type: "error" });
  };

  successToast = (text: string) => {
    toast(text, { position: "bottom-center", type: "success" });
  };

  normalToast = (text: string) => {
    toast(text, { position: "top-right", type: "default" });
  };

  notificationToast = (notification: any, onClick: () => void) => {
    toast(
      <div className="customToast">
        <h5>{notification?.title ?? "Notification"}</h5>
        <h6>{notification?.body ?? "body"}</h6>
      </div>,
      {
        position: "top-right",
        type: "default",
        onClick,
        autoClose: 10000,
        hideProgressBar: true,
      }
    );
  };
}

export default new SnackbarHandler();
