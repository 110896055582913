import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import images from "src/Utils/images";
import "./ParticipantItem.css";

function ParticipantItem(props: any) {
  const { item } = props;
  const { user } = useAuthValue();
  const receiverId =
    user?.username === item?.senderId ? item?.receiverId : item?.senderId;
  const receiverName =
    user?.username === item?.senderId ? item?.receiverName : item?.senderName;
  const receiverPhoto =
    user?.username === item?.senderId ? item?.receiverImage : item?.senderImage;

  return (
    <>
      <li className="messageListData">
        <Link to={`/messages/${receiverId}`}>
          <div className="messageListDataRow">
            <div className="messageListDataLeft">
              <div className="messageLeftRow">
                <div
                  className={`profileColLeft ${
                    item?.online === 1 ? "Online" : "Offline"
                  }`}
                >
                  <img
                    src={receiverPhoto ? receiverPhoto : images?.profileCamera}
                    alt="img"
                  />
                </div>
                <div className="rightColProfileDes">
                  <h3 className="poppinsbold fz16">{receiverName}</h3>
                  <p className="mb-0 fz13 poppinsregular paraTimeClr">
                    {item?.message}
                  </p>
                </div>
              </div>
            </div>
            <div className="messageListDataColRight">
              <h6 className="timeClr">{moment(item?.createdAt).fromNow()}</h6>
            </div>
          </div>
        </Link>
      </li>
    </>
  );
}

export default ParticipantItem;
