import moment from "moment";
import { Fragment } from "react";

type RIGHT_BUBBLE_TYPE = {
  item: any;
};

function RightBubble(props: RIGHT_BUBBLE_TYPE) {
  const { item } = props;
  return (
    <Fragment>
      <li className="replies">
        <div className="time-message">
          <p>{item?.message}</p>
          <h6>{moment(new Date(item.createdAt)).format("hh:mm A")}</h6>
        </div>
      </li>
    </Fragment>
  );
}
export default RightBubble;
