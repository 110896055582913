import { Fragment } from "react";
import CopyRight from "src/Components/Custom/CopyRight/CopyRight";
import Footer from "src/Components/Custom/Footer/Footer";
import NavBar from "src/Components/Custom/NavBar/NavBar";
import UserNavBar from "src/Components/Custom/UserNavBar/UserNavBar";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import ContactUsEn from "./ContactUsEn";

function AuthContactUs() {
  const { loggedIn } = useAuthValue();
  return (
    <Fragment>
      {!loggedIn ? <NavBar /> : <UserNavBar />}
      <ContactUsEn />
      <Footer />
      <CopyRight />
    </Fragment>
  );
}
export default AuthContactUs;
