import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_POLL_REQUEST } from "../Types/RequestTypes";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";

const commentPost = async (
  data: CREATE_POLL_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createPoll,
    method: "POST",
    data: data,
  });
};

function useCreatePollMutation() {
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(commentPost, {
    onSuccess: (responseData) => {
      SnackbarHandler.successToast("Poll created successfully");
      rewardMutation?.mutate({
        type: "POST",
        subId: user?.username,
        refId: String(responseData?.data?.data?.id ?? ""),
        title: "Poll Post",
      });
    },
  });
}

export default useCreatePollMutation;
