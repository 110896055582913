import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";

export const GET_BUSINESS_CATEGORIES_LIST = ["getBusinessCategories"];

const getBusinessCatgories = async (): Promise<AxiosResponse<any>> => {
  return fetcher({
    baseURL: `${EndPoints.businessCategoris}`,
    method: "GET",
  });
};
function useBusinessCategoriesQuery() {
  return useQuery(GET_BUSINESS_CATEGORIES_LIST, getBusinessCatgories);
}

export default useBusinessCategoriesQuery;
