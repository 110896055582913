import Loader from "src/Components/Shared/Loader/Loader";
import useHomePostDetailQuery from "src/Modules/HomeModule/Hooks/useHomePostDetailQuery";
import Layout from "src/Modules/Layout/Layout";
import EditSafetyPost from "src/Modules/SafetyModule/Components/EditSafetyPost/EditSafetyPost";

function EditSafetyPage() {
  const GetHomePostDetailQuery = useHomePostDetailQuery();
  const getHomePostDetail = GetHomePostDetailQuery?.data?.data?.data;
  return (
    <>
      {GetHomePostDetailQuery?.isLoading && <Loader />}
      <Layout>
        {!GetHomePostDetailQuery?.isLoading && (
          <EditSafetyPost safetyPostDetails={getHomePostDetail} />
        )}
      </Layout>
    </>
  );
}

export default EditSafetyPage;
