import React from "react";
import "./MyOrder.css";
import images from "src/Utils/images";
import useOrderListingQuery from "../Hooks/useOrderListingQuery";

function MyOrder() {
  const query = `query {
    orders(first: 10, query: "updated_at:>2019-12-01") {
      edges {
        node {
          id
          updatedAt
        }
      }
    }
  }`;
  const { data } = useOrderListingQuery(query);
  console.log("Data////", data);
  return (
    <>
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 myOrder">
                <h4>My Orders</h4>
                <h6>December 2022</h6>
                <div className="myOrderBoxWrapper">
                  {/* ---------------CancelBox starts here------ */}
                  <div className="myOrderCancelBox">
                    <div className="myOrderImage">
                      <div className="orderImageContainer">
                        <img src={images.shopimage} alt="order" />
                      </div>
                    </div>
                    <div className="myOrderInfo">
                      <div className="infoParaItem">
                        <div className="orderMoney">₱ 302.00</div>
                        <button type="button" className="btnCancelOrder">
                          Cancel
                        </button>
                      </div>
                      <div className="infoParaItem">
                        <p className="paraTitle">
                          T-shirt for men hiphop panda trend American plus size
                          oversized casual.
                        </p>
                        <p className="paraStatus">Processing</p>
                      </div>
                      <div className="infoParaItem">
                        <p>Men’s Clothing</p>
                        <span>Arriving by Sat, 10 Dec 2022</span>
                      </div>
                    </div>
                  </div>
                  {/* ---------------CancelBox ends here------ */}
                  {/* ---------------ReOrderBox starts here------ */}
                  <div className="myReOrderBox">
                    <div className="myOrderImage">
                      <div className="orderImageContainer">
                        <img src={images.shopimage} alt="order" />
                      </div>
                    </div>
                    <div className="myOrderInfo">
                      <div className="infoParaItem">
                        <div className="orderMoney">₱ 302.00</div>
                        <button type="button" className="btnReOrder">
                          Re-order
                        </button>
                      </div>
                      <div className="infoParaItem">
                        <p className="paraTitle">
                          T-shirt for men hiphop panda trend American plus size
                          oversized casual.
                        </p>
                        <p className="paraStatus">Cancelled</p>
                      </div>
                      <div className="infoParaItem">
                        <p>Men’s Clothing</p>
                        <span>on Thu, 1 Dec 2022</span>
                      </div>
                    </div>
                  </div>
                  {/* ---------------ReOrderBox ends here------ */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-3"></div>
      </div>
    </>
  );
}

export default MyOrder;
