import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import useMyGroupListInfiniteQuery, {
  extractMyGroups,
} from "../../Hooks/useMyGroupListInfiniteQuery";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";

type MY_GROUP_LIST_PROPS = {
  searchText: string;
};

function MyGroupList(props: MY_GROUP_LIST_PROPS) {
  const { searchText } = props;
  const myGroupListInfiniteQuery = useMyGroupListInfiniteQuery(searchText);
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    myGroupListInfiniteQuery;
  const myGroupList = useMemo(
    () => extractMyGroups(myGroupListInfiniteQuery?.data?.pages ?? []),
    [myGroupListInfiniteQuery?.data?.pages]
  );

  const fetchData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <>
      <h3 className={`fz16 mb-3 poppinssemibold`}>{strings?.my_groups}</h3>

      <InfiniteScroll
        dataLength={myGroupList.length} //This is important field to render the next data
        next={fetchData}
        hasMore={true}
        loader={isLoading && <Loader />}
        endMessage={
          <p className="text-center">
            <b>{strings.u_have_seen_all}</b>
          </p>
        }
        // below props only if you need pull down functionality
        refreshFunction={refetch}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
      >
        {myGroupList?.length !== 0
          ? myGroupList.map((item: any, index: number) => (
              <Link key={index} to={"/groups/" + item.id}>
                <div className="cursor-pointer">
                  <div
                    className={`d-flex justify-content-between position-relative pl-5`}
                  >
                    <div
                      className={`message_user_icon message_user_icon_posLTR`}
                    >
                      <img
                        src={
                          item.group_icon ? item.group_icon : images.groupIco
                        }
                        className="rounded-circle object_fit"
                        width="40"
                        height="40"
                        alt=""
                      />
                    </div>
                    <h4 className={`fz15 mb-1 pl-3 mb-0 poppinssemibold`}>
                      {item.title} <br />
                      <span className="fz10 poppinsregular text-secondary">
                        {item.total_members} {strings.members}
                      </span>
                    </h4>
                  </div>
                  <hr />
                </div>
              </Link>
            ))
          : !isLoading && <p>{strings.no_gp_found}</p>}
      </InfiniteScroll>
    </>
  );
}

export default MyGroupList;
