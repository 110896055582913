import "./PollItem.css";
import { openConfirmPollVoteModal } from "../PollModal/PollModal";

function PollItem(props: any) {
  const { item } = props;

  return (
    <>
      <article className="poll_item mt-4 post_content_wrap">
        <h3 className="mb-4 fz16">{item?.title}</h3>
        <ul className="poll_item_Details">
          {(item?.options ?? []).map((_item: any, index: number) => {
            return (
              <li
                className={
                  item?.vote?.optionName === _item?.name
                    ? "poll_item_Active"
                    : "poll_item"
                }
                key={`item${index}`}
                onClick={() =>
                  openConfirmPollVoteModal({
                    optionName: _item?.name,
                    postId: item?.id,
                  })
                }
              >
                <span>{_item?.name}</span>
                <span className="progressPercentage">
                  {_item?.votePercent > 0 ? parseInt(_item?.votePercent) : 0}%
                </span>
              </li>
            );
          })}
        </ul>
      </article>
    </>
  );
}

export default PollItem;
