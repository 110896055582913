import InfiniteScroll from "react-infinite-scroll-component";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useAuth from "src/Hooks/Custom/useAuth";
import { Fragment, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useEventEmitter, { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useContactListInfiniteQuery, {
  extractContactList,
} from "../../Hooks/useContactListInfiniteQuery";
import images from "src/Utils/images";
import "./ContactsModal.css";

const EVENT_CONTACTS_MODAL = "eventsContactModal";

export const openContactModal = () => {
  emitEvent(EVENT_CONTACTS_MODAL);
};

function ContactsModal() {
  const { loading } = useAuth();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const contactsListInfiniteQuery = useContactListInfiniteQuery(search);
  const { refetch, hasNextPage, fetchNextPage } = contactsListInfiniteQuery;
  const contactList = useMemo(
    () => extractContactList(contactsListInfiniteQuery?.data?.pages ?? []),
    [contactsListInfiniteQuery?.data?.pages]
  );
  useEventEmitter(EVENT_CONTACTS_MODAL, () => {
    setShowModal(true);
  });

  return (
    <>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="contact_modal"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-4 custom_modal">
              <div id="scrollableContacts">
                <InfiniteScroll
                  dataLength={0} //This is important field to render the next data
                  next={() => {
                    if (hasNextPage) {
                      fetchNextPage();
                    }
                  }}
                  hasMore={true}
                  loader={
                    <VisibilityToggle visible={loading}>
                      <span className="spinner-border spinner-border-lg"></span>
                    </VisibilityToggle>
                  }
                  endMessage={
                    <p className="text-center">
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  refreshFunction={refetch}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  scrollableTarget="scrollableContacts"
                >
                  <div className="d-flex justify-content-between mb-2 align-items-center px-3">
                    <h3 className="fz16 mb-0 mr-3">
                      <i
                        className={`fa  cursor-pointer mr-2 fa-arrow-left`}
                        aria-hidden="true"
                        onClick={() => setShowModal(false)}
                      ></i>
                      Message
                    </h3>
                    <div className="d-flex align-items-center w-70 position-relative">
                      <input
                        type="text"
                        className="form-control fz12 poppinsregular custom_input border-0"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                      <div className="custom-searchIcon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </div>
                  <div className="furniture_section">
                    <div className="container-fluid">
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="col_selector">
                            {contactList.map((user: any, index: number) => {
                              return (
                                <Fragment key={`contact-${index}`}>
                                  <Link to={`/messages/${user?.subId}`}>
                                    <div
                                      className={`d-flex justify-content-between position-relative pl-5 cursor-pointer`}
                                    >
                                      <div
                                        className={`message_user_icon message_user_icon_posLTR`}
                                      >
                                        <img
                                          src={
                                            user.picture && user.picture !== ""
                                              ? user.picture
                                              : images.profileCamera
                                          }
                                          className="rounded-circle"
                                          width="39"
                                          height="40"
                                          alt=""
                                        />
                                      </div>
                                      <div style={{ paddingTop: "12px" }}>
                                        <h4 className="fz14 mb-1 pl-3">
                                          {user.name + " " + user.family_name}
                                        </h4>
                                      </div>
                                    </div>
                                  </Link>
                                  <hr />
                                </Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          // onClick={() => setModalVisibility(false)}
        ></div>
      </VisibilityToggle>
    </>
  );
}
export default ContactsModal;
