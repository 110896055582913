import React, { Fragment, useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { useForm } from "react-hook-form";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { emitEvent } from "src/Hooks/Shared/useEventEmmiter";
import useEventEmitter from "../../../../Hooks/Shared/useEventEmmiter";
import { getRequiredRules } from "../../../../Utils/Validators";
import useCreateGroupMutation from "../../Hooks/useCreateGroupMutation";
import { useParams } from "react-router-dom";
import useUpdateGroupMutation from "../../Hooks/useUpdateGroupMutation";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const ADD_GROUP_EVENT = "addgroup";
export const addGroupEvent = (data?: any) => {
  emitEvent(ADD_GROUP_EVENT, data);
};
function GroupAddModal() {
  const params = useParams();
  const groupId = params?.id;
  const { user } = useAuthValue();
  const [groupIcon, setGroupIcon] = useState<any>("");
  const [iconUrl, setIconUrl] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      group_icon: [],
      is_private: "open",
      groupProfile: undefined,
    },
    mode: "onChange",
  });

  const createGroupMutation = useCreateGroupMutation();
  const updateGroupMutation = useUpdateGroupMutation();
  const handleAddGroup = (value: any) => {
    !groupId
      ? createGroupMutation.mutate(
          {
            description: value?.description,
            group_icon: groupIcon,
            is_private: value?.is_private === "open" ? false : true,
            subId: user?.username,
            title: value?.title,
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setIconUrl(undefined);
              setGroupIcon(undefined);
              SnackbarHandler.successToast(strings.gp_added_successfully);
            },
          }
        )
      : updateGroupMutation.mutate(
          {
            description: value?.description,
            group_icon: groupIcon,
            is_private: value?.is_private === "open" ? false : true,
            subId: user?.username,
            title: value?.title,
            groupId: Number(params?.id),
          },
          {
            onSuccess: () => {
              setShowModal(false);
              reset();
              setIconUrl(undefined);
              setGroupIcon(undefined);
            },
          }
        );
  };

  useEventEmitter(ADD_GROUP_EVENT, (data) => {
    setShowModal(true);
    reset({
      title: data?.title,
      description: data?.description,
      is_private: data?.is_private === false ? "open" : "private",
    });
    setIconUrl(data?.group_icon);
  });
  const fileSelectedHandler = async (imgs: any) => {
    const icon = imgs ? imgs[0] : null;

    if (icon) {
      const maxSize = 2;
      if (icon?.size / 1024 / 1024 > maxSize) {
        return SnackbarHandler.errorToast(
          "Please add image up to 2 MB in size."
        );
      }
      try {
        const imgBase64 = await convertImagesToBase64(icon);
        setIconUrl(URL.createObjectURL(icon));
        setGroupIcon(imgBase64);
      } catch (err) {
        console.log("URL VERIFY", err);
      }
    }
  };

  const convertImagesToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = function () {
        resolve(fileReader.result);
      };
      fileReader.onerror = function (error) {
        console.log("Error: ", error);
        reject(error);
      };
    });
  };

  return (
    <Fragment>
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="add_post"
        style={{ display: showModal ? "block" : "none" }}
        role={"dialog"}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg create-group-modal">
          <div className="modal-content border-0">
            <div className="modal-body p-3 p-md-5 custom_modal">
              <div className="">
                <form onSubmit={handleSubmit(handleAddGroup)}>
                  <div className={`form-group`}>
                    <h3 className="mb-0 poppinssemibold">
                      {groupId ? strings.edit_group : strings.create_group}
                    </h3>
                    <button
                      type="button"
                      className="modal_close_icon"
                      onClick={() => setShowModal(false)}
                    >
                      {strings.cross_sign}
                    </button>
                  </div>
                  <div className={`form-group`}>
                    <div className={`form-group`}>
                      <label>{strings.group_title}</label>
                      <input
                        className="form-control"
                        placeholder={strings.your_group_title}
                        {...register(
                          "title",
                          getRequiredRules(
                            strings.title,
                            strings.title_is_required
                          )
                        )}
                      />
                      {errors.title && (
                        <span className="ml-3 text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </div>

                    <p className="chooseName">{strings.gp_title_para}</p>
                    <h5 className="poppinsbold">{strings.gp_title_ex}</h5>
                    <div className={`form-group`}>
                      <label>{strings.description}</label>
                      <textarea
                        className="form-control"
                        placeholder={strings.description}
                        {...register(
                          "description",
                          getRequiredRules(
                            strings.description,
                            strings.description_is_required
                          )
                        )}
                        maxLength={150}
                      ></textarea>
                      {errors.description && (
                        <span className="ml-3 text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="photo-wrapper">
                    <div className="right-col">
                      <h4 className="poppinsbold">{strings.add_photo}</h4>
                      <p>{strings.jpg_format}</p>
                    </div>
                    <div
                      className="upload-photo"
                      style={{ position: "relative", textAlign: "center" }}
                    >
                      <label htmlFor="AddGroupPhoto">
                        <i className="fa fa-camera" aria-hidden="true"></i>
                        <span>
                          {" "}
                          {groupId ? strings.replace : strings.add_photo}
                        </span>

                        <input
                          id="AddGroupPhoto"
                          className="d-none"
                          type="file"
                          accept="image/*"
                          onChange={(e: any) => {
                            console.log("Files", e.target.files);
                            fileSelectedHandler(e.target.files);
                          }}
                        />
                      </label>
                    </div>
                  </div>

                  {iconUrl && (
                    <div className="photo-main-wrap">
                      <div className="wrap-col">
                        <div className="img-wrap">
                          <img src={iconUrl} alt="groupIcon" />
                        </div>
                        {/* <div className="photo-name">
                          <h5>{groupProfile?.name}</h5>
                          <p>
                            {(groupProfile?.size / 1024 / 1024).toFixed(2)}MB
                          </p>
                        </div> */}
                      </div>
                      <button
                        type="button"
                        className="delete-btn"
                        onClick={() => {
                          setGroupIcon(null);
                          setIconUrl(null);
                        }}
                      >
                        <i
                          className="fa fa-trash fz18"
                          title="delete"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  )}

                  <div className="">
                    <div className="radio-wrapper">
                      <div className="radio-col customRadioBtn">
                        <input
                          id="is_open"
                          type="radio"
                          {...register("is_private", {})}
                          value="open"
                        />
                        <label htmlFor="is_open" className="mb-0 ml-2">
                          {strings.open}
                        </label>
                      </div>
                      <div className="radio-col customRadioBtn">
                        <input
                          id="is_private"
                          type="radio"
                          {...register("is_private", {})}
                          value="private"
                        />
                        <label htmlFor="is_private" className="mb-0 ml-2">
                          {strings.private}
                        </label>
                      </div>
                    </div>
                    <div className="d-flex mt-4">
                      <button
                        type="submit"
                        disabled={
                          createGroupMutation?.isLoading ||
                          updateGroupMutation?.isLoading
                        }
                        className="btn custom_btn submit_btn d-flex justify-content-between "
                      >
                        <VisibilityToggle
                          visible={
                            createGroupMutation.isLoading ||
                            updateGroupMutation?.isLoading
                          }
                        >
                          <span className="spinner-border spinner-border-sm"></span>
                        </VisibilityToggle>
                        {groupId ? strings.update : strings.create}

                        <span>
                          <img src={images.rightArrow} alt="icon" />
                        </span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <VisibilityToggle visible={showModal ? true : false}>
        <div
          className={`modal-backdrop fade ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
      </VisibilityToggle>
    </Fragment>
  );
}

export default GroupAddModal;
