import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_HOME_POST_DETAILS = (postId: number) => [
  "homePostDetails",
  postId,
];

const homePostDetails = async (postId: number): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.postDetail}?subId=${user?.username}&postId=${postId}`,
    method: "GET",
  });
};
function useHomePostDetailQuery() {
  const params = useParams();
  console.log(params, "paramsparams");
  return useQuery(GET_HOME_POST_DETAILS(Number(params?.id)), () =>
    homePostDetails(Number(params?.id))
  );
}

export default useHomePostDetailQuery;
