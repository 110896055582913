import BusinessList from "src/Modules/BusinessModule/Components/BusinessList/BusinessList";
import BusinessPostModal from "src/Modules/BusinessModule/Components/BusinessPostModal/BusinessPostModal";
import Layout from "src/Modules/Layout/Layout";

function Business() {
  return (
    <Layout>
      <div id="content" className="ltr-content-padding">
        <div className="container-fluid">
          <div className="row ">
            <div className="sale_Details-CenterCol">
              <div className="col_selector">
                <div className="messages pt-4 post_feed">
                  <BusinessList />
                </div>
              </div>
            </div>
          </div>
        </div>

        <BusinessPostModal />
      </div>
    </Layout>
  );
}

export default Business;
