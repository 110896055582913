import useAuthValue from "src/Hooks/Custom/useAuthValue";
import Layout from "src/Modules/Layout/Layout";
import MyProfile from "src/Modules/MyProfileModule/Components/MyProfile/MyProfile";
function Profile() {
  const { toggleMenu } = useAuthValue();
  return (
    <Layout>
      <div className={toggleMenu ? "toggle_class" : ""}>
        {/* <div className="wrapper"> */}
        {/* <div className="container"> */}
        <MyProfile />
        {/* </div> */}
        {/* </div> */}
      </div>
    </Layout>
  );
}
export default Profile;
