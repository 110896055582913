import moment from "moment";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import useBusinessPostDetailsQuery from "../../Hooks/useBusinessPostDetailsQuery";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import BusinessPostModal, {
  openBusinessPostModal,
} from "../BusinessPostModal/BusinessPostModal";
import BusinessPostDeleteModal, {
  openDeleteBusinessPostModal,
} from "../BusinessPostDeleteModal/BusinessPostDeleteModal";
import BusinessPostReportModal, {
  reportBusinessPostModal,
} from "../BusinessPostReportModal/BusinessPostReportModal";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";
import Loader from "src/Components/Shared/Loader/Loader";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function BusinessPostDetail() {
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //   const { data, isLoading } = useSaleDetailQuery();
  //   const saleDetails = data?.data ?? {};
  const businessPostDetailsQuery = useBusinessPostDetailsQuery();
  console.group("////////businessPostDetailsQuery", businessPostDetailsQuery);
  const businessPostDetail = businessPostDetailsQuery?.data?.data;
  console.log("+++++++++++businessPostDetail", businessPostDetail);
  const { user } = useAuthValue();

  return (
    <>
      {businessPostDetailsQuery?.isLoading && <Loader />}
      <div className="d-flex justify-content-between px-0">
        <Link
          className="d-flex mb-4 align-items-center arrow_row"
          to="/business"
        >
          <img src={images.leftBackArrow} alt="icon" height="18" />
          <h3 className="fz20 mb-0 ml-3 poppinssemibold">{strings.detail}</h3>
        </Link>
        <div className={`slider_down_arrow position-relative pl-4`}>
          <i className="fa fa-angle-down fz26" aria-hidden="true"></i>
          <div className={`user_actions user_actions_posLTR`}>
            <ul className={`list-unstyled "text-left mb-0 poppinsbold`}>
              <VisibilityToggle
                visible={user.username === businessPostDetail?.data?.subId}
              >
                <li>
                  <Link
                    to={"/"}
                    className="py-4"
                    onClick={(e) => {
                      e.preventDefault();
                      openBusinessPostModal(businessPostDetail?.data);
                    }}
                  >
                    <i
                      className="fa fa-pencil mr-4 fz18"
                      aria-hidden="true"
                    ></i>
                    {strings.edit}
                  </Link>
                </li>
              </VisibilityToggle>
              <VisibilityToggle
                visible={user.username === businessPostDetail?.data?.subId}
              >
                <li>
                  <a
                    className="py-4"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      openDeleteBusinessPostModal();
                    }}
                  >
                    <i className="fa fa-trash mr-4 fz18" aria-hidden="true"></i>
                    {strings.delete}
                  </a>
                </li>
              </VisibilityToggle>
              <VisibilityToggle
                visible={user.username !== businessPostDetail?.data?.subId}
              >
                <li>
                  <a
                    className="py-4"
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      reportBusinessPostModal();
                    }}
                  >
                    {strings.report}
                  </a>
                </li>
              </VisibilityToggle>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="px-3 pt-2"> */}
      <div className="ItemDetailsRow position-relative">
        <figure className="itemDetailcolLeft">
          <Slider {...detilaSlider}>
            {(businessPostDetail?.data?.images ?? []).map(
              (item: any, index: number) => {
                return (
                  <div
                    className="detilaSlider_Item business-item"
                    key={`item${index}`}
                  >
                    <img
                      src={item?.url}
                      className="img-fluid w-100 rounded"
                      alt=""
                    />
                  </div>
                );
              }
            )}
          </Slider>
        </figure>

        <div className="rightColDetails">
          <div>
            <div className="mb-3 d-flex align-self-center">
              <h3 className="fz16 poppinssemibold mb-0">
                {businessPostDetail?.data?.title}
                {/* <span className="fz10 poppinsregular">
                  Posted:{""}
                  {businessPostDetail?.data?.timestamp
                    ? moment(
                        new Date(businessPostDetail?.data?.timestamp)
                      ).fromNow()
                    : ""}
                </span> */}
              </h3>
            </div>

            <ul className="item_Details pl-0 mb-0">
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.pinLocationIco} alt="location" />
                </figure>
                <p className="fz13 poppinssemibold">
                  {businessPostDetail?.data?.location}
                </p>
              </li>
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.emailIco} alt="email" />
                </figure>
                <p className="fz13 poppinssemibold">
                  <a
                    className="fz13 poppinssemibold"
                    href={"mailto:" + businessPostDetail?.data?.email}
                  >
                    {businessPostDetail?.data?.email}
                  </a>
                </p>
              </li>
              <li>
                <figure className="iconLeftDetails">
                  <img src={images.callIco} alt="phone" />
                </figure>
                <p className="fz13 poppinssemibold">
                  {businessPostDetail?.data?.phone_number}
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="descriptionRow mt-xl-4">
        <h3 className="fz14">{strings.description}</h3>
        <p className="pl-0 fz14 poppinsregular mb-0">
          {businessPostDetail?.data?.message}
        </p>
      </div>

      {/* </div> */}
      <BusinessPostModal />
      <BusinessPostDeleteModal />
      <BusinessPostReportModal />
    </>
  );
}
export default BusinessPostDetail;
