import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_HOME_FEED_QUERY_KEY } from "./useHomeFeedInfiniteQuery";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

const createPost = async (
  data: CREATE_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createPost,
    method: "POST",
    data: data,
  });
};

function useCreatePostMutation() {
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(createPost, {
    onSuccess: (responseData) => {
      console.log(responseData, "responseData/////////////////");
      queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
      // rewardMutation?.mutate({
      //   type: "POST",
      //   subId: user?.username,
      //   refId: String(responseData ?? ""),
      //   title: "POST",
      // });
    },
  });
}

export default useCreatePostMutation;
