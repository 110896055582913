import GroupAddModal from "../GroupAddModal/GroupAddModal";
import GroupProfile from "../GroupProfile/GroupProfile";
import GroupMembers from "../GroupMembers/GroupMembers";
import GroupPostList from "../GroupPostList/GroupPostList";
import useGroupDetailsQuery from "../../Hooks/useGroupDetailsQuery";
import GroupPostModal from "../GroupPostModal/GroupPostModal";

function GroupDetails() {
  const groupDetailsQuery = useGroupDetailsQuery();
  const groupDetailsData = groupDetailsQuery?.data?.data;
  console.log("**********groupDetailsQuery************", groupDetailsData);
  return (
    <>
      <div className="col-md-12 space_right">
        <div className="member_pages_section bg-transparent shadow-none">
          <div className="row">
            <div className={"col-lg-9"}>
              <div className="col_selector">
                <GroupProfile groupDetailsData={groupDetailsData} />

                {(groupDetailsData?.is_member ||
                  groupDetailsData?.is_admin) && (
                  <GroupPostList placeholderMessage={""} />
                )}
              </div>
            </div>

            <div className="col-lg-3 space_right">
              {(groupDetailsData?.is_member || groupDetailsData?.is_admin) && (
                <GroupMembers groupDetailsData={groupDetailsData} />
              )}
            </div>
          </div>

          <GroupAddModal />
          <GroupPostModal />
        </div>
      </div>
    </>
  );
}
export default GroupDetails;
