import React from "react";
import Layout from "src/Modules/Layout/Layout";
import NotificationList from "src/Modules/NotificationModule/Components/NotificationList/NotificationList";

function NotificationPage() {
  return (
    <Layout>
      <NotificationList />
    </Layout>
  );
}

export default NotificationPage;
