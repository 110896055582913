import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_EVENT_REQUEST_TYPE } from "../Types/RequestTypes";

const createEvent = async (
  data: CREATE_EVENT_REQUEST_TYPE
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.CreateEvent,
    method: "POST",
    data: data,
  });
};

function useCreateEventMutation() {
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(createEvent, {
    onSuccess: (responseData) => {
      rewardMutation?.mutate({
        type: "POST",
        subId: user?.username,
        refId: String(responseData?.data?.id ?? ""),
        title: "Events Post",
      });
    },
  });
}

export default useCreateEventMutation;
