import { Link } from "react-router-dom";
import useJoinGroupMutation from "../../Hooks/useJoinGroupMutation";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function GroupItem(props: any) {
  const { user } = useAuthValue();
  const { item } = props;
  const { id, group_icon, title, total_members, is_private, is_requested } =
    item;

  const joinGroupMutation = useJoinGroupMutation();
  const handleJoinGroup = (e: any) => {
    e.preventDefault();
    return joinGroupMutation.mutate({
      subId: user?.username,
      groupId: id,
    });
  };

  const requestedBtn = {
    backgroundColor: "#fff",
    color: "#E63F8F",
    borderRadius: "30px",
    border: "1px solid #E63F8F",
  };

  return (
    <>
      <Link to={"/groups/" + item.id}>
        <div className="cursor-pointer">
          <div
            className={`d-flex justify-content-between position-relative pl-5`}
          >
            <div className="colLeftGroupList">
              <div className={`message_user_icon message_user_icon_posLTR`}>
                <img
                  src={group_icon ? group_icon : images.groupIco}
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
              <h4
                className={`fz16 mb-1 pl-3 mb-0 poppinssemibold join_Text-Left`}
                // onClick={detailViewHandler}
              >
                {title} <br />
                <span className="fz10 poppinsregular text-secondary">
                  {total_members} {strings.members}
                </span>
              </h4>
            </div>
            <div className="rightCol_Join">
              {is_requested && is_private ? (
                <button
                  className="btn fz14 poppinsbold custom_join_requestBtn"
                  style={requestedBtn}
                  // onClick={detailViewHandler}
                >
                  {strings.requested}
                </button>
              ) : (
                <></>
              )}
              {is_private && !is_requested ? (
                <button
                  className="btn fz14 poppinsbold custom_join_requestBtn"
                  onClick={handleJoinGroup}
                  disabled={joinGroupMutation?.isLoading}
                >
                  <VisibilityToggle visible={joinGroupMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.join}
                </button>
              ) : (
                <></>
              )}
              {!is_private && !is_requested ? (
                <button
                  className={"btn fz14 poppinsbold custom_join_requestBtn"}
                  onClick={handleJoinGroup}
                  disabled={joinGroupMutation?.isLoading}
                >
                  <VisibilityToggle visible={joinGroupMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.join}
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
          <hr />
        </div>
      </Link>
    </>
  );
}

export default GroupItem;
