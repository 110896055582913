import React, { useState } from "react";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { useLocation, useParams } from "react-router-dom";
import useHidePostMutation from "src/Modules/HomeModule/Hooks/useHidePostMutation";
import { reportPost } from "src/Components/Custom/ReportModal/ReportModal";
import { openDeleteGlobalPostModal } from "src/Modules/HomeModule/Components/ConfirmDeletePostModal/ConfirmDeletePostModal";
import { openCreatePostModal } from "../GroupPostModal/GroupPostModal";
import { GET_GROUP_POST_LIST_KEY } from "../../Hooks/useGroupPostListInfiniteQuery";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import { strings } from "src/Utils/Localization";

function GroupPostListOptions(props: any) {
  const { item } = props;
  const { user } = useAuthValue();
  const { pathname } = useLocation();
  const params = useParams();

  const hidePostApiMutation = useHidePostMutation();
  const handleHidePostApi = (e: any) => {
    e.preventDefault();
    hidePostApiMutation.mutate(
      {
        subId: user?.username,
        postId: item?.id,
        groupId: item?.groupId,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            queryClient.refetchQueries(
              GET_GROUP_POST_LIST_KEY(Number(params?.id))
            );
          }
        },
      }
    );
  };

  return (
    <>
      <div className={`slider_down_arrow position-relative pl-4`}>
        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        <div className={`user_actions user_actions_posLTR`}>
          <ul className={`list-unstyled  text-left mb-0 poppinsbold`}>
            <VisibilityToggle visible={user.username === item?.subId}>
              <li>
                <a
                  className="py-4 d-flex align-items-center"
                  href="/"
                  onClick={(e: any) => {
                    e.preventDefault();
                    openDeleteGlobalPostModal(item);
                  }}
                >
                  {strings.delete}
                </a>
              </li>
            </VisibilityToggle>
            <VisibilityToggle visible={user.username !== item?.subId}>
              <li>
                <a
                  className="py-4 d-flex align-items-center"
                  href="/"
                  onClick={handleHidePostApi}
                >
                  <VisibilityToggle visible={hidePostApiMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                    &nbsp;
                  </VisibilityToggle>
                  {strings.hide}
                </a>
              </li>
            </VisibilityToggle>
            <VisibilityToggle visible={user.username === item?.subId}>
              <li>
                <a
                  className="py-4 d-flex align-items-center"
                  href="/"
                  onClick={(e: any) => {
                    e.preventDefault();
                    openCreatePostModal(item);
                  }}
                >
                  {strings.edit}
                </a>
              </li>
            </VisibilityToggle>
            <VisibilityToggle visible={user.username !== item?.subId}>
              <li>
                <a
                  className="py-4 d-flex align-items-center"
                  href="/"
                  onClick={(e) => {
                    e?.preventDefault();
                    reportPost({ ...item, type: "group" });
                  }}
                >
                  {strings.report}
                </a>
              </li>
            </VisibilityToggle>
          </ul>
        </div>
      </div>
    </>
  );
}

export default GroupPostListOptions;
