import { useQuery } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";
import { fetcher } from "src/Utils/Helpers";

export const GET_REWARDS_HISTORY = ["getRewardsHistory"];

const getRewardsHistory = async () => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.getRewardHistory}`,
    method: "POST",
    data: {
      subId: user?.username,
    },
  });
};

function useRewardsHistoryQuery() {
  return useQuery(GET_REWARDS_HISTORY, getRewardsHistory);
}
export default useRewardsHistoryQuery;
