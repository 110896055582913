import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useDeviceTokenMutation from "src/Modules/Layout/Hooks/useDeviceTokenMutation";
import { getFirebaseToken, onForegroundMessage } from "src/Utils/firebase";
import { getUniqueId } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useAuthValue from "./useAuthValue";

function useNotification() {
  const deviceTokenMutation = useDeviceTokenMutation();
  const deviceTokenRef = useRef(deviceTokenMutation);
  const { user, loggedIn } = useAuthValue();
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      (async () => {
        const token = await getFirebaseToken();
        if (deviceTokenRef.current) {
          deviceTokenRef.current.mutate({
            subId: user?.username,
            device_id: getUniqueId(),
            device_platform: "Web",
            device_os: window?.navigator?.platform ?? "",
            fcm_token: token,
          });
        }
      })();
    }
  }, [loggedIn, user?.username]);

  useEffect(() => {
    (() => {
      onForegroundMessage().then((message: any) => {
        console.log("Notifcation/////////", message);
        SnackbarHandler.notificationToast(
          {
            title: message?.notification?.title,
            body: message?.notification?.body,
          },
          () => {
            const notification_post_types = [
              "POST_COMMENT",
              "POST_LIKE",
              "POST_COMMENT_LIKE",
            ];
            const group_post_types = [
              "ADDED_IN_GROUP",
              "REQUESTED_JOIN",
              "JOINED_GROUP",
              "MADE_ADMIN",
              "REQUEST_REJECTED",
            ];
            const isPostNotification = notification_post_types.includes(
              message?.data?.type
            );
            const isGroupNotification = group_post_types.includes(
              message?.data?.type
            );
            const notificationPostId = JSON.parse(message?.data?.payload);
            if (isPostNotification) {
              if (notificationPostId?.groupId !== undefined) {
                return navigate(
                  `/groups/${notificationPostId?.groupId}/${notificationPostId?.id}`
                );
              } else {
                return navigate(`/home/${notificationPostId?.id}`);
              }
            }
            if (isGroupNotification) {
              return navigate(`/groups/${notificationPostId?.groupId}`);
            }
          }
        );
      });
    })();
  }, [navigate]);
}
export default useNotification;
