import React, { Fragment } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import "./CreatePoll.css";
import { getRequiredRules } from "src/Utils/Validators";
import useCreatePollMutation from "../../Hooks/useCreatePollMutation";
import { queryClient } from "../../../../Utils/ReactQueryConfig";
import { GET_HOME_FEED_QUERY_KEY } from "src/Modules/HomeModule/Hooks/useHomeFeedInfiniteQuery";
import { useNavigate } from "react-router-dom";
import Loader from "src/Components/Shared/Loader/Loader";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function CreatePoll() {
  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    control,
  } = useForm<any>({
    defaultValues: {
      options: [{ name: "" }, { name: "" }],
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray<any>({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "options", // unique name for your Field Array
  });

  const createPollMutation = useCreatePollMutation();
  const { user } = useAuthValue();
  const navigate = useNavigate();

  const createPoll = handleSubmit((values) => {
    console.log("Values", values);
    const data = values?.options?.map((item: any) => item?.name);
    const filterData = new Set(data);
    if (data?.length !== filterData?.size) {
      return SnackbarHandler.errorToast("Please Add Unique Options");
    }
    createPollMutation?.mutate(
      {
        subId: user?.username,
        title: values.title,
        options: values?.options?.map((item: any) => item?.name),
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            reset();
            queryClient.refetchQueries(GET_HOME_FEED_QUERY_KEY(""));
            navigate("/home");
          }
        },
      }
    );
  });

  return (
    <>
      {createPollMutation?.isLoading && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <button
                type="button"
                className="border-0 mb-3 text-captilize left_Arrow"
                onClick={() => navigate("/home")}
              >
                <img src={images.leftBackArrow} alt="arrow" />
                <span className="fz16 mb-3">{strings?.create} {strings?.poll}</span>
              </button>
              {/* create_post_Wrapper-start */}
              <div className="create_post_Wrapper">
                {/* add-categories */}
                <h3 className="fz16 text-captilize poppinsbold mb-3">
                  {strings.categories}
                </h3>
                <div className="categories_add_row">
                  <figure className="mb-0 leftAddPhotoBox">
                    <img src={images.leftAddPhoto} alt="icon" />
                  </figure>
                  <div className="right_addTextCol">
                    <p className="mb-0 poppinsmedium">{strings.poll}</p>
                  </div>
                </div>
                {/* add-categories-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}
                <form onSubmit={createPoll}>
                  {/* =====pollDetails-start=== */}
                  <div className="pollDetails">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.poll_detail}
                    </h3>
                    <input
                      type="text"
                      placeholder="Enter Poll title"
                      className="customInputDetails"
                      {...register("title", getRequiredRules("Title"))}
                    />
                    {errors.title?.message && (
                      <div className="ml-3 text-danger">
                        {errors.title.message}
                      </div>
                    )}
                  </div>
                  {/* ====pollDetails-close==== */}

                  {/*borderLine-start  */}
                  <div className="borderLine"></div>
                  {/*borderLine-close  */}

                  {/* option-section-start */}
                  <div className="pollDetails">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.options}
                    </h3>
                    {fields?.map((item, index: number) => {
                      return (
                        <Fragment key={`item${index}`}>
                          <div className="form-group">
                            <input
                              type="text"
                              placeholder={`option ${index + 1}`}
                              className="customInputDetails"
                              key={`item${index}`}
                              {...register(
                                `options.${index}.name`,
                                getRequiredRules(`Option ${index + 1}`)
                              )}
                            />
                            {errors.options?.[index]?.name?.message && (
                              <div className="text-danger">
                                {errors.options?.[index]?.name?.message}
                              </div>
                            )}
                            {index > 1 && (
                              <button
                                className="optiondeleteBtn"
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <img
                                  src={images.delIconOption}
                                  alt="deleteicon"
                                />
                              </button>
                            )}
                          </div>
                        </Fragment>
                      );
                    })}

                    <button
                      type="button"
                      className="addOption"
                      onClick={() => append({ name: "" })}
                    >
                      {strings.add_option}
                    </button>
                  </div>
                  {/* option-section-close */}

                  {/* tipsBox-start==== */}
                  <div className="tipsBox mt-4 position-relative">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.tips_getting_good_answers}
                    </h3>
                    <ul className="pl-0">
                      <li>{strings.pricillas_a_perfect_nanny}</li>
                      <li>{strings.keep_your_question_short}</li>
                      <li>{strings.double_check_grammar_and_spelling}</li>
                    </ul>
                    <img src={images.bulbImg} alt="bulb" />
                  </div>

                  <button
                    type="submit"
                    className="posttbn mt-4"
                    disabled={createPollMutation?.isLoading}
                  >
                    {strings.post} <img src={images.rightArrow} alt="icons" />
                  </button>
                </form>
              </div>
              {/* create_post_Wrapper-close */}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePoll;
