import React, { useState } from "react";
import { Link, useHref, useNavigate, useParams } from "react-router-dom";
import images from "src/Utils/images";
import Slider from "react-slick";
import "./ShopifyDetails.css";
import useShopifyProductDetailQuery from "src/Modules/ShopsModule/Hooks/useShopifyProductDetailQuery";
import useAddToCartMutation from "src/Modules/ShopsModule/Hooks/useAddToCartMutation";
import useCheckOutMutation from "src/Modules/ShopsModule/Hooks/useCheckOutMutation";
import useAddToCartValue, {
  getCartValue,
  setCartValue,
} from "src/Modules/ShopsModule/Hooks/useAddToCartValue";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function ShopifyDetails() {
  const params = useParams();
  const navigate = useNavigate();
  const [quantity, setQuantity] = useState<number>(1);
  const { user } = useAuthValue();
  const myCartValues = useAddToCartValue();
  let query = `{product(id: "gid://shopify/Product/${
    params?.id ?? ""
  }") {id, title, description, descriptionHtml, createdAt, productType, options {name, id, values}, images(first: 10) {
    edges {
      node {
        altText
        src
      }
    }
  }, variants(first: 100) {nodes {availableForSale, price, compareAtPrice, id, title, image {src}, selectedOptions {
    name,
    value
  } } }}}`;

  const { data } = useShopifyProductDetailQuery(query);
  const productDetails = data?.product;
  const productImages = data?.product?.images?.edges ?? [];

  const addToCartMutaion = useAddToCartMutation();
  const checkoutMutation = useCheckOutMutation();
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const addToCart = () => {
    const myCart = [...getCartValue()];
    myCart.push({
      quantity: quantity,
      variantId: productDetails?.variants?.nodes[0]?.id,
      title: productDetails?.title,
      price: productDetails?.variants?.nodes[0]?.price,
      comparePrice: productDetails?.variants?.nodes[0]?.compareAtPrice,
      image: productDetails?.variants?.nodes[0]?.image?.src,
      productType: productDetails?.productType,
    });
    setCartValue(myCart);
  };
  console.log("Emails", user.attributes.email);
  const CREATE_CART = `mutation {
    cartCreate(
      input: {
        lines: [
          {
            quantity: ${quantity}
            merchandiseId: "${productDetails?.variants?.nodes[0]?.id}"
          }
        ],
        buyerIdentity: {
          email: "${user.attributes.email}",
        },
        attributes: {
          key: "cart_attribute",
          value: "This is a cart attribute"
        }
      }
    ) {
      cart {
        id
        createdAt
        updatedAt
        lines(first: 10) {
          edges {
            node {
              id
              merchandise {
                ... on ProductVariant {
                  id
                }
              }
            }
          }
        }
        attributes {
          key
          value
        }
      }
    }
  }`;

  const buyNow = () => {
    addToCartMutaion.mutate(CREATE_CART, {
      onSuccess: (responseData: any) => {
        if (responseData) {
          const cartId = responseData?.data?.data?.cartCreate?.cart?.id;
          const CHECK_OUT = `query checkoutURL {
            cart(id: "${cartId}") {
              checkoutUrl
            }
          }`;
          checkoutMutation.mutate(CHECK_OUT, {
            onSuccess: (responseData) => {
              if (responseData) {
                window.location.href =
                  responseData?.data?.data?.cart?.checkoutUrl;
              }
            },
          });
        }
      },
    });
  };

  const isAddedToCart = myCartValues.some(
    (el: any) => el?.variantId === productDetails?.variants?.nodes[0]?.id
  );

  return (
    <>
      <div id="content" className="createPost shopify-wrapper mb-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 post_feed notification_Box">
                {/* reward-header-header */}
                <div className="d-flex justify-content-between px-0">
                  <Link
                    className="d-flex mb-4 align-items-center arrow_row"
                    to="/shop"
                  >
                    <img src={images.leftBackArrow} alt="icon" height="18" />
                    <h3 className="fz20 mb-0 ml-3 poppinssemibold">Details</h3>
                  </Link>
                </div>
                {/* reward-header-close */}

                {/* product-details */}
                <div className="ItemDetailsRow position-relative">
                  <figure className="itemDetailcolLeft">
                    <Slider {...detilaSlider}>
                      {productImages.map((productImage: any, index: number) => {
                        return (
                          <div
                            className="detilaSlider_Item business-item"
                            key={`item${index}`}
                          >
                            <img
                              src={productImage?.node?.src}
                              className="img-fluid w-100 rounded"
                              alt={productImage?.node?.altText}
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </figure>

                  <div className="rightColDetails">
                    <div className="mb-3 d-flex align-self-center">
                      <h3 className="fz16 poppinsbold mb-0 product-price">
                        $ {productDetails?.variants?.nodes[0]?.price}
                      </h3>
                      <span className="h6 h5 fz15 priceLess mb-0 poppinsmedium pl-3">
                        $ {productDetails?.variants?.nodes[0]?.compareAtPrice}
                      </span>
                    </div>
                    <p className="fz16 poppinssemibold">
                      {productDetails?.title}
                    </p>

                    <ul className="item_Details pl-0 mb-0">
                      {productDetails?.productType && (
                        <li>
                          <figure className="iconLeftDetails">
                            <img src={images.menuIcon} alt="location" />
                          </figure>
                          <p className="fz14 poppinssemibold">
                            {productDetails?.productType}
                          </p>
                        </li>
                      )}
                    </ul>

                    {/* product-count-start */}
                    <div className="count-box">
                      <span
                        className="operatorcustom"
                        onClick={() =>
                          setQuantity((prevState) =>
                            prevState > 1 ? prevState - 1 : prevState
                          )
                        }
                      >
                        -
                      </span>
                      <span className="countData">{quantity}</span>
                      <span
                        className="operatorcustom"
                        onClick={() =>
                          setQuantity((prevState) => prevState + 1)
                        }
                      >
                        +
                      </span>
                    </div>
                    {/* product-count-close */}

                    <div className="flex button-group">
                      <button
                        type="button"
                        className="addbutton poppinssemibold"
                        onClick={() =>
                          isAddedToCart ? navigate("/my-cart") : addToCart()
                        }
                      >
                        {isAddedToCart ? "Go to Cart" : "Add to Cart"}
                      </button>
                      <button
                        type="button"
                        className="addbutton buybutton poppinssemibold"
                        onClick={buyNow}
                      >
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
                {/* product-details-close */}

                {/* product-details-item-start */}
                <div className="product-details-item-row">
                  <div
                    className="product-details-item-col"
                    dangerouslySetInnerHTML={{
                      __html: productDetails?.descriptionHtml,
                    }}
                  ></div>
                </div>
                {/* product-details-item-close */}

                {/* accordion-start */}
                {/* accordion-close */}

                {/* Images with text start */}
                <div className="img-box-row mt-4">
                  <div className="imgLeftContent">
                    <h3 className="fz22 poppinsmedium">Image with text</h3>
                    <p className="mb-0 fz14 poppinsregular">
                      Pair text with an image to focus on your chosen product,
                      collection, or blog post. Add details on availability,
                      style, or even provide a review.
                    </p>
                  </div>
                  <picture className="shopifyImgRightCol">
                    {/* placeholder-img */}
                    <img
                      src={images.shopifyImgplaceholder}
                      alt="shopifyImgplaceholder"
                    />
                    {/* placeholder-img */}

                    {/* product-img */}
                    {/* <img src={images.shopifyImgplaceholder} alt="shopifyImgplaceholder" width={'100%'} height={'100%'} /> */}
                    {/* product-img-close */}
                  </picture>
                </div>
                {/* Images with text close*/}

                {/* shipping-start */}
                <div className="shipping mt-4">
                  <h3 className="fz14 poppinsbold mb-3">Free shipping</h3>
                  <p className="fz14 poppinsregular mb-0">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
                {/* shipping-close */}

                {/* Hassle-Free Exchanges-start */}
                <div className="Hassle-Free Exchanges mt-4">
                  <h3 className="fz14 poppinsbold mb-3">
                    Hassle-Free Exchanges
                  </h3>
                  <p className="fz14 poppinsregular mb-0">
                    Pair text with an image to focus on your chosen product,
                    collection, or blog post. Add details on availability,
                    style, or even provide a review.
                  </p>
                </div>
                {/* shipping-close */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopifyDetails;
