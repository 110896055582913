import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_EVENT_REQUEST_TYPE } from "../Types/RequestTypes";

const editEvent = async (
  data: CREATE_EVENT_REQUEST_TYPE
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.editEvent,
    method: "POST",
    data: data,
  });
};

function useEditEventMutation() {
  return useMutation(editEvent, {
    onSuccess: (responseData) => {
      console.log("rrrrrrrresponse data",responseData)
    },
  });
}

export default useEditEventMutation;
