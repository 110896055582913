import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import useCreateSalePostMutation from "../../Hooks/useCreateSalePostMutation";
import "./CreateSaleDetails.css";
import { getRequiredRules } from "../../../../Utils/Validators";
import useSaleCategoriesQuery from "../../Hooks/useSaleCategoriesQuery";
import Loader from "src/Components/Shared/Loader/Loader";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GET_SALE_LIST_QUERY_KEY } from "../../Hooks/useSaleInfiniteQuery";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function CreateSaleDetails() {
  const [imageList, setImageList] = useState<any>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [deleteImgs, setDeleteImgs] = useState<Array<string>>([]);
  const createSalePostMutation = useCreateSalePostMutation();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<any>({
    defaultValues: {
      condition: "NEW",
    },
    mode: "onChange",
  });
  const { user } = useAuthValue();
  const navigate = useNavigate();
  const createSale = handleSubmit((values: any) => {
    if (imageUrls?.length === 0) {
      return SnackbarHandler.errorToast(strings.add_image);
    }
    createSalePostMutation?.mutate(
      {
        subId: user?.username,
        category_id: Number(values?.category),
        product_name: values?.title,
        description: values?.description,
        condition: values?.condition,
        sale_price: values?.price,
        images: imageList,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            reset();
            setImageUrls([]);
            setImageList([]);
            navigate("/sales");
            queryClient.refetchQueries(GET_SALE_LIST_QUERY_KEY("", undefined));
          }
        },
      }
    );
  });

  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast(strings.image_length_validation);
        return;
      }
    }
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: any) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const { data } = useSaleCategoriesQuery();
  const saleCategories = data?.data?.Items ?? [];

  return (
    <>
      {createSalePostMutation?.isLoading && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <Link
                to="/sales"
                className="border-0 mb-3 text-captilize left_Arrow"
              >
                <img src={images.leftBackArrow} alt="arrow" />
                <span className="fz16 mb-3">{strings.for_sale_free}</span>
              </Link>
              {/* create_post_Wrapper-start */}
              <div className="create_post_Wrapper">
                {/* add-categories */}
                <h3 className="fz16 text-captilize poppinsbold mb-3">
                  {strings.categories}
                </h3>
                <div className="categories_add_row">
                  <figure className="mb-0 leftAddPhotoBox">
                    <img src={images.leftAddPhoto} alt="icon" />
                  </figure>
                  <div className="right_addTextCol">
                    <p className="mb-0 poppinsmedium">{strings.sale_free}</p>
                  </div>
                </div>
                {/* add-categories-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* AddPhotoAndVideo-start */}
                <div className="AddPhotoAndVideo">
                  <h3 className="fz16 text-captilize poppinsbold mb-3">
                    {strings.add_photo}
                  </h3>
                  <p className="fz14">{strings.you_upload_up_files_in_jpg}</p>
                  {/* upload_photo_Box-start */}
                  <div className="upload_photo_Box">
                    <div className="file-button1">
                      <label
                        htmlFor="add_photo_Multiple"
                        className="button-upload"
                      >
                        <div className="Upload-text1">
                          <p>
                            <img
                              src={images.eventCamera}
                              alt="img"
                              width="23"
                              height="20"
                            />
                            <span className="pl-2 fz15">
                              {strings.add_photo}
                            </span>
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        name="photos"
                        id="add_photo_Multiple"
                        multiple={true}
                        accept=".jpg, .png, .jpeg, .gif"
                        onChange={(e: any) =>
                          fileSelectedHandler(e?.target?.files)
                        }
                      />
                    </div>
                  </div>
                  {/* upload_photo_Box-start */}
                  <div className="upload_Row d-flex pt-3">
                    {imageList?.map((imgUrl: any, index: number) => {
                      return (
                        <div
                          className="img_col position-relative"
                          key={`imgUrl${index}`}
                        >
                          <div
                            className="uploadImgBox w-100"
                            key={`imgUrl${index}`}
                          >
                            <img src={imgUrl} alt="img" />
                            <div className="on_hover_overlay"></div>
                            <div
                              className="remove_icon"
                              key={`imgUrl${index}`}
                              onClick={() =>
                                handleImageRemove(index, imgUrl.id)
                              }
                            >
                              <img src={images.removeIco} alt="icon" />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* AddPhotoAndVideo-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                <form onSubmit={createSale}>
                  {/* description-area-start */}
                  <div className="description pt-2">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.product_detail}
                    </h3>
                    <div className="form-group">
                      <input
                        className="form-controlCstm"
                        placeholder={strings.enter_product_name}
                        maxLength={30}
                        {...register(
                          "title",
                          getRequiredRules(
                            strings.product_name,
                            strings.enter_product_name
                          )
                        )}
                      />
                      {errors?.title?.message && (
                        <div className="text-danger">
                          {errors?.title?.message}
                        </div>
                      )}
                    </div>
                    <textarea
                      className="form-control"
                      placeholder={strings.write_a_description}
                      {...register(
                        "description",
                        getRequiredRules(
                          strings.description,
                          strings.description_is_required
                        )
                      )}
                    />
                    {errors?.description?.message && (
                      <div className="text-danger">
                        {errors?.description?.message}
                      </div>
                    )}
                  </div>
                  {/* description-area-close */}

                  {/*borderLine-start  */}
                  <div className="borderLine"></div>
                  {/*borderLine-close  */}

                  {/* =======sub_category===== */}
                  <div className="sub_category">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.sale_category}
                    </h3>
                    <select
                      className="form-controlCstm cstm_Right_Arrow"
                      {...register(
                        "category",
                        getRequiredRules(
                          strings.sale_category,
                          strings.sale_cat_reqd
                        )
                      )}
                    >
                      <option value="">{strings.select_sale_category}</option>

                      {saleCategories?.map((saleCat: any, index: number) => {
                        return (
                          <option key={`item${index}`} value={saleCat?.id}>
                            {saleCat?.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors?.category?.message && (
                      <div className="text-danger">
                        {errors?.category?.message}
                      </div>
                    )}
                  </div>
                  {/* ========sub_category=close */}

                  {/*borderLine-start  */}
                  <div className="borderLine"></div>
                  {/*borderLine-close  */}

                  {/* price-start==== */}
                  <div className="priceBox">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.price}
                    </h3>
                    <div className="price_Row">
                      <div className="leftCurrenyCol">
                        <span>{strings.dollar_sign}</span>
                      </div>
                      <div className="RightCurrenyCol">
                        <input
                          type="number"
                          className="form-controlCstm"
                          placeholder={strings.enter_product_price}
                          {...register(
                            "price",
                            getRequiredRules(
                              strings?.price,
                              strings?.selling_price_is_required
                            )
                          )}
                        />
                        {errors?.price?.message && (
                          <div className="text-danger">
                            {errors?.price?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* price-close==== */}

                  {/*borderLine-start  */}
                  <div className="borderLine"></div>
                  {/*borderLine-close  */}

                  {/* condtion-start==== */}
                  <div className="price">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.condition}
                    </h3>
                    <div className="form-row">
                      <div className="customRadiobtn">
                        <input
                          type="radio"
                          {...register("condition")}
                          id="new"
                          className="customRadioInput"
                          value="NEW"
                        />
                        <label htmlFor="new">{strings.new}</label>
                      </div>
                      <div className="customRadiobtn">
                        <input
                          type="radio"
                          {...register("condition")}
                          id="old"
                          className="customRadioInput"
                          value="Used"
                        />
                        <label htmlFor="old">{strings.used}</label>
                      </div>
                      <div className="customRadiobtn">
                        <input
                          type="radio"
                          {...register("condition")}
                          id="free"
                          className="customRadioInput"
                          value="FREE"
                        />
                        <label htmlFor="free">{strings.free}</label>
                      </div>
                    </div>
                  </div>
                  {/* price-close==== */}

                  {/* tipsBox-start==== */}
                  <div className="tipsBox mt-4 position-relative">
                    <h3 className="fz16 text-captilize poppinsbold mb-3">
                      {strings.tips_getting_good_answers}
                    </h3>
                    <ul className="pl-0">
                      <li>{strings.pricillas_a_perfect_nanny}</li>
                      <li>{strings.keep_your_question_short}</li>
                      <li>{strings.double_check_grammar_and_spelling}</li>
                    </ul>
                    <img src={images.bulbImg} alt="bulb" />
                  </div>

                  <button
                    type="submit"
                    className="posttbn mt-4"
                    // disabled={createSalePostMutation?.isLoading}
                  >
                    {strings.post} <img src={images.rightArrow} alt="icons" />
                  </button>
                </form>
              </div>
              {/* create_post_Wrapper-close */}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateSaleDetails;
