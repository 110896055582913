import React, { Fragment } from "react";
import images from "src/Utils/images";

function AboutContentEn() {
  return (
    <Fragment>
      <section className="aboutPageWrap">
        <div className="about_banner1">
          <div className="container position-relative">
            <div className="about-banner-box">
              <img src={images.aboutImg} alt="banner" />
            </div>
            <div className="row align-items-center">
              <div className="col-0 col-sm-12 col-xl-6">
              </div>
              <div className="col-12 col-sm-12 col-xl-5">
                <div className="col_selector text-xl-right right-content-banner-heading">
                  <h2 className="poppinssemibold mb-2 about-titlecstm">About</h2>
                  <p className="fz13 poppinsregular">
                    We believe by bringing neighbors together, we can cultivate
                    a kinder world where everyone has a neighborhood they can
                    rely on.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about_page pb-5 pt-5 mt-xl-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="col_selector">
                  <h3 className="fz18 poppinssemibold mb-4">Introduction</h3>
                  <p>
                    All Bloom is a social and e-commerce app that helps you stay
                    connected with your neighbours. As an alternative to
                    traditional forms of social media, All Bloom allows you to
                    build closer relationships with your neighbours and stay on
                    top of the latest news and deals in your community.
                  </p>
                  <p>
                    It makes it easier to get information about services (both
                    private and public) and businesses from sources you trust,
                    your neighbours in the same Daira or surrounding Dairas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export default AboutContentEn;
