import GroupDetails from "src/Modules/GroupModule/Components/GroupDetails/GroupDetails";
import GroupPostDetails from "src/Modules/GroupModule/Components/GroupPostDetails/GroupPostDetails";
import Layout from "src/Modules/Layout/Layout";

function GroupsDetailPage() {
  return (
    <>
      <Layout>
        <div id="content" className={"ltr-content-padding"}>
          <div className="container-fluid">
            <div className="row">
              <GroupPostDetails />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default GroupsDetailPage;
