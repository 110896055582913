import { Link } from "react-router-dom";
import images from "src/Utils/images";
import { SHOP_PRODUCT_ITEM } from "../../Types/ResponseType";
import "./ShopCardItem.css";

type SHOP_PRODUCT_ITEM_PROPS = {
  item: SHOP_PRODUCT_ITEM;
};
function ShopCardItem(props: SHOP_PRODUCT_ITEM_PROPS) {
  const { item } = props;
  const getId = (url: any) => {
    const segments = new URL(url).pathname.split("/");
    const last = segments.pop() || segments.pop();
    return last;
  };
  return (
    <>
      <Link className="saleCardItem" to={`/shop/${getId(item?.node?.id)}`}>
        <div className="col_selector">
          <div className="furniture_card">
            <figure className=" m-0  saleItem_Box shop-box-item">
              <img
                src={
                  item?.node?.featuredImage?.src
                    ? item?.node?.featuredImage?.src
                    : images.shopimage
                }
                className="img-fluid w-100"
                alt="img"
              />
              <figcaption className="px-3 mx-3">
                <div className="pt-3 likepara-row row justify-content-between ">
                  <p className="fz14 mb-0 poppinssemibold paraSaleItem product-parades">
                    {item?.node?.title}
                  </p>
                  {/* <h3 className="fz14 poppinsbold item_Price mb-0">
                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                  </h3> */}
                </div>
                <div className="pt-3  pb-3 pricepara-row row align-items-center">
                  <h4 className="fz20 mb-0 blueClrcstm poppinsregular paraSaleItem product-parades">
                    <span className="dollarIcon">$</span> {item?.node?.variants?.nodes[0]?.price}
                  </h4>
                  <span className="h5 fz16 priceLess mb-0 poppinsmedium">
                    P {item?.node?.variants.nodes[0]?.compareAtPrice}
                  </span>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ShopCardItem;
