import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { strings } from "src/Utils/Localization";
import useAddToCartMutation from "../../Hooks/useAddToCartMutation";
import useAddToCartValue from "../../Hooks/useAddToCartValue";
import MyCartItem from "../MyCartItem/MyCartItem";
import "./MyCart.css";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function MyCart() {
  const navigate = useNavigate();
  const { user } = useAuthValue();
  const myCart = useAddToCartValue();
  const addToCartMutaion = useAddToCartMutation();
  const lineItems = myCart.map((item) => {
    return {
      variantId: item?.variantId,
      quantity: item?.quantity,
    };
  });

  const CREATE_CART = `mutation {
    checkoutCreate(input: {
      lineItems: [
        ${lineItems.map((item) => {
          return `{
        variantId: "${item?.variantId}", quantity: ${item?.quantity}
    }`;
        })}
      ],
        email: "${user.attributes.email}",
    }) {
      checkout {
         id
         webUrl
         lineItems(first: 5) {
           edges {
             node {
               title
               quantity
             }
           }
         }
      }
    }
  }`;

  const buyNow = () => {
    addToCartMutaion.mutate(CREATE_CART, {
      onSuccess: (responseData) => {
        if (responseData) {
          // window.open(
          //   responseData?.data?.data?.checkoutCreate?.checkout?.webUrl
          // );
          window.location.href =
            responseData?.data?.data?.checkoutCreate?.checkout?.webUrl;
        }
      },
    });
  };
  const totalPrice: any =
    (myCart ?? []).length !== 0
      ? myCart
          .map((item: any) => Number(item?.price) * item?.quantity)
          .reduce((acc, cur) => acc + cur)
      : 0;

  return (
    <Fragment>
      <div id="content" className="mb-4 createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9 shop-right-col-box my_cartBx">
              <div className="messages pt-4 post_feed notification_Box">
                {/* reward-header-header */}
                <div className="reward-header-row">
                  <h3>{strings.my_cart}</h3>
                </div>

                <div>
                  {(myCart ?? []).length !== 0 ? (
                    myCart.map((item, index) => {
                      return <MyCartItem item={item} key={`cart-${index}`} />;
                    })
                  ) : (
                    <div className="cartEmptyDiv"> Cart is Empty </div>
                  )}
                </div>
                {/* Continue Shopping Button*/}
                <div className="continueBtnDiv">
                  <button
                    onClick={() => navigate("/shop")}
                    className="continueBtn"
                  >
                    Continue shopping
                  </button>
                </div>
              </div>
            </div>

            {totalPrice > 0 && (
              <div className="col-md-3 cart_right_sidebar">
                <div className="rightBoxDiv">
                  <p className="rightBoxTotal">Total Amount</p>
                  <p className="rightBoxValue">
                    <span className="pr-1"> $ </span>
                    {totalPrice.toFixed(2)}
                  </p>
                  <button onClick={buyNow} className="rightBoxBtn">
                    Buy Now
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default MyCart;
