import { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { fetcher } from "src/Utils/Helpers";
import EndPoints from "src/Constants/EndPoints";
import { useParams } from "react-router-dom";
import { getAuthValue } from "src/Hooks/Custom/useAuthValue";

export const GET_GROUP_POST_DETAILS_COMMENTS_KEY = (
  postId: number,
  groupId: number
) => ["getCommentsKey", postId, groupId];

const getGroupPostDetailsComments = async (
  postId: number,
  groupId: number
): Promise<AxiosResponse<any>> => {
  const { user } = getAuthValue();
  return fetcher({
    baseURL: `${EndPoints.comments}?subId=${user?.username}&postId=${postId}&groupId=${groupId}`,
    method: "GET",
  });
};
function useGroupPostDetailsCommentsQuery() {
  const params = useParams();
  console.log(params, "////detailsParams////");
  return useQuery(
    GET_GROUP_POST_DETAILS_COMMENTS_KEY(
      Number(params?.postId),
      Number(params?.id)
    ),
    () =>
      getGroupPostDetailsComments(Number(params?.postId), Number(params?.id))
  );
}

export default useGroupPostDetailsCommentsQuery;
