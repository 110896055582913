import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_GROUP_POST_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_GROUP_POST_LIST_KEY } from "./useGroupPostListInfiniteQuery";
import { useParams } from "react-router-dom";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useAddRewardPoints from "src/Modules/RewardsModule/Hooks/useAddRewardPoints";

const createPost = async (
  data: CREATE_GROUP_POST_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.createGroupPost,
    method: "POST",
    data: data,
  });
};

function useCreateGroupPostMutation() {
  const params = useParams();
  const rewardMutation = useAddRewardPoints();
  const { user } = useAuthValue();
  return useMutation(createPost, {
    onSuccess: (responseData) => {
      if (responseData) {
        console.log("DATA???", responseData);
        queryClient.refetchQueries(GET_GROUP_POST_LIST_KEY(Number(params?.id)));
        rewardMutation?.mutate({
          type: "POST",
          subId: user?.username,
          refId: String(responseData?.data?.id ?? ""),
          title: "Group Post",
        });
      }
    },
  });
}

export default useCreateGroupPostMutation;
