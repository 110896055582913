import { Link, useNavigate } from "react-router-dom";
import { openCreatePostModal } from "../GroupPostModal/GroupPostModal";
import { addGroupEvent } from "../GroupAddModal/GroupAddModal";
import LeaveGroupConfirmModal, {
  openLeaveGroupConfirmModal,
} from "../ConfirmGroupModals/LeaveGroupConfirmModal/LeaveGroupConfirmModal";
import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import useJoinGroupMutation from "../../Hooks/useJoinGroupMutation";
import { queryClient } from "src/Utils/ReactQueryConfig";
import { GET_GROUP_DETAILS_KEY } from "../../Hooks/useGroupDetailsQuery";
import { strings } from "src/Utils/Localization";
import images from "../../../../Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function GroupProfile(props: any) {
  const { groupDetailsData } = props;
  const navigate = useNavigate();

  const { user } = useAuthValue();

  const joinGroupMutation = useJoinGroupMutation();
  const handleJoinGroup = (e: any) => {
    e.preventDefault();
    return joinGroupMutation.mutate(
      {
        subId: user?.username,
        groupId: groupDetailsData?.id,
      },
      {
        onSuccess: (responseData) => {
          if (responseData) {
            queryClient.refetchQueries(
              GET_GROUP_DETAILS_KEY(groupDetailsData?.id)
            );
          }
        },
      }
    );
  };

  return (
    <>
      <div className="home_search_section position-relative shadow-none mb-4 post_feed p-0 group_detail_top">
        <img
          src={
            groupDetailsData?.group_icon
              ? groupDetailsData?.group_icon
              : images.groupPlaceholder
          }
          className="img-fluid w-100 object_fit"
          alt=""
          style={{ height: 250 }}
        />
        <Link to="/groups">
          <span
            className="member_back_btn cursor-pointer"
            onClick={() => navigate("/groups")}
          >
            <i
              className="fa fa-chevron-left text-white"
              style={{
                position: "relative",
                left: "12px",
                top: "11px",
              }}
              aria-hidden="true"
            ></i>
          </span>
        </Link>
        <div className="member-banner">
          <div className="d-flex justify-content-between align-items-center position-relative member-banner-details">
            <h4 className="fz16 mb-1 mb-0 poppinssemibold text-white">
              {groupDetailsData?.title} <br />
              <p className="fz10 poppinsregular text-light content-wrap">
                {groupDetailsData?.description}
              </p>
            </h4>
            {groupDetailsData?.is_private &&
            groupDetailsData?.is_requested &&
            !groupDetailsData?.is_member &&
            !groupDetailsData?.is_admin ? (
              <button className="btn custom_btn fz14 poppinsbold">
                {strings.requested}
              </button>
            ) : (
              <></>
            )}
            {!groupDetailsData?.is_requested &&
              !groupDetailsData?.is_member &&
              !groupDetailsData?.is_admin && (
                <button
                  className="btn custom_btn fz14 poppinsbold"
                  onClick={handleJoinGroup}
                  disabled={joinGroupMutation?.isLoading}
                >
                  <VisibilityToggle visible={joinGroupMutation?.isLoading}>
                    <span className="spinner-border spinner-border-sm"></span>
                  </VisibilityToggle>
                  {strings.join}
                </button>
              )}
            {groupDetailsData?.is_member || groupDetailsData?.is_admin ? (
              <button
                className="btn custom_btn fz14 poppinsbold"
                onClick={() => openCreatePostModal(null)}
              >
                {strings.post}
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* =====dropdown==== */}
        <VisibilityToggle
          visible={groupDetailsData?.is_member || groupDetailsData?.is_admin}
        >
          <div className="dropdown custom_DropdownRight">
            <span className="dropdown-toggle" data-toggle="dropdown">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </span>
            <div className="dropdown-menu dropdown-menu-right">
              {groupDetailsData?.is_admin && (
                <a
                  className="dropdown-item cursor-pointer"
                  href="/"
                  onClick={(e: any) => {
                    e.preventDefault();
                    addGroupEvent(groupDetailsData);
                  }}
                >
                  <img src={images.editGroup} alt="edit" />
                  {strings.edit_group}
                </a>
              )}

              {(groupDetailsData?.is_admin || groupDetailsData?.is_member) && (
                <a
                  className="dropdown-item cursor-pointer"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    openLeaveGroupConfirmModal();
                  }}
                >
                  <img src={images.leaveGroup} alt="edit" />
                  {strings.leave_group}
                </a>
              )}
            </div>
          </div>
        </VisibilityToggle>
        <LeaveGroupConfirmModal />
      </div>
    </>
  );
}
export default GroupProfile;
