import { useMemo, useState } from "react";
import moment from "moment";
import { Link, useLocation, useParams } from "react-router-dom";
import useHomePostDetailQuery, {
  GET_HOME_POST_DETAILS,
} from "../../Hooks/useHomePostDetailQuery";
import useLikePostMutation from "src/Modules/HomeModule/Hooks/useLikePostMutation";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import { extractComments } from "../../Hooks/useCommentsInfiniteQuery";
import CommentItem from "../CommentItem/CommentItem";
import ReportCommentModal from "../ReportCommentModal/ReportCommentModal";
import HomeFeedOptions from "../HomeFeedOptions/HomeFeedOptions";
import Loader from "src/Components/Shared/Loader/Loader";
import ConfirmDeletePostModal from "../ConfirmDeletePostModal/ConfirmDeletePostModal";
import ReportPostModal from "../ReportPostModal/ReportPostModal";
import PostCommentInput from "../PostCommentInput/PostCommentInput";
import useCommentsInfiniteQuery from "../../Hooks/useCommentsInfiniteQuery";
import Slider from "react-slick";
import images from "src/Utils/images";
import ReadMoreLessMore from "src/Components/Custom/ReadMoreLessMore/ReadMoreLessMore";
import ReactPlayer from "react-player";
import { queryClient } from "src/Utils/ReactQueryConfig";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function HomePostDetail() {
  const params = useParams();
  const { pathname } = useLocation();
  const { user } = useAuthValue();
  const GetHomePostDetailQuery = useHomePostDetailQuery();
  const getHomePostDetail = GetHomePostDetailQuery?.data?.data;

  const likeHomePostDetailMutation = useLikePostMutation();
  const likeHomePostDetail = async () => {
    if (user.username === getHomePostDetail?.data?.subId || !user.username) {
      SnackbarHandler.errorToast("You can't like your own post.");
      return;
    }

    likeHomePostDetailMutation?.mutate(
      {
        subId: user?.username,
        postId: getHomePostDetail?.data?.id,
      },
      {
        onSuccess: (responseData: any) => {
          if (responseData) {
            queryClient.setQueryData(
              GET_HOME_POST_DETAILS(Number(params?.id)),
              (oldData: any) => ({
                data: {
                  ...oldData?.data,
                  data: {
                    ...oldData?.data?.data,
                    is_like: responseData?.data?.is_like,
                    likes: responseData?.data?.likes,
                  },
                },
              })
            );
          }
        },
      }
    );
  };

  const commentsInfiniteQuery = useCommentsInfiniteQuery();
  // const getComments = getCommentsQuery?.data?.data?.Items ?? [];
  const { hasNextPage, refetch, fetchNextPage, isLoading } =
    commentsInfiniteQuery;
  const getComments = useMemo(
    () => extractComments(commentsInfiniteQuery?.data?.pages ?? []),
    [commentsInfiniteQuery?.data?.pages]
  );

  // slider
  var detilaSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {GetHomePostDetailQuery?.isLoading && <Loader />}
      <div className="comments-section">
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between px-0">
            <div className="d-flex align-items-center profile_top_Row">
              <div className="mr-3">
                <Link
                  to={
                    pathname === `/home/${params?.id}` ? "/home" : "/my-posts"
                  }
                >
                  <i
                    className={`fa  fz24 cursor-pointer p-2 fa-angle-left`}
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
              <div className="mb-0 mb-md-0 mr-3 user_Profile">
                <img
                  src={
                    getHomePostDetail?.data?.user_image !== ""
                      ? getHomePostDetail?.data?.user_image
                      : images.profileCamera
                  }
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt="icon"
                />
              </div>
              <div className="user_profileContent">
                <h3 className="fz16 poppinssemibold mb-0">
                  {getHomePostDetail?.data?.user_name}
                </h3>
                <p className="fz10 mb-0 poppinsregular text-secondary">
                  <span className="ml-0">
                    <i className="fa fa-circle fz6 mr-1" aria-hidden="true"></i>
                    {getHomePostDetail?.data?.id
                      ? moment(new Date(getHomePostDetail?.data?.id)).fromNow()
                      : ""}
                  </span>
                </p>
              </div>
            </div>
            <HomeFeedOptions item={getHomePostDetail?.data} />
          </div>

          {/* event-details-start */}
          {getHomePostDetail?.data?.type === "EVENT" && (
            <div className="post_content_wrap pt-4">
              <div className="slider_section1 custom_group_Slider mb-3 ">
                <Slider {...detilaSlider}>
                  {getHomePostDetail?.data?.images?.map(
                    (eventItem: any, index: number) => {
                      return (
                        <div
                          className="detilaSlider_Item"
                          key={`event${index}`}
                        >
                          <img
                            src={eventItem?.url}
                            className="img-fluid w-100 rounded"
                            alt={"hotelimg"}
                          />
                        </div>
                      );
                    }
                  )}
                </Slider>
              </div>
              <h4 className="fz18 poppinsbold mb-3 event_Heading">
                {getHomePostDetail?.data?.title}
              </h4>

              {/* Location-Row */}
              <div className="safeyheadingRow mb-2">
                <img src={images.pinIcon} alt="pinIcon" />
                <span className="safetyHeading grey_color fz14">
                  {getHomePostDetail?.data?.location}
                </span>
              </div>

              {/* Event-Date-Row */}
              <div className="safeyheadingRow">
                <img src={images.dategreyicon} alt="dategreyicon" />
                <span className="safetyHeading grey_color fz14">
                  1 Jan 2023, 10:00 am to 4:00 pm
                </span>
              </div>
            </div>
          )}
          {/* event-details-close */}

          {/* audio-video-details */}
          {(getHomePostDetail?.data?.type === "GENERAL" ||
            getHomePostDetail?.data?.type === "POST") && (
            <div className="post_content_wrap pt-4">
              <div className="slider_section1 custom_group_Slider mb-3">
                <Slider {...detilaSlider}>
                  {(getHomePostDetail?.data?.images ?? [])?.length !== 0 &&
                    getHomePostDetail?.data?.images?.map(
                      (audioVideoItem: any, index: number) => {
                        return (
                          <div
                            className="detilaSlider_Item"
                            key={`event${index}`}
                          >
                            <img
                              src={audioVideoItem?.url}
                              className="img-fluid w-100 rounded"
                              alt={"hotelimg"}
                            />
                          </div>
                        );
                      }
                    )}
                </Slider>
                {(getHomePostDetail?.data?.videos ?? []).length !== 0 &&
                  ReactPlayer.canPlay(
                    getHomePostDetail?.data?.videos[0]?.url
                  ) && (
                    <ReactPlayer
                      url={getHomePostDetail?.data?.videos[0]?.url}
                      width={"100%"}
                      height={"300px"}
                    />
                  )}
              </div>
              <div className="Safety-description">
                <p className="mb-0 fz14 poppinsregular post_message mt-3 post_description readmore">
                  <ReadMoreLessMore
                    text={getHomePostDetail?.data?.message}
                    showChar={250}
                  />
                </p>
              </div>
            </div>
          )}
          {/* audio-video-details */}

          {/* safety-details-start */}
          {getHomePostDetail?.data?.type === "SAFETY" && (
            <div className="post_content_wrap pt-4">
              <div className="slider_section1 custom_group_Slider mb-3">
                <Slider {...detilaSlider}>
                  {(getHomePostDetail?.data?.images ?? [])?.length !== 0 &&
                    getHomePostDetail?.data?.images?.map(
                      (audioVideoItem: any, index: number) => {
                        return (
                          <div
                            className="detilaSlider_Item"
                            key={`event${index}`}
                          >
                            <img
                              src={audioVideoItem?.url}
                              className="img-fluid w-100 rounded"
                              alt={"hotelimg"}
                            />
                          </div>
                        );
                      }
                    )}
                </Slider>
              </div>
              {/* Safety-Title */}
              <div className="safeyheadingRow">
                <img
                  src={images.safetyIcon}
                  alt="safety"
                  width={"20px"}
                  height={"18px"}
                />
                <span className="safetyHeading fz14 poppinsmedium">
                  Post about safety
                </span>
              </div>
              {/* Safety-Title-close */}

              {/* description */}
              <div className="Safety-description">
                <p className="mb-0 fz14 poppinsregular post_message mt-3 post_description readmore">
                  <ReadMoreLessMore
                    text={getHomePostDetail?.data?.message}
                    showChar={250}
                  />
                </p>
              </div>
              {/* description-close */}
            </div>
          )}
          {/* saefty-close */}

          {/*  Like and comment-section-start*/}
          <div className="post_content_wrap pt-4">
            <div className="px-0">
              <hr />
            </div>
            <div className="post_list px-0">
              <ul className="list-unstyled d-flex mb-0">
                <li>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      likeHomePostDetail();
                    }}
                  >
                    <i
                      className={`fa ${
                        getHomePostDetail?.data?.is_like
                          ? "fa-heart like-post "
                          : "fa-heart-o base_color mr-3"
                      }`}
                      aria-hidden="true"
                    ></i>
                    {getHomePostDetail?.data?.likes ?? 0}
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i
                      className="fa fa-comment-o base_color mr-3"
                      aria-hidden="true"
                    ></i>
                    {getHomePostDetail?.data?.total_comments}
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-5 px-0">
              <div className="mb-4">
                <h3 className={`fz16 mb-0`}>Comments</h3>
              </div>
              <div className="custom_comments_Box">
                {getComments?.map((item: any, i: number) => {
                  return <CommentItem item={item} key={i} />;
                })}
              </div>

              <PostCommentInput postId={getHomePostDetail?.data?.id} />
            </div>
          </div>
          {/*  Like and comment-section-close*/}
        </div>

        <ReportCommentModal />
        <ConfirmDeletePostModal />
        <ReportPostModal />
      </div>
    </>
  );
}

export default HomePostDetail;
