import React from "react";
import Loader from "src/Components/Shared/Loader/Loader";
import Layout from "src/Modules/Layout/Layout";
import EditSaleForm from "src/Modules/SaleModule/Components/EditSaleForm/EditSaleForm";
import useSaleDetailQuery from "src/Modules/SaleModule/Hooks/useSaleDetailQuery";

function EditSaleDetailPage() {
  const { data, isLoading } = useSaleDetailQuery();
  const saleDetails = data?.data?.data ?? {};
  return (
    <>
      {isLoading && <Loader />}
      <Layout>
        <EditSaleForm saleDetails={saleDetails} />
      </Layout>
    </>
  );
}

export default EditSaleDetailPage;
