import { strings } from "src/Utils/Localization";
import AcceptGroupInviteConfirmModal from "../ConfirmGroupModals/AcceptGroupInviteConfirmModal/AcceptGroupInviteConfirmModal";
import GroupAdminRemoveConfirmModal from "../ConfirmGroupModals/GroupAdminRemoveConfirmModal/GroupAdminRemoveConfirmModal";
import GroupMakeAdminconfirmModal from "../ConfirmGroupModals/GroupMakeAdminConfirmModal/GroupMakeAdminconfirmModal";
import GroupRemoveConfirmModal from "../ConfirmGroupModals/GroupRemoveConfirmModal/GroupRemoveConfirmModal";
import RejectGroupInviteConfirmModal from "../ConfirmGroupModals/RejectGroupInviteConfirmModal/RejectGroupInviteConfirmModal";
import GroupAdminMembers from "../GroupAdminMembers/GroupAdminMembers";
import GroupMemberList from "../GroupMemberList/GroupMemberList";
import GroupRequests from "../GroupRequests/GroupRequests";

function GroupMembers(props: any) {
  const { groupDetailsData } = props;
  return (
    <>
      <div
        className="col_selector bg-white group_right"
        style={{ borderRadius: "9px" }}
      >
        <div className="messages members pt-2 d-flex justify-content-between pr-0">
          {/* <VisibilityToggle
            visible={groupDetailsData?.is_member || groupDetailsData?.is_admin}
          > */}
          <div className="d-flex justify-content-between mb-4 align-items-center">
            <h3 className="fz16 mb-0">
              {groupDetailsData?.total_members}
          {strings.members}
            </h3>
          </div>
        </div>
        <GroupAdminMembers isAdmin={groupDetailsData?.is_admin} />
        {/* <VisibilityToggle
          visible={groupInvites?.length !== 0 && groupAdmins?.is_admin}
        > */}
        <GroupRequests />
        {/* </VisibilityToggle> */}
        {/* <VisibilityToggle visible={groupMemberList.length !== 0}> */}

        <GroupMemberList isAdmin={groupDetailsData?.is_admin} />

        {/* </VisibilityToggle> */}
      </div>

      <GroupRemoveConfirmModal />
      <GroupAdminRemoveConfirmModal />
      <GroupMakeAdminconfirmModal />
      <AcceptGroupInviteConfirmModal />
      <RejectGroupInviteConfirmModal />
    </>
  );
}
export default GroupMembers;
