import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { convertImagesToBase64 } from "src/Utils/Helpers";
import SnackbarHandler from "src/Utils/SnackbarHandler";
import "./AddAudioVideo.css";
import { getRequiredRules } from "../../../../Utils/Validators";
import useCreateGeneralPostMutation from "../../Hooks/useCreateGeneralPostMutation";
import Loader from "src/Components/Shared/Loader/Loader";
import { strings } from "src/Utils/Localization";
import images from "src/Utils/images";
import useAuthValue from "src/Hooks/Custom/useAuthValue";

function AddAudioVideo() {
  const { user } = useAuthValue();
  const navigate = useNavigate();
  const [imageList, setImageList] = useState<any>([]);
  const [imageUrls, setImageUrls] = useState<any>([]);
  const [deleteImgs, setDeleteImgs] = useState<Array<string>>([]);
  const [fileType, setFileType] = useState<"PHOTOS" | "VIDEOS">("PHOTOS");
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      message: "",
      video: "",
    },
    mode: "onChange",
  });
  const createGeneralPostMutation = useCreateGeneralPostMutation();
  const createGeneralPostApi = handleSubmit((values: any) => {
    console.log("vvvvvvvvvvvvvvvv", values);
    if (fileType === "PHOTOS") {
      if (imageList?.length === 0) {
        return SnackbarHandler.errorToast("Please Select Image");
      }
    }
    const postReq =
      fileType === "VIDEOS"
        ? {
            subId: user?.username,
            message: values?.message,
            type: fileType,
            video: [values?.video],
          }
        : {
            subId: user?.username,
            message: values?.message,
            type: fileType,
            images: imageList,
          };
    createGeneralPostMutation?.mutate(postReq, {
      onSuccess: () => {
        navigate("/home");
        SnackbarHandler.successToast("Post Created Successfully");
      },
    });
  });
  const fileSelectedHandler = async (imgs: any) => {
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    const MAX_SIZE = 2;
    for (const image of imgs) {
      if (imgsArr.length < 5) {
        if (image.size / 1024 / 1024 > MAX_SIZE) {
          SnackbarHandler.errorToast(
            `${image.name} image size is more than ${MAX_SIZE}MB`
          );
        } else {
          const base64 = await convertImagesToBase64(image);
          imgsArr.push(base64);
          urlsArr.push({ url: URL.createObjectURL(image) });
        }
      } else {
        SnackbarHandler.errorToast(strings.image_length_validation);
        return;
      }
    }
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  const handleImageRemove = (index: number, id: any) => {
    if (id) {
      const dltImg = [...deleteImgs];
      dltImg.push(id);
      setDeleteImgs(dltImg);
    }
    const imgsArr = [...imageList];
    const urlsArr = [...imageUrls];
    urlsArr.splice(index, 1);
    imgsArr.splice(index, 1);
    setImageList(imgsArr);
    setImageUrls(urlsArr);
  };
  return (
    <>
      {createGeneralPostMutation?.isLoading && <Loader />}
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <button
                type="button"
                className="border-0 mb-3 text-captilize left_Arrow"
                onClick={() => navigate("/home")}
              >
                <img src={images.leftBackArrow} alt="arrow" />
                <span className="fz16 mb-3">{strings.create_general_post}</span>
              </button>
              {/* create_post_Wrapper-start */}
              <div className="create_post_Wrapper">
                {/* add-categories */}
                {/* add-categories */}
                <h3 className="fz16 text-captilize poppinsbold mb-3">
                  {strings.categories}
                </h3>
                <div className="categories_add_row">
                  <figure className="mb-0 leftAddPhotoBox">
                    <img src={images.leftAddPhoto} alt="icon" />
                  </figure>
                  <div className="right_addTextCol">
                    <p className="mb-0 poppinsmedium">
                      {strings.add_photo_and_video}
                    </p>
                  </div>
                </div>
                {/* add-categories-close */}
                {/* add-categories-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* AddPhotoAndVideo-start */}
                <div className="AddPhotoAndVideo">
                  <h3 className="fz16 text-captilize poppinsbold">
                    {strings.add_photo_and_video}
                  </h3>

                  {/* radio-input-start */}
                  <div className="radio-wrapper Photo-Video-Row mb-2">
                    <div className="radio-col-AudioVideo customRadioBtn">
                      <input
                        type="radio"
                        id="is_photo"
                        name="radio-group"
                        value="PHOTOS"
                        checked={fileType === "PHOTOS"}
                        onChange={(e: any) => setFileType(e.target.value)}
                      />
                      <label htmlFor="is_photo">{strings.photo}</label>
                    </div>
                    <div className="radio-col-AudioVideo customRadioBtn">
                      <input
                        type="radio"
                        id="is_video"
                        name="radio-group"
                        value="VIDEOS"
                        checked={fileType === "VIDEOS"}
                        onChange={(e: any) => setFileType(e.target.value)}
                      />
                      <label htmlFor="is_video">{strings.video}</label>
                    </div>
                  </div>
                  {/* radio-input-close */}

                  {/* video-field-start */}

                  {fileType === "PHOTOS" && (
                    <div className="photo_box_wrapper">
                      <p className="fz14">
                        {strings.you_upload_up_files_in_jpg}
                      </p>

                      {/* upload_photo_Box-start */}
                      <div className="upload_photo_Box">
                        <div className="file-button1">
                          <label
                            htmlFor="add_photo_Multiple"
                            className="button-upload"
                          >
                            <div className="Upload-text1">
                              <p>
                                <img
                                  src={images.eventCamera}
                                  alt="img"
                                  width="23"
                                  height="20"
                                />
                                <span className="pl-2 fz15">
                                  {strings.upload}
                                </span>
                              </p>
                            </div>
                          </label>
                          <input
                            type="file"
                            name="photos"
                            id="add_photo_Multiple"
                            accept="image/*"
                            multiple={true}
                            onChange={(e) =>
                              fileSelectedHandler(e.target.files)
                            }
                          />
                        </div>
                        <div className="upload_Row d-flex pt-3">
                          {(imageUrls ?? [])?.map((img: any, i: any) => {
                            return (
                              <div
                                key={`img${i}`}
                                className="img_col position-relative"
                              >
                                <div className="uploadImgBox w-100">
                                  <img
                                    key={`img${i}`}
                                    src={img?.url}
                                    alt="img"
                                  />
                                  <div className="on_hover_overlay"></div>
                                  <div
                                    className="remove_icon"
                                    key={`img${i}`}
                                    onClick={() => handleImageRemove(i, img.id)}
                                  >
                                    <img src={images.removeIco} alt="icon" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* upload_photo_Box-start */}
                    </div>
                  )}
                </div>
                {/* AddPhotoAndVideo-close */}

                {/*borderLine-start  */}
                <div className="borderLine"></div>
                {/*borderLine-close  */}

                {/* description-area-start */}
                <form onSubmit={createGeneralPostApi}>
                  {fileType === "VIDEOS" && (
                    <div className="video_url_field pt-2 mb-3">
                      <h3 className="fz16 text-captilize poppinsbold">
                        {strings.add_video_url}
                      </h3>
                      <input
                        type="url"
                        placeholder={strings.enter_vedio_url}
                        className="form-control-Cstm"
                        {...register(
                          "video",
                          getRequiredRules(strings.url, strings.video_url_reqd)
                        )}
                      />
                      {errors?.video?.message && (
                        <div className="text-danger">
                          {errors?.video?.message}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="description pt-2">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.add_description}
                    </h3>
                    <textarea
                      className="form-control"
                      placeholder={strings.write_a_description}
                      {...register(
                        "message",
                        getRequiredRules(
                          strings.description,
                          strings.description_is_required
                        )
                      )}
                    />
                    {errors?.message?.message && (
                      <div className="text-danger">
                        {errors?.message?.message}
                      </div>
                    )}
                  </div>

                  {/* description-area-close */}

                  {/* tipsBox-start==== */}

                  <div className="tipsBox mt-4 position-relative">
                    <h3 className="fz16 text-captilize poppinsbold">
                      {strings.tips_getting_good_answers}
                    </h3>
                    <ul className="pl-0">
                      <li>{strings.pricillas_a_perfect_nanny}</li>
                      <li>{strings.keep_your_question_short}</li>
                      <li>{strings.double_check_grammar_and_spelling}</li>
                    </ul>
                    <img src={images.bulbImg} alt="bulb" />
                  </div>

                  <button
                    type="submit"
                    className="posttbn mt-4"
                    disabled={createGeneralPostMutation?.isLoading}
                  >
                    {strings.post} <img src={images.rightArrow} alt="icons" />
                  </button>
                </form>
              </div>
              {/* create_post_Wrapper-close */}
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAudioVideo;
