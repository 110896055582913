import VisibilityToggle from "src/Components/Shared/VisibilityToggle/VisibilityToggle";
import { strings } from "src/Utils/Localization";
import useGroupRequestsQuery from "../../Hooks/useGroupRequestsQuery";
import { openAcceptGroupInviteModal } from "../ConfirmGroupModals/AcceptGroupInviteConfirmModal/AcceptGroupInviteConfirmModal";
import { openRejectGroupInviteModal } from "../ConfirmGroupModals/RejectGroupInviteConfirmModal/RejectGroupInviteConfirmModal";
import images from "../../../../Utils/images";

function GroupRequests() {
  const { data } = useGroupRequestsQuery();
  const groupRequests = data?.data?.Items ?? [];
  return (
    <>
      {groupRequests?.length !== 0 && (
        <div style={{ color: "gray", marginBottom: "10px" }}>Invites</div>
      )}
      {groupRequests?.map((_i: any, i: number) => {
        return (
          <div key={i} className="">
            <div
              className={`d-flex profileBox_Row align-items-center position-relative pl-0`}
            >
              <div className={`message_user_icon message_user_icon_posLTR`}>
                <img
                  src={
                    _i?.user_image !== "" ? _i?.user_image : images.profileIco
                  }
                  className="rounded-circle object_fit"
                  width="40"
                  height="40"
                  alt=""
                />
              </div>
              <h4 className={`fz16 mb-1 pl-3 mb-0 text-break`}>
                {_i?.user_name.length > 25
                  ? _i?.user_name.substr(0, 20) + "..."
                  : _i?.user_name}{" "}
                <br />
                <span className="fz10 poppinsregular text-secondary"></span>
              </h4>
              {/* <VisibilityToggle
                  visible={user.username === groupDetailsData?.subId}
                > */}
              <div className="slider_down_arrow position-relative ml-auto pr-2">
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                <div className={`user_actions user_actions_posLTR`}>
                  <ul className={`list-unstyled text-left mb-0 poppinsbold`}>
                    <VisibilityToggle
                      visible={_i?.is_requested && !_i?.is_member}
                    >
                      <li>
                        <a
                          className="py-4"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            openAcceptGroupInviteModal(_i?.subId);
                          }}
                        >
                          <i
                            className="fa fa-thumbs-up mr-4 fz18"
                            aria-hidden="true"
                          ></i>
                          {strings.accept}
                        </a>
                      </li>
                    </VisibilityToggle>
                    <VisibilityToggle
                      visible={_i?.is_requested && !_i?.is_member}
                    >
                      <li>
                        <a
                          className="py-4"
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            openRejectGroupInviteModal(_i?.subId);
                          }}
                        >
                          <i
                            className="fa fa-thumbs-down mr-4 fz18"
                            aria-hidden="true"
                          ></i>
                          {strings.reject}
                        </a>
                      </li>
                    </VisibilityToggle>
                  </ul>
                </div>
              </div>
              {/* </VisibilityToggle> */}
            </div>
            <hr />
          </div>
        );
      })}
    </>
  );
}
export default GroupRequests;
