import Layout from "src/Modules/Layout/Layout";
import SafetyPost from "src/Modules/SafetyModule/Components/SafetyPost/SafetyPost";

function Safety() {
  return (
    <Layout>
      <SafetyPost />
    </Layout>
  );
}

export default Safety;
