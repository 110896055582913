import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { CREATE_GROUP_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { GET_GROUP_DETAILS_KEY } from "./useGroupDetailsQuery";
import { useParams } from "react-router-dom";

const updateGroup = async (
  data: CREATE_GROUP_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.updateGroup,
    method: "POST",
    data: data,
  });
};

function useUpdateGroupMutation() {
  const params = useParams();
  return useMutation(updateGroup, {
    onSuccess: (responseData) => {
      if (responseData) {
        queryClient.refetchQueries(GET_GROUP_DETAILS_KEY(Number(params?.id)));
      }
    },
  });
}

export default useUpdateGroupMutation;
