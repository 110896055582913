import { useEffect, useMemo, useState } from "react";
import "./ParticipantsList.css";
import ParticipantItem from "../ParticipantItem/ParticipantItem";
import useChatValue, {
  getChatValue,
  setChatValue,
} from "../../Hooks/useChatValue";
import useAuthValue from "src/Hooks/Custom/useAuthValue";
import useChatEmit from "../../Hooks/useChatEmit";
import { EVENTS } from "../../Hooks/useChatListeners";
import { openContactModal } from "../ContactsModal/ContactsModal";

function ParticipantsList() {
  const [search, setSearch] = useState<string>("");
  const { emitEventWS } = useChatEmit();
  const { user } = useAuthValue();

  useEffect(() => {
    (() => {
      emitEventWS(
        EVENTS.CHAT_LIST,
        {
          subId: user?.username,
        },
        (responseData) => {
          const chatBody = JSON.parse(responseData?.body);
          setChatValue({
            ...getChatValue(),
            participantList: chatBody?.data,
          });
        }
      );
    })();
  }, [emitEventWS, user?.username]);

  const { participantList } = useChatValue();
  // console.log("CHAT LIST///", participantList);

  const filterList = useMemo(
    () =>
      (participantList ?? []).filter((item: any) =>
        item?.senderId === user?.username
          ? item?.receiverName?.toLowerCase().includes(search)
          : item?.senderName?.toLowerCase().includes(search)
      ),
    [participantList, search, user?.username]
  );

  return (
    <>
      <div id="content" className="createPost">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-9">
              <div className="messages pt-4 post_feed notification_Box">
                <div className="message-header-row mb-3">
                  <h2 className="mb-0 fz20 poppinssemibold message-heading notification_heading">
                    Messages
                  </h2>
                  <div className="message-right-header">
                    <div className="inputboxcustom">
                      <input
                        className="form-control fz12 poppinsregular custom_input pr-5"
                        type="text"
                        placeholder="search"
                        value={search}
                        onChange={(e: any) => setSearch(e?.target?.value)}
                      />
                      <i className="fa fa-search fa-search-ltr"></i>
                    </div>
                    <button className="contact-btn" onClick={openContactModal}>
                      Contacts
                    </button>
                  </div>
                </div>
                <ul className="message_datalist">
                  {(filterList ?? []).length !== 0
                    ? filterList.map((item: any, index: number) => {
                        return (
                          <ParticipantItem item={item} key={`item${index}`} />
                        );
                      })
                    : "No Data Found"}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
      {/* <ContactsModal /> */}
    </>
  );
}

export default ParticipantsList;
