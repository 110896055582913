import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { SAVE_DEVICE_DETAILS } from "../Types/RequestTypes";

const saveDeviceToken = async (
  data: SAVE_DEVICE_DETAILS
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.saveDeviceToken,
    method: "POST",
    data: data,
  });
};

function useDeviceTokenMutation() {
  return useMutation(saveDeviceToken);
}
export default useDeviceTokenMutation;
