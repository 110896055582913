import useObervableValue from "src/Hooks/Custom/useObservableValue";
import chat$ from "../Observables/chat$";
import { CHAT_STATE } from "../Types/CommonTypes";

function useChatValue() {
  return useObervableValue(chat$);
}

export const setChatValue = (value: CHAT_STATE) => {
  chat$.next(value);
};

export const getChatValue = () => {
  return chat$.getValue();
};

export default useChatValue;
