import { AxiosResponse } from "axios";
import { useMutation } from "react-query";
import EndPoints from "src/Constants/EndPoints";
import { fetcher } from "src/Utils/Helpers";
import { MAKE_ADMIN_REQUEST } from "../Types/RequestTypes";
import { queryClient } from "../../../Utils/ReactQueryConfig";
import { useParams } from "react-router-dom";
import { GET_GROUP_MEMBERS_KEY } from "./useGroupMembersQuery";
import { GET_GROUP_DETAILS_KEY } from "./useGroupDetailsQuery";
import { GET_GROUP_ADMIN_LIST_KEY } from "./useGroupAdminMembersQuery";

const removeAdmin = async (
  data: MAKE_ADMIN_REQUEST
): Promise<AxiosResponse<any>> => {
  return fetcher({
    url: EndPoints.removeAdmin,
    method: "POST",
    data: data,
  });
};

function useRemoveAdminMutation() {
  const params = useParams();
  return useMutation(removeAdmin, {
    onSuccess: (responseData) => {
      queryClient.refetchQueries(GET_GROUP_MEMBERS_KEY(params?.id));
      queryClient.refetchQueries(GET_GROUP_DETAILS_KEY(Number(params?.id)));
      queryClient.refetchQueries(GET_GROUP_ADMIN_LIST_KEY(params?.id));
    },
  });
}

export default useRemoveAdminMutation;
