import { Link } from "react-router-dom";

function BusinessItem(props: any) {
  const { item } = props;
  const { images, title } = item;
  const imgUrl = images.length ? images[0].url : "";

  return (
    <div className="saleCardItem cursor-pointer">
      <Link to={`/business/${item?.id}`}>
        <div className="furniture_card">
          <img src={imgUrl} className="img-fluid w-100" alt="" />
          <p className="fz15 mb-0 poppinssemibold  pt-3 px-2 pb-3">{title}</p>
        </div>
      </Link>
    </div>
  );
}

export default BusinessItem;
